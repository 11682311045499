import { Document, Page } from '@react-pdf/renderer';

const ReportPageWrapper = ({ children, pageStyles }) => (
  <Document>
    <Page size='A4' style={pageStyles}>
      {children}
    </Page>
  </Document>
);

export default ReportPageWrapper;
