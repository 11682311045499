import React, { Component, Fragment } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CSVLink } from 'react-csv';
import { useTable, useSortBy } from 'react-table';

import AddTaskBox from './AddTaskBox';

import { BiReset } from 'react-icons/bi';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import { ImPencil, ImPrinter, ImCheckboxChecked } from 'react-icons/im';
import { MdDelete } from 'react-icons/md';

const Table = ({ columns, data }) => {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);
  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              return (
                <th {...column.getHeaderProps()}>
                  <span {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    {column.isSorted ? (
                      column.isSortedAsc ? (
                        <BsArrowUp id='filterArrow' alt='Ascend' />
                      ) : (
                        <BsArrowDown id='filterArrow' alt='Descend' />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          let color = { background: 'white' };
          if (row.original.isComplete) color = { background: '#99ff99' };
          return (
            <tr {...row.getRowProps()} style={color}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
/* selectTaskUser select drop down { text: fullname, value: fullName } */
class TaskTable extends Component {
  constructor() {
    super();
    this.state = {
      taskName: '', // {String} name of task
      description: '', // {String} description of task
      assignedUser: '', // {String} name of assigned User
      actionType: 'CREATE_TASK', // {String} CREATE_TASK or EDIT_TASK action dispatcher
      // to determine whether to populate the input fields
      index: 0, // index of the current task being edited
      planUserList: [], // array of Objects {id, fullName}
      assignedRC: '', // {String} (empty str if no assigned Roll Call) assigned Roll Call to the task
      inputText: null, // {label: string, value: string}
      uCN: ''
    };
    this.populateTaskBox = this.populateTaskBox.bind(this);
    this.handleTaskAction = this.handleTaskAction.bind(this);
    this.handleTaskNameChange = this.handleTaskNameChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleAssignedUserChange = this.handleAssignedUserChange.bind(this);
    this.handleAssignedRollCallChange =
      this.handleAssignedRollCallChange.bind(this);
    this.showTaskModal = this.showTaskModal.bind(this);
    this.hideTaskModal = this.hideTaskModal.bind(this);
    this.hideTaskDetailsModal = this.hideTaskDetailsModal.bind(this);
    this.handleEditTemplateName = this.handleEditTemplateName.bind(this);
    this.editTemplateName = this.editTemplateName.bind(this);
    this.hideEditTemplateNameBox = this.hideEditTemplateNameBox.bind(this);
    this.showEditTemplateNameBox = this.showEditTemplateNameBox.bind(this);
  }
  handleTaskAction() {
    if (this.state.taskName.length === 0) {
      alert('Please Enter a Valid Task Name');
    } else {
      let UID = '';
      if (this.state.assignedUser !== '') {
        // assume the assigned user was selected
        let idx = this.state.planUserList.findIndex(
          (user) => user.fullName === this.state.assignedUser
        );
        if (idx !== -1) {
          UID = this.state.planUserList[idx].id;
        }
      }

      if (this.state.actionType === 'EDIT_TASK') {
        // assume editing a task
        this.props.onEditTask({
          taskName: this.state.taskName, // string
          description: this.state.description, // string
          assignedUser: UID,
          assignedRC: this.state.assignedRC,
          index: this.state.index,
          subPlanPager: this.props.subPlanPager
        });
      } else if (this.state.actionType === 'CREATE_TASK') {
        // assume creating a new task
        this.props.onAddTask({
          taskName: this.state.taskName,
          description: this.state.description,
          assignedUser: UID,
          assignedRC: this.state.assignedRC,
          subPlanPager: this.props.subPlanPager
        });
      }
      this.hideTaskModal();
    }
  }
  handleEditTemplateName(event) {
    this.setState({ templateName: event.target.value });
  }
  editTemplateName() {
    let templateName = this.state.templateName;
    if (templateName === '') {
      alert('Please set a Template Name');
    } else {
      this.props.editTemplateName({
        rowID: this.props.planRowID,
        id: this.props.planID,
        templateName: templateName
      });
      console.log('planID: ' + this.props.planID);
      console.log('new name: ' + templateName);
      this.hideEditTemplateNameBox();
    }
  }
  // handle the user selection of the assignedUser
  handleTaskNameChange(event) {
    this.setState({ taskName: event.target.value });
  }
  handleDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }
  handleAssignedUserChange(event) {
    this.setState({ assignedUser: event.target.value });
  }
  handleAssignedRollCallChange(event) {
    this.setState({ assignedRC: event.target.value });
  }
  // Add users to the dropdown menu for the task - depreciated
  addUsersToSelectOptions() {
    // document.getElementById('editSelectUserForTask').innerHTML = ''
    // var selectTaskUser = document.getElementById('editSelectUserForTask')
    // let defOpt = { text: 'Select User', value: '' }
    // selectTaskUser.options.add( new Option(defOpt.text, defOpt.value, defOpt.selected))
    // if (this.props.selectedUsers !== undefined && this.props.selectedUsers !== null) {
    // 	for (let i = 0; i < this.props.selectedUsers.length; i++) {
    // 		let user = this.props.selectedUsers[i]
    // 		let UID = user.id;
    // 		user.get().then((query) => {
    // 			let data = query.data()
    // 			let fullName = data.firstName + ' ' + data.lastName;
    // 			data.id = UID
    // 			data.fullName = fullName
    // 			// update state
    // 			this.state.planUserList.push(data)
    // 			// value of each user is their UID
    // 			// in order to get the UserRef when adding the task
    // 			let currOpt = { text: fullName, value: fullName }
    // 			selectTaskUser.options.add( new Option(currOpt.text, currOpt.value, currOpt.selected) )
    // 		})
    // 	}
    // }
  }
  // on edit Task Box, populate task popup
  populateTaskBox(tdata, idx, info = '') {
    // console.log("Populate task: "+ idx);
    // 	console.log(tdata);
    // 		console.log("Populate task: "+ info );
    if (info === 'showTaskDetailsDialog') {
      // console.log("if")
      this.setState({
        actionType: '',
        taskName: tdata.name,
        description: tdata.description,
        assignedUser: tdata.taskUser,
        index: idx,
        planUserList: this.state.planUserList
      });
      const addTaskDialog = document.getElementById('addTaskDetailsDialog');
      // addTaskDialog.style.left = (this.props.toggleMenu) ? '130px' : '330px'
      addTaskDialog.showModal();
    } else {
      // console.log("else")
      var selectTaskUser = document.getElementById('editSelectUserForTask');
      if ((selectTaskUser !== null) & (selectTaskUser !== undefined)) {
        document.getElementById('editSelectUserForTask').innerHTML = '';

        const defOpt = { text: 'Select User', value: '' };
        selectTaskUser.options.add(
          new Option(defOpt.text, defOpt.value, defOpt.selected)
        );
      }
      if (this.props.selectedUsers === undefined) {
        // console.log("else if")
        // console.log("[populateTaskBox]error getting the selected Users props")
        this.setState({
          actionType: 'EDIT_TASK',
          taskName: tdata.name,
          description: tdata.description,
          assignedUser: tdata.taskUser,
          index: idx,
          planUserList: this.state.planUserList
        });
        const addTaskDialog = document.getElementById('addTaskDialog');
        // addTaskDialog.style.left = (this.props.toggleMenu) ? '130px' : '330px'
        addTaskDialog.showModal();
      } else {
        // console.log("else if else")
        for (let i = 0; i < this.props.selectedUsers.length; i++) {
          let user = this.props.selectedUsers[i];
          let UID = user.id;
          user.get().then((query) => {
            let data = query.data();
            let fullName = data.firstName + ' ' + data.lastName;
            data.id = UID;
            data.fullName = fullName;
            // update state
            this.state.planUserList.push(data);
            // value of each user is their UID
            // in order to get the UserRef when adding the task
            let currOpt = { text: fullName, value: fullName };
            selectTaskUser.options.add(
              new Option(currOpt.text, currOpt.value, currOpt.selected)
            );
            if (i === this.props.selectedUsers.length - 1) {
              this.setState({
                actionType: 'EDIT_TASK',
                taskName: tdata.name,
                description: tdata.description,
                assignedUser: tdata.taskUser,
                index: idx,
                planUserList: this.state.planUserList
              });
              const addTaskDialog = document.getElementById('addTaskDialog');
              // addTaskDialog.style.left = (this.props.toggleMenu) ? '130px' : '330px'
              addTaskDialog.showModal();
            }
          });
        }
      }
    }
  }
  showEditTemplateNameBox() {
    const taskNameDialog = document.getElementById('templateNameEditBox');
    // taskNameDialog.style.left = (this.props.toggleMenu) ? '130px' : '330px'
    taskNameDialog.showModal();
  }
  hideEditTemplateNameBox() {
    this.setState({
      templateName: ''
    });
    const taskNameDialog = document.getElementById('templateNameEditBox');
    taskNameDialog.close();
  }

  showTaskModal() {
    const addTaskDialog = document.getElementById('addTaskDialog');
    addTaskDialog.style.left = this.props.toggleMenu ? '130px' : '330px';
    addTaskDialog.showModal();
    // this.addUsersToSelectOptions();
    // displays the modal and empties the fields
  }
  hideTaskModal() {
    // Clear all the values for creatation of new Task
    this.setState({
      actionType: 'CREATE_TASK',
      taskName: '',
      description: '',
      assignedUser: '',
      assignedRC: ''
    });

    const addTaskDialog = document.getElementById('addTaskDialog');
    addTaskDialog.close();
  }

  hideTaskDetailsModal() {
    // Clear all the values for creatation of new Task
    this.setState({
      actionType: 'CREATE_TASK',
      taskName: '',
      description: '',
      assignedUser: '',
      assignedRC: ''
    });

    const addTaskDialog = document.getElementById('addTaskDetailsDialog');
    addTaskDialog.close();
  }

  render() {
    // predefined names for the columns
    var columns = [
      {
        Header: 'Tasks',
        columns: [
          {
            Header: 'Name', //1
            accessor: 'name'
          },
          {
            Header: 'Description', //2
            accessor: 'description'
          },
          {
            Header: 'Completed', //3
            Cell: ({ row }) => {
              let assignee = 'N/A';
              let val = row.original.userCompletedName;
              if (val !== undefined && val !== '' && row.original.isComplete) {
                assignee = val;
              }
              return assignee;
            },
            accessor: 'assignee'
          },
          {
            Header: 'Time of Completion', //4
            Cell: ({ row }) => {
              // console.log("pp:"+this.props.planPager);
              let time = 'N/A';
              let val = row.original.dateCompleted;
              if (val !== undefined && val !== '' && row.original.isComplete) {
                time = val.toDate().toLocaleString();
              }
              return time;
            },
            accessor: 'time'
          },
          {
            Header: 'Check In', //5
            Cell: ({ row }) => {
              let body;
              if (row.original.isComplete) {
                body = (
                  <ImCheckboxChecked
                    height='20px'
                    alt='Check'
                    style={{
                      position: 'absolute',
                      top: 0 + 'px',
                      width: '24px',
                      height: '24px'
                    }}
                  />
                );
              }
              return (
                <div
                  id='taskCompletedCheckbox'
                  onClick={() => {
                    let res = window.confirm(
                      `Are you sure you want to change the completed status of ${row.original.name}?`
                    );
                    if (res) {
                      let assignedRC = '';
                      if (
                        row.original.assignedRC !== undefined &&
                        row.original.assignedRC !== ''
                      ) {
                        // assume the task has an assigned Roll Call
                        assignedRC = row.original.assignedRC;
                      }
                      this.props.onTaskStatusClick({
                        isComplete: row.original.isComplete,
                        index: row.id,
                        assignedRC: assignedRC
                      });
                    }
                  }}
                >
                  {body}
                </div>
              );
            }
          },
          {
            // Edit button functionality
            Header: '   ', //6
            Cell: ({ row }) => {
              // console.log("pp:"+this.props.planPager);
              return (
                <ImPencil
                  height={25}
                  width={25}
                  alt='edit'
                  className='icon_button'
                  onClick={() => {
                    this.populateTaskBox(row.original, row.id);
                    // Supposed to send the row ID of the User the admin wants to delete
                  }}
                />
              );
            }
          },
          {
            // Delete button functionality
            Header: ' ', //6
            Cell: ({ row }) => {
              return (
                <MdDelete
                  height={25}
                  width={25}
                  alt='delete'
                  className='icon_button'
                  onClick={(event) => {
                    event.persist();
                    var res = window.confirm(
                      'Are you sure you want to remove the task from the Plan?'
                    );
                    if (res) {
                      // Supposed to send the row ID of the User the admin wants to delete
                      this.props.onDeleteTask({
                        index: row.id,
                        id: row.original.id,
                        isComplete: row.original.isComplete
                      });
                    }
                  }}
                />
              );
            }
          },
          // {
          // // View task functionality
          // 	Header: 'Task Details',//7
          // 	Cell: ({row}) => {
          // 		return <button id="taskButton" title="View Tasks Details" onClick={() => this.populateTaskBox(row.original, row.id, 'showTaskDetailsDialog')}>View</button>
          //
          // 	}
          // },
          {
            Header: 'Print', // Print row functionality: 8
            Cell: ({ row }) => {
              let csvFields = [];
              let time = 'N/A';
              let val = row.original.completedDt;
              if (
                val !== undefined &&
                val.length > 0 &&
                row.original.isComplete
              ) {
                time = val;
              }

              csvFields.push({
                Name: row.original.name,
                Completed: row.original.completedBy,
                'Time of Completion': time
              });
              // console.log("csvfields:"+csvFields)
              return (
                <CSVLink data={csvFields}>
                  <ImPrinter
                    height={35}
                    width={35}
                    alt='Print'
                    className='icon_button'
                  />
                </CSVLink>
              );
            }
          }
        ]
      }
    ];
    if (this.props.planPager === 4) {
      if (this.props.subPlanPager === 1) {
        //checklist
        columns[0].columns.splice(4, 4);
      }
      if (this.props.subPlanPager === 2) {
        //template
        columns[0].columns.splice(1, 3);
        columns[0].columns.splice(3, 2);
      }
    }

    if (this.props.planPager === 6) {
      //Reports
      if (this.props.subPlanPager === 1) {
        //checklist
        columns[0].columns.splice(3, 3);
        columns[0].columns.splice(4, 1);
      }
    }
    console.log(this.props.taskList);
    // console.log(this.props.planID)
    // console.log("Task table > PP: "+ this.props.planPager)
    // console.log("Task table > SPP: "+ this.props.subPlanPager)
    return (
      <main>
        <p onClick={this.props.onBackClick} id='breadCrumbs'>
          {this.props.breadCrumbs}
        </p>

        <div className='MuiBox-root'>
          <div className='MuiPaper-root'>
            <div className='MuiBox-root css-1idik3a'>
              <div className='MuiBox-root css-uujcwg'>
                <h5 className='css-epib0t'>Task List</h5>
                {/* <span> */}
                {this.props.planPager === 4 || this.props.planPager === 6 ? (
                  <Fragment>
                    <span className='css-17vat79 d-block'>
                      {this.props.planName}
                      {this.props.subPlanPager === 2 ? (
                        <Fragment>
                          {' '}
                          <ImPencil
                            style={{ width: '15px', height: '15px' }}
                            alt='edit'
                            className='icon_button'
                            onClick={() => {
                              this.setState({
                                templateName: this.props.planName
                              });
                              this.showEditTemplateNameBox();
                            }}
                          />{' '}
                        </Fragment>
                      ) : null}
                    </span>
                    {this.props.subPlanPager === 1 ? (
                      <Fragment>
                        <span className='css-17vat79'>
                          Plan Leader: {this.props.leaderName}
                        </span>
                      </Fragment>
                    ) : null}
                  </Fragment>
                ) : null}
                {/* </span> */}
              </div>
              <div className='css-1yjo05o'>
                {/* <ul className="topnav">
										<li id="secondaryNavigationItems" onClick={this.props.onViewClientListAction} className={this.props.clientHighlight[0]}>Clients</li>
										<li id="secondaryNavigationItems" onClick={this.props.onViewDeletedClientListAction} className={this.props.clientHighlight[1]}>Deleted</li>
									</ul> */}
              </div>
            </div>
            <div className='MuiTableContainer-root css-13ypi1f'>
              <div className='MuiBox-root css-fgimv3'>
                <Row>
                  <Col lg={4}>
                    <div className='search'>
                      <input
                        id='searchBox'
                        className='pl-2'
                        placeholder='Search Task'
                        value={this.state.inputText}
                        onChange={(event) => {
                          this.props.onfilterTable({
                            type: 'FILTER_TASK_LIST',
                            value: event.target.value
                          });
                          let inputText = event.target.value;
                          this.setState({ inputText: inputText });
                        }}
                      />

                      <BiReset
                        id='resetTableListBtn'
                        alt='Reset'
                        className='icon_button'
                        onClick={() => {
                          this.props.onViewTasks();
                          this.setState({ inputText: null });
                        }}
                      />
                    </div>
                  </Col>

                  {this.props.planPager === 6 ? null : (
                    <Col lg={8}>
                      <ul className='addItem'>
                        <li>
                          {this.props.subPlanPager === 2 ? (
                            <Fragment>
                              <button
                                type='button'
                                onClick={() => {
                                  this.props.onViewTasks();
                                }}
                                className='btn'
                                style={{ width: '200px' }}
                              >
                                Refresh Task List
                              </button>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <button
                                type='button'
                                onClick={() => {
                                  this.props.onRefreshTaskList({
                                    index: this.props.planRowID
                                  });
                                }}
                                className='btn'
                                style={{ width: '200px' }}
                              >
                                Refresh Task List
                              </button>
                            </Fragment>
                          )}
                        </li>
                        <li>
                          <button
                            type='button'
                            onClick={this.showTaskModal}
                            className='btn'
                          >
                            Create Task
                          </button>
                        </li>
                      </ul>
                    </Col>
                  )}
                </Row>
              </div>
              <div className='MuiBox-root css-17uua2w'>
                <Table
                  responsive
                  columns={columns}
                  data={
                    this.props.viewFilteredList
                      ? this.props.filteredList
                      : this.props.taskList
                  }
                />
                {/* <Container>
										<Row>
											<Col>
											</Col>
										</Row>
									</Container> */}
              </div>
            </div>
          </div>
        </div>

        <AddTaskBox
          selectedUsers={this.props.selectedUsers}
          onHideTaskBox={this.hideTaskModal}
          onHideTaskDetailsBox={this.hideTaskDetailsModal}
          handleAssignedUserChange={this.handleAssignedUserChange}
          handleDescriptionChange={this.handleDescriptionChange}
          handleTaskNameChange={this.handleTaskNameChange}
          taskName={this.state.taskName}
          description={this.state.description}
          assignedUser={this.state.assignedUser}
          handleTaskAction={this.handleTaskAction}
          assignedRC={this.state.assignedRC}
          handleAssignedRollCallChange={this.handleAssignedRollCallChange}
        />

        <dialog
          id='templateNameEditBox'
          className='bigDialogBox'
          style={{ width: '30%' }}
        >
          <Container fluid>
            <h2>Edit Template Name</h2>
            <Row>
              <Col lg={12}>
                <label>Template Name</label>
                <br />
                <input
                  type='text'
                  value={this.state.templateName}
                  onChange={this.handleEditTemplateName}
                  placeholder='Template Name'
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className='btn--flex'>
                  <button
                    onClick={this.editTemplateName}
                    type='button'
                    className='btn--confirm dialog__btn'
                  >
                    Confirm
                  </button>
                  <button
                    onClick={this.hideEditTemplateNameBox}
                    type='button'
                    className='btn btn--close dialog__btn'
                  >
                    Cancel
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </dialog>
      </main>
    );
  }
}
export default TaskTable;
