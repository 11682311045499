import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  table: {
    display: 'table',
    width: 'auto',
    marginBottom: 20,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  tableCol: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    marginTop: 5,
    marginLeft: 5,
    fontSize: 10
  },
  tableHeader: {
    width: '100%',
    backgroundColor: '#4472c4'
  },
  tableHeaderText: {
    margin: 'auto',
    marginTop: 5,
    marginBottom: 5,
    fontSize: 12,
    color: 'white'
  }
});

const AlertTable = ({ data }) => {
  // console.log('AlertTable/data: ', data);
  const {
    type,
    name,
    message,
    createdBy,
    recipientCount,
    dateActivated,
    timeActivated,
    dateDeactivated,
    timeDeactivated
  } = data;

  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={styles.tableHeader}>
          <Text style={styles.tableHeaderText}>Alert Report</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Alert Type</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{type}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Alert Name</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{name}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Alert Message</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{message}</Text>
        </View>
      </View>
      {createdBy && (
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Created By</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{createdBy}</Text>
          </View>
        </View>
      )}
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Recipient Count</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{recipientCount}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Date Activated</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{dateActivated}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Time Activated</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{timeActivated}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Date Deactivated</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{dateDeactivated}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Time Deactivated</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{timeDeactivated}</Text>
        </View>
      </View>
    </View>
  );
};

export default AlertTable;
