import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';

import { ReactComponent as DownloadIcon } from '../../assets/download.svg';

const PdfDownload = ({ DownloadDocument, filename }) => {
  return (
    <div
      id='pdfDownload'
      style={{
        width: '100%',
        height: '100%'
      }}
    >
      <PDFDownloadLink
        className='pdf-download-link'
        document={<DownloadDocument />}
        fileName={filename}
        style={{ marginLeft: '1rem' }}
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            '...'
          ) : (
            <DownloadIcon
              style={{
                width: '20px',
                height: '20px',
                color: '#2152ff'
              }}
            />
          )
        }
      </PDFDownloadLink>
    </div>
  );
};

export default PdfDownload;
