import { refreshRootFolder } from '../../actions/library';

import {
  addFolderDoc,
  addSubfolderByDocID,
  createFolderDocRef,
  deleteFolderDoc,
  getFolderFilesByFolderID,
  getFolderNameAndParentIDByDocumentID,
  removeSubfolderByDocID,
  setFolderDocByRef,
  updateFolderDocByID
} from './query';

import { firestoreTimestamp } from '../../utils';

export const createFolder = async (data) => {
  try {
    let folderData = {
      ...data,
      createdAt: firestoreTimestamp(new Date()),
      updatedAt: firestoreTimestamp(new Date())
    };

    const res = await addFolderDoc(folderData);

    // update current folder
    await refreshRootFolder();

    return res;
  } catch (error) {
    console.log('ERROR - db/createFolder: ', error);
    return null;
  }
};

export const addSubfolder = async (parentFolderID, subfolderData) => {
  try {
    let folderData = {
      ...subfolderData,
      createdAt: firestoreTimestamp(new Date()),
      updatedAt: firestoreTimestamp(new Date())
    };

    const folderRef = await createFolderDocRef();
    folderData.folderID = folderRef.id;

    /* eslint-disable-next-line no-unused-vars */
    const result = await setFolderDocByRef(folderRef, folderData);

    const updatedFolderRes = await addSubfolderByDocID(
      parentFolderID,
      folderRef
    );

    // update current folder
    await refreshRootFolder();

    return updatedFolderRes;
  } catch (error) {
    console.log('ERROR - db/addSubfolder: ', error);
    return null;
  }
};

export const deleteSubfolder = async (folderID, parentFolderID) => {
  try {
    const deleteSubfolderRef = await deleteFolderDoc(folderID);

    if (!deleteSubfolderRef) {
      return null;
    }

    const updatedFolderRes = await removeSubfolderByDocID(
      parentFolderID,
      deleteSubfolderRef
    );

    // update current folder
    await refreshRootFolder();

    return updatedFolderRes;
  } catch (error) {
    console.log('ERROR - db/deleteSubfolder: ', error);
    return null;
  }
};

export const addFileToFolderDocByID = async (folderID, fileItem) => {
  try {
    const updatedFiles = await getFolderFilesByFolderID(folderID);

    updatedFiles.push(fileItem);

    await updateFolderDocByID(folderID, {
      files: updatedFiles
    });

    return {
      success: true
    };
  } catch (error) {
    console.log('ERROR - db/addFileToFolderDocByID: ', error);
    return {
      success: false
    };
  }
};

export const removeFileFromFolderDocByID = async (folderID, fullPath) => {
  try {
    const files = await getFolderFilesByFolderID(folderID);

    const updatedFiles = files.filter(
      (file) => file.storagePathname !== fullPath
    );

    await updateFolderDocByID(folderID, {
      files: updatedFiles
    });

    return {
      success: true
    };
  } catch (error) {
    console.log('ERROR - db/removeFileFromFolderDocByID: ', error);
    return {
      success: false
    };
  }
};

export const editFolderName = async (folderID, folderName) => {
  try {
    await updateFolderDocByID(folderID, {
      folderName: folderName
    });

    await refreshRootFolder();

    return {
      success: true
    };
  } catch (error) {
    console.log('ERROR - db/editFolderName: ', error);
    return null;
  }
};

export const enableFolderLock = async (folderID, password) => {
  try {
    await updateFolderDocByID(folderID, {
      isProtected: true,
      password
    });

    await refreshRootFolder();

    return {
      success: true
    };
  } catch (error) {
    console.log('ERROR - db/enableFolderLock: ', error);
    return null;
  }
};

export const removeFolderLock = async (folderID) => {
  try {
    await updateFolderDocByID(folderID, {
      isProtected: false,
      password: null
    });

    await refreshRootFolder();

    return {
      success: true
    };
  } catch (error) {
    console.log('ERROR - db/removeFolderLock: ', error);
    return null;
  }
};

export const updateFolderPassword = async (folderID, update) => {
  try {
    await updateFolderDocByID(folderID, update);

    await refreshRootFolder();

    return {
      success: true
    };
  } catch (error) {
    console.log('ERROR - db/updateFolderPassword: ', error);
    return null;
  }
};

export const getStoragePath = async (folderID, folderPath) => {
  try {
    console.log('getStoragePath/folderID: ', folderID);
    console.log('getStoragePath/folderPath: ', folderPath);

    const folder = await getFolderNameAndParentIDByDocumentID(folderID);

    if (!folder.parentFolderID) {
      console.log(
        'getStoragePath/folder has no parent root found/folderPath: ',
        folderPath
      );
      return folderPath;
    }

    const parentFolder = await getFolderNameAndParentIDByDocumentID(
      folder.parentFolderID
    );
    console.log('getStoragePath/parentFolder: ', parentFolder);

    if (!parentFolder || parentFolder.folderName === 'root') {
      console.log('getStoragePath root found/folderPath: ', folderPath);
      return folderPath;
    }

    const updatedFolderPath = `${parentFolder.folderName}/${folderPath}`;
    console.log(
      'getStoragePath parent found/updatedFolderPath: ',
      updatedFolderPath
    );

    return await getStoragePath(parentFolder.parentFolderID, updatedFolderPath);
  } catch (error) {
    console.log(
      'ERROR - db/folders/operations/getStoragePathFromFolder: ',
      error
    );
    return null;
  }
};
