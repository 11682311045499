import 'firebase/storage';
import firebaseApp from '../firebaseApp';

const storage = firebaseApp.storage();

export const getStorageList = async (path) => {
  try {
    const listRef = storage.ref(path);

    const result = await listRef.listAll();

    return result;
  } catch (error) {
    console.log('getStorageList/error: ', error);
    return null;
  }
};

export const uploadFileToStorage = async (path, file) => {
  try {
    const storageRef = storage.ref();
    const fileRef = storageRef.child(path);

    const upload = await fileRef.put(file);
    console.log('upload: ', upload);

    // return upload;
    return {
      success: true
    };
  } catch (error) {
    console.log('uploadFileToStorage/error: ', error);
    return null;
  }
};

export const deleteFileFromStorage = async (path) => {
  try {
    console.log('deleteFileFromStorage/path: ', path);

    const storageRef = storage.ref();
    const fileRef = storageRef.child(path);

    const result = await fileRef.delete();
    console.log('result: ', result);

    // return result;
    return {
      success: true
    };
  } catch (error) {
    console.log('deleteFileFromStorage/error: ', error);
    return null;
  }
};

export const downloadFileFromStorage = async (path, fileName) => {
  try {
    const storageRef = storage.ref();
    const fileRef = storageRef.child(path);
    const downloadURL = await fileRef.getDownloadURL();

    const response = await fetch(downloadURL);

    if (!response.ok) {
      throw new Error('Something went wrong');
    }

    const dataBlob = await response.blob();
    const dataUrl = URL.createObjectURL(dataBlob);

    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(path);

    return {
      success: true
    };
  } catch (error) {
    console.log('downloadFileFromStorage/error: ', error);
    return {
      success: false
    };
  }
};

export const getDownloadURLFromPath = async (path) => {
  try {
    console.log('getDownloadURLFromPath/path: ', path);
    const storageRef = storage.ref();
    const fileRef = storageRef.child(path);
    const url = await fileRef.getDownloadURL();
    console.log('url: ', url);

    return url;
  } catch (error) {
    console.log('getDownloadURLFromPath/error: ', error);
    return null;
  }
};
