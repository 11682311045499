import { useState } from 'react';

import FlashLogoBlue from './style/FlashLogoBlue.png';

const SignIn = ({
  forgotPassword,
  requestPasswordReset,
  signIn,
  toggleForgotPassword
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  var showSignIn = forgotPassword ? 'hidden' : '';
  var showFPassword = forgotPassword ? '' : 'hidden';

  const handleSignIn = (email, password) => {
    console.log('email: ', email);
    console.log('password: ', password);

    signIn(email, password);
  };

  const handleSubmitRequestResetPassword = (email) => {
    console.log('email: ', email);
    requestPasswordReset(email);
  };

  const handleForgotPasswordClick = () => {
    toggleForgotPassword();
  };

  return (
    <div id='signInBackGround'>
      <div id='signInBorder'>
        <section className={['signIn', showSignIn].join(' ')}>
          <img className='logo' alt='company' src={FlashLogoBlue} height={95} />
          <h3 className='lgnTitle'>Administrator Portal</h3>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              handleSignIn(email, password);
            }}
          >
            <div className='inputbox'>
              <input
                className='logInput'
                type='text'
                name='email'
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder='Username'
              />
            </div>
            <div className='inputbox'>
              <input
                className='logInput'
                type='password'
                name='password'
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                placeholder='Password'
              />
            </div>
            <br />
            {/* on click and on enter, the login function is executed */}
            <button className='signInBtn' type='submit'>
              Log In
            </button>
          </form>
          <p
            onClick={handleForgotPasswordClick}
            style={{
              cursor: 'pointer',
              fontSize: '14px',
              paddingTop: '7px'
            }}
          >
            Forgot Password
          </p>
        </section>
        <section
          className={['signIn', showFPassword].join(' ')}
          id='forgotPassword'
        >
          <img className='logo' alt='company' src={FlashLogoBlue} height={95} />
          <h3 className='lgnTitle'>Forgot Password</h3>
          <p>Enter your Email to continue</p>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmitRequestResetPassword(email);
            }}
          >
            <div className='inputbox'>
              <input
                className='logInput'
                type='text'
                name='email'
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder='Email'
              />
            </div>
            <br />
            {/* on click and on enter, the login function is executed */}
            <button className='signInBtn' type='submit'>
              Submit
            </button>
            <br />
            <p
              onClick={handleForgotPasswordClick}
              style={{ cursor: 'pointer' }}
            >
              Back to Login
            </p>
          </form>
        </section>
      </div>
    </div>
  );
};

export default SignIn;
