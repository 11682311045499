import { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';

import { BiReset } from 'react-icons/bi';
import Reset from './style/reset_btn.png';

import 'react-accessible-accordion/dist/fancy-example.css';
import './style/ClientSettings.css';

class FeatureSettingsTable extends Component {
  constructor() {
    super();
    this.state = {
      index: 0, // index of the selected client for edit
      domain: '', // the domain associated with  the client
      clientName: '', // the clients name
      address: '', // address of the client
      city: '', // city the client is located in
      stateName: '', //state the client is located in
      phoneNumber: '', // the clients phone number
      logo: '', // the file of the logo for the client
      imgSrc: '', // logo image source
      inputText: null // {label: string, value: string}
    };
  }
  render() {
    // var columns = [
    //   {
    //     Header: 'Users',
    //     columns: [
    //       {
    //         Header: '   ',
    //         Cell: ({ row }) => {
    //           return (
    //             <img
    //               style={{ borderRadius: '50%', background: 'lightgrey' }}
    //               src={row.original.logo}
    //               width='40'
    //               height='40'
    //               alt=''
    //             />
    //           );
    //         }
    //       },
    //       {
    //         Header: 'Client Name',
    //         accessor: 'clientName'
    //       },
    //       {
    //         Header: 'Client Code',
    //         accessor: 'companyID'
    //       },
    //       {
    //         Header: 'COVID-19 Vaccine tracking',
    //         // add the Toggle Switch over here
    //         Cell: ({ row }) => {
    //           let enabled = row.original.vaccineTracking;
    //           // save as string and then do the logic
    //           return (
    //             <label title='Enable Vaccine Tracking' className='switch'>
    //               <input type='checkbox' defaultChecked={enabled} />
    //               <span
    //                 className='slider round'
    //                 onClick={() => {
    //                   this.props.onToggleVaccineClient({
    //                     index: row.original.id
    //                   });
    //                 }}
    //               ></span>
    //             </label>
    //           );
    //         }
    //       }
    //     ]
    //   }
    // ];

    return (
      <main>
        <p id='breadCrumbs'>{this.props.breadCrumbs}</p>

        <div className='MuiBox-root'>
          <div className='MuiPaper-root'>
            <div className='MuiBox-root css-1idik3a'>
              <div className='MuiBox-root css-uujcwg'>
                <h5 className='css-epib0t'>Feature Settings</h5>
                <span className='css-17vat79'></span>
              </div>
            </div>
            <div className='MuiTableContainer-root css-13ypi1f'>
              <div className='MuiBox-root css-fgimv3'>
                <Row>
                  <Col lg={4}>
                    <div className='search'>
                      <input
                        id='searchBox'
                        className='pl-2'
                        placeholder='Search Client'
                        value={this.state.inputText}
                        onChange={(event) => {
                          this.props.onfilterTable({
                            type: 'FILTER_CLIENT_LIST',
                            value: event.target.value
                          });
                          let inputText = event.target.value;
                          this.setState({ inputText: inputText });
                        }}
                      />
                      <BiReset
                        id='resetTableListBtn'
                        src={Reset}
                        alt='Reset'
                        className='icon_button'
                        onClick={() => {
                          this.props.onViewClientList();
                          this.setState({ inputText: null });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className='MuiBox-root css-17uua2w'>
                <Accordion>
                  {this.props.clientList.map((client, index) => (
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          {client.clientName}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <Container>
                          <Row>
                            <Col>
                              <div className='featureTitle_sec'>
                                <p className='featureTitle'>Start Meeting</p>
                                <label title='' className='switch'>
                                  <input
                                    type='checkbox'
                                    defaultChecked={
                                      client.features !== undefined
                                        ? client.features.meeting
                                        : true
                                    }
                                    onClick={() => {
                                      this.props.toggleFeatureSettings(
                                        'meeting',
                                        client.id
                                      );
                                    }}
                                  />
                                  <span className='slider round'></span>
                                </label>
                              </div>
                              <div className='featureTitle_sec'>
                                <p className='featureTitle'>Library</p>
                                <label title='' className='switch'>
                                  <input
                                    type='checkbox'
                                    defaultChecked={
                                      client.features !== undefined
                                        ? client.features.library
                                        : true
                                    }
                                    onClick={() => {
                                      this.props.toggleFeatureSettings(
                                        'library',
                                        client.id
                                      );
                                    }}
                                  />
                                  <span className='slider round'></span>
                                </label>
                              </div>
                              <div className='featureTitle_sec'>
                                <p className='featureTitle'>
                                  Emergency Checklist
                                </p>
                                <label title='' className='switch'>
                                  <input
                                    type='checkbox'
                                    defaultChecked={
                                      client.features !== undefined
                                        ? client.features.emergencyChecklist
                                        : true
                                    }
                                    onClick={() => {
                                      this.props.toggleFeatureSettings(
                                        'emergencyChecklist',
                                        client.id
                                      );
                                    }}
                                  />
                                  <span className='slider round'></span>
                                </label>
                              </div>
                            </Col>
                            <Col>
                              <div className='featureTitle_sec'>
                                <p className='featureTitle'>Teams</p>
                                <label title='' className='switch'>
                                  <input
                                    type='checkbox'
                                    defaultChecked={
                                      client.features !== undefined
                                        ? client.features.teams
                                        : true
                                    }
                                    onClick={() => {
                                      this.props.toggleFeatureSettings(
                                        'teams',
                                        client.id
                                      );
                                    }}
                                  />
                                  <span className='slider round'></span>
                                </label>
                              </div>
                              <div className='featureTitle_sec'>
                                <p className='featureTitle'>Flash</p>
                                <label title='' className='switch'>
                                  <input
                                    type='checkbox'
                                    defaultChecked={
                                      client.features !== undefined
                                        ? client.features.flash
                                        : true
                                    }
                                    onClick={() => {
                                      this.props.toggleFeatureSettings(
                                        'flash',
                                        client.id
                                      );
                                    }}
                                  />
                                  <span className='slider round'></span>
                                </label>
                              </div>
                              <div className='featureTitle_sec'>
                                <p className='featureTitle'>Forms</p>
                                <label title='' className='switch'>
                                  <input
                                    type='checkbox'
                                    defaultChecked={
                                      client.features !== undefined
                                        ? client.features.forms
                                        : true
                                    }
                                    onClick={() => {
                                      this.props.toggleFeatureSettings(
                                        'forms',
                                        client.id
                                      );
                                    }}
                                  />
                                  <span className='slider round'></span>
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
export default FeatureSettingsTable;
