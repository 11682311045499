import { Component, Fragment } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';

import LibraryTreeView from './LibraryTreeView';
import FolderAdd from './FolderAdd';
import FileUpload from './FileUpload';

import { CSVLink } from 'react-csv';
import { GrClose } from 'react-icons/gr';
import {
  AiFillDelete,
  AiFillFile,
  AiFillFolder,
  AiFillLock,
  AiFillPrinter,
  AiFillUnlock,
  AiOutlineDownload
} from 'react-icons/ai';

import '../style/knowledgePage.css';
import '../style/reportsPage.css';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

class LibraryTable extends Component {
  constructor() {
    super();
    this.state = {
      folderIndex: '',
      folderPath: '',
      highlight: ['bottomLine', 'topLine'],
      viewUploadBtns: false,
      /* {boolean} display upload buttons
       * only for Super Admin & Root
       * if general, only for Root
       */
      inputText: null, // {label: string, value: string}
      clientName: 'Client',
      date: new Date().toLocaleString(),
      csvArr: [],
      startDate: new Date(),
      lastDate: new Date()
    };
    this.deleteFile = this.deleteFile.bind(this);
    this.handleNavigateToChild = this.handleNavigateToChild.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.addFolder = this.addFolder.bind(this);
    this.addFile = this.addFile.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.handleSingleClick = this.handleSingleClick.bind(this);
    this.handleDeleteFolder = this.handleDeleteFolder.bind(this);
    this.handleEditFolder = this.handleEditFolder.bind(this);
    this.viewClientRepo = this.viewClientRepo.bind(this);
    this.viewGenRepo = this.viewGenRepo.bind(this);
    this.handleClientFilterChange = this.handleClientFilterChange.bind(this);
    this.getCSVData = this.getCSVData.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleLastDateChange = this.handleLastDateChange.bind(this);
    this.handleFolderNameChange = this.handleFolderNameChange.bind(this);
    this.showEditFolderModal = this.showEditFolderModal.bind(this);
    this.hideEditFolderModal = this.hideEditFolderModal.bind(this);
    this.showSetPasswordModal = this.showSetPasswordModal.bind(this);
    this.hideSetPasswordModal = this.hideSetPasswordModal.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
    this.handleSetPassword = this.handleSetPassword.bind(this);
    this.handleEditPassword = this.handleEditPassword.bind(this);
    this.hideEditPasswordModal = this.hideEditPasswordModal.bind(this);
    this.showEditPasswordModal = this.showEditPasswordModal.bind(this);
    this.handleRemovePassword = this.handleRemovePassword.bind(this);
  }
  componentDidMount() {
    // if (this.props.role === 'Root') {
    //     this.viewGenRepo()
    // } else
    let clientName = 'Client';
    if (this.props.role === 'Super Admin') {
      let idx = this.props.clientList.findIndex(
        (client) => client.id === this.props.companyID
      );
      if (idx !== -1) {
        clientName = this.props.clientList[idx].clientName;
      }
    }
    if (this.props.role === 'Super Admin' || this.props.role === 'Root') {
      this.setState({
        viewUploadBtns: true,
        clientName: clientName
      });
    }
  }
  handleClientFilterChange(event) {
    this.props.onClientFilterChange({
      name: event.target.value
    });
  }
  addFolder(data) {
    this.props.onAddFolder({ itemName: data.folderName });
    this.props.createLibraryLog({
      folderUploadedDate: this.state.date
    });
  }
  addFile(fData) {
    this.props.onAddFile({
      itemName: fData.itemName,
      file: fData.file,
      type: fData.type,
      companyID: fData.companyID
    });
    this.props.createLibraryLog({
      fileUploadedDate: this.state.date
    });
  }
  viewClientRepo() {
    let rootPath = 'companies';
    if (this.props.role !== 'Root') {
      // assume current user is a Super Admin/User
      rootPath = `companies/${this.props.companyID}`;
    }
    this.props.onViewSelectedRepo({ path: rootPath });
    let viewUploadBtns = false;
    if (this.props.role === 'Root' || this.props.role === 'Super Admin') {
      viewUploadBtns = true;
    }
    this.setState({
      viewUploadBtns: viewUploadBtns,
      highlight: ['bottomLine', 'topLine']
    });
  }
  viewGenRepo() {
    this.props.onViewSelectedRepo({ path: 'companies/genItems' });
    let viewUploadBtns = false;
    if (this.props.role === 'Root') {
      viewUploadBtns = true;
    }
    this.setState({
      viewUploadBtns: viewUploadBtns,
      highlight: ['topLine', 'bottomLine']
    });
  }
  handleDeleteFolder(itemName, index, fullPath) {
    let folderName = itemName;
    var res = window.confirm(`Do you really want to delete ${folderName}?`);
    if (res) {
      this.props.onDeleteFolder({
        path: fullPath,
        index: index
      });
    }

    this.props.createLibraryLog({
      folderDeletedDate: this.state.date
    });
  }
  handleFolderNameChange(event) {
    this.setState({ folderName: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleNewPasswordChange(event) {
    this.setState({ newPassword: event.target.value });
  }

  handleEditFolder() {
    // var res = window.prompt('Enter New Folder Name:', itemName)
    let name = this.state.folderName;
    let index = this.state.index;
    let fullPath = this.state.fullPath;
    let itemName = this.state.itemName;

    if (name !== itemName && name !== null && name.length > 0) {
      this.props.onEditFolderName({
        itemName: itemName, // old folder name
        folderName: name, // new folder name
        index: index, //index of item in table
        path: fullPath // fullPath of item
      });
    }
    this.props.createLibraryLog({
      folderName: name,
      lastFolderName: itemName,
      lastUpdatedBy: 1,
      lastUpdatedDate: this.state.date,
      filetype: 'folder'
    });
  }

  handleSetPassword() {
    let name = this.state.folderName;
    let index = this.state.index;
    let password = this.state.password;
    let fullPath = this.state.fullPath;
    let itemName = this.state.itemName;

    if (password !== '') {
      this.props.onSetFolderPassword({
        index: index,
        password: password,
        path: fullPath
      });

      this.hideSetPasswordModal();
    } else {
      alert('Please enter a password to set');
    }

    this.props.createLibraryLog({
      folderName: name,
      lastFolderName: itemName,
      lastUpdatedBy: 1,
      lastUpdatedDate: this.state.date,
      password: 'set',
      filetype: 'folder'
    });
  }

  handleEditPassword() {
    let name = this.state.folderName;
    let index = this.state.index;
    let password = this.state.password;
    let newpassword = this.state.newPassword;
    let fullPath = this.state.fullPath;
    let itemName = this.state.itemName;

    if (newpassword !== '' && newpassword !== password) {
      this.props.onSetFolderPassword({
        index: index,
        password: password,
        path: fullPath
      });

      this.hideEditPasswordModal();
    } else {
      alert('Please enter a new password to set');
    }

    this.props.createLibraryLog({
      folderName: name,
      lastFolderName: itemName,
      lastUpdatedBy: 1,
      lastUpdatedDate: this.state.date,
      password: 'update',
      filetype: 'folder'
    });
  }

  handleRemovePassword() {
    let name = this.state.folderName;
    let index = this.state.index;
    let fullPath = this.state.fullPath;
    let itemName = this.state.itemName;

    let result = window.confirm(
      'Are you sure you want to remove the password?'
    );

    if (result) {
      this.props.onRemovePassword({
        index: index,
        path: fullPath
      });

      this.hideEditPasswordModal();

      this.props.createLibraryLog({
        folderName: name,
        lastFolderName: itemName,
        lastUpdatedBy: 1,
        lastUpdatedDate: this.state.date,
        password: 'unset',
        filetype: 'folder'
      });
    }
  }

  showEditFolderModal() {
    this.props.onEditBreadCrumbs({
      type: 'APPEND_BREADCRUMB',
      breadCrumbs: ' > Edit Folder'
    });
    const editFolderDialog = document.getElementById('editFolderDialog');
    editFolderDialog.showModal();
  }
  hideEditFolderModal() {
    this.props.onEditBreadCrumbs({
      type: 'REMOVE_BREADCRUMB',
      breadCrumbs: 'Library'
    });
    const editFolderDialog = document.getElementById('editFolderDialog');
    editFolderDialog.close();
  }
  showSetPasswordModal() {
    this.props.onEditBreadCrumbs({
      type: 'APPEND_BREADCRUMB',
      breadCrumbs: ' > Set Password'
    });
    const setPasswordDialog = document.getElementById('setPasswordDialog');
    setPasswordDialog.showModal();
  }
  hideSetPasswordModal() {
    this.props.onEditBreadCrumbs({
      type: 'REMOVE_BREADCRUMB',
      breadCrumbs: 'Library'
    });
    const setPasswordDialog = document.getElementById('setPasswordDialog');
    setPasswordDialog.close();
  }

  showEditPasswordModal() {
    this.props.onEditBreadCrumbs({
      type: 'APPEND_BREADCRUMB',
      breadCrumbs: ' > Edit Password'
    });
    const editPasswordDialog = document.getElementById('editPasswordDialog');
    editPasswordDialog.showModal();
  }
  hideEditPasswordModal() {
    this.props.onEditBreadCrumbs({
      type: 'REMOVE_BREADCRUMB',
      breadCrumbs: 'Library'
    });
    const editPasswordDialog = document.getElementById('editPasswordDialog');
    editPasswordDialog.close();
  }
  handleSingleClick({ row }) {
    const path = row.original.fullPath;
    const folder = row.original.folder;
    const index = row.id;
    if (
      folder &&
      (this.props.role === 'Root' || this.props.role === 'Super Admin')
    ) {
      // assuming its a folder, show the delete folder button
      this.setState({
        folderIndex: index,
        folderPath: path
      });
    }
  }
  handleNavigateToChild({ row }) {
    // changes all the backgrounds to white
    let tbodyArr = document.querySelector('tbody').getElementsByTagName('tr');
    for (let i = 0; i < tbodyArr.length; i++) {
      tbodyArr[i].style.background = 'white';
    }
    let currRepo = row.original;
    if (!currRepo.folder) {
      alert('You can not view the contents of a file');
    } else {
      // update the topography header that displays the current path
      // view the contents of the folder
      this.props.onNavigateToChild({
        path: currRepo.fullPath,
        companyID: currRepo.companyID
      });
    }
  }
  handleGoBack() {
    // changes all the backgrounds to white
    let tbodyArr = document.querySelector('tbody').getElementsByTagName('tr');
    for (let i = 0; i < tbodyArr.length; i++) {
      tbodyArr[i].style.background = 'white';
    }
    let size = this.props.path.length;
    let formattedPath = this.props.path;
    // go back a repository by cutting off a portion from the end
    // of the path until '/'
    for (let i = size - 1; i >= 0; i--) {
      if (this.props.path[i] === '/') {
        formattedPath = formattedPath.slice(0, -1);
        break;
      }
      formattedPath = formattedPath.slice(0, -1);
    }
    this.props.onNavigateToParent({ path: formattedPath });
  }
  deleteFile(row) {
    this.props.onDeleteFile({
      id: row.id,
      index: row.index,
      fullPath: row.fullPath,
      folder: row.folder
    });
    this.props.createLibraryLog({
      fileDeletedDate: this.state.date
    });
  }
  downloadFile(data) {
    this.props.onDownloadFile({
      fullPath: data.fullPath,
      folder: data.folder,
      itemName: data.itemName,
      storagePath: data.storagePath
    });

    this.props.createLibraryLog({
      fileDownloadedDate: this.state.date
    });
  }

  handleStartDateChange = (date) => {
    console.log('startDate:' + date);
    this.setState({ startDate: date });
  };
  handleLastDateChange = (date) => {
    console.log('lastDate:' + date);
    this.setState({ lastDate: date });
  };

  getCSVData(tdata) {
    let len = tdata.length;
    let csv = [];
    for (let i = 0; i < len; i++) {
      csv.push({
        'Item Name': tdata[i].itemName,
        'Last Updated By': tdata[i].lastUpdatedBy,
        'Last Updated Time': tdata[i].lastUpdatedDate,
        'Original Uploaded By': tdata[i].fullName,
        'Original Uploaded Time': tdata[i].uploadTime,
        'File Type': tdata[i].folder ? 'Folder' : tdata[i].fileType
      });
    }
    // console.log(csv);
    this.setState({ csvArr: csv });
  }

  render() {
    // console.log('Library/LibraryTable props: ', this.props);
    // {console.log(this.props.planPager);}
    let path = this.props.path.replace('genItems', 'General');

    path = path.split('/');

    if (path[1] !== undefined) {
      // assume not just viewing clients directory
      let idx = this.props.clientList.findIndex(
        (client) => client.id === path[1]
      );
      if (idx !== -1) {
        // assume inside a client folder
        path[1] = this.props.clientList[idx].clientName;
      }
    }

    path = path.join(' / ');
    path = path.replace('companies / ', '');

    var columns = [
      // predefined names for the Library
      {
        Header: 'Users',
        columns: [
          {
            Header: '  ',
            Cell: ({ row }) => {
              let body;
              if (!row.original.folder) {
                body = (
                  <AiFillFile
                    title='File'
                    className='icon_button'
                    height={25}
                    width={25}
                    alt='descriptor'
                  />
                );
              } else {
                body = (
                  <AiFillFolder
                    title='Folder'
                    className='icon_button'
                    height={25}
                    width={25}
                    alt='descriptor'
                  />
                );
              }
              return body;
            }
          },
          {
            Header: 'Item Name',
            Cell: ({ row }) => {
              let body = <span>{row.original.itemName}</span>;
              return body;
            },
            accessor: 'itemName'
          },
          {
            Header: 'Last Updated By',
            Cell: ({ row }) => {
              return row.original.lastUpdatedBy
                ? row.original.lastUpdatedBy
                : 'N/A';
            }
          },
          {
            Header: 'Last Updated Time',
            Cell: ({ row }) => {
              return row.original.lastUpdatedDate
                ? row.original.lastUpdatedDate
                : 'N/A';
            }
          },
          {
            Header:
              this.props.planPager === 6
                ? 'Original Uploaded By'
                : 'Uploaded By',
            accessor: 'fullName'
          },
          {
            Header:
              this.props.planPager === 6
                ? 'Original Uploaded Time'
                : 'Uploaded Time',
            Cell: ({ row }) => {
              //Doing something lame! the datatype is string but when passed through any of the formatting code gives invalid time value
              //Small trick can make wonders and play with people
              let value = row.original.uploadTime;
              let dateTime = value.split('/');
              return dateTime[1] + '/' + dateTime[0] + '/' + dateTime[2];
            },
            accessor: 'uploadTime'
          },
          {
            Header: 'File Type',
            Cell: ({ row }) => {
              if (!row.original.fileType) {
                return 'Folder';
              } else {
                let type = 'Folder';
                if (row.original.fileType === 'application/pdf') {
                  type = 'PDF';
                } else if (
                  row.original.fileType === 'application/vnd.ms-powerpoint'
                ) {
                  type = 'PPT';
                } else if (row.original.fileType === 'text/csv') {
                  type = 'CSV';
                } else if (
                  row.original.fileType === 'application/vnd.ms-excel'
                ) {
                  type = 'XLS';
                } else if (row.original.fileType === 'image/jpeg') {
                  type = 'JPEG';
                } else if (row.original.fileType === 'image/png') {
                  type = 'PNG';
                } else if (row.original.fileType === 'text/plain') {
                  type = 'TEXT';
                } else if (row.original.fileType === 'video/mp4') {
                  type = 'MP4 video';
                } else if (row.original.fileType === 'video/quicktime') {
                  type = 'Quicktime video';
                } else if (
                  row.original.fileType ===
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                ) {
                  type = 'sheet';
                } else if (
                  row.original.fileType ===
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                ) {
                  type = 'document';
                }
                return type;
              }
            }
          },
          {
            Header: 'Folder',
            Cell: ({ row }) => {
              if (path === 'companies') {
                return row.original.fullName;
              } else return path;
            }
          },
          {
            Header: '   ',
            Cell: ({ row }) => {
              // Download button
              if (row.original.folder) {
                return '';
              }
              return (
                <AiOutlineDownload
                  title='Download File'
                  height={25}
                  width={25}
                  alt='Download'
                  className='icon_button'
                  onClick={(e) => {
                    e.stopPropagation();
                    this.downloadFile({
                      fullPath: row.original.fullPath,
                      folder: row.original.folder,
                      itemName: row.original.itemName,
                      storagePath: row.original.storagePath
                    });
                  }}
                />
              );
            }
          },
          {
            Header: '      ',
            Cell: ({ row }) => {
              let body = '';
              if (row.original.folder === true) {
                if (row.original.protected === true) {
                  body = (
                    <AiFillLock
                      title='Locked'
                      height={20}
                      width={15}
                      style={{
                        marginLeft: '10px',
                        cursor: 'pointer',
                        fill: 'red',
                        fontSize: '24px'
                      }}
                      alt='lock'
                      onClick={() => {
                        this.setState(
                          {
                            index: row.id,
                            password: row.original.password,
                            newPassword: row.original.password,
                            fullPath: row.original.fullPath,
                            folderName: row.original.itemName,
                            itemName: row.original.itemName
                          },
                          () => {
                            console.log(this.state.index);
                            this.showEditPasswordModal();
                          }
                        );
                      }}
                    />
                  );
                } else {
                  body = (
                    <AiFillUnlock
                      title='Unlocked'
                      height={20}
                      width={20}
                      style={{
                        marginLeft: '10px',
                        cursor: 'pointer',
                        fill: 'green',
                        fontSize: '24px'
                      }}
                      alt='unlock'
                      onClick={() => {
                        this.setState(
                          {
                            index: row.id,
                            password: '',
                            fullPath: row.original.fullPath,
                            folderName: row.original.itemName,
                            itemName: row.original.itemName
                          },
                          () => {
                            console.log(this.state.index);
                            this.showSetPasswordModal();
                          }
                        );
                      }}
                    />
                  );
                }
              }
              return body;
            }
          },

          {
            Header: ' ', // Delete button functionality
            Cell: ({ row }) => {
              let body;
              if (!row.original.folder) {
                body = (
                  <AiFillDelete
                    title='Delete File'
                    height={25}
                    width={25}
                    alt='Delete'
                    className='icon_button'
                    onClick={(event) => {
                      event.persist();
                      event.stopPropagation();
                      var res = window.confirm(
                        'Are you sure you want to delete the File?'
                      );
                      if (res) {
                        // Supposed to send the row index and the fullPath of the item
                        // that the user wants to delete
                        this.deleteFile({
                          id: row.original.id,
                          index: row.id,
                          fullPath: row.original.fullPath,
                          folder: row.original.folder
                        });
                      }
                    }}
                  />
                );
              } else {
                body = (
                  <AiFillDelete
                    title='Delete Folder'
                    height={25}
                    width={25}
                    alt='Delete'
                    className='icon_button'
                    onClick={() =>
                      this.handleDeleteFolder(
                        row.original.itemName,
                        row.id,
                        row.original.fullPath
                      )
                    }
                  />
                );
              }
              return body;
            }
          }
        ]
      }
    ];
    if (this.props.planPager !== 6) {
      columns[0].columns.splice(2, 2);
    }
    if (this.props.planPager === 6) {
      columns[0].columns.splice(8, 3);
    }
    // console.log('this.props.planPager: ', this.props.planPager);
    return (
      <main>
        <p id='breadCrumbs'>
          {this.props.planPager === 6 ? 'Reports' : this.props.breadCrumbs}
        </p>

        <div className='MuiBox-root'>
          <div className='MuiPaper-root'>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0.75rem 1.5rem'
              }}
            >
              <div className='MuiBox-root css-uujcwg'>
                <h5 className='css-epib0t'>
                  Library {this.props.planPager === 6 ? 'Report' : ''}
                </h5>
                <h4 onClick={this.handleGoBack}>
                  <i className='left'></i>
                  {path}
                </h4>
                <span className='css-17vat79'></span>
              </div>
              <div className='css-1yjo05o'>
                {this.props.planPager === 6 ? (
                  <ul className='topnav'>
                    {this.props.companySettings.features.emergencyChecklist ===
                    true ? (
                      <li
                        id='secondaryNavigationItems'
                        className={this.props.reportsHighlight[0]}
                        onClick={() => {
                          this.setState({
                            viewUserList: false,
                            inputText: null
                          });
                          this.props.onEditBreadCrumbs({
                            type: 'REMOVE_BREADCRUMB',
                            breadCrumbs: 'Reports'
                          });
                          this.props.viewReports({
                            planPager: 6,
                            subPlanPager: 1
                          });
                        }}
                      >
                        Checklists
                      </li>
                    ) : null}
                    {this.props.companySettings.features.flash === true ? (
                      <li
                        id='secondaryNavigationItems'
                        className={this.props.reportsHighlight[1]}
                        onClick={() => {
                          this.setState({
                            viewUserList: false,
                            inputText: null
                          });
                          this.props.viewReports({
                            planPager: 6,
                            subPlanPager: 2
                          });
                          this.props.onEditBreadCrumbs({
                            type: 'REMOVE_BREADCRUMB',
                            breadCrumbs: 'Reports'
                          });
                        }}
                      >
                        Flash
                      </li>
                    ) : null}
                    {this.props.companySettings.features.library === true ? (
                      <li
                        id='secondaryNavigationItems'
                        className={this.props.reportsHighlight[2]}
                        onClick={() => {
                          this.setState({
                            viewUserList: false,
                            inputText: null
                          });
                          this.props.onEditBreadCrumbs({
                            type: 'REMOVE_BREADCRUMB',
                            breadCrumbs: 'Library'
                          });
                          this.props.onViewLibrary({ planPager: 6 });
                        }}
                      >
                        Library View
                      </li>
                    ) : null}
                    {this.props.companySettings.vaccineTracking === true ? (
                      <Fragment>
                        <li
                          id='secondaryNavigationItems'
                          className={this.props.reportsHighlight[3]}
                          onClick={() => {
                            this.setState({
                              viewUserList: false,
                              inputText: null
                            });
                            this.props.onEditBreadCrumbs({
                              type: 'REMOVE_BREADCRUMB',
                              breadCrumbs: 'COVID-19 Vaccines'
                            });
                            this.props.onViewUserList({
                              planPager: 6,
                              subPlanPager: 0
                            });
                          }}
                        >
                          COVID-19 Vaccines
                        </li>
                      </Fragment>
                    ) : null}
                    {this.props.companySettings.healthScreen === true &&
                    this.props.companySettings.features.forms === true ? (
                      <Fragment>
                        <li
                          id='secondaryNavigationItems'
                          className={this.props.reportsHighlight[4]}
                          onClick={() => {
                            this.setState({
                              viewUserList: false,
                              inputText: null
                            });
                            this.props.onEditBreadCrumbs({
                              type: 'REMOVE_BREADCRUMB',
                              breadCrumbs: 'Health Screen'
                            });
                            this.props.viewCompletedForms({ planPager: 6 });
                          }}
                        >
                          Health Screen
                        </li>
                      </Fragment>
                    ) : null}
                  </ul>
                ) : (
                  <ul className='topnav'>
                    <li
                      id='secondaryNavigationItems'
                      onClick={this.viewClientRepo}
                      className={this.state.highlight[1]}
                    >
                      {this.state.clientName}
                    </li>
                    {/* <li id="secondaryNavigationItems" onClick={this.viewGenRepo} className={this.state.highlight[0]}>General</li> */}
                  </ul>
                )}
              </div>
            </div>
            <div className='MuiTableContainer-root css-13ypi1f'>
              <div style={{ padding: '0.75rem 1.5rem' }}>
                <Row>
                  {this.props.role === 'Root' ? (
                    <Col lg={3}>
                      <select
                        id='selectClientUserTable'
                        onChange={this.handleClientFilterChange}
                      ></select>
                    </Col>
                  ) : null}

                  {this.props.planPager === 6 ? (
                    <Fragment>
                      <Col lg={5}>
                        <div className='dateRange'>
                          <div className='startDate'>
                            <DatePicker
                              selected={this.state.startDate}
                              onChange={this.handleStartDateChange}
                              className='datepickerStyle'
                            />
                          </div>
                          <div className='endDate'>
                            <DatePicker
                              selected={this.state.lastDate}
                              onChange={this.handleLastDateChange}
                              className='datepickerStyle'
                            />
                          </div>
                          <button
                            className='go'
                            title='Go'
                            onClick={() =>
                              this.props.onfilterTable({
                                type: 'FILTER_LIBRARY_BY_DATE_RANGE',
                                startdate: this.state.startDate,
                                lastdate: this.state.lastDate
                              })
                            }
                          >
                            Go
                          </button>
                        </div>
                      </Col>
                      <Col>
                        <div className='printbutton'>
                          <CSVLink
                            data={this.state.csvArr}
                            filename={'Library Full Report.csv'}
                          >
                            <AiFillPrinter
                              height={35}
                              width={35}
                              alt='Print'
                              className='icon_button icon-button-print'
                              onClick={() =>
                                this.getCSVData(
                                  this.props.viewFilteredList
                                    ? this.props.filteredList
                                    : this.props.repository
                                )
                              }
                            />
                          </CSVLink>
                        </div>
                      </Col>
                    </Fragment>
                  ) : null}
                </Row>
                {this.state.viewUploadBtns && this.props.planPager !== 6 ? (
                  <>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                      <FolderAdd />
                      <FileUpload />
                    </div>
                  </>
                ) : null}
              </div>

              <div className='MuiBox-root css-17uua2w'>
                <LibraryTreeView />
              </div>
            </div>
          </div>
        </div>

        <dialog id='editFolderDialog' className='bigDialogBox'>
          <GrClose
            id='exit-dialog-icon'
            height={28}
            onClick={this.hideEditFolderModal}
            alt='exit button'
          />
          <Row>
            <Col>
              <h2>Edit Folder</h2>
            </Col>
          </Row>

          <Row>
            <Col>
              <label>Folder Name</label>
              <input
                type='text'
                value={this.state.folderName}
                onChange={this.handleFolderNameChange}
                placeholder='Folder Name'
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='btn--flex'>
                <button
                  onClick={this.handleEditFolder}
                  type='button'
                  className='btn--confirm dialog__btn'
                >
                  Confirm
                </button>
                <button
                  onClick={this.hideEditFolderModal}
                  type='button'
                  className='btn--close dialog__btn'
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </dialog>

        <dialog id='setPasswordDialog' className='bigDialogBox'>
          <GrClose
            id='exit-dialog-icon'
            height={28}
            onClick={this.hideSetPasswordModal}
            alt='exit button'
          />
          <Row>
            <Col>
              <h2>Set Password</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Password</label>
              <input
                type='text'
                value={this.state.password}
                onChange={this.handlePasswordChange}
                placeholder='Password'
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='btn--flex'>
                <button
                  onClick={this.handleSetPassword}
                  type='button'
                  className='btn btn--confirm dialog__btn'
                >
                  Confirm
                </button>
                <button
                  onClick={this.hideSetPasswordModal}
                  type='button'
                  className='btn--close dialog__btn'
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </dialog>

        <dialog id='editPasswordDialog' className='bigDialogBox'>
          <GrClose
            id='exit-dialog-icon'
            height={28}
            onClick={this.hideEditPasswordModal}
            alt='exit button'
          />
          <h2>Edit Password</h2>
          <Row>
            <Col>
              <label>Password</label>
              <input
                type='text'
                value={this.state.newPassword}
                onChange={this.handleNewPasswordChange}
                placeholder='Password'
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='btn--flex'>
                <button
                  onClick={this.handleEditPassword}
                  type='button'
                  className='btn btn--confirm dialog__btn'
                >
                  Update
                </button>
                <button
                  onClick={this.handleRemovePassword}
                  type='button'
                  className='btn btn--confirm dialog__btn'
                >
                  Remove
                </button>
                <button
                  onClick={this.hideEditPasswordModal}
                  type='button'
                  className='btn btn--close dialog__btn'
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </dialog>
      </main>
    );
  }
}

export default LibraryTable;
