import React, { Component } from 'react';
import Exit from './style/exit.png';
import "./style/addUserDialog.css";
import { GrClose } from 'react-icons/gr';

class AddFolderDialog extends Component {
    constructor() {
        super();
        this.state = {
            folderName: '',
        }
        this.addFolder = this.addFolder.bind(this)
        this.handleFolderNameChange = this.handleFolderNameChange.bind(this)
        this.showModal = this.showModal.bind(this)
        this.hideModal = this.hideModal.bind(this)
    }
    showModal () {
        this.props.onEditBreadCrumbs({
            type: 'APPEND_BREADCRUMB',
            breadCrumbs: " > Add Folder"
        })
        const addFolderDialog = document.getElementById('addFolderDialog');
        addFolderDialog.showModal();
        // displays the modal and empties the fields
        this.setState({ folderName: '' });
    }
    hideModal () {
        this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Library"
        })
        const addFolderDialog = document.getElementById('addFolderDialog');
        addFolderDialog.close();
    }
    addFolder() {
        this.props.onAddFolder({
            folderName: this.state.folderName
        });
        this.hideModal();
    }
    handleFolderNameChange(event) {
        this.setState({folderName: event.target.value})
    }
    render() {
        return (
            <section>
                <nav role="navigation">
                    <button type="button" onClick={this.showModal} className="btn">
                        Add Folder
                    </button>
                </nav>
                <dialog id="addFolderDialog" className="bigDialogBox">
                    <GrClose id="exit-dialog-icon" src={Exit} height={28} onClick={this.hideModal} alt="exit button"/>
                    <h2>Add Folder</h2>
                    <br/>
                    <input type="text" value={this.state.folderName} onChange={this.handleFolderNameChange} placeholder="Folder Name"/>
                    <br/>
                    <div className="btn--flex">
                        <button onClick={this.addFolder} type="button" className="btn--confirm dialog__btn">
                            Confirm
                        </button>
                        <button onClick={this.hideModal} type="button" className="btn--close dialog__btn">
                            Cancel
                        </button>
                    </div>
                </dialog>
            </section>
        )
    }

}
export default AddFolderDialog;