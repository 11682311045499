import { useEffect } from 'react';
import { useSelector } from '@legendapp/state/react';

import Box from '@mui/material/Box';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { ClickAwayListener, Container, LinearProgress } from '@mui/material';

import FileItems from './FileItems';
import FolderItems from './FolderItems';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { isObjectEmpty } from 'utils/data';

import Store from '../../store/Store';

const LibraryTreeView = () => {
  const rootFolder = useSelector(Store.library.rootFolder);
  const rootFolderID = useSelector(Store.library.rootFolderID);
  const isLoading = isObjectEmpty(rootFolder);

  useEffect(() => {
    return () => {
      Store.library.selectedFolder.set(null);
    };
  }, []);

  const onSelectedItemChange = (e, nodeId) => {
    try {
      e.stopPropagation();
      // determine if folder or file (files have 'companies' in id)
      const isFile = nodeId.split('/').includes('companies');

      if (isFile) {
        Store.library.selectedFolder.set('');
        return;
      }

      Store.library.selectedFolder.set(nodeId);
    } catch (error) {
      console.log('error: ', error);
      return;
    }
  };

  const onClickAway = () => {
    const isDialogOpen = Store.library.isAddOrUploadDialogOpen.get();

    if (isDialogOpen) {
      return;
    }

    Store.library.selectedFolder.set('');
  };

  return (
    <Box
      sx={{
        minHeight: 180,
        flexGrow: 1
      }}
    >
      <div
        style={{
          paddingLeft: '2rem',
          marginBottom: '0.5rem',
          borderBottom: '1px solid #e0e0e0'
        }}
      >
        <div className='library__tree-item__content'>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                width: '15rem',
                textTransform: 'uppercase',
                fontSize: '0.75rem',
                marginLeft: '36px'
              }}
            >
              Name
            </div>
          </div>
          <div
            style={{
              width: '400px',
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              marginRight: '1.5rem'
            }}
          >
            <span
              style={{
                width: '195px',
                textTransform: 'uppercase',
                fontSize: '0.75rem',
                marginRight: '1.5rem'
              }}
            >
              Date
            </span>
            <span
              style={{
                width: '20%',
                textTransform: 'uppercase',
                fontSize: '0.75rem'
              }}
            >
              Type
            </span>
            <span
              style={{
                width: '30%'
              }}
            ></span>
          </div>
        </div>
      </div>
      {isLoading && (
        <Container maxWidth='md' sx={{ mt: '4rem' }}>
          <LinearProgress />
        </Container>
      )}
      <ClickAwayListener onClickAway={onClickAway}>
        <TreeView
          aria-label='icon expansion'
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{
            display: 'block',
            width: '100%',
            overflowY: 'auto',
            maxHeight: '66vh'
          }}
          onNodeSelect={(e, nodeIds) => onSelectedItemChange(e, nodeIds)}
        >
          {rootFolder?.folders && rootFolder.folders.length > 0 && (
            <FolderItems folders={rootFolder.folders} />
          )}

          {rootFolder?.files && rootFolder.files.length > 0 && (
            <FileItems files={rootFolder.files} parentFolderID={rootFolderID} />
          )}
        </TreeView>
      </ClickAwayListener>
    </Box>
  );
};

export default LibraryTreeView;
