/* High Level: Generates the page that displays all the users, a main
 *             navigation bar, buttons to add new users and a search box.
 * Purpose: Enables the admin to manage the users in case of an emergency
 *          and allows the clients to input their own users in bulk and filter
 *          the users by categories.
 * Approach: Get the users from Firebase to fill up the list. Allow clients
 *           to add their own users through the buttons.
 */
import React, { Component, Fragment } from 'react';

import Input from 'react-phone-number-input/input';
import { CSVLink } from 'react-csv';
import DatePicker from 'react-datepicker';
// import Select from 'react-select';
import Tooltip from '@material-ui/core/Tooltip';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  withStyles
  //  makeStyles
} from '@material-ui/core/styles';

import AddUserDialog from './addUserDialog';
import AddManyUsersDialog from './addManyUsersDialog';
import UserLocationMap from './userLocation';
import TableWithPagination from './TableWithPagination';

import { ImPencil } from 'react-icons/im';
import { BiCheckCircle, BiCircle, BiReset } from 'react-icons/bi';
import { BsCircle } from 'react-icons/bs';
import { BsCheckCircle } from 'react-icons/bs';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';
import {
  AiFillDelete,
  // AiFillFile,
  // AiFillFolder,
  // AiFillLock,
  AiFillPrinter
  // AiFillUnlock,
  // AiOutlineDownload,
  // AiTwotoneFolderOpen
} from 'react-icons/ai';

import Styles from './Styles';
import Bell from './style/bell.png';
import Uncheck from './style/uncheck-round.png';
import Print from './style/print.png';
// import Check from './style/check-round.png';
// import TrashCan from './style/delete.png';
// import Edit from './style/edit.png';
// import Exit from './style/exit.png';
// import Reset from './style/reset_btn.png';
// import Mail from './style/mail.png';
// import Location from './style/location.png';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import './style/UserTable.css';

// get the doc reference from a UID when adding user to ERP
// const db = FBapp.firestore();
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: 'black',
    border: '1px solid black',
    boxShadow: theme.shadows[1],
    fontSize: 12
  }
}))(Tooltip);

class UserTable extends Component {
  // gets the data thats generated from userPage.js which fills up the table
  constructor() {
    super();
    this.addUserDialogRef = React.createRef();
    // using these fields to handle the user input
    this.state = {
      index: 0, // index of the selected user for edit
      id: '', // user's id
      profilePicture: '', // picture url
      firstName: '', // User's First Name
      lastName: '', // User's Last Name
      email: '', // Users Email
      phone: '', // User's phone number,
      emergencyPhone: '', //User's emergency contact phone number,
      emergencyName: '', //User's emergency contact full name,
      password: '', // Users's password
      imgSrc: '', // Stores the profilePicture source
      otherUser: '', // {String} the other users UID
      role: '', // {String} user's role
      inputText: null, // {Obj for React Select} = {label: string, value: string}
      csvArr: [],
      prevData: [],
      startDate: new Date(),
      lastDate: new Date(),
      appOnly: true,
      userLocation: { lat: 0, lng: 0 },
      locationUserName: '',
      userLocationAddress: ''
    };
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.handlePicChange = this.handlePicChange.bind(this);
    this.handleEditUser = this.handleEditUser.bind(this);
    this.handleToggleChange = this.handleToggleChange.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleEmergencyNameChange = this.handleEmergencyNameChange.bind(this);
    this.handleEmergencyPhoneChange =
      this.handleEmergencyPhoneChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleClientFilterChange = this.handleClientFilterChange.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.getCSVData = this.getCSVData.bind(this);
    this.getPrevData = this.getPrevData.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleLastDateChange = this.handleLastDateChange.bind(this);
    this.sendPasswordResetEmail = this.sendPasswordResetEmail.bind(this);
    this.getUserLocation = this.getUserLocation.bind(this);
    this.showUserLocation = this.showUserLocation.bind(this);
    this.showLocationModal = this.showLocationModal.bind(this);
    this.hideLocationModal = this.hideLocationModal.bind(this);
  }

  getPrevData(data) {
    // // console.log(data);

    //before edited data
    let arr = [];
    arr.push({
      profilePicture: data.profilePicture,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: '+1' + data.phone,
      emergencyName: data.emergencyName ? data.emergencyName : '',
      emergencyPhone: data.emergencyPhone ? data.emergencyPhone : '',
      index: data.index,
      password: data.password,
      role: data.role,
      id: data.id
    });

    this.setState({ prevData: arr });
  }

  createLog() {
    //// console.log(arr[0]['name'])
    // console.log(this.state.prevData[0]['phone']);
    // console.log(this.state.phone);

    if (this.state.prevData.length !== 0) {
      if (this.state.prevData[0]['firstName'] !== this.state.firstName) {
        this.props.createUserLog({
          logType: 'edited',
          source: 'web-console',
          oldValue: this.state.prevData[0]['firstName'],
          newValue: this.state.firstName,
          document: this.state.prevData[0]['id']
        });
      }
      if (this.state.prevData[0]['lastName'] !== this.state.lastName) {
        this.props.createUserLog({
          logType: 'edited',
          source: 'web-console',
          oldValue: this.state.prevData[0]['lastName'],
          newValue: this.state.lastName,
          document: this.state.prevData[0]['id']
        });
      }
      if (this.state.prevData[0]['email'] !== this.state.email) {
        this.props.createUserLog({
          logType: 'edited',
          source: 'web-console',
          oldValue: this.state.prevData[0]['email'],
          newValue: this.state.email,
          document: this.state.prevData[0]['id']
        });
      }
      if (this.state.prevData[0]['phone'] !== this.state.phone) {
        this.props.createUserLog({
          logType: 'edited',
          source: 'web-console',
          oldValue: this.state.prevData[0]['phone'],
          newValue: this.state.phone,
          document: this.state.prevData[0]['id']
        });
      }
      if (
        this.state.prevData[0]['emergencyName'] !== this.state.emergencyName
      ) {
        this.props.createUserLog({
          logType: 'edited',
          source: 'web-console',
          oldValue: this.state.prevData[0]['emergencyName'],
          newValue: this.state.emergencyName,
          document: this.state.prevData[0]['id']
        });
      }
      if (
        this.state.prevData[0]['emergencyPhone'] !== this.state.emergencyPhone
      ) {
        this.props.createUserLog({
          logType: 'edited',
          source: 'web-console',
          oldValue: this.state.prevData[0]['emergencyPhone'],
          newValue: this.state.emergencyPhone,
          document: this.state.prevData[0]['id']
        });
      }
      if (this.state.prevData[0]['password'] !== this.state.password) {
        this.props.createUserLog({
          logType: 'edited',
          source: 'web-console',
          oldValue: this.state.prevData[0]['password'],
          newValue: this.state.password,
          document: this.state.prevData[0]['id']
        });
      }

      if (this.state.prevData[0]['role'] !== this.state.role) {
        this.props.createUserLog({
          logType: 'edited',
          source: 'web-console',
          oldValue: this.state.prevData[0]['role'],
          newValue: this.state.role,
          document: this.state.prevData[0]['id']
        });
      }
    }
  }

  handleEditUser() {
    // get user index using user id from row.original
    const index = this.props.userList.findIndex(
      (user) => user.id === this.state.id
    );
    // get current data for user from userList
    // const targetUser = this.props.userList[index];
    // has email changed?
    // const emailHasChanged = targetUser.email !== this.state.email;

    // if so, check if new email already exists in DB
    // const newEmailNotAvailable = this.props.gUserList.some(
    //   (user) => user.email === this.state.email && user.id !== this.state.id
    // );

    if (this.state.phone.length !== 12) {
      alert('Please Input 10 digits for your phone number.');
    } else if (
      this.state.email.indexOf('@') === -1 ||
      this.state.email.length < 7
    ) {
      alert('Please make sure you enter a valid email');
      // User email update is disabled for now
      // } else if (emailHasChanged && newEmailNotAvailable) {
      //   // assume email has been changed and new email already exists
      //   alert('Another user already has the same email.');
    } else {
      // sending data to userPage to update the user list
      this.props.onEditUser({
        profilePicture: this.state.profilePicture,
        name: this.state.firstName + ' ' + this.state.lastName,
        email: this.state.email,
        phone: this.state.phone.slice(2), //remove the first two characters of the number
        emergencyName: this.state.emergencyName,
        emergencyPhone: this.state.emergencyPhone,
        index: this.state.index !== index ? index : this.state.index,
        password: this.state.password,
        role: this.state.role
      });
      this.createLog();
      this.hideModal();
    }
  }

  handleClientFilterChange(event) {
    this.props.onClientFilterChange({
      name: event.target.value
    });
  }
  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }
  handleToggleChange(fullRow) {
    let email = fullRow.fullRow.email;
    this.props.onToggleUser({
      index: this.props.gUserList.findIndex((obj) => obj.email === email),
      email: email
    });
  }
  handlePicChange(event) {
    event.preventDefault();
    let reader = new FileReader();
    // get the file thats been uploads
    let file = event.target.files[0];
    // after the file finished loading
    reader.onloadend = () => {
      this.setState({
        profilePicture: file,
        imgSrc: reader.result
      });
    };
    reader.readAsDataURL(file);
  }
  handleRoleChange(event) {
    this.setState({ role: event.target.value });
  }
  handleFirstNameChange(event) {
    this.setState({ firstName: event.target.value });
  }
  handleLastNameChange(event) {
    this.setState({ lastName: event.target.value });
  }
  handleEmailChange(event) {
    // disable email change for now
    // this.setState({ email: event.target.value });
    return;
  }
  handlePhoneChange(num) {
    this.setState({ phone: num });
  }
  handleEmergencyNameChange(event) {
    this.setState({ emergencyName: event.target.value });
  }
  handleEmergencyPhoneChange(num) {
    this.setState({ emergencyPhone: num });
  }
  showModal() {
    this.props.onEditBreadCrumbs({
      type: 'APPEND_BREADCRUMB',
      breadCrumbs: ' > Edit User'
    });

    var editUserRole = document.getElementById('editUserRole');
    editUserRole.innerHTML = '';
    const roleOptions = [
      'User',
      'External User',
      'Manager',
      'Super Admin',
      'Root'
    ];
    for (let role of roleOptions) {
      let curr = { text: role, value: role };
      // assume the user is not Root so they cant create a Root User
      if (role === 'Root' && this.props.role !== 'Root') continue;
      editUserRole.options.add(
        new Option(curr.text, curr.value, curr.selected)
      );
    }
    // addUserDialog.style.left = (this.props.toggleMenu) ? '105px' : '330px'
    // display edit user dialog
    this.addUserDialogRef.current.showModal();
  }
  hideModal() {
    // this somehow causes ResizeObserver loop limit exceeded error
    this.props.onEditBreadCrumbs({
      type: 'REMOVE_BREADCRUMB',
      breadCrumbs: 'Users'
    });

    this.addUserDialogRef.current.close();
  }
  handleStartDateChange = (date) => {
    // console.log("startDate:"+ date);
    this.setState({ startDate: date });
  };
  handleLastDateChange = (date) => {
    // console.log("lastDate:"+ date);
    this.setState({ lastDate: date });
  };
  getCSVData(tdata) {
    let len = tdata.length;
    let csv = [];
    var iDate = null;
    var sDate = null;
    var ini = null;
    var sec = null;

    for (let i = 0; i < len; i++) {
      if (this.props.subPlanPager === 0) {
        //covid reports
        if (tdata[i].covid19 !== undefined || tdata[i].covid19 === null) {
          ini =
            (tdata[i].covid19.initial !== null ||
              tdata[i].covid19.initial !== undefined) &&
            tdata[i].covid19.initial === true
              ? 'Checked'
              : 'Unchecked';
          sec =
            (tdata[i].covid19.secondary !== null ||
              tdata[i].covid19.secondary !== undefined) &&
            tdata[i].covid19.secondary === true
              ? 'Checked'
              : 'Unchecked';

          iDate = tdata[i].covid19.initialDate
            ? new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              }).format(tdata[i].covid19.initialDate.seconds * 1000)
            : 'Unavailable';
          sDate = tdata[i].covid19.secondaryDate
            ? new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              }).format(tdata[i].covid19.secondaryDate.seconds * 1000)
            : 'Unavailable';
        } else {
          ini = 'Unavailable';
          sec = 'Unavailable';
          iDate = 'Unavailable';
          sDate = 'Unavailable';
        }
        csv.push({
          Name: tdata[i].firstName + ' ' + tdata[i].lastName,
          'Initial Vaccine': ini,
          'Date Received - Initial': iDate,
          'Follow Up Vaccine': sec,
          'Date Received / Will Receive - Follow Up': sDate
        });
      }
      if (this.props.subPlanPager === 1) {
        //health screen reports
        if (
          tdata[i].healthScreen !== undefined ||
          tdata[i].healthScreen === null
        ) {
          var ans0 =
            tdata[i].healthScreen.answers[0] === undefined
              ? 'Unavailable'
              : tdata[i].healthScreen.answers[0]
              ? 'Yes'
              : 'No';
          var ans1 =
            tdata[i].healthScreen.answers[1] === undefined
              ? 'Unavailable'
              : tdata[i].healthScreen.answers[1]
              ? 'Yes'
              : 'No';
          var ans2 =
            tdata[i].healthScreen.answers[2] === undefined
              ? 'Unavailable'
              : tdata[i].healthScreen.answers[2]
              ? 'Yes'
              : 'No';
          var dateSigned =
            tdata[i].healthScreen.dateSigned === undefined
              ? 'Unavailable'
              : new Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                }).format(tdata[i].healthScreen.dateSigned.seconds * 1000);
          var checkIn =
            tdata[i].healthScreen.checkIn === undefined
              ? 'Unavailable'
              : tdata[i].healthScreen.checkIn
              ? 'Yes'
              : 'No';
        }

        csv.push({
          Name: tdata[i].firstName + ' ' + tdata[i].lastName,
          'Date Signed In':
            tdata[i].healthScreen !== undefined ||
            tdata[i].healthScreen === null
              ? dateSigned
              : '-',
          'Answer to Q1':
            tdata[i].healthScreen !== undefined ||
            tdata[i].healthScreen === null
              ? ans0
              : '-',
          'Answer to Q2':
            tdata[i].healthScreen !== undefined ||
            tdata[i].healthScreen === null
              ? ans1
              : '-',
          'Answer to Q3':
            tdata[i].healthScreen !== undefined ||
            tdata[i].healthScreen === null
              ? ans2
              : '-',
          'Check In ':
            tdata[i].healthScreen !== undefined ||
            tdata[i].healthScreen === null
              ? checkIn
              : '-'
        });
      }
    }
    this.setState({ csvArr: csv });
  }

  sendPasswordResetEmail() {
    let email = this.state.email;

    if (email) {
      // console.log(email);
      this.props.sendForgotPasswordLink(email);
      this.hideModal();
    }
  }

  getUserLocation(user_obj) {
    if (user_obj !== undefined) {
      let user_id = user_obj.id;
      let userLocation = this.props.getUserLocation({ user_id });
      console.log(user_obj.name);
      this.setState(
        {
          locationUserid: user_id,
          userLocation: userLocation,
          userLocationTime: userLocation.locationTime,
          userLocationAddress: userLocation.locationAddress,
          locationUserName: user_obj.name
        },
        () => {
          // this.showLocationModal()
        }
      );
    }
  }

  async showUserLocation(user_obj) {
    // let location = {lat:'',lng:''};
    // let locationTime = 'No Location Available';
    // if(user_obj.location !== undefined){
    //   let temp = user_obj.location.split(',')
    //   location = {lat:temp[0],lng:temp[1]}
    //   locationTime =  new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(user_obj.locationTime.seconds*1000);
    // }
    let location = { lat: '', lng: '' };
    let locationTime = 'No Location Available';
    let user_id = user_obj.id;
    await this.props.getUserLocation(user_id).then((userLocation) => {
      console.log(userLocation);
      if (
        userLocation.location !== undefined &&
        userLocation.location !== '' &&
        userLocation.location !== null
      ) {
        // locationTime = user_obj.
        let temp = userLocation.location.split(',');
        location = { lat: temp[0], lng: temp[1] };

        if (
          locationTime !== '' &&
          locationTime !== null &&
          locationTime !== undefined
        ) {
          locationTime = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          }).format(userLocation.locationTime.seconds * 1000);
        } else {
          locationTime = '';
        }
      }

      this.setState(
        {
          locationUserid: user_obj.id,
          userLocation: location,
          userLocationTime: locationTime,
          userLocationAddress: userLocation.locationAddress,
          locationUserName: user_obj.name
        },
        () => {
          this.showLocationModal();
        }
      );
    });
  }

  showLocationModal() {
    const userLocationDialog = document.getElementById('userLocationDialog');
    // userLocationDialog.style.width = (this.props.toggleMenu) ? '1280px' : ''
    userLocationDialog.showModal();
  }

  hideLocationModal() {
    const userLocationDialog = document.getElementById('userLocationDialog');
    userLocationDialog.close();
  }

  render() {
    var columns = [
      {
        Header: 'Users', //1
        columns: [
          {
            Header: '     ',
            Cell: ({ row }) => {
              return (
                <img
                  style={{ borderRadius: '50%', background: 'lightgrey' }}
                  src={row.original.profilePicture}
                  width='40'
                  height='40'
                  alt='profile'
                />
              );
            }
          },
          {
            Header: 'Name', //2
            accessor: 'name',
            Cell: ({ row }) => {
              let num = row.original.phone;
              let phone = !num
                ? ''
                : `- (${num.substr(0, 3)}) ${num.substr(3, 3)}-${num.substr(
                    6
                  )}`;

              const title = `${row.original.email} ${phone}`;

              return (
                <LightTooltip title={title} placement='top'>
                  <span>{row.original.name}</span>
                </LightTooltip>
              );
            }
          },
          {
            Header: 'Email', //3
            accessor: 'email'
          },
          {
            Header: 'Phone Number', //4
            Cell: ({ row }) => {
              let num = row.original.phone;
              let phone = !num ? (
                ''
              ) : (
                <span>
                  {`(${num.substr(0, 3)}) ${num.substr(3, 3)}-${num.substr(6)}`}
                </span>
              );

              return phone;
            }
          },
          {
            Header: 'User Type', //5
            Cell: ({ row }) => {
              let user_type = row.original.user_type;

              return user_type;
            }
          },
          {
            Header: 'Client Code', //6
            accessor: 'companyID'
          },
          {
            Header: 'Role', //7
            accessor: 'role'
          },
          {
            // deactived field (deleted or not) //8
            // DONOT SHOW IT UNDER DELETED TAB
            Header: 'Enabled',
            Cell: ({ row }) => {
              let deactivated = row.original.deactivated;
              // save as string and then do the logic
              return (
                <label className='switch' title='Enable Toggle'>
                  <input type='checkbox' defaultChecked={!deactivated} />
                  <span
                    className='slider round'
                    onClick={() => {
                      this.handleToggleChange({ fullRow: row.original });
                    }}
                  ></span>
                </label>
              );
            }
          },
          {
            // Edit button functionality //9
            // DONOT SHOW IT TO OTHERS EXCEPT USERS AND TEAMS
            Header: '  ',
            Cell: ({ row }) => {
              return (
                <ImPencil
                  title='Edit User'
                  height={25}
                  width={25}
                  alt='edit'
                  className='icon_button'
                  onClick={(event) => {
                    event.persist();
                    const rData = row.original; // updates the fields to display the current
                    let nameArr = rData.name.split(' '); // get the first/last name
                    const first = nameArr[0];
                    nameArr.shift();
                    // // console.log("En: "+rData.emergencyName);
                    const last = nameArr.join(' ');
                    this.setState({
                      email: rData.email,
                      firstName: first,
                      lastName: last,
                      imgSrc: rData.profilePicture,
                      id: rData.id,
                      index: row.index,
                      phone: '+1' + rData.phone,
                      emergencyName: rData.emergencyName
                        ? rData.emergencyName
                        : '',
                      emergencyPhone: rData.emergencyPhone
                        ? rData.emergencyPhone
                        : '',
                      password: rData.password,
                      role: rData.role
                    });

                    this.getPrevData(rData);
                    this.showModal();
                  }}
                />
              );
            }
          },
          {
            // Edit button functionality //10
            // DONOT SHOW IT TO OTHERS EXCEPT USERS AND TEAMS
            Header: '           ',
            Cell: ({ row }) => {
              let temp = '';
              if (row.original.user_type === 'APP') {
                return (
                  <FaMapMarkerAlt
                    title='Get User Location'
                    height={25}
                    width={25}
                    alt='location'
                    className='icon_button'
                    onClick={(event) => {
                      event.persist();
                      const rData = row.original; // updates the fields to display the current
                      let nameArr = rData.name.split(' '); // get the first/last name
                      // const first = nameArr[0];
                      nameArr.shift();
                      // // console.log("En: "+rData.emergencyName);
                      let location =
                        rData.location !== undefined ? rData.location : '';
                      let locationTime = rData.locationTime
                        ? rData.locationTime
                        : '';
                      this.showUserLocation({
                        id: rData.id,
                        name: rData.firstName + ' ' + rData.lastName,
                        location: location,
                        locationTime: locationTime
                      });
                    }}
                  />
                );
              } else {
                return temp;
              }
            }
          },
          {
            // Delete button functionality //11
            // DONOT show to others except under users and teams tab
            Header: ' ',
            Cell: (row) => {
              return (
                <AiFillDelete
                  title='Delete User'
                  height={25}
                  width={25}
                  alt='delete'
                  className='icon_button'
                  onClick={(event) => {
                    event.persist();
                    var rowIndex = row.row.id;
                    var res = window.confirm(
                      'Are you sure you want to delete the User?'
                    );
                    if (res) {
                      // Supposed to send the row ID of the User the admin wants to delete
                      this.props.onDeleteUser({ id: rowIndex });
                    }
                  }}
                />
              );
            }
          },
          //*************************COVID REPORTS FIELDS******************************
          //DONOT SHOW TO OTHERS EXCEPT IN REPORTS
          {
            Header: 'Initial Vaccine', //12
            Cell: ({ row }) => {
              if (
                'covid19' in row.original &&
                row.original.covid19 !== undefined &&
                row.original.covid19 !== null &&
                row.original.covid19.initial !== null
              ) {
                return <BiCheckCircle alt='Check' className='icon_button' />;
              }
              return (
                <BiCircle src={Uncheck} alt='Uncheck' className='icon_button' />
              );
            }
          },

          {
            Header: 'Date Received - Initial ', //13
            Cell: ({ row }) => {
              let result = '-';
              if (
                'covid19' in row.original &&
                row.original.covid19 !== undefined &&
                row.original.covid19 !== null &&
                row.original.covid19.initialDate !== null
              ) {
                result = row.original.covid19.initialDate;
                result = new Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
                }).format(result.seconds * 1000);
              }

              return result;
            }
          },
          {
            Header: 'Follow Up Vaccine', //14
            Cell: ({ row }) => {
              // // console.log("Planpager:"+this.props.planPager);
              if (
                'covid19' in row.original &&
                row.original.covid19 !== undefined &&
                row.original.covid19 !== null &&
                row.original.covid19.secondary !== null
              ) {
                return <BiCheckCircle alt='Check' className='icon_button' />;
              }
              return <BiCircle alt='Uncheck' className='icon_button' />;
            }
          },
          {
            Header: 'Date Received / Will Receive - FollowUp', //15
            Cell: ({ row }) => {
              let result = '-';
              if (
                'covid19' in row.original &&
                row.original.covid19 !== undefined &&
                row.original.covid19 !== null &&
                row.original.covid19.secondaryDate !== null
              ) {
                // assume that the ERP has tasks
                result = row.original.covid19.secondaryDate;
                result = new Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
                }).format(result.seconds * 1000);
              }
              if (
                'covid19' in row.original &&
                row.original.covid19 !== undefined &&
                row.original.covid19 !== null &&
                (row.original.covid19.secondaryDate !== undefined ||
                  row.original.covid19.secondaryDate === null) &&
                row.original.covid19.initialDate !== null
              ) {
                // assume that the ERP has tasks

                var future = new Date(
                  row.original.covid19.initialDate.seconds * 1000
                );
                result = new Date(
                  future.setDate(future.getDate() + 30)
                ).toLocaleString();
              }
              return result;
            }
          },
          {
            Header: ' ', //16
            Cell: ({ row }) => {
              var result;
              // Add if  today is less than dateFollowUpVaccine add a bell
              if (
                'covid19' in row.original &&
                row.original.covid19 !== undefined &&
                row.original.covid19 !== null &&
                row.original.covid19.secondaryDate !== null
              ) {
                // assume that the ERP has tasks
                result = row.original.covid19.secondaryDate;
                result = new Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
                }).format(result.seconds * 1000);
              }
              if (
                'covid19' in row.original &&
                row.original.covid19 !== undefined &&
                row.original.covid19 !== null &&
                (row.original.covid19.secondaryDate !== undefined ||
                  row.original.covid19.secondaryDate === null) &&
                row.original.covid19.initialDate !== null
              ) {
                // assume that the ERP has tasks

                var future = new Date(
                  row.original.covid19.initialDate.seconds * 1000
                );
                result = new Date(
                  future.setDate(future.getDate() + 30)
                ).toLocaleString();
              }
              if (
                new Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
                }).format(Date.now()) < result
              ) {
                return (
                  <img
                    title='Notify'
                    height={25}
                    width={25}
                    src={Bell}
                    alt='Notify'
                    className='icon_button'
                  />
                );
              }

              return null;
            }
          }
        ]
        //*************************COVID REPORTS FIELDS ENDS******************************
      }
    ];

    //SHOWN ONLY TO DELETED TAB
    if (this.props.viewDeletedUsers) {
      //14
      // assume the deleted Users table is displayed
      columns[0].columns.splice(9, 12); // remove the deleted button column and the covid reports button
      columns[0].columns.splice(7, 1); // remove the enabled column
      columns[0].columns.push({
        Header: 'Reactivate User',
        Cell: ({ row }) => (
          <button
            id='taskButton'
            onClick={() => {
              let res = window.confirm(
                'Are you sure you want to reactivate the user?'
              );
              if (res) {
                this.props.reactivateUser({
                  index: row.id,
                  UID: row.original.id
                });
              }
            }}
          >
            Reactivate
          </button>
        )
      });
    }

    if (this.props.planPager !== 6) {
      if (this.props.role !== 'Root') {
        // assume the user is a Super Admin
        columns[0].columns.splice(5, 1); // remove the client code column
        columns[0].columns.splice(10, 5);
      }
      if (this.props.role === 'Manager') {
        // assume the user is a Manager
        columns[0].columns.splice(10, 1); // remove the edit button column
        columns[0].columns.splice(10, 1); // remove the delete button column
      }
      columns[0].columns.splice(11, 5);
      columns[0].columns.splice(12, 6);
    } else {
      if (this.props.subPlanPager === 0) {
        //covid reports
        columns[0].columns.splice(2, 7);
        columns[0].columns.splice(7, 6);
      } else columns[0].columns.splice(2, 12); // health screening report
    }

    let addUserBtn = null;
    let bulkAddBtn = null;

    if (this.props.role === 'Super Admin' || this.props.role === 'Root') {
      // assume user is Super Admin or Root
      // initilaize the add User Button
      // // console.log("cl:"+this.props.clientList);
      addUserBtn = this.props.viewDeletedUsers ? null : (
        <li>
          <AddUserDialog
            onSubmit={this.props.onAddNewUser}
            clientList={this.props.clientList}
            onEditBreadCrumbs={this.props.onEditBreadCrumbs}
            toggleMenu={this.props.toggleMenu}
            role={this.props.role}
            companyID={this.props.companyID}
          />
        </li>
      );
      // initialize the Add Users in Bulk button
      bulkAddBtn = this.props.viewDeletedUsers ? null : (
        <li>
          <AddManyUsersDialog
            onSubmit={this.props.onAddManyNewUsers}
            onEditBreadCrumbs={this.props.onEditBreadCrumbs}
            clientList={this.props.clientList}
            companyID={this.props.companyID}
          />
        </li>
      );
    }

    let header = 'User List';
    if (this.props.planPager === 6) {
      if (this.props.subPlanPager === 0) header = 'COVID-19 Vaccines Report';
    }

    return (
      <main>
        <p id='breadCrumbs'>
          {this.props.planPager === 6 ? 'Reports' : this.props.breadCrumbs}
        </p>

        <div className='MuiBox-root'>
          <div className='MuiPaper-root'>
            <div className='MuiBox-root css-1idik3a'>
              <div className='MuiBox-root css-uujcwg'>
                <h5 className='css-epib0t'>{header}</h5>
                <span className='css-17vat79'></span>
              </div>
              <div className='css-1yjo05o'>
                {this.props.planPager !== 6 ? (
                  <Fragment>
                    <ul className='topnav'>
                      <li
                        id='secondaryNavigationItems'
                        onClick={this.props.onViewUserList}
                        className={this.props.highlight[0]}
                      >
                        Users
                      </li>
                      {this.props.companySettings.features.teams === true ? (
                        <li
                          id='secondaryNavigationItems'
                          onClick={this.props.onViewUserGroups}
                          className={this.props.highlight[1]}
                        >
                          Groups
                        </li>
                      ) : null}
                      <li
                        id='secondaryNavigationItems'
                        onClick={this.props.onViewDeletedUsers}
                        className={this.props.highlight[2]}
                      >
                        Deleted
                      </li>
                    </ul>
                  </Fragment>
                ) : (
                  <Fragment>
                    <ul className='topnav'>
                      <li
                        id='secondaryNavigationItems'
                        className={this.props.reportsHighlight[0]}
                        onClick={() => {
                          this.setState({
                            viewUserList: false,
                            inputText: null
                          });
                          this.props.onEditBreadCrumbs({
                            type: 'REMOVE_BREADCRUMB',
                            breadCrumbs: 'Reports'
                          });
                          this.props.viewReports({
                            planPager: 6,
                            subPlanPager: 1
                          });
                        }}
                      >
                        Checklists
                      </li>
                      <li
                        id='secondaryNavigationItems'
                        className={this.props.reportsHighlight[1]}
                        onClick={() => {
                          this.setState({
                            viewUserList: false,
                            inputText: null
                          });
                          this.props.viewReports({
                            planPager: 6,
                            subPlanPager: 2
                          });
                          this.props.onEditBreadCrumbs({
                            type: 'REMOVE_BREADCRUMB',
                            breadCrumbs: 'Reports'
                          });
                        }}
                      >
                        Flash
                      </li>
                      <li
                        id='secondaryNavigationItems'
                        className={this.props.reportsHighlight[2]}
                        onClick={() => {
                          this.setState({
                            viewUserList: false,
                            inputText: null
                          });
                          this.props.onEditBreadCrumbs({
                            type: 'REMOVE_BREADCRUMB',
                            breadCrumbs: 'Library'
                          });
                          this.props.onViewLibrary({ planPager: 6 });
                        }}
                      >
                        Library
                      </li>
                      <li
                        id='secondaryNavigationItems'
                        className={this.props.reportsHighlight[3]}
                        onClick={() => {
                          this.setState({
                            viewUserList: false,
                            inputText: null
                          });
                          this.props.onEditBreadCrumbs({
                            type: 'REMOVE_BREADCRUMB',
                            breadCrumbs: 'COVID-19 Vaccines'
                          });
                          this.props.onViewUserList({
                            planPager: 6,
                            subPlanPager: 0
                          });
                        }}
                      >
                        COVID-19 Vaccines
                      </li>

                      <li
                        id='secondaryNavigationItems'
                        className={this.props.reportsHighlight[4]}
                        onClick={() => {
                          this.setState({
                            viewUserList: false,
                            inputText: null
                          });
                          this.props.onEditBreadCrumbs({
                            type: 'REMOVE_BREADCRUMB',
                            breadCrumbs: 'Health Screen'
                          });
                          this.props.viewCompletedForms({ planPager: 6 });
                        }}
                      >
                        Health Screen
                      </li>
                    </ul>
                  </Fragment>
                )}
              </div>
            </div>

            <div className='MuiTableContainer-root css-13ypi1f'>
              <div className='MuiBox-root css-fgimv3'>
                <nav>
                  {/* <Container fluid> */}
                  <Row>
                    <Col lg={3}>
                      <div className='search'>
                        <input
                          id='search'
                          placeholder='Search User'
                          autoComplete='none'
                          value={this.state.inputText || ''}
                          onChange={(event) => {
                            this.props.onfilterTable({
                              type: 'FILTER_USER_LIST',
                              value: event.target.value
                            });

                            let inputText = event.target.value;

                            this.setState({ inputText: inputText });
                          }}
                        />

                        <BiReset
                          id='resetTableListBtn'
                          alt='Reset'
                          className='icon_button'
                          onClick={() => {
                            if (this.props.viewDeletedUsers) {
                              this.props.onViewDeletedUsers();
                            } else {
                              this.props.onViewUserList({
                                pageInfo: {
                                  planPager: 2,
                                  subPlanPager: this.props.subPlanPager
                                },
                                appOnly: this.props.appOnlyUsers
                              });
                            }
                            this.setState({ inputText: null });
                          }}
                        />
                      </div>
                    </Col>

                    {this.props.role === 'Root' &&
                    this.props.viewDeletedUsers === false ? (
                      <Col lg={3}>
                        <select
                          id='selectClientUserTable'
                          onChange={this.handleClientFilterChange}
                        ></select>
                      </Col>
                    ) : null}
                    {this.props.planPager === 2 &&
                    this.props.appOnlyUsers === true ? (
                      <Col lg={2}>
                        <div
                          id='appOnlyCheckBox'
                          onClick={() => {
                            this.props.onViewUserList({
                              pageInfo: {
                                planPager: 2,
                                subPlanPager: this.props.subPlanPager
                              },
                              appOnly: false
                            });
                          }}
                        >
                          <BsCheckCircle
                            id='appOnlyCheck'
                            alt='App Only Users'
                            className='icon_button'
                          />
                          App Only
                        </div>
                      </Col>
                    ) : null}

                    {this.props.planPager === 2 &&
                    this.props.appOnlyUsers === false ? (
                      <Col lg={2}>
                        <div
                          id='appOnlyCheckBox'
                          onClick={() => {
                            this.props.onViewUserList({
                              pageInfo: {
                                planPager: 2,
                                subPlanPager: this.props.subPlanPager
                              },
                              appOnly: true
                            });
                          }}
                        >
                          <BsCircle
                            id='appOnlyCheck'
                            alt='App Only Users'
                            className='icon_button'
                          />
                          App Only
                        </div>
                      </Col>
                    ) : null}

                    {this.props.planPager === 4 ? (
                      <Fragment>
                        <Col lg={3}>
                          <div className='dateRange'>
                            <div className='startDate'>
                              <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handleStartDateChange}
                                className='datepickerStyle'
                              />
                            </div>
                            <div className='endDate'>
                              <DatePicker
                                selected={this.state.lastDate}
                                onChange={this.handleLastDateChange}
                                className='datepickerStyle'
                              />
                            </div>
                            <button
                              className='go'
                              title='Go'
                              onClick={() =>
                                this.props.onfilterTable({
                                  type: 'FILTER_HEALTHSCREENREPORT_BY_DATE_RANGE',
                                  startdate: this.state.startDate,
                                  lastdate: this.state.lastDate
                                })
                              }
                            >
                              Go
                            </button>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div>
                            <CSVLink
                              data={this.state.csvArr}
                              filename={'Covid19 Vaccines Report.csv'}
                            >
                              <AiFillPrinter
                                height={35}
                                width={35}
                                alt='Print'
                                className='icon_button icon-button-print'
                                src={Print}
                                onClick={() =>
                                  this.getCSVData(
                                    this.props.viewFilteredList
                                      ? this.props.filteredList
                                      : this.props.userList
                                  )
                                }
                              />
                            </CSVLink>
                          </div>
                        </Col>
                      </Fragment>
                    ) : null}

                    {this.props.planPager === 2 ? (
                      <Fragment>
                        <Col lg={4}>
                          <ul className='addItem'>
                            {addUserBtn}
                            {bulkAddBtn}
                          </ul>
                        </Col>
                      </Fragment>
                    ) : null}
                  </Row>
                  {/* </Container> */}
                </nav>
              </div>
              <div className='MuiBox-root css-17uua2w'>
                <TableWithPagination
                  id='userTable'
                  responsive
                  columns={columns}
                  autoResetPage={false}
                  data={
                    this.props.viewFilteredList
                      ? this.props.filteredList
                      : this.props.userList
                  }
                />
                {/* <Container>
								<Row>
									<Col>
									</Col>
								</Row>
							</Container> */}
              </div>
            </div>
          </div>
        </div>

        <Styles>
          {/* gets the user data from the userPage class and fills in the table */}
        </Styles>
        {/* Supposed to display when the user clicks on the edit user button */}
        {this.props.userList[this.state.index] !== undefined &&
        this.props.userList[this.state.index] !== null ? (
          <dialog
            id='addUserDialog'
            className='bigDialogBox'
            ref={this.addUserDialogRef}
          >
            <GrClose
              id='exit-dialog-icon'
              height={28}
              onClick={this.hideModal}
              alt='close pop up'
            />
            <h2>Edit User</h2>

            <Row>
              <Col lg={2}>
                <label>Profile Picture</label>
                <label className='inputProfile'>
                  <input
                    type='file'
                    accept='image/*'
                    id='uploadImage'
                    onChange={this.handlePicChange}
                  />
                  <img src={this.state.imgSrc} alt='profile' />
                </label>
              </Col>
              <Col lg={10}>
                <Row>
                  <Col lg={4}>
                    <label>First Name</label>
                    <input
                      type='text'
                      value={this.state.firstName}
                      onChange={this.handleFirstNameChange}
                      placeholder='First Name'
                    />
                  </Col>
                  <Col lg={4}>
                    <label>Last Name</label>
                    <input
                      type='text'
                      value={this.state.lastName}
                      onChange={this.handleLastNameChange}
                      placeholder='Last Name'
                    />
                  </Col>
                  <Col lg={4}>
                    <label>Phone Number</label>
                    <Input
                      country='US'
                      value={this.state.phone}
                      onChange={this.handlePhoneChange}
                      maxLength={14}
                      placeholder='Phone Number'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <label>Email</label>
                    <input
                      type='email'
                      value={this.state.email}
                      className='disabled-input'
                      disabled={true}
                      onChange={this.handleEmailChange}
                      placeholder='Email'
                      autoComplete='off'
                    />
                  </Col>
                  <Col lg={4}>
                    <label>Emergency Contact: Full Name</label>
                    <input
                      type='text'
                      value={this.state.emergencyName}
                      onChange={this.handleEmergencyNameChange}
                      placeholder='Emergency Full Name'
                    />
                  </Col>
                  <Col lg={4}>
                    <label>Emergency Contact: Phone Number</label>
                    <Input
                      country='US'
                      value={this.state.emergencyPhone}
                      onChange={this.handleEmergencyPhoneChange}
                      maxLength={14}
                      placeholder='Emergency Contact: Phone Number'
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <label>Admin Level:</label>
                    <select
                      id='editUserRole'
                      value={this.state.role}
                      onChange={this.handleRoleChange}
                    >
                      <option value='User'>User</option>
                      <option value='Manager'>Manager</option>
                      <option value='Super Admin'>Super Admin</option>
                      <option value='Root'>Root</option>
                    </select>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    {this.props.userList[this.state.index].id ===
                    this.props.uid ? (
                      <Fragment>
                        <input
                          type='password'
                          value={this.state.password}
                          onChange={this.handlePasswordChange}
                          placeholder='Password'
                        />
                        <br />
                      </Fragment>
                    ) : null}
                    <div className='btn--flex'>
                      <button
                        style={{ minWidth: '110px' }}
                        onClick={this.handleEditUser}
                        type='button'
                        className='btn btn--confirm dialog__btn'
                      >
                        Confirm
                      </button>
                      <button
                        style={{ minWidth: '185px' }}
                        onClick={this.sendPasswordResetEmail}
                        type='button'
                        className='btn btn--reset dialog__btn'
                      >
                        Password Reset
                      </button>
                      <button
                        onClick={this.hideModal}
                        type='button'
                        className='btn--close dialog__btn'
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </dialog>
        ) : null}

        <dialog
          id='userLocationDialog'
          className='bigDialogBox'
          style={{ height: '700px' }}
        >
          <div id='mapWrapper'>
            <GrClose
              id='exit-dialog-icon'
              height={28}
              onClick={this.hideLocationModal}
              alt='close pop up'
            />
            <h2>{this.state.locationUserName}</h2>
            <div style={{ width: '980px', height: '500px' }}>
              <UserLocationMap
                user_id={this.state.locationUserid}
                address={this.state.userLocationAddress}
                lat={this.state.userLocation.lat}
                lng={this.state.userLocation.lng}
                userLocation={this.state.userLocation}
                locationTime={this.state.userLocationTime}
                userName={this.state.locationUserName}
              />
            </div>
          </div>

          {/* <div className="btn--flex">

            <button onClick={this.hideLocationModal} type="button" className="btn btn--close dialog__btn">Close</button>
          </div> */}
        </dialog>
      </main>
    );
  }
}
export default UserTable;
