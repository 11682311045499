/* High Level: Displays a list of all the ERPs with the Response Plan name,
 * 			   Client Code, # of Users, Tasks, Roll Call, Enabled, Active,
 * 			   Practice, edit button and delete button
 * Purpose: Displays a list of all the ERPs and edit/delete buttons for each ERP.
 * Approach: Handles the edit/delete ERP changes which entails adding titles, adding users
 * 			 and adding tasks to the ERP.
 */
import React, { Component, Fragment } from 'react';

import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { CSVLink } from 'react-csv';
import CsvDownloader from 'react-csv-downloader';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import ReportDownload from './ReportDownload';

import Table from '../Table';

import { BiReset } from 'react-icons/bi';
import { AiFillPrinter } from 'react-icons/ai';
import { GrClose } from 'react-icons/gr';

import ProfilePreview from '../style/flash_icon.png';
import Print from '../style/print.png';

// import FBapp from '../firebaseConfig'

import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import '../style/reportsPage.css';

// get the doc reference from a UID when adding user to ERP
// const db = FBapp.firestore();
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#21408b',
    color: '#ffffff',
    boxShadow: theme.shadows[1],
    fontSize: 14
  }
}))(Tooltip);

class Reports extends Component {
  constructor() {
    super();
    this.state = {
      // isActive:, // indicates whether the Check List is active & on edit, before removing task, check if activated
      editCheckList: false, // {Boolean} true: editing a Check List & false: creating a new one
      currUserName: '', // Edit User's Name displayed in Edit Title Box
      currUserTitle: '', // Edit User's Title displayed in Edit Title Box
      // states that are updated on edit
      planName: '', // name of the ERP
      leader: '', // name of the Leader
      userList: [], // array of doc references (users)
      titleList: [], // array of strings (titles)
      userStatus: {}, // map of JS objects (key: user ref ID / value: {isCheckedIn: bool, title: string})
      index: 0, // index of the selected ERP
      // CREATE_TASK Values
      taskNameChangeMap: [], // hmap of key/value pairs {key: taskID / value: new task name }
      newTasks: [], // {array of Objs (String: taskName, String: description, String: assignedUser, String: assignedRC)}
      taskList: [], // array of doc references (tasks)
      taskName: '', // {String} name of task
      description: '', // {String} description of task
      assignedUser: '', // {String} name of assigned User
      assignedRC: '', // {String} (empty str if no assigned Roll Call) assigned Roll Call to the task
      // Create Add Roll Call
      companyID: '', // {String} ensure that root choose a companyID before creating a Roll Call
      rollCallName: '', // {String} name of the Roll Call
      rollCallList: [], // {Array of Strings} Array of names that are
      // added to the Roll Call
      editRollCall: false, // {Boolean} false: edit exisiting Roll Call || true: create new Roll Call
      withNotifications: false, // {Boolean} false: roll call notifications disabled ||
      // true: roll Call notifications enabled
      rcDescription: '', // {String} description of the Roll Call
      viewUserList: false, // {Boolean} true: view UserList of Roll Call || false: view Roll Call List
      filter: '', // {String} filter the roll call user list based on the status color
      logo: '', // {Object} stores the file of the image
      imgSrc: ProfilePreview, // {String} url to the image
      userListBox: [], // {Array of Objects {label: name, value: id}} list of all the users sent to the UserListBox
      inputText: null, // {label: string, value: string}
      inputTextCID: null,
      templateName: '', // {String} name of the template
      editTemplate: false, // {Boolean} whether to edit the template
      tmplName: '', // {String} selected template
      teamName: '', // {String} selected Team
      isPractice: false, // {Bool} whether the checklist is a drill or not
      checkinReq: false, // {Bool} whether users have to checkin to checklist
      selectedUsers: [], // {Array of objects} {value: UID, label: name}
      flashType: 'Roll Call', // {string} (Critical Event Alert, Mass Notification, Roll Call)
      totalresult: 0, //handle total result calculation
      startDate: new Date(),
      lastDate: new Date(),
      csvArr: [],
      csvFields: [],
      flashHeader: '',
      flashSubHeader: '',
      flashDeactivatedBy: 'Not Available',
      flashActivatedBy: 'Not Available'
    };

    // Roll Call Functions
    this.showRollCallDialog = this.showRollCallDialog.bind(this);
    this.hideRollCallDialog = this.hideRollCallDialog.bind(this);
    this.handleRollCallNameChange = this.handleRollCallNameChange.bind(this);
    this.handleRollCallAction = this.handleRollCallAction.bind(this);
    this.populateRollCall = this.populateRollCall.bind(this);
    this.handleNotificationChange = this.handleNotificationChange.bind(this);
    this.handleRCDescriptionChange = this.handleRCDescriptionChange.bind(this);
    this.handleFilterStatusChange = this.handleFilterStatusChange.bind(this);
    this.handleLogoChange = this.handleLogoChange.bind(this);
    this.handleTeamChange = this.handleTeamChange.bind(this);
    this.handleCompanyIDChangeRollCall =
      this.handleCompanyIDChangeRollCall.bind(this);
    this.handleFlashTypeChange = this.handleFlashTypeChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleLastDateChange = this.handleLastDateChange.bind(this);
    // this.calculateTotalResult = this.calculateTotalResult.bind(this)
    this.getCSVData = this.getCSVData.bind(this);
    this.getTaskStatus = this.getTaskStatus.bind(this);
    // getChecklistData => unused ??
    this.getChecklistData = this.getChecklistData.bind(this);
  }

  handleStartDateChange = (date) => {
    // console.log("startDate:"+ date);
    this.setState({ startDate: date });
  };
  handleLastDateChange = (date) => {
    // console.log("lastDate:"+ date);
    this.setState({ lastDate: date });
  };

  /************************** ROLL CALL FUNCTIONS *****************************/
  handleCompanyIDChangeRollCall(event) {
    this.props.onUpdateCompanyID({
      companyID: event.target.value
    });
    // clear the user option list
    // update the userlist state to 0
    // update the userlist html element to empty
    this.setState({
      rollCallList: [],
      companyID: event.target.value,
      selectedUsers: []
    });
    document.getElementById('editRollCallUserList').innerHTML = '';
  }
  handleRollCallNameChange(event) {
    this.setState({ rollCallName: event.target.value });
  }
  handleNotificationChange() {
    let status = this.state.withNotifications;
    this.setState({ withNotifications: !status });
  }
  handleRCDescriptionChange(event) {
    this.setState({ rcDescription: event.target.value });
  }
  populateRollCall(data) {
    var notificationToggle = document.getElementById('notificationToggle');
    if (data.withNotifications) {
      notificationToggle.checked = true;
    } else {
      notificationToggle.checked = false;
    }
    for (let user in data.users) {
      let fullName = data.users[user].name;
      // update state
      this.state.rollCallList.push(fullName);
      // TODO: replace this abomination with a react component
      var item = document.createElement('li'); // create the list item
      var userName = document.createElement('span'); // add the userName to the li
      userName.innerHTML = fullName;
      var minusSign = document.createElement('span');
      minusSign.innerHTML = '-';
      minusSign.setAttribute('class', 'selectedUserMinusSign');
      minusSign.addEventListener('click', this.removeUser);
      item.appendChild(userName); // add the description to the element
      item.appendChild(minusSign);
      document.getElementById('editRollCallUserList').appendChild(item);
    }
    this.setState({
      rollCallName: data.name,
      rcDescription: data.rcDescription,
      withNotifications: data.withNotifications,
      imgSrc: data.logo,
      rollCallList: this.state.rollCallList
    });

    // add teams to the drop down menu
    document.getElementById('editTeamRollCall').innerHTML = '';
    let editTeamRollCall = document.getElementById('editTeamRollCall');
    let currOpt = { text: 'Select Team', value: '' };
    editTeamRollCall.options.add(
      new Option(currOpt.text, currOpt.value, currOpt.selected)
    );
    for (let ug of this.props.userGroups) {
      currOpt = { text: ug.name, value: ug.name };
      editTeamRollCall.options.add(
        new Option(currOpt.text, currOpt.value, currOpt.selected)
      );
    }
    // display dialog
    const rollCallDialog = document.getElementById('addRollCallBox');
    // rollCallDialog.style.left = (this.props.toggleMenu) ? '105px' : '330px'
    rollCallDialog.showModal();
  }
  //
  populateFlash(data, info = '') {
    if (info === 'MN' || info === 'CEA') {
      //console.log(data);
      for (let user in data.users) {
        let fullName = data.users[user].name;
        this.state.rollCallList.push(fullName);
      }
      this.setState({
        flashHeader:
          info === 'MN'
            ? 'Mass Notification'
            : info === 'CEA'
            ? 'Critical Event Alert'
            : null,
        flashSubHeader: info === 'MN' ? 'MN' : info === 'CEA' ? 'CEA' : null,
        flashActivatedBy:
          data.activatedBy !== undefined || data.activatedBy !== null
            ? data.activatedBy
            : 'Not Available',
        flashDeactivatedBy:
          data.deactivatedBy !== undefined || data.deactivatedBy !== null
            ? data.deactivatedBy
            : 'Not Available',
        rollCallName: data.name,
        rcDescription: data.rcDescription,
        rollCallList: this.state.rollCallList
      });
    }

    // display dialog
    const flashDialog = document.getElementById('flashBox');
    // flashDialog.style.left = (this.props.toggleMenu) ? '105px' : '330px'
    flashDialog.showModal();
  }
  handleRollCallAction() {
    let hmap = {}; // used just to get all the user ids quickly
    for (let user of this.state.rollCallList) {
      // Create the hashmap
      hmap[user] = user;
    }
    let userMap = {};
    for (let user of this.props.userList) {
      if (hmap[user.name]) {
        // assume the user has been selected for the rollcall
        userMap[user.id] = { name: user.name };
      }
    }
    if (this.state.rollCallName.length === 0) {
      alert('Please enter a valid Flash name.');
    } else if (this.state.rcDescription.length === 0) {
      alert('Please enter a valid description.');
    } else {
      if (this.state.editRollCall) {
        this.props.onEditRollCall({
          name: this.state.rollCallName,
          userMap: userMap,
          index: this.state.index,
          withNotifications: this.state.withNotifications,
          rcDescription: this.state.rcDescription,
          logo: this.state.logo
        });
      } else {
        this.props.onAddRollCall({
          name: this.state.rollCallName,
          userMap: userMap,
          withNotifications: this.state.withNotifications,
          rcDescription: this.state.rcDescription,
          logo: this.state.logo,
          isRCActive: true,
          flashType: this.state.flashType,
          teamName: this.state.teamName
        });
      }
      this.hideRollCallDialog();
    }
  }
  showRollCallDialog() {}
  hideRollCallDialog() {
    var flashBox = document.getElementById('flashBox');
    if (flashBox !== null) {
      flashBox.close();
      this.setState({
        flashHeader: '',
        flashSubHeader: '',
        flashActivatedBy: '',
        flashDeactivatedBy: '',
        rcDescription: '',
        rollCallName: '',
        rollCallList: []
      });
    }
  }
  handleFilterStatusChange(event) {
    this.setState({ filter: event.target.value });
    this.props.onfilterTable({
      type: 'FILTER_ROLL_CALL_LIST',
      filter: event.target.value
    });
  }
  handleLogoChange(event) {
    event.preventDefault();
    let reader = new FileReader();
    // get the file thats been uploads
    let file = event.target.files[0];
    // after the file finished loading
    reader.onloadend = () => {
      this.setState({
        logo: file,
        imgSrc: reader.result
      });
    };
    reader.readAsDataURL(file);
  }

  handleTeamChange(event) {
    this.setState({ teamName: event.target.value });
  }
  handleUserListChange(event) {
    this.setState({ selectedUsers: event });
  }
  handleCompanyIDChangeTemplate(event) {
    this.props.onUpdateCompanyID({
      companyID: event.target.value
    });
    // update companyID
    this.setState({ companyID: event.target.value });
  }

  handleFlashTypeChange(event) {
    this.setState({ flashType: event.target.value });
  }

  //Depriciated - function
  // calculateTotalResult(tdata)
  // {
  // 	let len = tdata.tdata.length;
  // 	let data = [];
  // 	let total = 0;
  // 	for(let i = 0; i<len; i++){

  // 		let numCompleted = tdata.tdata[i].completedTasks
  // 		let numTasks = tdata.tdata[i].tasks.length
  // 		let value = (isNaN(numCompleted/numTasks)) ? 0 : Math.round(numCompleted/numTasks * 100)
  // 		total = total+value;
  // 	}

  // 		this.setState({totalresult:total})
  // }//Depriciated - function ends

  // getChecklistData => unused ??
  async getChecklistData(fields, tasks) {
    let tasks_list = [];
    let formatted_list = '';
    if (tasks.length > 0) {
      for (let task of tasks) {
        const doc = await task.get();
        // .then((doc) => {
        let d = doc.data();
        if (d) {
          let task_name = 'N/A';
          let compl = 'No';
          if (d.name) {
            task_name = d.name;
          }
          if (d.isComplete) {
            compl = 'Yes ';
            let date_completed =
              d.dateCompleted !== undefined &&
              d.dateCompleted !== null &&
              d.dateCompleted !== ''
                ? new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  }).format(d.dateCompleted.seconds * 1000)
                : 'Unavailable ';
            compl = compl + 'Completed Date: ' + date_completed;
          }
          let task_text = 'Name: ' + task_name + ' Completed:' + compl;
          tasks_list.push(task_text);

          formatted_list = '"' + tasks_list.join('\n') + '"';
        }
        // });
      }
      fields[0].eighth = formatted_list;
    }

    console.log('fields', fields);
    return Promise.resolve(fields);
    // return tasks_list
  }

  async getTaskStatus(tasks) {
    let tasks_list = [];
    // let formatted_list = ''
    if (tasks.length > 0) {
      for (let task of tasks) {
        let task_name = '';
        let task_completed = 'No';
        let task_desc = '';
        let task_completed_date = '';
        let task_completed_by = '';
        let task_id = task.id;

        let task_obj = this.props.taskList.find((o) => o.id === task_id);
        console.log(task_obj);
        if (task_obj) {
          task_name = 'N/A';
          let compl = 'No';
          if (task_obj.name) {
            task_name = task_obj.name;
          }
          if (task_obj.description) {
            task_desc = task_obj.description;
          }
          if (task_obj.isComplete) {
            let completedByName = '';
            if (
              task_obj.userCompleted !== null &&
              task_obj.userCompleted !== undefined
            ) {
              let user_obj = this.props.userList.find(
                (o) => o.id === task_obj.userCompleted.id
              );
              if (user_obj !== undefined) {
                completedByName = user_obj.firstName + ' ' + user_obj.lastName;
              }
            }
            compl = 'Yes ';
            let date_completed =
              task_obj.dateCompleted !== undefined &&
              task_obj.dateCompleted !== null &&
              task_obj.dateCompleted !== ''
                ? new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                  }).format(task_obj.dateCompleted.seconds * 1000)
                : 'Unavailable ';
            // compl = compl+'Completed Date: '+date_completed
            // compl = compl+' Completed By: '+completedByName

            task_completed = compl;
            task_completed_date = date_completed.replace(',', ' - ');
            task_completed_by = completedByName;
          }

          let task_text = {
            name: task_name,
            desc: task_desc,
            completed: task_completed,
            date: task_completed_date,
            completed_by: task_completed_by
          };
          tasks_list.push(task_text);
          console.log(tasks_list);
          // formatted_list = tasks_list
        }
      }
    }
    return tasks_list;
  }
  async getRowCSVTasks(tasks) {
    return await this.getTaskStatus(tasks);
  }
  async getCSVData(tdata) {
    console.log(tdata);
    let len = tdata.tdata.length;
    let csv = [];
    let status, notes;
    if (this.props.subPlanPager === 1) {
      for (let i = 0; i < len; i++) {
        if (
          tdata.tdata[i].isArchived === true ||
          (tdata.tdata[i].isArchived === true &&
            tdata.tdata[i].deactivated === true)
        ) {
          status = 'Archived';
          notes = tdata.tdata[i].notes;
        } else if (
          tdata.tdata[i].deactivated === true &&
          (tdata.tdata[i].isArchived === undefined ||
            tdata.tdata[i].isArchived === null ||
            tdata.tdata[i].isArchived === false)
        ) {
          status = 'Deactivated';
          notes = 'N/A';
        } else {
          status = 'Active';
          notes = 'N/A';
        }
        let tasks = tdata.tdata[i].tasks;
        let tasks_list = await this.getTaskStatus(tasks);

        let numTasks = tasks.length;
        let numCompleted = 0;
        let result = 0;

        if (
          'completedTasks' in tdata.tdata[i] &&
          tdata.tdata[i].completedTasks !== undefined
        ) {
          numCompleted = tdata.tdata[i].completedTasks;
        }
        result = isNaN(numCompleted / numTasks)
          ? 0
          : Math.round((numCompleted / numTasks) * 100);

        for (let tasks of tasks_list) {
          csv.push({
            Name: tdata.tdata[i].name,
            'Leader-Name': tdata.tdata[i].leaderName,
            'Checklist-Status': status,
            Notes: notes,
            // 'Activated':(tdata.tdata[i].dateActivated)?	new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(tdata.tdata[i].dateActivated.seconds*1000) : 'Unavailable',
            Activated: tdata.tdata[i].dateActivated
              ? tdata.tdata[i].dateActivatedString
              : 'Unavailable',
            // 'Deactivated':(tdata.tdata[i].dateDeactivated)? new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(tdata.tdata[i].dateDeactivated.seconds*1000)  : 'Unavailable',
            Deactivated: tdata.tdata[i].dateDeactivated
              ? tdata.tdata[i].dateDeactivatedString
              : 'Unavailable',
            Completion: result + '%',
            Tasks: tasks.name,
            'Task Description': tasks.desc,
            'Task Completed': tasks.completed,
            'Completed Date': tasks.date,
            'Completed By': tasks.completed_by
          });
        }
      }
    }
    if (this.props.subPlanPager === 2) {
      for (let i = 0; i < len; i++) {
        csv.push({
          'Flash Name': tdata.tdata[i].name,
          'Flash Type': tdata.tdata[i].type,
          Activated: tdata.tdata[i].dateActivated
            ? new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              }).format(tdata.tdata[i].dateActivated.seconds * 1000)
            : 'Unavailable',
          Deactivated: tdata.tdata[i].dateDeactivated
            ? new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              }).format(tdata.tdata[i].dateDeactivated.seconds * 1000)
            : 'Unavailable'
        });
      }
    }
    if (this.props.subPlanPager === 3) {
      for (let i = 0; i < len; i++) {
        csv.push({
          Name: tdata.tdata[i].name,
          Status: tdata.tdata[i].status,
          'Time Notified': tdata.tdata[i].dateActivated
            ? new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              }).format(tdata.tdata[i].dateActivated.seconds * 1000)
            : 'Unavailable',
          'Time Of Check In': tdata.tdata[i].dateDeactivated
            ? new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              }).format(tdata.tdata[i].dateDeactivated.seconds * 1000)
            : 'Unavailable'
        });
      }
    }
    // console.log(csv);
    this.setState({ csvArr: csv });
  }

  render() {
    /****************************** Check List *******************/
    var checklistColumns = [
      {
        Header: 'Checklists',
        columns: [
          {
            Header: 'Checklist Name',
            Cell: ({ row }) => {
              let name_text = row.original.name;
              return <span>{name_text}</span>;
            },
            accessor: 'name_text'
          },
          {
            Header: 'Checklist Leader',
            accessor: 'leaderName'
          },
          {
            id: 'checklist_status_col',
            Header: 'Checklist Status',
            Cell: ({ row }) => {
              let checklist_status = 'Active';
              if (
                row.original.isArchived === true ||
                (row.original.isArchived === true &&
                  row.original.deactivated === true)
              ) {
                checklist_status = 'Archived';
                let notes = row.original.notes;
                return (
                  <LightTooltip title={notes}>
                    <span>{checklist_status}</span>
                  </LightTooltip>
                );
              } else if (
                row.original.deactivated === true &&
                (row.original.isArchived === undefined ||
                  row.original.isArchived === null ||
                  row.original.isArchived === false)
              ) {
                checklist_status = 'Deactivated';
                return checklist_status;
              } else {
                return checklist_status;
              }
            },
            accessor: 'status_string'
          },
          {
            Header: 'Activated',
            Cell: ({ row }) => {
              let result = 'Unavailable';
              if (
                'dateActivated' in row.original &&
                row.original.dateActivated !== undefined &&
                row.original.dateActivated !== null
              ) {
                // assume that the ERP has tasks
                result = row.original.dateActivated;
                result = new Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
                }).format(result.seconds * 1000);
              }
              return result;
            },
            accessor: 'dateActivatedSec'
          },
          {
            Header: 'Deactivated',
            Cell: ({ row }) => {
              let result = 'Unavailable';
              if (
                'dateDeactivated' in row.original &&
                row.original.dateDeactivated !== undefined &&
                row.original.dateDeactivated !== null
              ) {
                // assume that the ERP has tasks
                result = row.original.dateDeactivated;
                result = new Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
                }).format(result.seconds * 1000);
              }
              return result;
            },
            accessor: 'dateDeactivatedSec'
          },
          {
            id: 'checklist_completion',
            Header: 'Completion', // Task button functionality
            Cell: ({ row }) => {
              let numTasks = 0;
              let numCompleted = 0;
              let result = 0;
              if ('tasks' in row.original && row.original.tasks !== undefined) {
                // assume that the ERP has tasks
                numTasks = row.original.tasks.length;
              }

              if (
                'completedTasks' in row.original &&
                row.original.completedTasks !== undefined
              ) {
                //assume the ERP has completed tasks
                numCompleted = row.original.completedTasks;
              }

              // this.calculate({ index: row.id })
              result = isNaN(numCompleted / numTasks)
                ? 0
                : Math.round((numCompleted / numTasks) * 100);
              // var percent_val = result;
              return (
                <button
                  id='taskButton'
                  title='View Tasks'
                  onClick={() => this.props.onViewTasks({ index: row.id })}
                >
                  {result}%
                </button>
              );
            },
            accessor: 'percent_completed'
          },
          {
            Header: 'Print', // Print row functionality
            Cell: ({ row }) => {
              // console.log(row.original)
              let activatedDate = '';
              let deactivatedDate = '';
              let csvFields = [];
              let status, notes;
              let tdata = [];

              tdata.push(row.original);
              // (row.original.dateActivated === undefined) ? console.log('yes'):console.log('no')

              if (
                'dateActivated' in row.original &&
                row.original.dateActivated !== undefined &&
                row.original.dateActivated !== null
              )
                if (
                  'dateDeactivated' in row.original &&
                  row.original.dateDeactivated !== undefined &&
                  row.original.dateDeactivated !== null
                ) {
                  activatedDate = new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                  }).format(row.original.dateActivated.seconds * 1000);
                  deactivatedDate = new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                  }).format(row.original.dateDeactivated.seconds * 1000);
                }

              if (
                row.original.isArchived === true ||
                (row.original.isArchived === true &&
                  row.original.deactivated === true)
              ) {
                status = 'Archived';
                notes = row.original.notes;
              } else if (
                row.original.deactivated === true &&
                (row.original.isArchived === undefined ||
                  row.original.isArchived === null ||
                  row.original.isArchived === false)
              ) {
                status = 'Deactivated';
                notes = 'N/A';
              } else {
                status = 'Active';
                notes = 'N/A';
              }

              let tasks = row.original.tasks;

              let numTasks = tasks.length;
              let numCompleted = 0;
              let result = 0;

              if (
                'completedTasks' in row.original &&
                row.original.completedTasks !== undefined
              ) {
                numCompleted = row.original.completedTasks;
              }
              result = isNaN(numCompleted / numTasks)
                ? 0
                : Math.round((numCompleted / numTasks) * 100);

              const columns = [
                { id: 'first', displayName: 'Name' },
                { id: 'second', displayName: 'Leader Name' },
                { id: 'third', displayName: 'Checklist Status' },
                { id: 'fourth', displayName: 'Notes' },
                { id: 'fifth', displayName: 'Activated' },
                { id: 'sixth', displayName: 'Deactivated' },
                { id: 'seventh', displayName: 'Completion' },
                { id: 'eighth', displayName: 'Tasks' },
                { id: 'nineth', displayName: 'Task Description' },
                { id: 'tenth', displayName: 'Task Completed' },
                { id: 'eleventh', displayName: 'Completed Date' },
                { id: 'twelvth', displayName: 'Completed By' }
              ];

              if (this.props.subPlanPager === 1) {
                for (let task of tasks) {
                  // let task_text = '';
                  let task_name = '';
                  let task_desc = '';
                  let task_completed_date = '';
                  let task_completed = 'No';
                  let task_completed_by = '';

                  let task_id = task.id;

                  let task_obj = this.props.taskList.find(
                    (o) => o.id === task_id
                  );
                  // console.log(task_obj)
                  if (task_obj) {
                    task_name = 'N/A';
                    let compl = 'No';
                    // let userCompletedName = '';
                    if (task_obj.name) {
                      task_name = task_obj.name;
                    }
                    if (task_obj.description) {
                      task_desc = task_obj.description;
                    }
                    if (task_obj.isComplete) {
                      let completedByName = '';
                      if (
                        task_obj.userCompleted !== null &&
                        task_obj.userCompleted !== undefined
                      ) {
                        let user_obj = this.props.userList.find(
                          (o) => o.id === task_obj.userCompleted.id
                        );
                        if (user_obj !== undefined) {
                          completedByName =
                            user_obj.firstName + ' ' + user_obj.lastName;
                        }
                      }
                      compl = 'Yes ';
                      task_completed = compl;
                      let date_completed =
                        task_obj.dateCompleted !== undefined &&
                        task_obj.dateCompleted !== null &&
                        task_obj.dateCompleted !== ''
                          ? new Intl.DateTimeFormat('en-US', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                              second: '2-digit'
                            }).format(task_obj.dateCompleted.seconds * 1000)
                          : 'Unavailable  ';
                      // compl = compl+'Completed Date: '+date_completed
                      // compl = compl+' Completed By: '+completedByName
                      task_completed_date = date_completed.replace(',', ' - ');
                      task_completed_by = completedByName;
                    }
                    // task_text = task_name
                  }

                  csvFields.push({
                    first: row.original.name,
                    second: row.original.leaderName,
                    third: status,
                    fourth: notes,
                    fifth: activatedDate
                      ? activatedDate.replace(',', '')
                      : 'Unavailable',
                    sixth: deactivatedDate
                      ? deactivatedDate.replace(',', '')
                      : 'Unavailable',
                    seventh: result + '%',
                    eighth: task_name,
                    nineth: task_desc,
                    tenth: task_completed,
                    eleventh: task_completed_date,
                    twelvth: task_completed_by
                  });
                }
              }

              return (
                <CsvDownloader
                  filename='Archived Checklist Row Report'
                  extension='.csv'
                  columns={columns}
                  datas={csvFields}
                  text='DOWNLOAD'
                >
                  <AiFillPrinter
                    height={35}
                    width={35}
                    alt='Print'
                    className='icon_button'
                  />
                </CsvDownloader>
              );
            }
          }
        ]
      }
    ];

    /****************************** Roll Call Columns *******************/
    var rollCallColumns = [
      {
        Header: 'Roll Call',
        columns: [
          {
            Header: ' ',
            Cell: ({ row }) => {
              return (
                <img
                  style={{ borderRadius: '50%', background: 'lightgrey' }}
                  src={row.original.logo}
                  width='40'
                  height='40'
                  alt='Profile'
                />
              );
            }
          },
          {
            Header: 'Flash Name',
            accessor: 'name'
          },
          {
            Header: 'Flash Type',
            accessor: 'type'
          },
          {
            Header: 'Activated',
            Cell: ({ row }) => {
              let result = '-';
              if (
                'dateActivated' in row.original &&
                row.original.dateActivated !== undefined &&
                row.original.dateActivated !== null
              ) {
                // assume that the ERP has tasks
                result = row.original.dateActivated;
                result = new Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
                }).format(result.seconds * 1000);
              }
              return result;
            },
            accessor: 'dateActivated'
          },
          {
            Header: 'Deactivated',
            Cell: ({ row }) => {
              let result = '-';
              if (
                'dateDeactivated' in row.original &&
                row.original.dateDeactivated !== undefined &&
                row.original.dateDeactivated !== null
              ) {
                // assume that the ERP has tasks
                result = row.original.dateDeactivated;
                result = new Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
                }).format(result.seconds * 1000);
              }
              return result;
            },
            accessor: 'dateDeactivated'
          },
          {
            Header: 'Completion', // Button to view users
            Cell: ({ row }) => {
              if (row.original.type === 'Mass Notification') {
                return (
                  <button
                    id='taskButton'
                    title='Users'
                    onClick={() => {
                      this.setState({
                        viewUserList: false,
                        index: row.id
                      });
                      this.props.onEditBreadCrumbs({
                        type: 'REMOVE_BREADCRUMB',
                        breadCrumbs: 'Flash > Flash Details'
                      });
                      this.populateFlash(row.original, 'MN');
                    }}
                  >
                    View
                  </button>
                );
              } else if (row.original.type === 'Roll Call') {
                let totalUsers = 0; // total users
                let completedUsers = 0; // those has isCheckedIn true

                for (let user in row.original.users) {
                  totalUsers = totalUsers + 1;
                  if (row.original.users[user].isCheckedIn)
                    completedUsers = completedUsers + 1;
                }

                let result = isNaN(completedUsers / totalUsers)
                  ? 0
                  : Math.round((completedUsers / totalUsers) * 100);

                return (
                  <button
                    id='taskButton'
                    title='Users'
                    onClick={() => {
                      this.setState({
                        viewUserList: true,
                        index: row.id
                      });
                      this.props.onEditBreadCrumbs({
                        type: 'REMOVE_BREADCRUMB',
                        breadCrumbs: 'Flash > Flash Details'
                      });
                      this.props.onViewRCUserList({
                        index: row.id,
                        id: row.original.id,
                        planPager: 6,
                        subPlanPager: 3
                      });
                    }}
                  >
                    {result}%
                  </button>
                );
              }
              return null;
            }
          },
          {
            // FlashReportDownload
            Header: 'Download',
            Cell: ({ row }) => {
              return <ReportDownload data={row.original} />;
            }
          }
        ]
      }
    ];

    /****************************** User List Columns *********************/

    var rcUserListColumns = [
      {
        Header: 'Roll Call User List',
        columns: [
          {
            Header: 'Name',
            accessor: 'name'
          },
          {
            Header: 'Status',
            Cell: ({ row }) => {
              let status = row.original.status;
              let color = 'grey';
              if (this.props.rollCallList[this.state.index].isRCActive) {
                if (status === 'green') {
                  color = '#078f45';
                } else if (status === 'yellow') {
                  color = '#ffc60a';
                } else if (status === 'red') {
                  color = '#cc3232';
                } else {
                  // assume the user is offline
                  color = 'black';
                }
              }
              return (
                <div
                  id='userStatusBox'
                  height='20px'
                  style={{ background: color }}
                ></div>
              );
            }
          },
          {
            Header: 'Time Notified',
            Cell: ({ row }) => {
              return '-';
            }
          },
          {
            Header: 'Time Of Check In',
            Cell: ({ row }) => {
              return '-';
            }
          }
        ]
      }
    ];
    // let addUserListHeader = 'Checklist';
    let size = this.props.rcUserList.length;
    let numGreen = 0,
      numYellow = 0,
      numRed = 0; // integer values of # of users in each status
    let greenStat = 0,
      yellowStat = 0,
      redStat = 0; // stat for # of users in each status
    let isRCActive = false;
    let activeText = 'Deactivated';
    let textColor = 'red';
    if (size > 0) {
      isRCActive = this.props.rollCallList[this.state.index].isRCActive;
      if (isRCActive) {
        greenStat = (this.props.numGreen / size) * 100;
        yellowStat = (this.props.numYellow / size) * 100;
        redStat = (this.props.numRed / size) * 100;
        // assume the roll call is active
        numGreen = this.props.numGreen;
        numYellow = this.props.numYellow;
        numRed = this.props.numRed;
        activeText = 'Activated';
        textColor = '#078f45';
      }
    }
    // 0 = active checklists // 1 = template Lists // 2 = roll Call Columns // 3 = rc User List Columns
    let headerName = '';
    let searchBoxName = '';
    let filterType = '';
    let cols = [];
    let tdata = [];
    // let currFilterList = [];

    //For the total results percentage
    let tresult = 'TBD';
    let total = 0;
    let numTasks;

    if (this.props.planPager === 6 && this.props.subPlanPager === 1) {
      // console.log("PlanTable:"+this.props.planList)
      cols = checklistColumns;
      tdata = this.props.viewFilteredList
        ? this.props.filteredList
        : (tdata = this.props.planList);
      headerName = 'Checklists Report';
      searchBoxName = 'Search Checklist';
      filterType = 'FILTER_CHECK_LIST';
      // currFilterList = this.props.planList;
    }

    if (this.props.planPager === 6 && this.props.subPlanPager === 2) {
      cols = rollCallColumns;
      // assume viewing the Roll Call List
      tdata = this.props.viewFilteredList
        ? this.props.filteredList
        : this.props.rollCallList;
      headerName = 'Flash Report';
      searchBoxName = 'Search Flash';
      filterType = 'FILTER_ROLLCALL_LIST';
      // currFilterList = this.props.rollCallList;
      // addUserListHeader = 'Flash';
    }
    if (this.props.planPager === 6 && this.props.subPlanPager === 3) {
      cols = rcUserListColumns;
      // issue not getting all the users for some reason
      let rcUserList = this.props.viewFilteredList
        ? this.props.filteredList
        : this.props.rcUserList;
      tdata = rcUserList; // assume viewing the user list
      headerName = 'Flash';
    }

    // console.log({tdata});
    if (this.props.planPager === 6) {
      let len = { tdata }.tdata.length;

      if (this.props.subPlanPager === 1) {
        //CHECKLIST REPORT

        for (let i = 0; i < len; i++) {
          let numCompleted = { tdata }.tdata[i].completedTasks;
          numTasks = { tdata }.tdata[i].tasks.length;
          let value = isNaN(numCompleted / numTasks)
            ? 0
            : Math.round((numCompleted / numTasks) * 100);
          total = total + value;
        }

        tresult = len !== 0 ? Math.round(total / len) + '%' : '- - -';
      }
      if (this.props.subPlanPager === 2) {
        //FLASH REPORT
        let result = 0;
        let total = 0;
        let count = 0;
        for (let i = 0; i < len; i++) {
          let totalUsers = 0;
          let completedUsers = 0;

          if ({ tdata }.tdata[i].type === 'Roll Call') {
            count = count + 1;
            for (let user in { tdata }.tdata[i].users) {
              totalUsers = totalUsers + 1;
              if ({ tdata }.tdata[i].users[user].isCheckedIn)
                completedUsers = completedUsers + 1;
            }
            // console.log("tu: "+totalUsers + " name: "+{tdata}.tdata[i].name);
            // console.log("cu: "+completedUsers);
            result = isNaN(completedUsers / totalUsers)
              ? 0
              : (completedUsers / totalUsers) * 100;
            // console.log("result: "+result);
            total = total + result;
            // console.log("total : "+total);
          }
        }
        // console.log("final: "+total);
        tresult = count !== 0 ? Math.round(total / count) + '%' : '- - -';
      }
    }

    let callingSubPlanPager = this.props.subPlanPager;
    // console.log("Reports Table > PP: "+this.props.planPager)
    // console.log("Reports Table > SPP: "+this.props.subPlanPager)
    return (
      <main>
        <p
          onClick={() => {
            this.setState({ viewUserList: false });
            this.props.viewReports({
              planPager: 6,
              subPlanPager: callingSubPlanPager === 3 ? 2 : callingSubPlanPager
            });
            this.props.onEditBreadCrumbs({
              type: 'REMOVE_BREADCRUMB',
              breadCrumbs: 'Reports'
            });
          }}
          id='breadCrumbs'
        >
          {this.props.breadCrumbs}
        </p>

        <div className='MuiBox-root'>
          <div className='MuiPaper-root'>
            <div className='MuiBox-root css-1idik3a'>
              <div className='MuiBox-root css-uujcwg'>
                <h5 className='css-epib0t'>{headerName}</h5>
              </div>
              <div className='css-1yjo05o'>
                {this.props.subPlanPager !== 3 ? (
                  <ul className='topnav'>
                    {this.props.companySettings.features.emergencyChecklist ===
                    true ? (
                      <li
                        id='secondaryNavigationItems'
                        className={this.props.reportsHighlight[0]}
                        onClick={() => {
                          this.setState({
                            viewUserList: false,
                            inputText: null
                          });
                          this.props.onEditBreadCrumbs({
                            type: 'REMOVE_BREADCRUMB',
                            breadCrumbs: 'Reports'
                          });
                          this.props.viewReports({
                            planPager: 6,
                            subPlanPager: 1
                          });
                        }}
                      >
                        Checklists
                      </li>
                    ) : null}
                    {this.props.companySettings.features.flash === true ? (
                      <li
                        id='secondaryNavigationItems'
                        className={this.props.reportsHighlight[1]}
                        onClick={() => {
                          this.setState({
                            viewUserList: false,
                            inputText: null
                          });
                          this.props.viewRollCallList({
                            planPager: 6,
                            subPlanPager: 2
                          });
                          this.props.onEditBreadCrumbs({
                            type: 'REMOVE_BREADCRUMB',
                            breadCrumbs: 'Reports'
                          });
                        }}
                      >
                        Flash
                      </li>
                    ) : null}
                    {/* {this.props.companySettings.features.library === true ? (
                      <li
                        id='secondaryNavigationItems'
                        className={this.props.reportsHighlight[2]}
                        onClick={() => {
                          this.setState({
                            viewUserList: false,
                            inputText: null
                          });
                          this.props.onEditBreadCrumbs({
                            type: 'REMOVE_BREADCRUMB',
                            breadCrumbs: 'Library'
                          });
                          this.props.onViewLibrary({ planPager: 6 });
                        }}
                      >
                        Library
                      </li>
                    ) : null} */}
                    {this.props.companySettings.vaccineTracking === true ? (
                      <Fragment>
                        <li
                          id='secondaryNavigationItems'
                          className={this.props.reportsHighlight[3]}
                          onClick={() => {
                            this.setState({
                              viewUserList: false,
                              inputText: null
                            });
                            this.props.onEditBreadCrumbs({
                              type: 'REMOVE_BREADCRUMB',
                              breadCrumbs: 'COVID-19 Vaccines'
                            });
                            this.props.onViewUserList({
                              planPager: 6,
                              subPlanPager: 0
                            });
                          }}
                        >
                          COVID-19 Vaccines
                        </li>
                      </Fragment>
                    ) : null}
                    {this.props.companySettings.healthScreen === true &&
                    this.props.companySettings.features.forms === true ? (
                      <Fragment>
                        <li
                          id='secondaryNavigationItems'
                          className={this.props.reportsHighlight[4]}
                          onClick={() => {
                            this.setState({
                              viewUserList: false,
                              inputText: null
                            });
                            this.props.onEditBreadCrumbs({
                              type: 'REMOVE_BREADCRUMB',
                              breadCrumbs: 'Health Screen'
                            });
                            this.props.viewCompletedForms({ planPager: 6 });
                          }}
                        >
                          Health Screen
                        </li>
                      </Fragment>
                    ) : null}
                  </ul>
                ) : null}
              </div>
            </div>
            <div className='MuiTableContainer-root css-13ypi1f'>
              <div className='MuiBox-root css-fgimv3'>
                <div className='progressBar_sec'>
                  {this.props.subPlanPager === 3 ? (
                    <Fragment>
                      {this.state.viewUserList ? (
                        <div id='planName-ProgressBar-Div'>
                          <h1>{headerName}</h1>
                          <div id='progressBar-description'>
                            <progress
                              id='rcProgressBar1'
                              height='40px'
                              value={greenStat}
                              max='100'
                            />
                            <span id='progressBarPercentage'>
                              {numGreen + ' of ' + size} Checked In
                            </span>
                            <progress
                              id='rcProgressBar2'
                              height='40px'
                              value={yellowStat}
                              max='100'
                            />
                            <span id='progressBarPercentage'>
                              {numYellow + ' of ' + size} Notified
                            </span>
                            <progress
                              id='rcProgressBar3'
                              height='40px'
                              value={redStat}
                              max='100'
                            />
                            <span id='progressBarPercentage'>
                              {numRed + ' of ' + size} Absent
                            </span>
                          </div>
                        </div>
                      ) : (
                        <h1>{headerName}</h1>
                      )}

                      {this.state.viewUserList ? (
                        <p
                          style={{ margin: '0 0 20px 30px', fontSize: '20px' }}
                        >
                          Flash is
                          <span style={{ color: textColor }}>
                            {' '}
                            {activeText}
                          </span>
                        </p>
                      ) : null}

                      <Row style={{ margin: '0 15px 0 15px' }}>
                        <Col>
                          <div>Activated By: {this.state.flashActivatedBy}</div>
                        </Col>
                        <Col>
                          <div style={{ float: 'right' }}>
                            {' '}
                            Deactivated By: {this.state.flashDeactivatedBy}
                          </div>
                        </Col>
                      </Row>
                    </Fragment>
                  ) : (
                    ' '
                  )}
                </div>
                <Row>
                  {this.props.planPager === 6 &&
                  this.props.subPlanPager === 3 ? (
                    <Fragment>
                      <Col>
                        <label style={{ marginTop: '40px' }}>
                          Filter By&nbsp;
                        </label>
                        <select
                          id='selectFilter'
                          value={this.state.filter}
                          onChange={this.handleFilterStatusChange}
                        >
                          <option value=''>All</option>
                          <option value='green'>Green</option>
                          <option value='yellow'>Yellow</option>
                          <option value='red'>Red</option>
                        </select>
                      </Col>
                    </Fragment>
                  ) : null}

                  <Col>
                    <div className='search'>
                      {this.state.viewUserList ? null : (
                        <Fragment>
                          <input
                            className='pl-2'
                            id='searchBox'
                            placeholder={searchBoxName}
                            value={this.state.inputText || ''}
                            onChange={(event) => {
                              this.props.onfilterTable({
                                type: filterType,
                                value: event.target.value
                              });
                              let inputText = event.target.value;
                              this.setState({ inputText: inputText });
                            }}
                          />

                          <BiReset
                            alt='Reset'
                            className='icon_button'
                            onClick={() => {
                              //console.log(this.props.planPager)
                              if (this.props.subPlanPager === 1) {
                                this.props.viewReports({
                                  planPager: 6,
                                  subPlanPager: 1
                                });
                              } else if (this.props.subPlanPager === 2) {
                                this.props.viewRollCallList({
                                  planPager: 4,
                                  subPlanPager: 2
                                });
                              }
                              this.setState({ inputText: null });
                            }}
                            style={{ width: '40px', height: '40px' }}
                          />
                        </Fragment>
                      )}
                    </div>
                  </Col>

                  {this.props.role === 'Root' ? (
                    <Fragment>
                      <Col>
                        <div className='search searchByComapnyID'>
                          {
                            <Fragment>
                              <Select
                                options={this.props.clientList.map((item) => {
                                  return { value: item, label: item };
                                })}
                                id='searchBox'
                                placeholder='Filter by CompanyID'
                                value={this.state.inputTextCID}
                                onChange={(event) => {
                                  this.props.onfilterTable({
                                    type:
                                      this.props.subPlanPager === 1
                                        ? 'FILTER_CHECK_LIST_BY_COMPANYID'
                                        : this.props.subPlanPager === 2
                                        ? 'FILTER_ROLLCALL_LIST_BY_COMPANYID'
                                        : null,
                                    value: event.value
                                  });
                                  let inputTextCID = {
                                    label: event.label,
                                    value: event.label
                                  };
                                  this.setState({ inputTextCID: inputTextCID });
                                }}
                              />
                            </Fragment>
                          }
                        </div>
                      </Col>
                    </Fragment>
                  ) : null}

                  <Col>
                    <Fragment>
                      <div className='dateRange'>
                        <div className='startDate'>
                          <DatePicker
                            selected={this.state.startDate}
                            onChange={this.handleStartDateChange}
                            className='datepickerStyle'
                          />
                        </div>

                        <div className='endDate'>
                          <DatePicker
                            selected={this.state.lastDate}
                            onChange={this.handleLastDateChange}
                            className='datepickerStyle'
                          />
                        </div>

                        <button
                          type='button'
                          className='go'
                          title='Go'
                          onClick={() =>
                            // if no table, return null
                            this.props.onfilterTable({
                              type:
                                this.props.subPlanPager === 1
                                  ? 'FILTER_CHECK_LIST_BY_DATE_RANGE'
                                  : this.props.subPlanPager === 2
                                  ? 'FILTER_ROLLCALL_LIST_BY_DATE_RANGE'
                                  : null,
                              startdate: this.state.startDate,
                              lastdate: this.state.lastDate
                            })
                          }
                        >
                          Go
                        </button>
                      </div>
                    </Fragment>
                  </Col>

                  {this.props.subPlanPager !== 3 ? (
                    <Col xs='auto'>
                      <div className='totalresultdiv'>
                        <span className='totalresultspan'>
                          Total Results:
                          <p className='totalresultsp'>{tresult}</p>
                        </span>
                      </div>
                    </Col>
                  ) : null}

                  <Col xs='auto'>
                    <div>
                      <CSVLink
                        data={this.state.csvArr}
                        filename={
                          this.props.subPlanPager === 1
                            ? 'Archived Checklist Full Report.csv'
                            : this.props.subPlanPager === 2
                            ? 'Flash Report.csv'
                            : this.props.subPlanPager === 3
                            ? 'Flash Details Report.csv'
                            : null
                        }
                      >
                        <AiFillPrinter
                          height={35}
                          width={35}
                          alt='Print'
                          className='icon_button icon-button-print'
                          src={Print}
                          onClick={() => this.getCSVData({ tdata })}
                        />
                      </CSVLink>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className='MuiBox-root css-17uua2w'>
                <div className='reports__table-wrapper'>
                  <Table responsive columns={cols} data={tdata} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <dialog id='flashBox' className='bigDialogBox'>
          <Container fluid>
            <Row>
              <Col>
                <h2>{this.state.flashHeader}</h2>
              </Col>
              <Col style={{ float: 'right', marginBottom: '10px' }}>
                <GrClose
                  id='exit-dialog-icon'
                  height={28}
                  onClick={this.hideRollCallDialog}
                  alt='exit button'
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ color: '#999999', fontSize: '14px' }}>
                  {' '}
                  Activated By: {this.state.flashActivatedBy}{' '}
                  {this.state.dateActivated
                    ? ' - ' +
                      new Intl.DateTimeFormat('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                      }).format(this.state.dateActivated.seconds * 1000)
                    : null}
                </div>
              </Col>
              <Col>
                <div
                  style={{ color: '#999999', fontSize: '14px', float: 'right' }}
                >
                  {' '}
                  Deactivated By: {this.state.flashDeactivatedBy}{' '}
                  {this.state.dateDeactivated
                    ? ' - ' +
                      new Intl.DateTimeFormat('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                      }).format(this.state.dateActivated.seconds * 1000)
                    : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  style={{
                    color: '#999999',
                    fontSize: '14px',
                    marginTop: '25px'
                  }}
                >
                  {this.state.flashSubHeader} Name
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ color: 'black', marginTop: '10px' }}>
                  {this.state.rollCallName}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  style={{
                    color: '#999999',
                    fontSize: '14px',
                    marginTop: '25px'
                  }}
                >
                  {this.state.flashSubHeader} Description
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ color: 'black', marginTop: '10px' }}>
                  {this.state.rcDescription}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  style={{
                    color: '#999999',
                    fontSize: '14px',
                    marginTop: '25px'
                  }}
                >
                  {this.state.flashSubHeader} Participants
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {this.state.rollCallList.map((member) => {
                  return (
                    <div style={{ color: 'black', marginTop: '10px' }}>
                      {member}
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Container>
        </dialog>
      </main>
    );
  }
}
export default Reports;
