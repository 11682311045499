import React, { Component } from 'react';
import Styles from './Styles';
import Table from './Table';
import Check from './style/check_icon.png';

class RollCallTable extends Component {
  render() {
    // predefined names for the columns
    var columns = [
      {
        Header: 'Plans',
        columns: [
          {
            Header: 'Name',
            accessor: 'name'
          },
          {
            Header: 'Title',
            accessor: 'title'
          },
          {
            // Task button functionality
            Header: 'Tasks',
            Cell: ({ row }) => {
              let numTasks = row.original.numTasks;
              if (numTasks === undefined) numTasks = 0;
              let numCompleted = row.original.numCompleted;
              if (numCompleted === undefined) numCompleted = 0;
              return (
                <button
                  id='taskButton'
                  onClick={() => console.log(row.original)}
                >{`${numCompleted}/${numTasks}`}</button>
              );
            }
          },
          {
            // Checked In
            Header: 'Checked In',
            Cell: ({ row }) => {
              let body;
              let checkedIn = row.original.isCheckedIn;
              if (checkedIn) {
                // assume the user has checked in
                body = (
                  <div id='taskCompletedCheckbox'>
                    <img
                      height='20px'
                      style={{
                        position: 'absolute',
                        top: '-5px'
                      }}
                      src={Check}
                      alt='Check'
                    />
                  </div>
                );
              } else {
                // assume the user is not checked in
                body = (
                  <div
                    id='taskCompletedCheckbox'
                    onClick={() => {
                      let res = window.confirm(
                        `Are you sure you wish to change the check-in status of ${row.original.name}?`
                      );
                      if (res) {
                        this.props.onUserStatusChange({
                          isCheckedIn: checkedIn,
                          index: row.id,
                          UID: row.original.UID // needed to update isCheckedIn status
                        });
                      }
                    }}
                  ></div>
                );
              }
              return body;
            }
          }
        ]
      }
    ];
    return (
      <main>
        <p onClick={this.props.onBackClick} id='breadCrumbs'>
          {this.props.breadCrumbs}
        </p>
        <div id='planName-ProgressBar-Div'>
          <h1>Roll Call</h1>
          <div id='progressBar-description'>
            <progress
              id='progressBar'
              height='40px'
              value={(this.props.numCompleted / this.props.totalNum) * 100}
              max='100'
            />
            <span id='progressBarPercentage'>
              {this.props.numCompleted + ' of ' + this.props.totalNum} Users
              Checked In
            </span>
          </div>
        </div>
        <h4 className='planNameDescription'>{this.props.planName}</h4>
        <nav>
          <ul className='addItem'>
            <li>
              <button id='taskButton'>Resend Notifications</button>
            </li>
          </ul>
          <div className='search'>
            <input
              type='text'
              name='searchBox'
              placeholder='Search'
              id='searchBox'
            />
          </div>
        </nav>
        <Styles>
          {/* gets the user data from the userPage class and fills in the table */}
          <Table columns={columns} data={this.props.rollCallList} />
        </Styles>
      </main>
    );
  }
}
export default RollCallTable;
