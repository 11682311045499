import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';

import { AiFillLock, AiFillUnlock } from 'react-icons/ai';

import { generateRandomString } from 'utils/data';

const FolderPassword = ({
  folderName,
  isProtected,
  currentPwd,
  updateFolderPassword
}) => {
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const isDirty =
    (currentPwd && password !== currentPwd) || (!currentPwd && password);

  const resetDefaultValues = () => {
    setError(false);
    setIsProcessing(false);
    setOpen(false);
    setPassword('');
  };

  useEffect(() => {
    return () => resetDefaultValues();
  }, []);

  const handleLockClick = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleCancel = () => resetDefaultValues();

  const handleUpdatePwd = async () => {
    try {
      if (!password) return;
      setIsProcessing(true);

      const update = {
        isProtected: true,
        password
      };

      await updateFolderPassword(update);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      resetDefaultValues();
    }
  };

  const handleRemovePwd = async () => {
    try {
      setIsProcessing(true);

      const update = {
        isProtected: false,
        password: null
      };

      updateFolderPassword(update);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      resetDefaultValues();
    }
  };

  return (
    <>
      {isProtected ? (
        <AiFillLock
          title='Locked'
          height={20}
          width={15}
          style={{
            cursor: 'pointer',
            fill: 'red',
            fontSize: '24px'
          }}
          alt='lock'
          className='icon_button'
          onClick={(e) => handleLockClick(e)}
        />
      ) : (
        <AiFillUnlock
          title='Unlocked'
          height={20}
          width={20}
          style={{
            cursor: 'pointer',
            fill: 'green',
            fontSize: '24px'
          }}
          alt='unlock'
          className='icon_button'
          onClick={(e) => handleLockClick(e)}
        />
      )}
      <Dialog
        id='folder-update-dialog'
        maxWidth='xs'
        open={open}
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      >
        <DialogTitle>Update Folder Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span>Folder:</span>
            <span
              style={{ paddingLeft: '1rem', fontSize: 16, fontWeight: 'bold' }}
            >
              {open && folderName}
            </span>
          </DialogContentText>
          <DialogContentText>
            <span>Current Password:</span>
            <span
              style={{ paddingLeft: '1rem', fontSize: 16, fontWeight: 'bold' }}
            >
              {open && currentPwd ? currentPwd : 'None'}
            </span>
          </DialogContentText>
          <DialogContentText sx={{ marginTop: 2 }}>
            Enter new password or click Remove Password to unlock the folder.
          </DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id={`password-input-${generateRandomString(7)}`}
            label='New Password'
            error={error}
            helperText='Enter new password of 8 characters or more.'
            fullWidth
            variant='standard'
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setError(e.target.value.length < 8);
            }}
          />
          {isProcessing && (
            <CircularProgress
              sx={{ position: 'absolute', top: '50%', left: '50%' }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant='outlined'
            color='warning'
            onClick={handleRemovePwd}
            disabled={open && (!currentPwd || isProcessing)}
          >
            Remove Password
          </Button>
          <Button
            variant='contained'
            onClick={handleUpdatePwd}
            disabled={!isDirty || isProcessing || error}
          >
            Update Password
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FolderPassword;
