import {
  addFileToFolderDocByID,
  getStoragePath,
  removeFileFromFolderDocByID
} from 'firebase/db/folders/operations';
import {
  getFolderByDocumentID,
  getAllFoldersbyRef,
  getFolderRefByDocumentID
} from 'firebase/db/folders/query';
import { getLibraryByCompanyID } from 'firebase/db/libraries/query';
import {
  deleteFileFromStorage,
  uploadFileToStorage
} from 'firebase/storage/operations';

import Store from '../../store';

export const fetchFolder = async (folderID) => {
  try {
    const folder = await getFolderByDocumentID(folderID);

    Store.library.rootFolder.set(folder);

    return {
      success: true
    };
  } catch (error) {
    console.log('ERROR - Store/library/fetchFolder: ', error);
    return {
      success: false
    };
  }
};

export const refreshRootFolder = async () => {
  try {
    const rootFolder = Store.library.rootFolder.get();

    const rootFolderRef = await getFolderRefByDocumentID(rootFolder.folderID);

    const folder = await getAllFoldersbyRef(rootFolderRef);

    Store.library.rootFolder.set(folder);

    return {
      success: true
    };
  } catch (error) {
    console.log('ERROR - Store/library/fetchFolder: ', error);
    return {
      success: false
    };
  }
};

export const fetchLibrary = async (companyID) => {
  try {
    const library = await getLibraryByCompanyID(companyID);

    if (!library) {
      return {
        success: false
      };
    }

    Store.library.libraryID.set(library.libraryID);
    Store.library.rootFolderID.set(library.rootFolder.id);

    const rootFolder = await getAllFoldersbyRef(library.rootFolder);
    // const rootFolder = await getFolderbyRef(library.rootFolder);

    if (!rootFolder) {
      return {
        success: false
      };
    }

    Store.library.rootFolder.set(rootFolder);

    return {
      success: true
    };
  } catch (error) {
    console.log('ERROR - Store/library/fetchLibrary: ', error);
    return {
      success: false
    };
  }
};

export const uploadFile = async (file, parentFolderID, parentFolderName) => {
  try {
    // get file data
    const timestamp = new Date().toISOString();
    const uploadedBy = Store.user.userName.get();
    const companyID = Store.user.companyID.get();

    const baseStoragePath = `companies/${companyID}/`;
    let fullPath;

    if (parentFolderName === 'root') {
      fullPath = `${baseStoragePath}${file.name}`;
    } else {
      const storagePath = await getStoragePath(
        parentFolderID,
        parentFolderName
      );

      // console.log('storagePath: ', storagePath);
      fullPath = `${baseStoragePath}${storagePath}/${file.name}`;
    }
    // console.log('fullPath: ', fullPath);

    const folderFileData = {
      companyID: companyID,
      fileName: file.name,
      fileSize: file.size,
      fileType: file.type,
      storagePathname: fullPath,
      uploadedAt: timestamp,
      uploadedBy
    };
    // console.log('folderFileData: ', folderFileData);
    // upload file to Storage
    const uploadResult = await uploadFileToStorage(fullPath, file);
    console.log('File uploaded: ', !!uploadResult);

    // // update folder; add folderFileData to files array
    const folderUpdateResult = await addFileToFolderDocByID(
      parentFolderID,
      folderFileData
    );
    console.log('Folder Updated: ', !!folderUpdateResult);

    await refreshRootFolder();

    return {
      success: true
    };
  } catch (error) {
    console.log('ERROR - firebase/actions/library/uploadFile: ', error);
    return {
      success: false
    };
  }
};

export const deleteFile = async (folderID, fileStoragePath) => {
  try {
    // remove file from Storage
    const deleteResult = await deleteFileFromStorage(fileStoragePath);
    console.log('file deleted: ', !!deleteResult);

    // update folder; remove file from files array
    const folderUpdateResult = await removeFileFromFolderDocByID(
      folderID,
      fileStoragePath
    );
    console.log('folder updated: ', !!folderUpdateResult);

    await refreshRootFolder();

    return {
      success: true
    };
  } catch (error) {
    console.log('ERROR - firebase/actions/library/deleteFile: ', error);
    return {
      success: false
    };
  }
};

export const createCompanyLibrary = async (companyID) => {
  try {
    console.log('createCompanyLibrary/companyID: ', companyID);
    const response = await fetch(
      'https://us-central1-crisis-management-15d38.cloudfunctions.net/library',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          companyID
        })
      }
    );

    const result = await response.json();
    console.log('library created: ', !!result);

    return {
      success: true,
      message: 'Library created'
    };
  } catch (error) {
    console.log('ERROR - Store/library/fetchFolder: ', error);
    return {
      success: false,
      message: error.message
    };
  }
};
