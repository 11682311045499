import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';

import { generateRandomString } from 'utils/data';

import { ImPencil } from 'react-icons/im';

const FolderEdit = ({ folderName, updateFolderName }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(folderName);
  const [isProcessing, setIsProcessing] = useState(false);

  const isDirty = name !== folderName;

  const resetDefaultValues = () => {
    setName('');
    setIsProcessing(false);
    setOpen(false);
  };

  useEffect(() => {
    return () => resetDefaultValues();
  }, []);

  const handleEditClick = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleUpdatePwd = async () => {
    try {
      if (!name) return;
      setIsProcessing(true);

      await updateFolderName(name);

      resetDefaultValues();
    } catch (error) {
      console.log('error: ', error);
      resetDefaultValues();
    }
  };

  return (
    <>
      <ImPencil
        title='Edit Folder'
        height={18}
        width={18}
        alt='Edit'
        style={{ fontSize: '18px', fill: '#2152ff' }}
        onClick={(e) => handleEditClick(e)}
      />
      <Dialog
        id='folder-update-dialog'
        maxWidth='xs'
        open={open}
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      >
        <DialogTitle>Update Folder</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span>Current Name:</span>
            <span
              style={{ paddingLeft: '1rem', fontSize: 16, fontWeight: 'bold' }}
            >
              {folderName}
            </span>
          </DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id={`edit-folder-name-${generateRandomString(8)}`}
            label='Folder Name'
            fullWidth
            variant='standard'
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ marginTop: '1rem' }}
          />
          {isProcessing && (
            <CircularProgress
              sx={{ position: 'absolute', top: '50%', left: '50%' }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>

          <Button
            variant='contained'
            color='error'
            onClick={handleUpdatePwd}
            disabled={!isDirty || isProcessing}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FolderEdit;
