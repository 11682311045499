import * as React from 'react';

import LibraryTreeItem from './LibraryTreeItem';

const FileItems = ({ files, parentFolderID }) => {
  // console.log('FileItems/files: ', files);

  return (
    <>
      {files.map((file) => {
        const { fileName, storagePathname } = file;
        const fileProps = {
          itemData: {
            ...file,
            isFolder: false,
            parentFolderID
          }
        };
        return (
          <LibraryTreeItem
            key={storagePathname}
            nodeId={storagePathname}
            label={fileName}
            ContentProps={fileProps}
          />
        );
      })}
    </>
  );
};

export default FileItems;
