import { Component } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Table from './Table';

import { BiReset } from 'react-icons/bi';

class VaccineSettingsTable extends Component {
  constructor() {
    super();
    this.state = {
      index: 0, // index of the selected client for edit
      domain: '', // the domain associated with  the client
      clientName: '', // the clients name
      address: '', // address of the client
      city: '', // city the client is located in
      stateName: '', //state the client is located in
      phoneNumber: '', // the clients phone number
      logo: '', // the file of the logo for the client
      imgSrc: '', // logo image source
      inputText: null // {label: string, value: string}
    };
    // this.handleLogoChange = this.handleLogoChange.bind(this)
    // this.handleDomainChange = this.handleDomainChange.bind(this)
    // this.handleClientNameChange = this.handleClientNameChange.bind(this)
    // this.handleAddressChange = this.handleAddressChange.bind(this)
    // this.handleCityChange = this.handleCityChange.bind(this)
    // this.handleStateNameChange = this.handleStateNameChange.bind(this)
    // this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this)
    // this.handleEditClient = this.handleEditClient.bind(this);
    // this.hideEditClientBox = this.hideEditClientBox.bind(this);
  }
  // sends the data to the client page
  // triggered when the the confirm button in the dialog is pressed
  // handleEditClient() {
  //     console.log(": 9");
  //     this.props.onEditClient({
  //         logo: this.state.logo,
  //         index: this.state.index,
  //         domain: this.state.domain,
  //         clientName: this.state.clientName,
  //         phoneNumber: this.state.phoneNumber.slice(2), //remove the first two characters of the number
  //         address: this.state.address,
  //         city: this.state.city,
  //         stateName: this.state.stateName,
  //     });
  //     this.hideEditClientBox();
  // }
  // handleLogoChange(event) {
  //     event.preventDefault();
  //     let reader = new FileReader();
  //     // get the file thats been uploads
  //     let file = event.target.files[0];
  //     // after the file finished loading
  //     reader.onloadend = () => {
  //         this.setState({
  //             logo: file,
  //             imgSrc: reader.result
  //          });
  //     }
  //     reader.readAsDataURL(file)
  // }
  // handleDomainChange(event) {
  //     console.log(":10");
  //     this.setState({domain: event.target.value});
  // }
  // handleClientNameChange(event) {
  //     this.setState({clientName: event.target.value});
  // }
  // handleAddressChange(event) {
  //     this.setState({address: event.target.value});
  // }
  // handleCityChange(event) {
  //     this.setState({city: event.target.value});
  // }
  // handleStateNameChange(event) {
  //     this.setState({stateName: event.target.value});
  // }
  // handlePhoneNumberChange(num) {
  //     this.setState({phoneNumber: num});
  // }
  render() {
    console.log(': 13');
    // console.log(newClient);
    // predefined names for the columns
    var columns = [
      {
        Header: 'Users',
        columns: [
          {
            Header: '   ',
            Cell: ({ row }) => {
              return (
                <img
                  style={{ borderRadius: '50%', background: 'lightgrey' }}
                  src={row.original.logo}
                  width='40'
                  height='40'
                  alt=''
                />
              );
            }
          },
          {
            Header: 'Client Name',
            accessor: 'clientName'
          },
          {
            Header: 'Client Code',
            accessor: 'companyID'
          },
          {
            Header: 'COVID-19 Vaccine tracking',
            // add the Toggle Switch over here
            Cell: ({ row }) => {
              let enabled = row.original.vaccineTracking;
              // save as string and then do the logic
              return (
                <label title='Enable Vaccine Tracking' className='switch'>
                  <input type='checkbox' defaultChecked={enabled} />
                  <span
                    className='slider round'
                    onClick={() => {
                      this.props.onToggleVaccineClient({
                        index: row.original.id
                      });
                    }}
                  ></span>
                </label>
              );
            }
          }
        ]
      }
    ];

    // if (this.props.viewDeletedClients) {
    //     // assume the deleted clients table is displayed
    //     columns[0].columns.splice(5,5) // remove the deleted button column
    //     columns[0].columns.push({
    //         Header: 'Reactivate CLient',
    //         Cell: ({row}) => <button id="taskButton" onClick={() => {
    //             let res = window.confirm("Are you sure you want to reactivate the client?")
    //             if (res) {
    //                 this.props.reactivateClient({
    //                     index: row.id,
    //                     id: row.original.id
    //                 })
    //             }
    //         }}>Reactivate</button>
    //     })
    // }
    return (
      <main>
        <p id='breadCrumbs'>{this.props.breadCrumbs}</p>

        <div className='MuiBox-root'>
          <div className='MuiPaper-root'>
            <div className='MuiBox-root css-1idik3a'>
              <div className='MuiBox-root css-uujcwg'>
                <h5 className='css-epib0t'>Vaccine Settings</h5>
                <span className='css-17vat79'></span>
              </div>
            </div>
            <div className='MuiTableContainer-root css-13ypi1f'>
              <div className='MuiBox-root css-fgimv3'>
                <Row>
                  <Col lg={4}>
                    <div className='search'>
                      <input
                        id='searchBox'
                        className='pl-2'
                        placeholder='Search Client'
                        value={this.state.inputText || ''}
                        onChange={(event) => {
                          this.props.onfilterTable({
                            type: 'FILTER_CLIENT_LIST',
                            value: event.target.value
                          });
                          let inputText = event.target.value;
                          this.setState({ inputText: inputText });
                        }}
                      />
                      <BiReset
                        id='resetTableListBtn'
                        alt='Reset'
                        className='icon_button'
                        onClick={() => {
                          this.props.onViewClientList();
                          this.setState({ inputText: null });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className='MuiBox-root css-17uua2w'>
                <Table
                  columns={columns}
                  data={
                    this.props.viewFilteredList
                      ? this.props.filteredList
                      : this.props.clientList
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
export default VaccineSettingsTable;
