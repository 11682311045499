import firebaseApp from '../../firebaseApp';

const db = firebaseApp.firestore();
const collectionName = 'libraries';

export const getLibraryByCompanyID = async (companyID) => {
  try {
    const docRef = db.collection(collectionName);

    const snapshot = await docRef.where('companyID', '==', companyID).get();

    if (snapshot.empty) {
      return null;
    }

    const docData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }));

    return docData[0];
  } catch (error) {
    console.log('ERROR - db/getLibraryByCompanyID: ', error);
    return null;
  }
};

export const getLibraryByDocumentID = async (documentID) => {
  try {
    console.log('documentID: ', documentID);
    const docRef = db.collection(collectionName).doc(documentID);

    const doc = await docRef.get();
    // console.log('doc: ', doc);

    if (doc.exists) {
      return doc.data();
    } else {
      return null;
    }
  } catch (error) {
    console.log('ERROR - db/getLibraryByDocumentID: ', error);
    return null;
  }
};

export const createLibraryDocRef = async () => {
  try {
    const libraryRef = db.collection(collectionName).doc();

    console.log('new libraryRef: ', libraryRef.id);

    if (libraryRef.id) {
      return libraryRef;
    } else {
      return null;
    }
  } catch (error) {
    console.log('ERROR - db/createLibraryDocRef: ', error);
    return null;
  }
};

export const setLibraryDoc = async (documentID, data) => {
  try {
    console.log('setLibraryDoc/documentID: ', documentID);
    console.log('setLibraryDoc/data: ', data);
    const docRef = await db
      .collection(collectionName)
      .doc(documentID)
      .set(data);

    console.log('setLibraryDoc/docRef: ', docRef);

    if (docRef) {
      return docRef;
    } else {
      return null;
    }
  } catch (error) {
    console.log('ERROR - db/setLibraryDoc: ', error);
    return null;
  }
};

export const addLibraryDoc = async (data) => {
  try {
    const docRef = await db.collection(collectionName).add(data);

    console.log('add new docRef: ', docRef.id);

    if (docRef.id) {
      return docRef;
    } else {
      return null;
    }
  } catch (error) {
    console.log('ERROR - db/addLibraryDoc: ', error);
    return null;
  }
};

export const deleteLibraryDoc = async (documentID) => {
  try {
    const docRef = await db.collection(collectionName).doc(documentID).delete();

    console.log('deleted docRef: ', docRef.id);

    if (docRef.id) {
      return docRef;
    } else {
      return null;
    }
  } catch (error) {
    console.log('ERROR - db/deleteLibraryDoc: ', error);
    return null;
  }
};
