import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import BulkUserFormat from './style/bulkUsersFormat.png';
// import Exit from './style/exit.png';
import './style/addUserDialog.css';
import { GrClose } from 'react-icons/gr';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class AddManyUsers extends Component {
  constructor() {
    super();
    this.state = {
      xlsxData: []
    };
    this.handleXLSXChange = this.handleXLSXChange.bind(this);
    this.handleAddManyUser = this.handleAddManyUser.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }
  showModal() {
    this.props.onEditBreadCrumbs({
      type: 'APPEND_BREADCRUMB',
      breadCrumbs: ' > Bulk User Upload'
    });
    const addManyUserDialog = document.getElementById('addManyUsersDialog');
    addManyUserDialog.showModal();
    // displays the modal and empties the fields
    this.setState({ file: '' });
  }
  hideModal() {
    this.props.onEditBreadCrumbs({
      type: 'REMOVE_BREADCRUMB',
      breadCrumbs: 'Users'
    });
    this.setState({ xlsxData: [] });
    document.getElementById('bulkUploadInput').value = '';
    const addManyUserDialog = document.getElementById('addManyUsersDialog');
    addManyUserDialog.close();
  }
  // adding the new user into the table
  handleAddManyUser() {
    if (this.props.role !== 'Root') {
      let alloted = '',
        used = '',
        noOfUsers = '',
        saAlloted,
        saUsed;
      // assume Super Admin
      let idx = this.props.clientList.findIndex(
        (client) => client.companyID === this.props.companyID
      );
      if (
        idx === -1 ||
        this.props.clientList[idx].licensesUsed + this.state.xlsxData.length >
          this.props.clientList[idx].licensesAssigned
      ) {
        // assume they dont have enough licenses to create user
        noOfUsers = this.state.xlsxData.length;
        alloted = this.props.clientList[idx].licensesAssigned
          ? this.props.clientList[idx].licensesAssigned
          : 0;
        used = this.props.clientList[idx].licensesUsed
          ? this.props.clientList[idx].licensesUsed
          : 0;
        saAlloted = this.props.clientList[idx].adminLicensesAssigned
          ? this.props.clientList[idx].adminLicensesAssigned
          : 0;
        saUsed = this.props.clientList[idx].adminLicensesUsed
          ? this.props.clientList[idx].adminLicensesUsed
          : 0;

        alert(
          'There are not enough licenses available for the client' +
            '\n' +
            '\n' +
            'User Licenses Alloted: ' +
            alloted +
            '\n' +
            'User Licenses Used: ' +
            used +
            '\n' +
            'SA Licenses Alloted: ' +
            saAlloted +
            '\n' +
            'SA Licenses Used: ' +
            saUsed +
            '\n\n' +
            'Number of users to upload: ' +
            noOfUsers +
            '\n\n' +
            'Please remove inactive users or contact admin@tpcm-usa.com to purchase additional licenses.'
        );

        return;
      }
    }
    this.props.onSubmit({
      data: this.state.xlsxData
    });
    this.hideModal();
  }
  handleXLSXChange(event) {
    event.preventDefault();
    let f = event.target.files[0];
    var name = f.name;
    console.log(name);
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Update state */
      let data = XLSX.utils.sheet_to_json(ws);

      let cleanData = [];

      for (let ele of data) {
        let obj = {};
        for (let index in ele) {
          //take out front and end spaces
          obj[index] = ele[index].toString().trim();
          //Sanitize phonenumber
          if (index.toLowerCase() === 'phonenumber') {
            let str = obj[index];
            //Filter only numbers from the input
            let cleaned = ('' + str).replace(/\D/g, '');
            //Check if the input is of correct length
            let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
              // obj[index] = '(' + match[1] + ') ' + match[2] + '-' + match[3]
            }
          }
          //Sanitize role
          if (index.toLowerCase() === 'role') {
            let role = obj[index].toLowerCase().split(' ');

            for (var i = 0; i < role.length; i++) {
              role[i] = role[i][0].toUpperCase() + role[i].slice(1);
            }
            role = role.join(' ');
            if (role !== 'User' && role !== 'Super Admin') {
              role = 'User';
            }

            obj[index] = role;
          }

          console.log(obj);
        }

        cleanData.push(obj);
      }

      console.log(cleanData);
      this.setState({ xlsxData: cleanData });
    };
    reader.readAsBinaryString(f);
  }
  render() {
    return (
      <section>
        <nav role='navigation'>
          <button type='button' onClick={this.showModal} className='btn'>
            Bulk Upload
          </button>
        </nav>
        <dialog id='addManyUsersDialog' className='bigDialogBox'>
          <GrClose
            id='exit-dialog-icon'
            height={28}
            onClick={this.hideModal}
            alt='exit button'
          />

          <Row>
            <Col xs={12} md={12} lg={12}>
              <h2>Bulk User Upload</h2>
              <h4>
                Please Use the Format From the Image Below to Upload Users in
                Bulk
              </h4>
              <img
                id='bulkUserImg'
                src={BulkUserFormat}
                alt='xlsx format example'
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} lg={4}>
              <label>Enter the XLSX file:</label>
              <input
                type='file'
                id='bulkUploadInput'
                onChange={this.handleXLSXChange}
                accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} lg={4}>
              <div className='btn--flex'>
                <button
                  onClick={this.handleAddManyUser}
                  type='button'
                  className='btn--confirm dialog__btn'
                >
                  Confirm
                </button>
                <button
                  onClick={this.hideModal}
                  type='button'
                  className='btn--close dialog__btn'
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </dialog>
      </section>
    );
  }
}
export default AddManyUsers;
