import { useEffect, useMemo, useState } from 'react';
import { useSelector } from '@legendapp/state/react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';

import { getFolderNameAndParentIDByDocumentID } from '../../firebase/db/folders/query';
import { addSubfolder } from '../../firebase/db/folders/operations';

import Store from '../../store/Store';

const FolderAdd = () => {
  const selectedFolderID = useSelector(Store.library.selectedFolder);
  const rootFolderID = useSelector(Store.library.rootFolderID);
  const companyID = useSelector(Store.user.companyID);
  const userName = useSelector(Store.user.userName);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [parentFolder, setParentFolder] = useState('root');

  const parentFolderID = useMemo(
    () => (selectedFolderID ? selectedFolderID : rootFolderID),
    [rootFolderID, selectedFolderID]
  );

  useEffect(() => {
    const getFolderName = async () => {
      try {
        const result = await getFolderNameAndParentIDByDocumentID(
          parentFolderID
        );
        setParentFolder(result.folderName);
      } catch (error) {
        console.log('error: ', error);
      }
    };

    if (!open) return;

    getFolderName();

    return () => {};
  }, [open, parentFolderID]);

  const handleAddFolderClick = (e) => {
    e.stopPropagation();
    setOpen(true);
    Store.library.isAddOrUploadDialogOpen.set(true);
  };

  const handleCancel = () => {
    setOpen(false);
    Store.library.isAddOrUploadDialogOpen.set(false);
  };

  const handleSubmit = async () => {
    try {
      if (!name) return;
      setIsProcessing(true);
      const data = {
        companyID,
        folderName: name.trim(),
        folders: [],
        files: [],
        isProtected: false,
        parentFolderID: parentFolderID || rootFolderID,
        updatedBy: userName
      };

      const result = await addSubfolder(parentFolderID, data);
      console.log('result: ', result);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setIsProcessing(false);
      setName('');
      setOpen(false);
    }
  };

  return (
    <>
      <Button
        variant='contained'
        onClick={(e) => handleAddFolderClick(e)}
        sx={{ backgroundColor: '#2152ff' }}
      >
        Add Folder
      </Button>

      <Dialog
        id='folder-update-dialog'
        maxWidth='xs'
        open={open}
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      >
        <DialogTitle>Add Folder</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span>Parent Folder:</span>
            <span
              style={{ paddingLeft: '1rem', fontSize: 16, fontWeight: 'bold' }}
            >
              {parentFolder}
            </span>
          </DialogContentText>

          <TextField
            autoFocus
            margin='dense'
            id='add-folder-name'
            label='Folder Name'
            fullWidth
            variant='standard'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {isProcessing && (
            <CircularProgress
              sx={{ position: 'absolute', top: '50%', left: '50%' }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>

          <Button
            variant='contained'
            onClick={handleSubmit}
            disabled={isProcessing}
          >
            Add Folder
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FolderAdd;
