import { Component, Fragment } from 'react';

import GoogleMapReact from 'google-map-react';
import { FaMapMarkerAlt } from 'react-icons/fa';

const AnyReactComponent = ({ text }) => (
  <div
    style={{
      color: 'red',
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      transform: 'translate(-50%, -50%)'
    }}
  >
    <FaMapMarkerAlt
      style={{ fontSize: '20px' }}
      title={text}
      height={25}
      width={25}
      alt='location'
    />
  </div>
);

// Geocode.setApiKey("AIzaSyCDyBYDrO6HncWa0V0avahyZist4k_mF7A");
//
// Geocode.setLanguage("en");
//
// // set response region. Its optional.
// // A Geocoding request with region=es (Spain) will return the Spanish city.
// Geocode.setRegion("es");
//
// // set location_type filter . Its optional.
// // google geocoder returns more that one address for given lat/lng.
// // In some case we need one address as response for which google itself provides a location_type filter.
// // So we can easily parse the result for fetching address components
// // ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
// // And according to the below google docs in description, ROOFTOP param returns the most accurate result.
// Geocode.setLocationType("ROOFTOP");
//
// // Enable or disable logs. Its optional.
// Geocode.enableDebug();

const handleApiLoaded = (map, maps) => {
  // use map and maps objects
  // Geocode.fromLatLng("48.8583701", "2.2922926").then(
  //   (response) => {
  //     const address = response.results[0].formatted_address;
  //     console.log(address);
  //   },
  //   (error) => {
  //     console.error(error);
  //   }
  // );
  // getAddress()
};

const getAddress = () => {
  // https://maps.googleapis.com/maps/api/geocode/json?latlng=40.714224,-73.961452&key=AIzaSyCDyBYDrO6HncWa0V0avahyZist4k_mF7A
  fetch(
    'https://maps.googleapis.com/maps/api/geocode/json?latlng=40.714224,-73.961452&key=AIzaSyCDyBYDrO6HncWa0V0avahyZist4k_mF7A',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Access-Control-Allow-Methods': 'GET, PUT, POST, OPTIONS',
        'Access-Control-Allow-Headers': '*'
      }
    }
  ).then((res) => {
    console.log(res);
  });
};

class UserLocationMap extends Component {
  constructor() {
    super();
  }

  render() {
    if (this.props.lat === '0' || this.props.lng === '0') {
      return (
        <Fragment>
          <h4>Location Not Available</h4>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <h4>Address: {this.props.address}</h4>
          <h4>
            Location: {this.props.lat},{this.props.lng}{' '}
          </h4>
          <h4>Location Updated At: {this.props.locationTime} </h4>

          <div className='btn--flex'></div>
          <br />
          <GoogleMapReact
            //dev key
            bootstrapURLKeys={{
              key: 'AIzaSyCDyBYDrO6HncWa0V0avahyZist4k_mF7A'
            }}
            //prod key
            // bootstrapURLKeys={{ key: 'AIzaSyCDyBYDrO6HncWa0V0avahyZist4k_mF7A' }}
            defaultCenter={{ lat: 37.0902, lng: -95.7129 }}
            defaultZoom={4}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          >
            <AnyReactComponent
              lat={this.props.lat}
              lng={this.props.lng}
              text={this.props.userName}
            />
          </GoogleMapReact>
        </Fragment>
      );
    }
  }
}

export default UserLocationMap;
