import styled from 'styled-components';
const Styles = styled.div`
    table {
        border-spacing: 0;
        border: 1px solid #C9C9C9;
        tr {
            :last-child {
            td {
                border-bottom: 0;
            }
            }
        }
        th,
        td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #C9C9C9;

            :last-child {
            border-right: 0;
            }
        }
    }`
export default Styles;