import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// import firebase from 'firebase/app';

import Routes from './routes';

import './index.css';

// firebase.firestore().enablePersistence();

const rootElement = document.getElementById('root');
ReactDOM.render(
  <BrowserRouter>
    <Routes />
  </BrowserRouter>,
  rootElement
);
