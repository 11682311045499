import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

import DeleteIcon from '@mui/icons-material/Delete';
import { AiFillDelete } from 'react-icons/ai';

const ConfirmDelete = ({ item, itemType, onConfirm, isDisabled }) => {
  const [open, setOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleDeleteClick = (e) => {
    e.stopPropagation();

    if (isDisabled) {
      alert(
        `Folder: ${item} is not empty. Please delete all files and folders first.`
      );
      return;
    }

    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    return () => {
      setIsProcessing(false);
      setOpen(false);
    };
  }, []);

  const handleConfirm = () => {
    try {
      setIsProcessing(true);

      onConfirm();
    } catch (error) {
      console.log('error: ', error);
      setIsProcessing(false);
      setOpen(false);
    }
  };

  return (
    <>
      <AiFillDelete
        title='Delete'
        height={25}
        width={25}
        alt='Delete'
        className='icon_button'
        onClick={(e) => handleDeleteClick(e)}
      />
      <Dialog
        id='confirm-delete-dialog'
        maxWidth='xs'
        open={open}
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant='subtitle1'>
            Are you sure you want to delete this {itemType}?
          </Typography>
          <Typography variant='subtitle2'>{item}</Typography>
          {isProcessing && (
            <CircularProgress
              sx={{ position: 'absolute', top: '50%', left: '50%' }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant='contained'
            color='error'
            startIcon={<DeleteIcon />}
            onClick={handleConfirm}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmDelete;
