import React, { Component, Fragment } from 'react';

import Styles from './Styles';
import Table from './Table';

import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';
import downArrow from './style/downArrow.png';
import rightArrow from './style/rightArrow.png';

// import './style/ClientSettings.css'

class ClientSettingsTable extends Component {
  constructor() {
    super();
    this.state = {
      show: true
    };
    this.handleToggleChange = this.handleToggleChange.bind(this);
  }
  componentDidMount() {}

  formCheck(action) {
    let arr = [];
    for (let form of this.props.formList) {
      if (form['title'].includes('Daily Health Screen')) {
        if (action.forms === undefined || !action.forms.includes(form.id)) {
          arr['formId'] = form.id;
          arr['res'] = false;
        } else if (
          action.forms !== undefined &&
          action.forms.includes(form.id)
        ) {
          arr['formId'] = form.id;
          arr['res'] = true;
        }
        return arr;
      }
    }
  }

  findUserList(compId) {
    let sameCompUsers = [];

    for (let users of this.props.userList) {
      if (users.companyID === compId) {
        sameCompUsers.push(users);
      }
    }
    console.log(sameCompUsers);

    this.props.sendReminder({ row: null, users: sameCompUsers });
  }
  handleToggleChange(action) {
    // sending data to userPage to update the Toggle for the user
    this.props.onToggleForm(action);
  }

  render() {
    var columns = [
      {
        Header: 'Users',
        columns: [
          {
            Header: 'Name',
            accessor: 'clientName'
          },
          {
            Header: 'Status',
            Cell: ({ row }) => {
              //when this is toggled to on, which means that company id should get added the forms collection
              let arr = this.formCheck(row.original);
              return (
                <label className='switch' title='Enable Toggle'>
                  <input
                    type='checkbox'
                    defaultChecked={arr['res']}
                    onClick={() => {
                      this.handleToggleChange({
                        id: row.id,
                        row: row.original,
                        toggleValue: !arr['res'],
                        formId: arr['formId']
                      });
                    }}
                  />
                  <span className='slider round'></span>
                </label>
              );
            }
          },
          {
            Header: 'Send User-Reminder ',
            Cell: ({ row }) => {
              //send reminder button should alert all the users of that company
              return (
                <button
                  id='reminderButton'
                  title='Reminds the user to fill up the health form'
                  onClick={() => this.findUserList(row.original.companyID)}
                >
                  Send
                </button>
              );
            }
          }
        ]
      }
    ];

    let body = <p>Loading Client Settings</p>;
    var idx = this.props.formSettings.findIndex(
      (client) => client.id === this.props.companyID
    );
    var arr = this.formCheck(this.props.formSettings[idx]);
    console.log(idx);
    console.log(arr);

    body = (
      <Fragment>
        <article id='dashboardTableCont'>
          <Styles>
            {/* gets the user data from the userPage class and fills in the table */}
            <div className='outerBucket'>
              <div
                className='formsType'
                onClick={() => this.setState({ show: !this.state.show })}
              >
                <div className='formName'>
                  DAILY HEALTH SCREEN FORM &nbsp;&nbsp;
                  {this.props.role === 'Super Admin' ? (
                    <label
                      className='switch'
                      style={{ float: 'right' }}
                      title='Enable Toggle'
                    >
                      <input
                        type='checkbox'
                        defaultChecked={arr['res']}
                        onClick={() => {
                          this.handleToggleChange({
                            id: idx,
                            row: arr,
                            toggleValue: !arr['res'],
                            formId: arr['formId']
                          });
                        }}
                      />
                      <span className='slider round'></span>
                    </label>
                  ) : this.props.role === 'Root' ? (
                    this.state.show ? (
                      <MdKeyboardArrowDown
                        style={{ float: 'right', 'font-size': '24px' }}
                        src={rightArrow}
                        height={25}
                        width={25}
                        alt='Right Arrow'
                      />
                    ) : (
                      <MdKeyboardArrowRight
                        style={{ float: 'right', 'font-size': '24px' }}
                        src={downArrow}
                        height={25}
                        width={25}
                        alt='Down Arrow'
                      />
                    )
                  ) : null}
                </div>
              </div>

              {this.props.role === 'Root' && this.state.show ? (
                <div className='formTable'>
                  <Table columns={columns} data={this.props.formSettings} />
                </div>
              ) : null}
            </div>
          </Styles>
        </article>
      </Fragment>
    );

    return (
      <main id='dashboardPage'>
        <p id='breadCrumbs'>Settings</p>

        <div className='MuiBox-root'>
          <div className='MuiPaper-root'>
            <div className='MuiBox-root css-1idik3a'>
              <div className='MuiBox-root css-uujcwg'>
                <h5 className='css-epib0t'>Form Settings</h5>
                <span className='css-17vat79'></span>
              </div>
            </div>
            <div className='MuiTableContainer-root css-13ypi1f'>
              <div className='MuiBox-root css-17uua2w'>{body}</div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
export default ClientSettingsTable;
