/* High Level: Displays a list of all the ERPs with the Response Plan name,
 * 			   Client Code, # of Users, Tasks, Roll Call, Enabled, Active,
 * 			   Practice, edit button and delete button
 * Purpose: Displays a list of all the ERPs and edit/delete buttons for each ERP.
 * Approach: Handles the edit/delete ERP changes which entails adding titles, adding users
 * 			 and adding tasks to the ERP.
 */
import React, { Component, Fragment } from 'react';
// import Styles from './Styles';
import Table from './Table';
import UserLocationMap from './userLocation';
import ProfilePreview from './style/flash_icon.png';
// import TrashCan from './style/delete.png';
import Edit from './style/edit.png';
// import Check from './style/check_icon.png';
import AddUserListBox from './AddUserListBox';
import AddTaskBox from './AddTaskBox';
// import FBapp from '../firebaseConfig';
import Exit from './style/exit.png';
// import Select from 'react-select';
// import Reset from './style/reset_btn.png';
import Activate from './style/plus_sign.png';
import Archive from './style/archive.png';
import Location from './style/location.png';
//BOOTSRAP:
// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ImPencil, ImCheckboxChecked } from 'react-icons/im';
import { MdArchive, MdDelete } from 'react-icons/md';
import { BiReset } from 'react-icons/bi';
import { AiOutlinePlus } from 'react-icons/ai';
import { GrClose } from 'react-icons/gr';
import ProgressBar from '@ramonak/react-progress-bar';

import { db } from '../firebase/index';
// get the doc reference from a UID when adding user to ERP
// const db = FBapp.firestore();
class PlanTable extends Component {
  constructor() {
    super();
    this.state = {
      isActive: false, // indicates whether the Check List is active & on edit, before removing task, check if activated
      editCheckList: false, // {Boolean} true: editing a Check List & false: creating a new one
      currUserName: '', // Edit User's Name displayed in Edit Title Box
      currUserTitle: '', // Edit User's Title displayed in Edit Title Box
      // states that are updated on edit
      planName: '', // name of the ERP
      leader: '', // name of the Leader
      userList: [], // array of doc references (users)
      titleList: [], // array of strings (titles)
      userStatus: {}, // map of JS objects (key: user ref ID / value: {isCheckedIn: bool, title: string})
      index: 0, // index of the selected ERP
      // CREATE_TASK Values
      taskNameChangeMap: [], // hmap of key/value pairs {key: taskID / value: new task name }
      newTasks: [], // {array of Objs (String: taskName, String: description, String: assignedUser, String: assignedRC)}
      taskList: [], // array of doc references (tasks)
      taskName: '', // {String} name of task
      description: '', // {String} descriƒption of task
      assignedUser: '', // {String} name of assigned User
      assignedRC: '', // {String} (empty str if no assigned Roll Call) assigned Roll Call to the task
      // Create Add Roll Call
      companyID: '', // {String} ensure that root choose a companyID before creating a Roll Call
      rollCallName: '', // {String} name of the Roll Call
      rollCallList: [], // {Array of Strings} Array of names that are
      // added to the Roll Call
      editRollCall: false, // {Boolean} false: edit exisiting Roll Call || true: create new Roll Call
      withNotifications: false, // {Boolean} false: roll call notifications disabled ||
      // true: roll Call notifications enabled
      rcDescription: '', // {String} description of the Roll Call
      viewUserList: false, // {Boolean} true: view UserList of Roll Call || false: view Roll Call List
      filter: '', // {String} filter the roll call user list based on the status color
      logo: '', // {Object} stores the file of the image
      imgSrc: ProfilePreview, // {String} url to the image
      userListBox: [], // {Array of Objects {label: name, value: id}} list of all the users sent to the UserListBox
      inputText: null, // {label: string, value: string}
      templateName: '', // {String} name of the template
      editTemplate: false, // {Boolean} whether to edit the template
      tmplName: '', // {String} selected template
      teamName: '', // {String} selected Team
      isPractice: false, // {Bool} whether the checklist is a drill or not
      checkinReq: false, // {Bool} whether users have to checkin to checklist
      selectedUsers: [], // {Array of objects} {value: UID, label: name}
      flashType: 'Roll Call', // {string} (Critical Event Alert, Mass Notification, Roll Call)
      rcArchiveMsg: '', //{String}{Used for storing the archive message in checkliist and flash, when archive button is clicked}
      archive: false, // {boolean} {Used for archiving a column, be either checklist or flash}
      archiveHeader: '',
      checklist: false, // {boolean} {Used for archiving a column, just to inform if archiving is called from checklist or flash}
      rollcall: false, //	{boolean} {Used for archiving a column, just to inform if archiving is called from checklist or flash}
      date: new Date(),
      userLocation: { lat: 0, lng: 0 },
      locationUserName: '',
      userLocationAddress: ''
    };
    this.handlePlanAction = this.handlePlanAction.bind(this);
    this.handlePlanNameChange = this.handlePlanNameChange.bind(this);
    this.handleActivatedChange = this.handleActivatedChange.bind(this);
    this.handleCheckinReqChange = this.handleCheckinReqChange.bind(this);
    this.handlePracticeChange = this.handlePracticeChange.bind(this);
    this.handleLeaderChange = this.handleLeaderChange.bind(this);
    this.showPlanBox = this.showPlanBox.bind(this);
    this.hidePlanBox = this.hidePlanBox.bind(this);
    this.editTitle = this.editTitle.bind(this);
    this.removeTitle = this.removeTitle.bind(this);
    this.handleEditUser = this.handleEditUser.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.editTask = this.editTask.bind(this);
    this.removeTask = this.removeTask.bind(this);
    this.showEditUserBox = this.showEditUserBox.bind(this);
    this.hideEditUserBox = this.hideEditUserBox.bind(this);
    this.showUserBoxModal = this.showUserBoxModal.bind(this);
    this.hideUserBoxModal = this.hideUserBoxModal.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.showTaskModal = this.showTaskModal.bind(this);
    this.addTaskToERP = this.addTaskToERP.bind(this);
    this.hideTaskBox = this.hideTaskBox.bind(this);
    this.handleTaskNameChange = this.handleTaskNameChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);

    this.handleAssignedUserChange = this.handleAssignedUserChange.bind(this);
    this.handleAssignedRollCallChange =
      this.handleAssignedRollCallChange.bind(this);
    this.populatePlanBox = this.populatePlanBox.bind(this);
    this.addUserOptions = this.addUserOptions.bind(this);
    this.handleCompanyIDChangeChecklist =
      this.handleCompanyIDChangeChecklist.bind(this);
    // Roll Call Functions
    this.showRollCallDialog = this.showRollCallDialog.bind(this);
    this.hideRollCallDialog = this.hideRollCallDialog.bind(this);
    this.handleRollCallNameChange = this.handleRollCallNameChange.bind(this);
    this.handleRollCallAction = this.handleRollCallAction.bind(this);
    this.populateRollCall = this.populateRollCall.bind(this);
    this.populateName = this.populateName.bind(this);
    this.handleNotificationChange = this.handleNotificationChange.bind(this);
    this.handleRCDescriptionChange = this.handleRCDescriptionChange.bind(this);
    this.handleFilterStatusChange = this.handleFilterStatusChange.bind(this);
    this.handleLogoChange = this.handleLogoChange.bind(this);
    this.handleTeamChange = this.handleTeamChange.bind(this);
    this.handleCompanyIDChangeRollCall =
      this.handleCompanyIDChangeRollCall.bind(this);
    this.handleFlashTypeChange = this.handleFlashTypeChange.bind(this);

    this.handleArchiveDescriptionChange =
      this.handleArchiveDescriptionChange.bind(this);

    // Template functions
    this.showTemplateBox = this.showTemplateBox.bind(this);
    this.hideTemplateBox = this.hideTemplateBox.bind(this);
    this.handleTemplateNameChange = this.handleTemplateNameChange.bind(this);
    this.handleEditTemplateName = this.handleEditTemplateName.bind(this);
    this.handleCreateTemplate = this.handleCreateTemplate.bind(this);
    this.handleTmplChange = this.handleTmplChange.bind(this);
    this.populateTemplateUserList = this.populateTemplateUserList.bind(this);
    this.handleUserListChange = this.handleUserListChange.bind(this);
    this.handleCompanyIDChangeTemplate =
      this.handleCompanyIDChangeTemplate.bind(this);

    //Map FUNCTIONS
    this.getUserLocation = this.getUserLocation.bind(this);
    this.showUserLocation = this.showUserLocation.bind(this);
    this.showLocationModal = this.showLocationModal.bind(this);
    this.hideLocationModal = this.hideLocationModal.bind(this);
  }
  /******************************** Edit Plan Box *****************/
  handlePlanAction() {
    if (this.state.planName.length === 0) {
      // assume the user did not name the plan
      alert('Please enter a name for the Emergency Response Plan');
    } else {
      if (this.state.editCheckList) {
        // console.log("EditChecklis")
        // console.log(this.state.taskList)
        // assume the user is the Plan Leader
        this.props.onEditPlan({
          index: this.state.index,
          planName: this.state.planName,
          titleList: this.state.titleList,
          userList: this.state.userList,
          userStatus: this.state.userStatus,
          taskList: this.state.taskList,
          taskNameChangeMap: this.state.taskNameChangeMap,
          newTasks: this.state.newTasks,
          isArchived: false
        });
        this.hidePlanBox();
      } else {
        console.log('EditChecklis else');
        console.log(this.state.planList);
        let idx = this.props.tmplList.findIndex(
          (tmpl) => tmpl.name === this.state.tmplName
        );
        let tasks = this.props.tmplList[idx].tasks;
        this.props.onCreatePlan({
          // keys needed for the plan
          planName: this.state.planName,
          titleList: this.state.titleList,
          isActive: this.state.isActive,
          isArchived: false,
          users: this.state.userList,
          userStatus: this.state.userStatus,
          tasks: tasks,
          teamName: this.state.teamName,
          isPractice: this.state.isPractice,
          checkinReq: this.state.checkinReq,
          dateAcivated: this.state.date
        });
        this.hidePlanBox();
      }
    }
  }
  handleCompanyIDChangeChecklist(event) {
    this.props.onUpdateCompanyID({
      companyID: event.target.value
    });
    // clear the user option list
    // update the userlist state to 0
    // update the userlist html element to empty
    this.setState({
      userList: [],
      userStatus: {},
      companyID: event.target.value,
      selectedUsers: []
    });
    document.getElementById('editSelectedUsersList').innerHTML = '';
  }
  handleActivatedChange() {
    let status = this.state.isActive;
    this.setState({ isActive: !status });
  }
  handlePracticeChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({ isPractice: value });
  }
  handleCheckinReqChange() {
    let status = this.state.checkinReq;
    this.setState({ checkinReq: !status });
  }
  handlePlanNameChange(event) {
    this.setState({ planName: event.target.value });
  }
  handleLeaderChange(event) {
    this.setState({ leader: event.target.value });
  }
  showPlanBox() {
    this.props.onEditBreadCrumbs({
      type: 'APPEND_BREADCRUMB',
      breadCrumbs: ' > Create Checklist'
    });
    // add teams to the drop down menu
    document.getElementById('editTeamPlan').innerHTML = '';
    let editTeamPlan = document.getElementById('editTeamPlan');
    let currOpt = { text: 'Select Group', value: '' };
    editTeamPlan.options.add(
      new Option(currOpt.text, currOpt.value, currOpt.selected)
    );
    for (let ug of this.props.userGroups) {
      currOpt = { text: ug.name, value: ug.name };
      editTeamPlan.options.add(
        new Option(currOpt.text, currOpt.value, currOpt.selected)
      );
    }
    if (this.props.role === 'Root' && this.state.editCheckList === false) {
      // assumme root admin and creating a new checklist
      var selectCompanyIDChecklist = document.getElementById(
        'selectCompanyIDChecklist'
      ); // get the select Client tag
      selectCompanyIDChecklist.innerHTML = '';
      let curr = { text: 'Select a Client', value: '' };
      selectCompanyIDChecklist.options.add(
        new Option(curr.text, curr.value, curr.selected)
      );
      for (let client of this.props.clientList) {
        curr = { text: client, value: client };
        selectCompanyIDChecklist.options.add(
          new Option(curr.text, curr.value, curr.selected)
        );
      }
      this.setState({ companyID: this.props.companyID });
    }

    const editPlanBox = document.getElementById('editPlanBox');
    editPlanBox.style.left = this.props.toggleMenu ? '105px' : '330px';
    editPlanBox.showModal();
  }
  hidePlanBox() {
    const editPlanBox = document.getElementById('editPlanBox');
    editPlanBox.close();
    // clear all the html lists
    // document.getElementById('editTitleList').innerHTML = '';
    document.getElementById('editSelectedUsersList').innerHTML = '';
    document.getElementById('editTasksList').innerHTML = '';
    // document.getElementById('editSelectLeaderPlan').innerHTML = '';
    this.setState({
      editCheckList: false,
      planName: '',
      userList: [],
      taskList: [],
      titleList: [],
      userStatus: {},
      index: 0,
      isActive: false
    });
    this.props.onEditBreadCrumbs({
      type: 'REMOVE_BREADCRUMB',
      breadCrumbs: 'Checklists'
    });
  }

  populateTemplateUserList(obj) {
    for (let user of obj.users) {
      // let UID = user.id;
      // let utitle = pdata.userStatus[UID].title;
      // iterating through the users
      user.get().then((query) => {
        let uname = query.data().firstName + ' ' + query.data().lastName;
        // add the current user to the Leader user dropdown
        // let currOpt = { text: uname, value: uname };
        // editSelectLeaderPlan.options.add( new Option(currOpt.text, currOpt.value, currOpt.selected) );
        // if (user.id === pdata.leader.id) {
        // 	// assume the current user is the leader and has already been added to the menu
        // 	this.setState({leader: uname})
        // }
        let urole = query.data().role;
        var item = document.createElement('li'); // create the list item
        var userName = document.createElement('span'); // add the userName to the li
        userName.innerHTML = uname;
        var pencilIcon = document.createElement('img');
        pencilIcon.setAttribute('src', Edit);
        pencilIcon.setAttribute('height', '20px');
        pencilIcon.setAttribute('class', 'selectedUserPencilIcon');
        pencilIcon.addEventListener('click', this.showEditUserBox);
        var br = document.createElement('br');
        var userRole = document.createElement('span'); // span element to store name of the title/role
        // userRole.innerHTML = utitle + ', ' + urole;
        userRole.innerHTML = 'Role: ' + urole;
        var minusSign = document.createElement('span');
        minusSign.innerHTML = 'X';
        minusSign.setAttribute('class', 'selectedUserMinusSign');
        minusSign.addEventListener('click', this.removeUser);
        item.appendChild(userName); // add the description to the element
        item.appendChild(minusSign);
        item.appendChild(br);
        item.appendChild(userRole);
        document.getElementById('editSelectedUsersList').appendChild(item);
      });
    }
  }
  // populating the titlelist, userlist, and tasklist in the html popup
  // Leader is the Plan Leaders document reference
  populatePlanBox(pdata, index) {
    // add teams to the drop down menu
    document.getElementById('editTeamPlan').innerHTML = '';
    let editTeamPlan = document.getElementById('editTeamPlan');
    let currOpt = { text: 'Select Group', value: '' };
    editTeamPlan.options.add(
      new Option(currOpt.text, currOpt.value, currOpt.selected)
    );
    for (let ug of this.props.userGroups) {
      currOpt = { text: ug.name, value: ug.name };
      editTeamPlan.options.add(
        new Option(currOpt.text, currOpt.value, currOpt.selected)
      );
    }

    for (let user of pdata.users) {
      // let UID = user.id;
      // let utitle = pdata.userStatus[UID].title;
      // iterating through the users
      user.get().then((query) => {
        let uname = query.data().firstName + ' ' + query.data().lastName;
        // add the current user to the Leader user dropdown
        // let currOpt = { text: uname, value: uname };
        // editSelectLeaderPlan.options.add( new Option(currOpt.text, currOpt.value, currOpt.selected) );
        // if (user.id === pdata.leader.id) {
        // 	// assume the current user is the leader and has already been added to the menu
        // 	this.setState({leader: uname})
        // }
        let urole = query.data().role;
        var item = document.createElement('li'); // create the list item
        var userName = document.createElement('span'); // add the userName to the li
        userName.innerHTML = uname;
        var pencilIcon = document.createElement('img');
        pencilIcon.setAttribute('src', Edit);
        pencilIcon.setAttribute('height', '20px');
        pencilIcon.setAttribute('class', 'selectedUserPencilIcon');
        pencilIcon.addEventListener('click', this.showEditUserBox);
        var br = document.createElement('br');
        var userRole = document.createElement('span'); // span element to store name of the title/role
        // userRole.innerHTML = utitle + ', ' + urole;
        userRole.innerHTML = 'Role: ' + urole;
        var minusSign = document.createElement('span');
        minusSign.innerHTML = 'X';
        minusSign.setAttribute('class', 'selectedUserMinusSign');
        minusSign.addEventListener('click', this.removeUser);
        item.appendChild(userName); // add the description to the element
        item.appendChild(minusSign);
        item.appendChild(br);
        item.appendChild(userRole);
        document.getElementById('editSelectedUsersList').appendChild(item);
      });
    }
    // ADD EXISTING TASKS to the EDIT PLAN BOX
    if (this.props.planPager === 4 && this.props.subPlanPager === 1) {
      for (let task of pdata.tasks) {
        task.get().then((query) => {
          if (query.data() === undefined || query.data() === null) return;
          let tname = query.data().name;
          var item = document.createElement('li'); // the list item
          var taskName = document.createElement('span'); // the span element to store name of the task
          taskName.innerHTML = tname;
          var pencilIcon = document.createElement('img'); // img element to store the edit icon
          pencilIcon.setAttribute('src', Edit);
          pencilIcon.setAttribute('height', '20px');
          pencilIcon.setAttribute('class', 'selectedUserPencilIcon taskPencil');
          pencilIcon.addEventListener('click', this.editTask);
          var minusSign = document.createElement('span'); // span element to store the minus sign
          minusSign.innerHTML = 'X';
          minusSign.setAttribute(
            'class',
            'selectedUserMinusSign taskMinusSign'
          );
          minusSign.addEventListener('click', this.removeTask);
          item.appendChild(taskName);
          item.appendChild(pencilIcon);
          item.appendChild(minusSign);
          document.getElementById('editTasksList').appendChild(item);
        });
      }
    }
    this.props.onEditBreadCrumbs({
      type: 'APPEND_BREADCRUMB',
      breadCrumbs: ' > Edit Checklist'
    });
    let teamName = '';
    if (pdata.teamName !== '') {
      teamName = pdata.teamName;
    }
    this.setState({
      planName: pdata.name,
      userList: pdata.users,
      taskList: pdata.tasks,
      titleList: pdata.titleList,
      userStatus: pdata.userStatus,
      index: index,
      isActive: pdata.isActive,
      editCheckList: true,
      teamName: teamName
    });
    const editPlanBox = document.getElementById('editPlanBox');
    editPlanBox.style.left = this.props.toggleMenu ? '105px' : '330px';

    editPlanBox.showModal();
  }
  /********************** Edit Title ********************************/
  // Updates a title in the titleList array state
  editTitle(row) {
    let title = row.path[1].textContent.slice(0, -1); // get the tasks name
    let res = window.prompt('Enter a new Title:');
    if (res !== null && res.length > 0) {
      // assume that the user has entered a valid title name
      let titleList = document
        .getElementById('editTitleList')
        .getElementsByTagName('li');
      // iterate  through all the html list elements
      for (let element of titleList) {
        let name = element.childNodes[0].textContent;
        if (name === title) {
          element.childNodes[0].textContent = res;
          break;
        }
      }
      // Update state
      for (let currTitle of this.state.titleList) {
        if (currTitle === title) {
          currTitle = res;
          break;
        }
      }
    }
  }
  removeTitle(row) {
    let title = row.path[1].textContent.slice(0, -1); // get the tasks name
    let res = window.confirm(
      `Are you sure you want to remove ${title} from the plan?`
    );
    if (res) {
      let currTitle;
      for (let i = 0; i < this.state.titleList.length; i++) {
        currTitle = this.state.titleList[i];
        if (currTitle === title) {
          // udpate state
          this.state.titleList.splice(i, 1);
          break;
        }
      }
      // html remove the title from the list
      row.path[1].parentNode.removeChild(row.path[1]);
    }
  }
  /******************************* Edit User Title **********************/
  // Updates the user's title in the userStatus object state
  handleEditUser() {
    let currUserName = this.state.currUserName;
    let currUserTitle = this.state.currUserTitle;
    for (let user of this.state.userList) {
      user.get().then((query) => {
        let data = query.data();
        let fullName = data.firstName + ' ' + data.lastName;
        if (fullName === currUserName) {
          // assume user is selected
          // update state of userStatus by changing the title for the selected user
          let userStatus = this.state.userStatus;
          userStatus[user.id].title = currUserTitle;
          this.setState({ userStatus: userStatus });
        }
      });
    }
    //iterate through the ul and update the selected element
    let userList = document
      .getElementById('editSelectedUsersList')
      .getElementsByTagName('li');
    for (let element of userList) {
      let name = element.childNodes[0].textContent;
      if (name === this.state.currUserName) {
        // assume the user has been selected
        // get the curr user's title and role
        let titleRole = element.childNodes[4].textContent.split(', ');
        titleRole[0] = this.state.currUserTitle;
        titleRole = titleRole.join(', ').toString();
        element.childNodes[4].innerHTML = titleRole;
        break;
      }
    }
    this.hideEditUserBox();
  }
  // EDIT USER BOX update the currUserTitle of the selected user in edit ERP
  handleTitleChange(event) {
    this.setState({ currUserTitle: event.target.value });
  }
  // populate the role list and dropdown menu of titles in edit user box
  showEditUserBox(row) {
    if (this.state.editCheckList) {
      // assume editing a user with an existing titleList
      document.getElementById('editSelectUserTitleBox').innerHTML = '';
      var selectUserTitle = document.getElementById('editSelectUserTitleBox');
      let currOpt = { text: 'Add Title', value: 'Add Title' };
      selectUserTitle.options.add(
        new Option(currOpt.text, currOpt.value, currOpt.selected)
      );
      for (let title of this.state.titleList) {
        currOpt = { text: title, value: title };
        selectUserTitle.options.add(
          new Option(currOpt.text, currOpt.value, currOpt.selected)
        );
      }
    }
    let userData = row.path[1].textContent.split('-'); // get the users name
    let userTitleRole = userData.pop(); // pop off the title/role of user
    userTitleRole = userTitleRole.split(', ');
    let userTitle = userTitleRole[0]; // curr users title
    let userName = userData.toString(); // curr user's name
    const editUserTitleBox = document.getElementById('editUserTitleBox');
    editUserTitleBox.showModal();
    this.setState({
      currUserName: userName,
      currUserTitle: userTitle
    });
  }
  hideEditUserBox() {
    const editUserTitleBox = document.getElementById('editUserTitleBox');
    editUserTitleBox.close();
    this.setState({
      currUserTitle: '',
      currUserName: ''
    });
  }
  removeUser(row) {
    // remove the item for the list
    let username = row.path[1].textContent.split('-'); // get the users name
    username.pop(); // get the name of the user
    username = username.toString();
    let res = window.confirm(
      `Are you sure you want to remove the ${username} from the plan?`
    );
    if (res) {
      if (this.props.planPager === 0 || this.props.planPager === 1) {
        // assume removing a user from the Response Plan
        for (let i = 0; i < this.state.userList.length; i++) {
          // iterate through all the user doc references
          let userRef = this.state.userList[i];
          userRef.get().then((query) => {
            let data = query.data();
            let cname = data.firstName + ' ' + data.lastName;
            if (cname === username) {
              // update state of userList and userStatus
              this.state.userList.splice(i, 1);
              delete this.state.userStatus[userRef.id];
            }
          });
        }
      } else {
        // assume removing a user from the Roll Call
        for (let i = 0; i < this.state.rollCallList.length; i++) {
          let cname = this.state.rollCallList[i];
          if (cname === username) {
            // update state of userList and userStatus
            this.state.rollCallList.splice(i, 1);
          }
        }
      }
      // remove the list item from the list
      row.path[1].parentNode.removeChild(row.path[1]);
    }
  }
  /****************************** Add User to ERP ******************/
  showUserBoxModal() {
    if (
      this.props.planPager === 2 &&
      this.props.role === 'Root' &&
      this.props.companyID !== this.state.companyID
    ) {
      // assume a root is creating a roll call
      alert('Please select a client before adding users.');
    } else {
      const UserBoxDialog = document.getElementById('UserBoxDialog');
      UserBoxDialog.showModal();
      this.addUserOptions();
    }
  }
  // add UserList to the UserList Box
  addUserOptions() {
    var userListBox = [];
    for (let user of this.props.userList) {
      userListBox.push({ value: user.id, label: user.name });
    }
    this.setState({ userListBox: userListBox });
  }
  // add selected users to the DOM and update the userList and userStatus
  hideUserBoxModal(row) {
    if (
      row !== undefined &&
      row.selectedUsers !== undefined &&
      row.selectedUsers.length > 0
    ) {
      let selectedUsers = row.selectedUsers;
      let userList = this.state.userList;
      /* values needed to add options to the selectUserForTask */
      // add the selected users to the select options when creating a new task
      var editSelectUserForTask = document.getElementById(
        'editSelectUserForTask'
      );
      // add the selected users to the selection menu when assigning the leader
      // var editSelectLeaderPlan = document.getElementById('editSelectLeaderPlan')
      let userStatus = this.state.userStatus;
      let currOpt;
      console.log(selectedUsers);
      for (let i = 0; i < selectedUsers.length; i++) {
        console.log('adding ' + selectedUsers[i].name);
        let fullName = selectedUsers[i].name; // name of user
        var item = document.createElement('li'); // create the list item
        var userName = document.createElement('span'); // add the userName to the li
        userName.innerHTML = fullName;
        var minusSign = document.createElement('span');
        minusSign.innerHTML = 'X';
        minusSign.setAttribute('class', 'selectedUserMinusSign');
        minusSign.addEventListener('click', this.removeUser);
        item.appendChild(userName); // add the description to the element
        item.appendChild(minusSign);
        console.log('plan pager ' + this.props.planPager);
        if (this.props.subPlanPager === 1 || this.props.subPlanPager === 2) {
          console.log('in cond');
          // assume viewing the template page or active checklist page
          if (
            this.state.userList.findIndex(
              (user) => user.id === selectedUsers[i].id
            ) !== -1
          )
            continue;
          currOpt = { text: fullName, value: fullName };
          // add the current user to the task user dropdown
          if (
            editSelectUserForTask !== null &&
            editSelectUserForTask !== undefined
          ) {
            editSelectUserForTask.options.add(
              new Option(currOpt.text, currOpt.value, currOpt.selected)
            );
          }

          // editSelectLeaderPlan.options.add( new Option(currOpt.text, currOpt.value, currOpt.selected) );
          if (i === 0 && this.state.leader === '') {
            // assume the leader has not been selected
            this.setState({ leader: fullName });
          }
          var br = document.createElement('br'); // new line between name/role
          item.appendChild(br);
          // get UID from the userList prop
          const idx = this.props.userList.findIndex(
            (user) => user.name === selectedUsers[i].name
          );
          const UID = this.props.userList[idx].id; // UID of the taskUser
          const userRef = db.collection('users').doc(UID);
          userList.push(userRef);
          userStatus[UID] = { isCheckedIn: false, title: '' };
          var pencilIcon = document.createElement('img');
          pencilIcon.setAttribute('src', Edit);
          pencilIcon.setAttribute('height', '20px');
          pencilIcon.setAttribute('class', 'selectedUserPencilIcon');
          pencilIcon.addEventListener('click', this.showEditUserBox);
          // item.appendChild(pencilIcon)
          var userRole = document.createElement('span'); // stores title/role
          userRole.innerHTML = 'Role: ' + this.props.userList[idx].role; // role of user;
          item.appendChild(userRole);
          document.getElementById('editSelectedUsersList').appendChild(item);
          console.log('appended');
        } else {
          // assume its a duplicate
          console.log('other cond');
          if (
            this.state.rollCallList.findIndex(
              (user) => user === selectedUsers[i].name
            ) !== -1
          )
            continue;
          // assume the user is adding users to the roll call
          console.log('here');
          document.getElementById('editRollCallUserList').appendChild(item);
          this.state.rollCallList.push(fullName);
        }
      }
      // update the state of userStatus
      this.setState({
        userList: userList,
        userStatus: userStatus,
        rollCallList: this.state.rollCallList
      });
    }
    // hide the UserList box
    const UserBoxDialog = document.getElementById('UserBoxDialog');
    UserBoxDialog.close();
  }
  /********************************* CREATE TASK *******************/
  // handle the user selection of the assignedUser
  handleTaskNameChange(event) {
    this.setState({ taskName: event.target.value });
  }
  handleDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  handleAssignedUserChange(event) {
    this.setState({ assignedUser: event.target.value });
  }
  handleAssignedRollCallChange(event) {
    this.setState({ assignedRC: event.target.value });
  }
  addTaskToERP() {
    if (this.state.taskName.length === 0) {
      alert('Please Input a Valid Task Name and Description.');
    } else {
      // New task that needs to be added to the taskList
      let item = document.createElement('li'); // the list item
      let taskName = document.createElement('span'); // the span element to store name of the task
      taskName.innerHTML = this.state.taskName;
      var pencilIcon = document.createElement('img'); // img element to store the edit icon
      pencilIcon.setAttribute('src', Edit);
      pencilIcon.setAttribute('height', '20px');
      pencilIcon.setAttribute('class', 'selectedUserPencilIcon taskPencil');
      pencilIcon.addEventListener('click', this.editTask);
      item.appendChild(taskName);
      item.appendChild(pencilIcon);
      var minusSign = document.createElement('span'); // span element to store the minus sign
      minusSign.innerHTML = 'X';
      minusSign.setAttribute('class', 'selectedUserMinusSign taskMinusSign');
      minusSign.addEventListener('click', this.removeTask);
      item.appendChild(minusSign);
      document.getElementById('editTasksList').appendChild(item);
      // console.log(this.state.assignedRC)
      // console.log(this.state.assignedUser)
      this.state.newTasks.push({
        taskName: this.state.taskName,
        description: this.state.description,
        assignedUser: this.state.assignedUser,
        assignedRC: this.state.assignedRC
      });
      this.hideTaskBox();
    }
  }
  editTask(row) {
    let taskName = row.path[1].textContent.slice(0, -1); // get the tasks name
    let res = window.prompt('Enter the New Task Name:');
    if (res !== null && res.length > 0) {
      // assume that the user has entered a valid task name
      let taskList = document
        .getElementById('editTasksList')
        .getElementsByTagName('li');
      // iterate  through all the html list elements
      for (let element of taskList) {
        let name = element.childNodes[0].textContent;
        if (taskName === name) {
          element.childNodes[0].innerHTML = res;
          break;
        }
      }
      let taskNameChangeMap = this.state.taskNameChangeMap;
      // iterate through the taskList state
      for (let task of this.state.taskList) {
        task.get().then((query) => {
          let data = query.data();
          if (data.name === taskName) {
            taskNameChangeMap[task.id] = res;
          }
        });
      }
      this.setState({ taskNameChangeMap: taskNameChangeMap });
      // iterate through the new Tasks
      for (let nTask of this.state.newTasks) {
        if (nTask.taskName === taskName) {
          // assume the task was newly added to the ERP
          // udpate newTask State
          nTask.taskName = res;
        }
      }
    }
  }
  removeTask(row) {
    if (this.state.isActive) {
      // assume user is editing a plan and the plan is active
      alert('Cannot Delete a Task from an Active Emergency Checklist');
    } else {
      let taskName = row.path[1].textContent.slice(0, -1); // get the tasks name
      let res = window.confirm(
        `Are you sure you want to remove ${taskName} from the Emergency Checklist?`
      );
      if (res) {
        // iterate through the array of task doc references
        for (let i = 0; i < this.state.taskList.length; i++) {
          this.state.taskList[i].get().then((query) => {
            let data = query.data();
            if (data.name === taskName) {
              // update taskList state
              this.state.taskList.splice(i, 1);
            }
          });
        }
        for (let j = 0; j < this.state.newTasks.length; j++) {
          let nTask = this.state.newTasks[j];
          if (nTask.taskName === taskName) {
            // update newTasks state
            this.state.newTasks.splice(j, 1);
          }
        }
        // remove the item from the html list
        row.path[1].parentNode.removeChild(row.path[1]);
      }
    }
  }
  showTaskModal() {
    // displays the modal and empties the fields
    const addTaskDialog = document.getElementById('addTaskDialog');
    // addTaskDialog.style.left = (this.props.toggleMenu) ? '105px' : '330px'
    addTaskDialog.showModal();
    var selectTaskUser = document.getElementById('editSelectUserForTask');
    if ((selectTaskUser !== null) & (selectTaskUser !== undefined)) {
      document.getElementById('editSelectUserForTask').innerHTML = '';
      const defOpt = { text: 'Select User', value: '' };
      selectTaskUser.options.add(
        new Option(defOpt.text, defOpt.value, defOpt.selected)
      );
    }
    // Add users to the dropdown menu for the task
    for (let i = 0; i < this.state.userList.length; i++) {
      let user = this.state.userList[i];
      user.get().then((query) => {
        let data = query.data();
        let fullName = data.firstName + ' ' + data.lastName;
        let firstUser;
        if (i === 0) firstUser = fullName;
        let currOpt = { text: fullName, value: fullName };
        if ((selectTaskUser !== null) & (selectTaskUser !== undefined)) {
          selectTaskUser.options.add(
            new Option(currOpt.text, currOpt.value, currOpt.selected)
          );
        }
        if (i === this.state.userList.length - 1) {
          if (this.state.assignedUser === '') {
            // assume users were just added and the leader was not selected yet
            this.setState({ assignedUser: firstUser });
          }
        }
      });
    }
  }
  hideTaskBox() {
    this.setState({
      taskName: '',
      description: '',
      assignedUser: '',
      assignedRC: ''
    });
    const addTaskDialog = document.getElementById('addTaskDialog');
    addTaskDialog.close();
  }
  /************************** ROLL CALL FUNCTIONS *****************************/
  handleCompanyIDChangeRollCall(event) {
    this.props.onUpdateCompanyID({
      companyID: event.target.value
    });
    // clear the user option list
    // update the userlist state to 0
    // update the userlist html element to empty
    this.setState({
      rollCallList: [],
      companyID: event.target.value,
      selectedUsers: []
    });
    document.getElementById('editRollCallUserList').innerHTML = '';
  }
  handleRollCallNameChange(event) {
    this.setState({ rollCallName: event.target.value });
  }
  handleNotificationChange() {
    let status = this.state.withNotifications;
    this.setState({ withNotifications: !status });
  }
  handleRCDescriptionChange(event) {
    this.setState({ rcDescription: event.target.value });
  }
  handleArchiveDescriptionChange(event) {
    this.setState({ rcArchiveMsg: event.target.value });
  }
  populateName(data) {
    this.setState({
      archiveHeader: data.name
    });
  }

  populateRollCall(data) {
    var notificationToggle = document.getElementById('notificationToggle');
    if (data.withNotifications) {
      notificationToggle.checked = true;
    } else {
      notificationToggle.checked = false;
    }
    for (let user in data.users) {
      let fullName = data.users[user].name;
      // update state
      this.state.rollCallList.push(fullName);
      var item = document.createElement('li'); // create the list item
      var userName = document.createElement('span'); // add the userName to the li
      userName.innerHTML = fullName;
      var minusSign = document.createElement('span');
      minusSign.innerHTML = 'X';
      minusSign.setAttribute('class', 'selectedUserMinusSign');
      minusSign.addEventListener('click', this.removeUser);
      item.appendChild(userName); // add the description to the element
      item.appendChild(minusSign);
      document.getElementById('editRollCallUserList').appendChild(item);
    }
    this.setState({
      rollCallName: data.name,
      rcDescription: data.rcDescription,
      withNotifications: data.withNotifications,
      imgSrc: data.logo,
      rollCallList: this.state.rollCallList
    });

    // add teams to the drop down menu
    document.getElementById('editTeamRollCall').innerHTML = '';
    let editTeamRollCall = document.getElementById('editTeamRollCall');
    let currOpt = { text: 'Select Group', value: '' };
    editTeamRollCall.options.add(
      new Option(currOpt.text, currOpt.value, currOpt.selected)
    );
    for (let ug of this.props.userGroups) {
      currOpt = { text: ug.name, value: ug.name };
      editTeamRollCall.options.add(
        new Option(currOpt.text, currOpt.value, currOpt.selected)
      );
    }
    // display dialog
    const rollCallDialog = document.getElementById('addRollCallBox');
    // rollCallDialog.style.left = (this.props.toggleMenu) ? '105px' : '330px'
    rollCallDialog.showModal();
  }

  handleRollCallAction() {
    let hmap = {}; // used just to get all the user ids quickly
    for (let user of this.state.rollCallList) {
      // Create the hashmap
      hmap[user] = user;
    }
    let userMap = {};
    for (let user of this.props.userList) {
      if (hmap[user.name]) {
        // assume the user has been selected for the rollcall
        userMap[user.id] = { name: user.name };
      }
    }
    let flag = 0;
    if (this.state.archive) {
      flag = 1;
    }
    if (this.state.rollCallName.length === 0 && !flag) {
      alert('Please enter a valid Flash name.');
    } else if (this.state.rcDescription.length === 0 && !flag) {
      alert('Please enter a valid description.');
    } else {
      if (this.state.editRollCall) {
        this.props.onEditRollCall({
          name: this.state.rollCallName,
          userMap: userMap,
          index: this.state.index,
          isArchived: false,
          withNotifications: this.state.withNotifications,
          rcDescription: this.state.rcDescription,
          logo: this.state.logo
        });
      } else if (this.state.archive) {
        //console.log("here1:"+this.state.rcArchiveMsg)
        if (this.state.rcArchiveMsg.length === 0) {
          alert('Please enter a valid descriptions.');
        } else if (this.state.rcArchiveMsg.length <= 10) {
          alert('Please explain more on your feedback.');
        } else {
          //console.log("here2:"+this.state.rcArchiveMsg)

          if (this.state.checklist) {
            this.props.onArchive({
              index: this.state.index,
              isArchived: true,
              checklist: true,
              notes: this.state.rcArchiveMsg
            });
          } else if (this.state.rollcall) {
            this.props.onArchive({
              index: this.state.index,
              isArchived: true,
              rollcall: true,
              notes: this.state.rcArchiveMsg
            });
          }
        }
      } else {
        this.props.onAddRollCall({
          name: this.state.rollCallName,
          userMap: userMap,
          withNotifications: this.state.withNotifications,
          rcDescription: this.state.rcDescription,
          logo: this.state.logo,
          isRCActive: true,
          isArchived: false,
          dateActivated: new Date(),
          flashType: this.state.flashType,
          teamName: this.state.teamName
        });
      }

      this.hideRollCallDialog();
    }
  }
  showRollCallDialog() {
    var rollCallDialog = document.getElementById('addRollCallBox');
    // rollCallDialog.style.left = (this.props.toggleMenu) ? '105px' : '330px'
    rollCallDialog.showModal();

    // add teams to the drop down menu
    document.getElementById('editTeamRollCall').innerHTML = '';
    let editTeamRollCall = document.getElementById('editTeamRollCall');
    let currOpt = { text: 'Select Group', value: '' };
    editTeamRollCall.options.add(
      new Option(currOpt.text, currOpt.value, currOpt.selected)
    );
    for (let ug of this.props.userGroups) {
      currOpt = { text: ug.name, value: ug.name };
      editTeamRollCall.options.add(
        new Option(currOpt.text, currOpt.value, currOpt.selected)
      );
    }

    if (this.props.role === 'Root' && this.state.editRollCall === false) {
      // assumme root admin and creating a new roll call
      var selectClient = document.getElementById('selectCompanyID'); // get the select Client tag
      selectClient.innerHTML = '';
      let curr = { text: 'Select a Client', value: '' };
      selectClient.options.add(
        new Option(curr.text, curr.value, curr.selected)
      );
      for (let client of this.props.clientList) {
        curr = { text: client, value: client };
        selectClient.options.add(
          new Option(curr.text, curr.value, curr.selected)
        );
      }
      this.setState({ companyID: this.props.companyID });
    }
  }
  hideRollCallDialog() {
    // console.log("here3: "+this.state.rcArchiveMsg)
    var rollCallDialog = document.getElementById('addRollCallBox');
    if (rollCallDialog !== null) {
      rollCallDialog.close();
      document.getElementById('editRollCallUserList').innerHTML = '';
      this.setState({
        editRollCall: false,
        withNotifications: false,
        rcDescription: '',
        rollCallName: '',
        rollCallList: [],
        logo: ''
      });
    }

    var rcArchiveDescription = document.getElementById(
      'rcArchiveDescriptionBox'
    );
    if (rcArchiveDescription !== null) {
      rcArchiveDescription.close();
      document.getElementById('archiveDescription').innerHTML = '';
      this.setState({
        checklist: false,
        rollcall: false,
        archive: false,
        rcArchiveMsg: ''
      });
    }
  }

  handleFilterStatusChange(event) {
    this.setState({ filter: event.target.value });
    this.props.onfilterTable({
      type: 'FILTER_ROLL_CALL_LIST',
      filter: event.target.value
    });
  }
  handleLogoChange(event) {
    event.preventDefault();
    let reader = new FileReader();
    // get the file thats been uploads
    let file = event.target.files[0];
    // after the file finished loading
    reader.onloadend = () => {
      this.setState({
        logo: file,
        imgSrc: reader.result
      });
    };
    reader.readAsDataURL(file);
  }

  /**************************************** Template functions *******************************/
  showTemplateBox() {
    var templateDialog = document.getElementById('addTemplateBox');
    // templateDialog.style.left = (this.props.toggleMenu) ? '105px' : '330px'
    templateDialog.showModal();
    if (this.props.role === 'Root' && this.state.editTemplate === false) {
      // assumme root admin and creating a new checklist
      var selectCompanyIDTemplate = document.getElementById(
        'selectCompanyIDTemplate'
      ); // get the select Client tag
      selectCompanyIDTemplate.innerHTML = '';
      for (let client of this.props.clientList) {
        let curr = { text: client, value: client };
        selectCompanyIDTemplate.options.add(
          new Option(curr.text, curr.value, curr.selected)
        );
      }
      this.setState({ companyID: this.props.companyID });
    }
  }
  hideTemplateBox() {
    const templateDialog = document.getElementById('addTemplateBox');
    templateDialog.close();
    document.getElementById('editTasksList').innerHTML = '';
    this.setState({
      templateName: '',
      newTasks: [],
      editTemplate: false
    });
    this.props.onEditBreadCrumbs({
      type: 'REMOVE_BREADCRUMB',
      breadCrumbs: 'Checklists'
    });
  }
  handleTemplateNameChange(event) {
    this.setState({ templateName: event.target.value });
  }

  handleCreateTemplate() {
    if (this.state.templateName.length === 0) {
      alert('Please enter a valid Template Name');
    } else {
      if (this.state.editTemplate) {
        // assume editing tempalte
        this.props.editTemplate({
          index: this.state.index,
          templateName: this.state.templateName,
          taskList: this.state.taskList,
          taskNameChangeMap: this.state.taskNameChangeMap,
          newTasks: this.state.newTasks
        });
      } else {
        // assume creating new template
        this.props.createTemplate({
          templateName: this.state.templateName,
          tasks: this.state.newTasks
        });
      }
      this.hideTemplateBox();
    }
  }
  handleEditTemplateName(obj) {
    this.props.editTemplateName(obj);
  }
  populateTemplate(tmpl) {
    // ADD EXISTING TASKS to the EDIT PLAN BOX
    for (let task of tmpl.tasks) {
      task.get().then((query) => {
        if (query.data() === undefined || query.data() === null) return;
        let tname = query.data().name;
        var item = document.createElement('li'); // the list item
        var taskName = document.createElement('span'); // the span element to store name of the task
        taskName.innerHTML = tname;
        var pencilIcon = document.createElement('img'); // img element to store the edit icon
        pencilIcon.setAttribute('src', Edit);
        pencilIcon.setAttribute('height', '20px');
        pencilIcon.setAttribute('class', 'selectedUserPencilIcon taskPencil');
        pencilIcon.addEventListener('click', this.editTask);
        var minusSign = document.createElement('span'); // span element to store the minus sign
        minusSign.innerHTML = 'X';
        minusSign.setAttribute('class', 'selectedUserMinusSign taskMinusSign');
        minusSign.addEventListener('click', this.removeTask);
        item.appendChild(taskName);
        item.appendChild(pencilIcon);
        item.appendChild(minusSign);
        document.getElementById('editTasksList').appendChild(item);
      });
    }
    this.setState({
      templateName: tmpl.name,
      taskList: tmpl.tasks
    });
    this.props.onEditBreadCrumbs({
      type: 'APPEND_BREADCRUMB',
      breadCrumbs: ' > Edit Template'
    });

    var templateDialog = document.getElementById('addTemplateBox');
    // templateDialog.style.left = (this.props.toggleMenu) ? '105px' : '330px'
    templateDialog.showModal();
  }
  handleTmplChange(event) {
    this.setState({ tmplName: event.target.value });
  }
  handleTeamChange(event) {
    this.setState({ teamName: event.target.value });
  }
  handleUserListChange(event) {
    this.setState({ selectedUsers: event });
  }
  handleCompanyIDChangeTemplate(event) {
    this.props.onUpdateCompanyID({
      companyID: event.target.value
    });
    // update companyID
    this.setState({ companyID: event.target.value });
  }

  handleFlashTypeChange(event) {
    this.setState({ flashType: event.target.value });
  }
  /****************************** Map functions *******************/
  getUserLocation(user_obj) {
    if (user_obj !== undefined) {
      let user_id = user_obj.id;
      let userLocation = this.props.getUserLocation({ user_id });
      console.log(user_obj.name);
      this.setState(
        {
          locationUserid: user_id,
          userLocation: userLocation,
          userLocationTime: userLocation.locationTime,
          userLocationAddress: userLocation.locationAddress,
          locationUserName: user_obj.name
        },
        () => {
          // this.showLocationModal()
        }
      );
    }
  }

  async showUserLocation(user_obj) {
    // let location = {lat:'',lng:''};
    // let locationTime = 'No Location Available';
    // if(user_obj.location !== undefined){
    //   let temp = user_obj.location.split(',')
    //   location = {lat:temp[0],lng:temp[1]}
    //   locationTime =  new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(user_obj.locationTime.seconds*1000);
    // }
    let location = { lat: '', lng: '' };
    let locationTime = 'No Location Available';
    let user_id = user_obj.id;
    await this.props.getUserLocation(user_id).then((userLocation) => {
      if (
        userLocation.location !== undefined &&
        userLocation.location !== '' &&
        userLocation.location !== null
      ) {
        // locationTime = user_obj.
        let temp = userLocation.location.split(',');
        location = { lat: temp[0], lng: temp[1] };

        if (
          locationTime !== '' &&
          locationTime !== null &&
          locationTime !== undefined
        ) {
          locationTime = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          }).format(userLocation.locationTime.seconds * 1000);
        } else {
          locationTime = '';
        }
      }

      this.setState(
        {
          locationUserid: user_obj.id,
          userLocation: location,
          userLocationTime: locationTime,
          userLocationAddress: userLocation.locationAddress,
          locationUserName: user_obj.name
        },
        () => {
          this.showLocationModal();
        }
      );
    });
  }

  showLocationModal() {
    const userLocationDialog = document.getElementById('userLocationDialog');
    // userLocationDialog.style.width = (this.props.toggleMenu) ? '1280px' : ''
    userLocationDialog.showModal();
  }

  hideLocationModal() {
    const userLocationDialog = document.getElementById('userLocationDialog');
    userLocationDialog.close();
  }

  render() {
    /****************************** Check List *******************/
    var checklistColumns = [
      {
        Header: 'Checklists',
        columns: [
          {
            Header: 'Checklist Name',
            accessor: 'name'
          },
          {
            Header: 'Checklist Leader',
            accessor: 'leaderName'
          },
          {
            Header: 'Users',

            Cell: ({ row }) => {
              // let users = 0;
              if (
                row.original.users !== undefined &&
                row.original.users !== null &&
                row.original.users.length > 0
              ) {
                // users = row.original.users.length;
                return (
                  <p title={row.original.userNames}>
                    {row.original.users.length}
                  </p>
                );
              }

              return 0;
            },
            accessor: 'users'
          },
          {
            Header: 'Tasks', // Task button functionality
            Cell: ({ row }) => {
              let numTasks = 0;
              let numCompleted = 0;

              if ('tasks' in row.original && row.original.tasks !== undefined) {
                numTasks = row.original.tasks.length;
              }
              if (
                'completedTasks' in row.original &&
                row.original.completedTasks !== undefined
              ) {
                //assume the ERP has completed tasks
                numCompleted = row.original.completedTasks;
              }

              return (
                <button
                  className='btn'
                  title='View Tasks'
                  onClick={() => this.props.onViewTasks({ index: row.id })}
                >
                  {numCompleted}/{numTasks}
                </button>
              );
            },
            accessor: 'numCompleted'
          },
          {
            Header: 'Practice', // isPractice field
            Cell: ({ row }) => {
              let practice = row.original.isPractice;
              // save as string and then do the logic
              let toggle;
              if (practice) {
                toggle = (
                  <label
                    id='practiceSwitch'
                    className='switch'
                    title='Practice Toggle'
                  >
                    <input type='checkbox' defaultChecked />
                    <span
                      className='slider round'
                      onClick={() =>
                        this.props.onPracticeToggle({
                          index: row.id,
                          id: row.original.id
                        })
                      }
                    ></span>
                  </label>
                );
              } else {
                toggle = (
                  <label
                    id='practiceSwitch'
                    className='switch'
                    title='Practice Toggle'
                  >
                    <input type='checkbox' />
                    <span
                      className='slider round'
                      onClick={() =>
                        this.props.onPracticeToggle({
                          index: row.id,
                          id: row.original.id
                        })
                      }
                    ></span>
                  </label>
                );
              }
              return toggle;
            },
            accessor: 'practice'
          },
          {
            Header: 'Activated',
            Cell: ({ row }) => {
              let result = 'Unavailable';
              if (
                'dateActivated' in row.original &&
                row.original.dateActivated !== undefined &&
                row.original.dateActivated !== null
              ) {
                // assume that the ERP has tasks
                result = row.original.dateActivated;
                result = new Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
                }).format(result.seconds * 1000);
              }
              return result;
            },
            accessor: 'dateActivatedSec'
          },
          {
            Header: '   ', // Edit button functionality
            Cell: ({ row }) => {
              return (
                <ImPencil
                  height={25}
                  width={25}
                  alt='edit'
                  className='icon_button'
                  title='Edit Checklist'
                  onClick={() => {
                    if (
                      this.props.role === 'Manager' &&
                      row.original.createdByRole !== 'Manager'
                    ) {
                      // assume the current user is a Manager and the RC was not created by a manager
                      alert(
                        'You can only edit a Checklist that was created by a Manager.'
                      );
                    } else {
                      this.populatePlanBox(row.original, row.id);
                    }
                  }}
                />
              );
            }
          },
          {
            Header: ' ', // Delete button functionality
            Cell: ({ row }) => {
              if (!row.original.deactivated) {
                return (
                  <button
                    title='Delete Checklist'
                    id='deleteChecklist'
                    onClick={(event) => {
                      event.persist();
                      if (
                        this.props.role === 'Manager' &&
                        row.original.createdByRole !== 'Manager'
                      ) {
                        // assume the current user is a Manager and the RC was not created by a manager
                        alert(
                          'You can only edit a Checklist that was created by a Manager.'
                        );
                      } else {
                        var res = window.confirm(
                          'Are you sure you want to deacivate the Plan?'
                        );
                        if (res) {
                          // Assume the user confirm the deleteion of the plan
                          this.props.onDeactivatePlan({ index: row.id });
                        }
                      }
                    }}
                  >
                    Deactivate
                  </button>
                );
              } else {
                return 'Deactivated';
              }
            }
          },
          {
            // Archive button functionality
            Header: '    ',
            Cell: ({ row }) => {
              return (
                <MdArchive
                  height={25}
                  width={25}
                  alt='edit'
                  className='icon_button'
                  title='Archive Flash'
                  src={Archive}
                  onClick={() => {
                    if (
                      this.props.role === 'Manager' &&
                      row.original.createdByRole !== 'Manager'
                    ) {
                      // assume the current user is a Manager and the RC was not created by a manager
                      alert(
                        'You can only archive a Flash that was created by a Manager.'
                      );
                    } else {
                      this.setState({
                        index: row.id,
                        archive: true,
                        checklist: true
                      });
                      // display dialog
                      this.populateName(row.original);
                      const archiveDialog = document.getElementById(
                        'rcArchiveDescriptionBox'
                      );
                      // archiveDialog.style.left = (this.props.toggleMenu) ? '105px' : '400px';
                      archiveDialog.showModal();
                    }
                  }}
                />
              );
            }
          }
        ]
      }
    ];
    /****************************** Roll Call Columns *******************/
    var rollCallColumns = [
      {
        Header: 'Roll Call',
        columns: [
          {
            Header: 'Flash Name',
            accessor: 'name'
          },
          {
            Header: 'Type',
            accessor: 'type'
          },
          {
            Header: 'Users',
            Cell: ({ row }) => {
              if (
                row.original.users === undefined ||
                row.original.users === null
              ) {
                return 0;
              }
              return Object.keys(row.original.users).length;
            }
          },
          {
            Header: '      ', // Button to view users
            Cell: ({ row }) => {
              if (row.original.type !== 'Roll Call') {
                return null;
              }
              return (
                <button
                  id='taskButton'
                  title='Users'
                  onClick={() => {
                    this.setState({
                      viewUserList: true,
                      index: row.id
                    });
                    this.props.onEditBreadCrumbs({
                      type: 'APPEND_BREADCRUMB',
                      breadCrumbs: ' > Users'
                    });
                    this.props.onViewUserList({
                      index: row.id,
                      id: row.original.id,
                      planPager: 4,
                      subPlanPager: 4
                    });
                  }}
                >
                  View Users
                </button>
              );
            }
          },
          {
            Header: 'Active',
            // add the Toggle Switch over here
            Cell: ({ row }) => {
              return (
                <label className='switch' title='Activate Toggle'>
                  <input
                    type='checkbox'
                    defaultChecked={row.original.isRCActive}
                  />
                  <span
                    className='slider round'
                    onClick={() =>
                      this.props.onRCActiveToggle({ index: row.id })
                    }
                  ></span>
                </label>
              );
            }
          },
          {
            // Edit button functionality
            Header: '   ',
            Cell: ({ row }) => {
              return (
                <ImPencil
                  height={25}
                  width={25}
                  alt='edit'
                  className='icon_button'
                  title='Edit Flash'
                  onClick={() => {
                    if (row.original.type !== 'Critical Event Alert') {
                      alert('Only a Critical Event Alert can be modified');
                    } else if (
                      this.props.role === 'Manager' &&
                      row.original.createdByRole !== 'Manager'
                    ) {
                      // assume the current user is a Manager and the RC was not created by a manager
                      alert(
                        'You can only edit a Flash that was created by a Manager.'
                      );
                    } else {
                      this.setState({
                        index: row.id,
                        editRollCall: true
                      });
                      this.populateRollCall(row.original);
                    }
                  }}
                />
              );
            }
          },
          {
            // Archive button functionality
            Header: '    ',
            Cell: ({ row }) => {
              return (
                <MdArchive
                  height={25}
                  width={25}
                  alt='edit'
                  className='icon_button'
                  title='Archive Flash'
                  onClick={() => {
                    if (
                      this.props.role === 'Manager' &&
                      row.original.createdByRole !== 'Manager'
                    ) {
                      // assume the current user is a Manager and the RC was not created by a manager
                      alert(
                        'You can only archive a Flash that was created by a Manager.'
                      );
                    } else {
                      this.setState({
                        index: row.id,
                        archive: true,
                        rollcall: true
                      });
                      // display dialog
                      this.populateName(row.original);
                      const archiveDialog = document.getElementById(
                        'rcArchiveDescriptionBox'
                      );
                      // archiveDialog.style.left = (this.props.toggleMenu) ? '105px' : '400px';
                      archiveDialog.showModal();
                    }
                  }}
                />
              );
            }
          }
        ]
      }
    ];
    /******************************** Template List Columns */
    var templateColumns = [
      {
        Header: 'Template List',
        columns: [
          {
            Header: 'Name',
            accessor: 'name'
          },
          {
            Header: '  ',
            Cell: ({ row }) => {
              let numTasks = 0;
              if ('tasks' in row.original && row.original.tasks !== undefined) {
                // assume that the ERP has tasks
                numTasks = row.original.tasks.length;
              }
              return (
                <button
                  className='btn'
                  title='View Tasks'
                  onClick={() =>
                    this.props.viewTasksFromTemplate({ index: row.id })
                  }
                >
                  {numTasks} Tasks
                </button>
              );
            }
          },
          {
            Header: '      ',
            Cell: ({ row }) => {
              if (
                this.props.companySettings.features.emergencyChecklist === true
              ) {
                return (
                  <AiOutlinePlus
                    style={{ cursor: 'pointer' }}
                    src={Activate}
                    alt='activate'
                    height={32}
                    title='Activate Template'
                    className='icon_button'
                    onClick={() => {
                      if (
                        this.props.role !== 'Manager' &&
                        this.props.role !== 'User'
                      ) {
                        this.setState({ tmplName: row.original.name });
                        this.showPlanBox();
                        console.log(this.state.userLists);
                        // this.populatePlanBox({users:this.state.userList}, row.id)
                      } else {
                        alert('Managers cannot activate the a checklist.');
                      }
                    }}
                  />
                );
              } else {
                return null;
              }
            }
          },
          {
            // Edit button functionality
            Header: '   ',
            Cell: ({ row }) => {
              return (
                <ImPencil
                  height={25}
                  width={25}
                  alt='edit'
                  className='icon_button'
                  title='Edit Template'
                  onClick={() => {
                    // if (this.props.role !== 'Super Admin' && this.props.role !== 'Root') {
                    // 	alert('Only Super Admins and Root Admins can edit Checklist Templates.')
                    // } else {
                    // 	this.setState({
                    // 		index: row.id,
                    // 		editTemplate: true,
                    // 	})
                    // 	this.populateTemplate(row.original)
                    // }
                    this.props.viewTasksFromTemplate({ index: row.id });
                  }}
                />
              );
            }
          },
          {
            // Delete button functionality
            Header: '    ',
            Cell: ({ row }) => {
              return (
                <MdDelete
                  height={25}
                  width={25}
                  alt='edit'
                  className='icon_button'
                  title='Delete Template'
                  onClick={() => {
                    if (
                      this.props.role !== 'Super Admin' &&
                      this.props.role !== 'Root'
                    ) {
                      alert(
                        'Only Super Admins and Root Admins can edit Checklist Templates.'
                      );
                    } else {
                      let res = window.confirm(
                        'Are you sure you want to delete the Checklist Template?'
                      );
                      if (res) this.props.onDeleteTemplate(row.id);
                    }
                  }}
                />
              );
            }
          }
        ]
      }
    ];
    /****************************** User List Columns *********************/
    var rcUserListColumns = [
      {
        Header: 'Roll Call User List',
        columns: [
          {
            Header: 'Name',
            accessor: 'name'
          },
          {
            Header: 'Status',
            Cell: ({ row }) => {
              let status = row.original.status;
              let color = 'grey';
              if (this.props.rollCallList[this.state.index].isRCActive) {
                if (status === 'green' || row.original.isCheckedIn) {
                  color = '#078f45';
                } else if (status === 'yellow') {
                  color = '#ffc60a';
                } else if (status === 'red') {
                  color = '#cc3232';
                } else {
                  // assume the user is offline
                  color = 'black';
                }
              }
              return (
                <div
                  id='userStatusBox'
                  height='20px'
                  style={{ background: color }}
                ></div>
              );
            }
          },
          {
            Header: ' ',
            Cell: ({ row }) => {
              let inDanger = '';
              if (row.original.inDanger === true) {
                inDanger = 'IN DANGER';
              }
              return (
                <div
                  className='danger-status'
                  style={{ color: 'red', fontWeight: 'bold' }}
                >
                  {inDanger}
                </div>
              );
            }
          },
          {
            Header: '  ',
            Cell: ({ row }) => {
              let inDanger = false;
              let body_text = '';
              if (row.original.inDanger === true) {
                inDanger = row.original.inDanger;
              }
              if (inDanger) {
                body_text = 'Mark As Safe';
              } else {
                body_text = 'Mark As In Danger';
              }
              return (
                <button
                  className='btn w-25'
                  onClick={() => {
                    let res = window.confirm(
                      `Are you sure you wish to change the check-in status of ${row.original.name}?`
                    );
                    if (res) {
                      this.props.onUserSafetyChange({
                        index: this.state.index,
                        status: row.original.status,
                        uid: row.original.uid // needed to update isCheckedIn status
                      });
                    }
                  }}
                >
                  {body_text}
                </button>
              );
            }
          },
          {
            Header: 'Checked In',
            Cell: ({ row }) => {
              let body;
              let checkedIn = row.original.isCheckedIn;

              if (checkedIn) {
                // assume the user has checked in
                body = (
                  <div id='taskCompletedCheckbox'>
                    <ImCheckboxChecked
                      style={{
                        position: 'absolute',
                        top: 0 + 'px',
                        width: '24px',
                        height: '24px'
                      }}
                      alt='Check'
                    />
                  </div>
                );
              } else {
                // assume the user is not checked in
                body = (
                  <div
                    id='taskCompletedCheckbox'
                    onClick={() => {
                      let res = window.confirm(
                        `Are you sure you wish to change the check-in status of ${row.original.name}?`
                      );
                      if (res) {
                        this.props.onUserStatusChange({
                          index: this.state.index,
                          status: row.original.status,
                          uid: row.original.uid // needed to update isCheckedIn status
                        });
                      }
                    }}
                  ></div>
                );
              }
              return body;
            }
          },
          {
            Header: '           ',
            Cell: ({ row }) => {
              let temp = '';
              if (row.original.user_type === 'APP') {
                return (
                  <img
                    title='Get User Location'
                    height={25}
                    width={25}
                    alt='location'
                    className='icon_button'
                    src={Location}
                    onClick={(event) => {
                      event.persist();
                      const rData = row.original; // updates the fields to display the current
                      let nameArr = rData.name.split(' '); // get the first/last name
                      // const first = nameArr[0];
                      nameArr.shift();
                      // // console.log("En: "+rData.emergencyName);
                      console.log(row.original);
                      let location =
                        rData.location !== undefined ? rData.location : '';
                      let locationTime = rData.locationTime
                        ? rData.locationTime
                        : '';
                      this.showUserLocation({
                        id: rData.uid,
                        name: rData.firstName + ' ' + rData.lastName,
                        location: location,
                        locationTime: locationTime
                      });
                    }}
                  />
                );
              } else {
                return temp;
              }
            }
          }
        ]
      }
    ];
    let addUserListHeader = 'Checklist';
    let size = this.props.rcUserList.length;
    let numGreen = 0,
      numYellow = 0,
      numRed = 0; // integer values of # of users in each status
    let greenStat = 0,
      yellowStat = 0,
      redStat = 0; // stat for # of users in each status
    let isRCActive = false;
    let activeText = 'Deactivated';
    let textColor = 'red';
    if (size > 0) {
      isRCActive = this.props.rollCallList[this.state.index].isRCActive;
      if (isRCActive) {
        greenStat = (this.props.numGreen / size) * 100;
        yellowStat = (this.props.numYellow / size) * 100;
        redStat = (this.props.numRed / size) * 100;
        // assume the roll call is active
        numGreen = this.props.numGreen;
        numYellow = this.props.numYellow;
        numRed = this.props.numRed;
        activeText = 'Activated';
        textColor = '#078f45';
      }
    }
    // 0 = active checklists // 1 = template Lists // 2 = roll Call Columns // 3 = rc User List Columns
    let headerName = '';
    let searchBoxName = '';
    let filterType = '';
    let cols = [];
    let tdata = [];
    // let currFilterList = [];
    if (this.props.planPager === 4 && this.props.subPlanPager === 1) {
      cols = checklistColumns;
      tdata = this.props.viewFilteredList
        ? this.props.filteredList
        : (tdata = this.props.planList);
      headerName = 'Active Checklists';
      searchBoxName = 'Search Checklist';
      filterType = 'FILTER_CHECK_LIST';
      // currFilterList = this.props.planList;
    }
    if (this.props.planPager === 4 && this.props.subPlanPager === 2) {
      cols = templateColumns;
      headerName = 'Templates';
      tdata = this.props.viewFilteredList
        ? this.props.filteredList
        : this.props.tmplList;
      searchBoxName = 'Search Template';
      filterType = 'FILTER_TEMPLATE_LIST';
      // currFilterList = this.props.tmplList;
      addUserListHeader = 'Checklist';
    }
    if (this.props.planPager === 4 && this.props.subPlanPager === 3) {
      cols = rollCallColumns;
      // assume viewing the Roll Call List
      tdata = this.props.viewFilteredList
        ? this.props.filteredList
        : this.props.rollCallList;
      headerName = 'Flash';
      searchBoxName = 'Search Flash';
      filterType = 'FILTER_ROLLCALL_LIST';
      // currFilterList = this.props.rollCallList;
      addUserListHeader = 'Flash';
    }
    if (this.props.planPager === 4 && this.props.subPlanPager === 4) {
      cols = rcUserListColumns;
      // issue not getting all the users for some reason
      let rcUserList = this.props.viewFilteredList
        ? this.props.filteredList
        : this.props.rcUserList;
      tdata = rcUserList; // assume viewing the user list
      headerName = 'Flash';
    }

    let callingPlanPager = this.props.planPager;
    // console.log("PlanTable > PP:"+callingPlanPager);
    // console.log("PlanTable > SPP:"+this.props.subPlanPager);
    // create/edit checklist dialog header
    let dialogHeader = this.state.editCheckList
      ? 'Edit Emergency Checklist'
      : 'Activate Emergency Checklist';
    return (
      <main>
        <p
          onClick={() => {
            this.setState({ viewUserList: false });
            this.props.viewChecklist({
              planPager: callingPlanPager,
              subPlanPager: 1
            });
            this.props.onEditBreadCrumbs({
              type: 'REMOVE_BREADCRUMB',
              breadCrumbs: 'Checklists'
            });
          }}
          id='breadCrumbs'
        >
          {this.props.breadCrumbs}
        </p>

        <div className='MuiBox-root'>
          <div className='MuiPaper-root'>
            <div className='MuiBox-root css-1idik3a'>
              <div className='MuiBox-root css-uujcwg'>
                {this.props.subPlanPager === 4 ? (
                  <h5 className='css-epib0t'>{headerName}</h5>
                ) : (
                  <h5 className='css-epib0t'>Checklists</h5>
                )}
                <span className='css-17vat79'></span>
              </div>
              <div className='css-1yjo05o'>
                <ul className='topnav'>
                  {this.props.companySettings.features.emergencyChecklist ===
                  true ? (
                    <li
                      id='secondaryNavigationItems'
                      onClick={() => {
                        this.setState({
                          viewUserList: false,
                          inputText: null
                        });
                        this.props.onEditBreadCrumbs({
                          type: 'REMOVE_BREADCRUMB',
                          breadCrumbs: 'Checklists'
                        });
                        this.props.viewChecklist({
                          planPager: callingPlanPager,
                          subPlanPager: 1
                        });
                      }}
                      className={this.props.planHighlight[0]}
                    >
                      Active Checklists
                    </li>
                  ) : null}
                  <li
                    id='secondaryNavigationItems'
                    onClick={() => {
                      this.setState({
                        viewUserList: false,
                        inputText: null
                      });
                      this.props.viewTemplates({
                        planPager: callingPlanPager,
                        subPlanPager: 2
                      });
                      this.props.onEditBreadCrumbs({
                        type: 'REMOVE_BREADCRUMB',
                        breadCrumbs: 'Checklists'
                      });
                    }}
                    className={this.props.planHighlight[1]}
                  >
                    Templates
                  </li>
                  {this.props.companySettings.features.flash === true ? (
                    <li
                      id='secondaryNavigationItems'
                      onClick={() => {
                        this.setState({
                          viewUserList: false,
                          inputText: null
                        });
                        this.props.viewRollCallList({
                          planPager: callingPlanPager,
                          subPlanPager: 3
                        });
                        this.props.onEditBreadCrumbs({
                          type: 'REMOVE_BREADCRUMB',
                          breadCrumbs: 'Checklists'
                        });
                      }}
                      className={this.props.planHighlight[2]}
                    >
                      Flash
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
            <div className='MuiTableContainer-root css-13ypi1f'>
              <div className='MuiBox-root css-fgimv3'>
                <div className='progressBar-sec w-100'>
                  {this.props.subPlanPager === 4 ? (
                    <Fragment>
                      {this.state.viewUserList ? (
                        <div>
                          <h5 className='css-epib0t'>{headerName}</h5>

                          <ProgressBar
                            bgColor='green'
                            completed={greenStat}
                            ariaValuemax='100'
                            customLabel={
                              numGreen + ' of ' + size + ' Checked In'
                            }
                          />
                          <ProgressBar
                            className='my-2'
                            bgColor='yellow'
                            completed={yellowStat}
                            ariaValuemax='100'
                            customLabel={
                              numYellow + ' of ' + size + ' Notified'
                            }
                          />
                          <ProgressBar
                            bgColor='red'
                            completed={redStat}
                            ariaValuemax='100'
                            customLabel={numRed + ' of ' + size + ' Absent'}
                          />

                          {/* <div id="progressBar-description">
										<progress id="rcProgressBar1" height="40px" value={greenStat} max="100" />
										<span id="progressBarPercentage">{numGreen + ' of ' + size} Checked In</span>
										<progress id="rcProgressBar2" height="40px" value={yellowStat} max="100" />
										<span id="progressBarPercentage">{numYellow + ' of ' + size} Notified</span>
										<progress id="rcProgressBar3" height="40px" value={redStat} max="100" />
										<span id="progressBarPercentage">{numRed + ' of ' + size} Absent</span>
									</div> */}
                        </div>
                      ) : (
                        <h5>{headerName}</h5>
                      )}
                      {this.state.viewUserList ? (
                        <p style={{ marginTop: '.75rem', fontSize: '16px' }}>
                          Flash is
                          <span style={{ color: textColor }}>
                            {' '}
                            {activeText}
                          </span>
                        </p>
                      ) : null}
                    </Fragment>
                  ) : (
                    ''
                  )}
                </div>

                <Row>
                  {this.props.planPager === 4 &&
                  this.props.subPlanPager === 4 ? (
                    <Fragment>
                      <Col lg={6}>
                        <label style={{ margin: '0', fontSize: '12px' }}>
                          Filter By&nbsp; :
                        </label>
                        <select
                          id='selectFilter'
                          value={this.state.filter}
                          onChange={this.handleFilterStatusChange}
                        >
                          <option value=''>All</option>
                          <option value='green'>Green</option>
                          <option value='yellow'>Yellow</option>
                          <option value='red'>Red</option>
                        </select>
                      </Col>
                    </Fragment>
                  ) : null}
                  <Col lg={4}>
                    <div className='search'>
                      {this.state.viewUserList ? null : (
                        <Fragment>
                          <input
                            className='pl-2'
                            id='searchBox'
                            placeholder={searchBoxName}
                            value={this.state.inputText || ''}
                            onChange={(event) => {
                              this.props.onfilterTable({
                                type: filterType,
                                value: event.target.value
                              });
                              let inputText = event.target.value;
                              this.setState({ inputText: inputText });
                            }}
                          />
                          <BiReset
                            id='resetTableListBtn'
                            alt='Reset'
                            className='icon_button'
                            onClick={() => {
                              if (
                                this.props.planPager === 4 &&
                                this.props.subPlanPager === 1
                              ) {
                                this.props.viewChecklist({
                                  planPager: callingPlanPager,
                                  subPlanPager: 1
                                });
                              } else if (
                                this.props.planPager === 4 &&
                                this.props.subPlanPager === 2
                              ) {
                                this.props.viewTemplates({
                                  planPager: callingPlanPager,
                                  subPlanPager: 2
                                });
                              } else {
                                this.props.viewRollCallList({
                                  planPager: callingPlanPager,
                                  subPlanPager: 3
                                });
                              }
                              this.setState({ inputText: null });
                            }}
                          />
                        </Fragment>
                      )}
                    </div>
                  </Col>
                  <Col lg={4}>
                    <ul className='addItem'>
                      {this.props.planPager === 4 &&
                      this.props.subPlanPager === 2 &&
                      this.props.role !== 'User' ? (
                        <li>
                          <button
                            style={{ width: '160px' }}
                            type='button'
                            onClick={this.showTemplateBox}
                            className='btn'
                          >
                            Create Template
                          </button>
                        </li>
                      ) : null}
                      {this.props.planPager === 4 &&
                      this.props.subPlanPager === 3 &&
                      this.props.role !== 'User' ? (
                        <li>
                          <button
                            style={{ width: '160px' }}
                            type='button'
                            onClick={this.showRollCallDialog}
                            className='btn'
                          >
                            Activate Flash
                          </button>
                        </li>
                      ) : null}
                    </ul>
                  </Col>
                </Row>
              </div>
              <div className='MuiBox-root css-17uua2w'>
                <Table responsive columns={cols} data={tdata} />
                {/* <Container>
									<Row>
										<Col>
										</Col>
									</Row>
								</Container> */}
              </div>
            </div>
          </div>
        </div>

        {/* Plan Box */}
        <dialog id='editPlanBox' className='bigDialogBox'>
          <GrClose
            id='exit-dialog-icon'
            height={28}
            onClick={this.hidePlanBox}
            alt='exit button'
          />
          <Row>
            <Col lg={12}>
              <h2>{dialogHeader}</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <label>Checklist Name</label>
              <input
                type='text'
                value={this.state.planName}
                onChange={this.handlePlanNameChange}
                placeholder='Checklist Name'
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {this.state.editCheckList ? null : (
                <Fragment>
                  <label>Practice Drill</label>
                  <label className='switch'>
                    {/* isPractice Toggle */}
                    <input
                      type='checkbox'
                      checked={this.state.isPractice}
                      onChange={this.handlePracticeChange}
                    />
                    <span className='slider round'></span>
                  </label>
                </Fragment>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <label>Group</label>
              <select
                id='editTeamPlan'
                value={this.state.teamName}
                onChange={this.handleTeamChange}
              ></select>
              {this.props.role === 'Root' &&
              this.state.editCheckList === false ? (
                <Fragment>
                  <p>Select Client</p>
                  <select
                    id='selectCompanyIDChecklist'
                    value={this.state.companyID}
                    onChange={this.handleCompanyIDChangeChecklist}
                  ></select>
                </Fragment>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <label>Users</label>
              <ul id='editSelectedUsersList'></ul>
              <div onClick={this.showUserBoxModal} className='addItemPlan'>
                <span>+</span>
                <span className='addItemText'>Add New User</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className='btn--flex'>
                <button
                  onClick={this.handlePlanAction}
                  type='button'
                  className='btn--confirm dialog__btn'
                >
                  Confirm
                </button>
                <button
                  onClick={this.hidePlanBox}
                  type='button'
                  className='btn--close dialog__btn'
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </dialog>

        {/* Edit User's Title Box in Plan*/}
        <dialog id='editUserTitleBox'>
          <h4 id='userListBoxTitle'>Edit the title</h4>
          {/* Current User's Name */}
          <h4 id='userListBoxTitle'>{this.state.currUserName}</h4>
          {/* Current User's Title */}
          <select
            id='editSelectUserTitleBox'
            value={this.state.currUserTitle}
            onChange={this.handleTitleChange}
          >
            <option value='Add Title'>Add Title</option>
          </select>
          <div className='btn--flex' style={{ margin: 'auto' }}>
            <span
              className='btn btn--confirm dialog__btn'
              onClick={this.handleEditUser}
            >
              Confirm
            </span>
            <span
              className='btn--close dialog__btn'
              onClick={this.hideEditUserBox}
            >
              Cancel
            </span>
          </div>
        </dialog>

        <AddUserListBox
          onHideUserBox={this.hideUserBoxModal}
          dialogHeader={addUserListHeader}
          options={this.state.userListBox}
          selectedUsers={this.state.selectedUsers}
          handleUserListChange={this.handleUserListChange}
          planPager={this.props.planPager}
          subPlanPager={this.props.subPlanPager}
        />
        {/* add tasks to plan */}
        <AddTaskBox
          selectedUsers={this.state.userList}
          handleTaskAction={this.addTaskToERP}
          onHideTaskBox={this.hideTaskBox}
          taskName={this.state.taskName}
          description={this.state.description}
          handleAssignedUserChange={this.handleAssignedUserChange}
          handleDescriptionChange={this.handleDescriptionChange}
          handleTaskNameChange={this.handleTaskNameChange}
          assignedUser={this.state.assignedUser}
          assignedRC={this.state.assignedRC}
          handleAssignedRollCallChange={this.handleAssignedRollCallChange}
          planPager={this.props.planPager}
          subPlanPager={this.props.subPlanPager}
        />

        {/*Add a notes befor the item can be archived */}

        <dialog id='rcArchiveDescriptionBox' className='bigDialogBox'>
          <GrClose
            id='exit-dialog-icon'
            src={Exit}
            height={28}
            onClick={this.hideRollCallDialog}
            alt='exit button'
          />

          <Row>
            <Col lg={12}>
              <h2>Archive {this.state.archiveHeader}</h2>
              <h4> Please provide feedback on the performance of the group </h4>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <textarea
                id='archiveDescription'
                type='text'
                value={this.state.rcArchiveMsg}
                onChange={this.handleArchiveDescriptionChange}
                rows='5'
                cols='58'
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className='btn--flex'>
                <button
                  onClick={this.handleRollCallAction}
                  type='button'
                  className='btn--confirm dialog__btn'
                >
                  Confirm
                </button>
                <button
                  onClick={this.hideRollCallDialog}
                  type='button'
                  className='btn--close dialog__btn'
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </dialog>

        {/* Add Roll Call Box */}
        <dialog id='addRollCallBox' className='bigDialogBox'>
          <GrClose
            id='exit-dialog-icon'
            src={Exit}
            height={28}
            onClick={this.hideRollCallDialog}
            alt='exit button'
          />
          <Row>
            <Col lg={12}>
              <h2>Activate Flash</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={2} className='mt-3'>
              <label>Profile Image</label>
              <label className='inputProfile'>
                <input
                  type='file'
                  accept='image/*'
                  id='uploadImage'
                  onChange={this.handleLogoChange}
                />
                <img src={this.state.imgSrc} alt='profile' />
              </label>
            </Col>
            <Col lg={10}>
              <Row>
                <Col lg={6} className='mt-4'>
                  {this.state.editRollCall ? null : (
                    <Fragment>
                      <label>Is this a drill?</label>
                      <label className='switch'>
                        <input type='checkbox' id='notificationToggle' />
                        <span
                          className='slider round'
                          onClick={this.handleNotificationChange}
                        ></span>
                      </label>
                      <label className='d-block mt-3'>Flash Type</label>
                      <select
                        value={this.state.flashType}
                        onChange={this.handleFlashTypeChange}
                      >
                        <option>Roll Call</option>
                        <option>Critical Event Alert</option>
                        <option>Mass Notification</option>
                      </select>
                    </Fragment>
                  )}
                </Col>
                <Col lg={6} className='mt-4'>
                  <label>Message</label>
                  <textarea
                    id='taskDescription'
                    type='text'
                    value={this.state.rcDescription}
                    onChange={this.handleRCDescriptionChange}
                    rows='5'
                    cols='58'
                  />
                </Col>

                <Col lg={6} className='mt-4'>
                  <label>Name</label>
                  <input
                    type='text'
                    value={this.state.rollCallName}
                    onChange={this.handleRollCallNameChange}
                    placeholder='Name'
                  />
                </Col>
                <Col lg={6} className='mt-4'>
                  {this.props.role === 'Root' &&
                  this.state.editRollCall === false ? (
                    <Fragment>
                      <label>Select Client</label>
                      <select
                        id='selectCompanyID'
                        value={this.state.companyID}
                        onChange={this.handleCompanyIDChangeRollCall}
                      ></select>
                    </Fragment>
                  ) : null}
                </Col>

                <Col lg={6} className='mt-4'>
                  <label>Group</label>
                  <select
                    id='editTeamRollCall'
                    value={this.state.teamName}
                    onChange={this.handleTeamChange}
                  ></select>
                </Col>
                <Col lg={6} className='mt-4'>
                  <label>User(s)</label>
                  {/* User List Added to Plan */}
                  <ul id='editRollCallUserList'></ul>
                  <div onClick={this.showUserBoxModal} className='addItemPlan'>
                    <span>+</span>
                    <span className='addItemText'>Add New User</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className='btn--flex'>
                    <button
                      onClick={this.handleRollCallAction}
                      type='button'
                      className='btn--confirm dialog__btn'
                    >
                      Confirm
                    </button>
                    <button
                      onClick={this.hideRollCallDialog}
                      type='button'
                      className='btn--close dialog__btn'
                    >
                      Cancel
                    </button>
                  </div>
                </Col>
                <Col lg={6}></Col>
              </Row>
            </Col>
          </Row>
        </dialog>

        {/* Add template dialog */}
        <dialog id='addTemplateBox' className='bigDialogBox'>
          <GrClose
            id='exit-dialog-icon'
            src={Exit}
            height={28}
            onClick={this.hideTemplateBox}
            alt='exit button'
          />
          <Row>
            <Col lg={12}>
              <h2>Create Template</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <label>Template Name</label>
              <input
                type='text'
                value={this.state.templateName}
                onChange={this.handleTemplateNameChange}
                placeholder='Template Name'
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {this.props.role === 'Root' &&
              this.state.editTemplate === false ? (
                <Fragment>
                  <label>Select Client</label>
                  <select
                    id='selectCompanyIDTemplate'
                    value={this.state.companyID}
                    onChange={this.handleCompanyIDChangeTemplate}
                  ></select>
                </Fragment>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <label>Tasks</label>
              {/* Task List Added to Plan */}
              <ul id='editTasksList'></ul>
              <div onClick={this.showTaskModal} className='addItemPlan'>
                <span>+</span>
                <span className='addItemText'>Add New Task</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className='btn--flex'>
                <button
                  onClick={this.handleCreateTemplate}
                  type='button'
                  className='btn btn--confirm dialog__btn'
                >
                  Confirm
                </button>
                <button
                  onClick={this.hideTemplateBox}
                  type='button'
                  className='btn--close dialog__btn'
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </dialog>

        <dialog
          id='userLocationDialog'
          className='bigDialogBox'
          style={{ height: '700px' }}
        >
          <div id='mapWrapper'>
            <GrClose
              id='exit-dialog-icon'
              height={28}
              onClick={this.hideLocationModal}
              alt='close pop up'
            />
            <h2>{this.state.locationUserName}</h2>
            <div style={{ width: '980px', height: '500px' }}>
              <UserLocationMap
                user_id={this.state.locationUserid}
                address={this.state.userLocationAddress}
                lat={this.state.userLocation.lat}
                lng={this.state.userLocation.lng}
                userLocation={this.state.userLocation}
                locationTime={this.state.userLocationTime}
                userName={this.state.locationUserName}
              />
            </div>
          </div>
        </dialog>
      </main>
    );
  }
}
export default PlanTable;
