/* High Level: Generates the page that displays all the users, a main
 *             navigation bar, buttons to add new users and a search box.
 * Purpose: Enables the admin to manage the users in case of an emergency
 *          and allows the clients to input their own users in bulk and filter
 *          the users by categories.
 * Approach: Get the users from Firebase to fill up the list. Allow clients
 *           to add their own users through the buttons.
 */
import React, { Component, Fragment } from 'react';
// import AddUserDialog from './addUserDialog';
// import AddManyUsersDialog from './addManyUsersDialog';
// import Input from 'react-phone-number-input/input';
// import Select from 'react-select';
// import Styles from './Styles';
// import TrashCan from './style/delete.png';
// import Edit from './style/edit.png';
// import Exit from './style/exit.png';
// import Reset from './style/reset_btn.png';
import Table from './Table';
import './style/UserTable.css';
// import Bell from './style/bell.png';
// import Check from './style/check-round.png';
// import Uncheck from './style/uncheck-round.png';
import Print from './style/print.png';
import { CSVLink } from 'react-csv';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// CSS Modules, react-datepicker-cssmodules.css//
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
//BOOTSRAP:
// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// TOOLTIP:
// import ReactTooltip from 'react-tooltip';
// import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import './style/UserTable.css';
import {
  withStyles
  //  makeStyles
} from '@material-ui/core/styles';
import { BiReset } from 'react-icons/bi';
import { AiFillPrinter } from 'react-icons/ai';
// get the doc reference from a UID when adding user to ERP
// const db = FBapp.firestore();
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: 'black',
    border: '1px solid black',
    boxShadow: theme.shadows[1],
    fontSize: 18
  }
}))(Tooltip);

class CompletedFormsTable extends Component {
  // gets the data thats generated from userPage.js which fills up the table
  constructor() {
    super();
    // using these fields to handle the user input
    this.state = {
      role: '', // {String} user's role
      inputText: null, // {Obj for React Select} = {label: string, value: string}
      csvArr: [],
      startDate: new Date(),
      lastDate: new Date()
    };
    this.getCSVData = this.getCSVData.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleLastDateChange = this.handleLastDateChange.bind(this);
  }

  handleStartDateChange = (date) => {
    //console.log("startDate:"+ date);
    this.setState({ startDate: date });
  };
  handleLastDateChange = (date) => {
    //console.log("lastDate:"+ date);
    this.setState({ lastDate: date });
  };
  getCSVData(tdata) {
    let csv = [];

    for (let i = 0; i < tdata.length; i++) {
      const answer1 = Object.values(tdata[i].answers['0'])[0] ? 'Yes' : 'No';
      const answer2 = Object.values(tdata[i].answers['1'])[0] ? 'Yes' : 'No';
      const answer3 = Object.values(tdata[i].answers['2'])[0] ? 'Yes' : 'No';

      var dateSigned =
        tdata[i].date === undefined
          ? 'Unavailable'
          : new Intl.DateTimeFormat('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }).format(tdata[i].date.seconds * 1000);
      var checkIn =
        tdata[i].date === undefined
          ? 'Unavailable'
          : tdata[i].date
          ? 'Yes'
          : 'No';

      csv.push({
        Name: tdata[i].name,
        Email: tdata[i].email,
        'Phone Number': tdata[i].phoneNumber,
        'Date Signed In':
          tdata[i].date !== undefined || tdata[i].date === null
            ? dateSigned
            : '-',
        'Answer to Q1':
          tdata[i].answers['0'] !== undefined || tdata[i].answers['0'] === null
            ? answer1
            : '-',
        'Answer to Q2':
          tdata[i].answers['1'] !== undefined || tdata[i].answers['1'] === null
            ? answer2
            : '-',
        'Answer to Q3':
          tdata[i].answers['2'] !== undefined || tdata[i].answers['2'] === null
            ? answer3
            : '-',
        'Check In ':
          tdata[i].date !== undefined || tdata[i].date === null ? checkIn : '-'
      });
    }
    // console.log(csv);
    this.setState({ csvArr: csv });
  }

  render() {
    console.log('CompletedFormsTable');
    var columns = [
      {
        Header: 'Users', //1
        columns: [
          {
            Header: '     ',
            Cell: ({ row }) => {
              return (
                <img
                  style={{ borderRadius: '50%', background: 'lightgrey' }}
                  src={row.original.profilePicture}
                  width='40'
                  height='40'
                  alt=''
                />
              );
            }
          },
          {
            Header: 'Name', //2
            Cell: ({ row }) => {
              let num = row.original.phoneNumber;
              let phone = 'NA';
              if (num !== null && num !== undefined) {
                num = num.toString(); // convert to string
                phone =
                  '(' +
                  num.substr(0, 3) +
                  ')-' +
                  num.substr(3, 3) +
                  '-' +
                  num.substr(6);
              }

              let email =
                row.original.email !== null && row.original.email !== undefined
                  ? row.original.email
                  : 'NA';
              let titleDesc = email + ' - ' + phone;
              let name =
                row.original.name !== null && row.original.name !== undefined
                  ? row.original.name
                  : '-';

              return (
                <LightTooltip title={titleDesc} placement='top'>
                  <span>{name}</span>
                </LightTooltip>
              );
            },
            accessor: 'name'
          },
          {
            Header: 'Date SignedIn', //3
            Cell: ({ row }) => {
              var result;
              // Add if  today is less than dateFollowUpVaccine add a bell
              if (
                row.original.date !== undefined &&
                row.original.date !== null
              ) {
                // assume that the ERP has tasks

                result = row.original.date;
                result = new Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
                }).format(result.seconds * 1000);
                return result;
              }
              return '-';
            }
          },
          {
            Header: 'Answer to Q1', //4
            Cell: ({ row }) => {
              var resultA1 = '-';
              // Add if  today is less than dateFollowUpVaccine add a bell
              if (
                'answers' in row.original &&
                row.original.answers !== undefined &&
                row.original.answers !== null &&
                row.original.answers['0'] !== null
              ) {
                Object.keys(row.original.answers['0']).map(function (
                  keyName,
                  keyIndex
                ) {
                  resultA1 = row.original.answers['0'][keyName] ? 'Yes' : 'No';

                  return null;
                });
                return resultA1;
              }
              return resultA1;
            },
            accessor: 'resultA1'
          },
          {
            Header: 'Answer to Q2', //5
            Cell: ({ row }) => {
              var resultA2 = '-';
              // Add if  today is less than dateFollowUpVaccine add a bell
              if (
                'answers' in row.original &&
                row.original.answers !== undefined &&
                row.original.answers !== null &&
                row.original.answers['1'] !== null
              ) {
                Object.keys(row.original.answers['1']).map(function (
                  keyName,
                  keyIndex
                ) {
                  resultA2 = row.original.answers['1'][keyName] ? 'Yes' : 'No';

                  return null;
                });
                return resultA2;
              }
              return resultA2;
            },
            accessor: 'resultA2'
          },
          {
            Header: 'Answer to Q3', //6
            Cell: ({ row }) => {
              var resultA3 = '-';
              // Add if  today is less than dateFollowUpVaccine add a bell
              if (
                'answers' in row.original &&
                row.original.answers !== undefined &&
                row.original.answers !== null &&
                row.original.answers['2'] !== null
              ) {
                Object.keys(row.original.answers['2']).map(function (
                  keyName,
                  keyIndex
                ) {
                  resultA3 = row.original.answers['3'][keyName] ? 'Yes' : 'No';

                  return null;
                });
                return resultA3;
              }
              return resultA3;
            },
            accessor: 'resultA3'
          },
          {
            Header: 'Check-In', //7
            Cell: ({ row }) => {
              var result;
              // Add if  today is less than dateFollowUpVaccine add a bell
              if (
                row.original.date !== undefined &&
                row.original.date !== null
              ) {
                // assume that the ERP has tasks
                result = row.original.date;
                result = new Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
                }).format(result.seconds * 1000);
                return result;
              }
              return '-';
            },
            accessor: 'result'
          },
          {
            Header: 'Reminder', //8
            Cell: ({ row }) => {
              return (
                <button
                  id='reminderButton'
                  title='Reminds the user to fill up the health form'
                  onClick={() => this.props.sendReminder({ row: row.original })}
                >
                  Send
                </button>
              );
            }
          }
        ]
      }
    ];

    return (
      <main>
        <p id='breadCrumbs'>Reports</p>

        <div className='MuiBox-root'>
          <div className='MuiPaper-root'>
            <div className='MuiBox-root css-1idik3a'>
              <div className='MuiBox-root css-uujcwg'>
                <h5 className='css-epib0t'>Health Screen Report</h5>
                <span className='css-17vat79'></span>
              </div>
              <div className='css-1yjo05o'>
                {
                  <ul className='topnav'>
                    {this.props.companySettings.features.emergencyChecklist ===
                    true ? (
                      <li
                        id='secondaryNavigationItems'
                        className={this.props.reportsHighlight[0]}
                        onClick={() => {
                          this.setState({
                            viewUserList: false,
                            inputText: null
                          });
                          this.props.onEditBreadCrumbs({
                            type: 'REMOVE_BREADCRUMB',
                            breadCrumbs: 'Reports'
                          });
                          this.props.viewReports({
                            planPager: 6,
                            subPlanPager: 1
                          });
                        }}
                      >
                        Checklists
                      </li>
                    ) : null}
                    {this.props.companySettings.features.flash === true ? (
                      <li
                        id='secondaryNavigationItems'
                        className={this.props.reportsHighlight[1]}
                        onClick={() => {
                          this.setState({
                            viewUserList: false,
                            inputText: null
                          });
                          this.props.viewRollCallList({
                            planPager: 6,
                            subPlanPager: 2
                          });
                          this.props.onEditBreadCrumbs({
                            type: 'REMOVE_BREADCRUMB',
                            breadCrumbs: 'Reports'
                          });
                        }}
                      >
                        Flash
                      </li>
                    ) : null}
                    {this.props.companySettings.features.library === true ? (
                      <li
                        id='secondaryNavigationItems'
                        className={this.props.reportsHighlight[2]}
                        onClick={() => {
                          this.setState({
                            viewUserList: false,
                            inputText: null
                          });
                          this.props.onEditBreadCrumbs({
                            type: 'REMOVE_BREADCRUMB',
                            breadCrumbs: 'Library'
                          });
                          this.props.onViewLibrary({ planPager: 6 });
                        }}
                      >
                        Library
                      </li>
                    ) : null}
                    {this.props.companySettings.vaccineTracking === true ? (
                      <Fragment>
                        <li
                          id='secondaryNavigationItems'
                          className={this.props.reportsHighlight[3]}
                          onClick={() => {
                            this.setState({
                              viewUserList: false,
                              inputText: null
                            });
                            this.props.onEditBreadCrumbs({
                              type: 'REMOVE_BREADCRUMB',
                              breadCrumbs: 'COVID-19 Vaccines'
                            });
                            this.props.onViewUserList({
                              planPager: 6,
                              subPlanPager: 0
                            });
                          }}
                        >
                          COVID-19 Vaccines
                        </li>
                      </Fragment>
                    ) : null}
                    {this.props.companySettings.vaccineTracking === true &&
                    this.props.companySettings.features.forms === true ? (
                      <Fragment>
                        <li
                          id='secondaryNavigationItems'
                          className={this.props.reportsHighlight[4]}
                          onClick={() => {
                            this.setState({
                              viewUserList: false,
                              inputText: null
                            });
                            this.props.onEditBreadCrumbs({
                              type: 'REMOVE_BREADCRUMB',
                              breadCrumbs: 'Health Screen'
                            });
                            this.props.viewCompletedForms({ planPager: 6 });
                          }}
                        >
                          Health Screen
                        </li>
                      </Fragment>
                    ) : null}
                  </ul>
                }
              </div>
            </div>

            <div className='MuiTableContainer-root css-13ypi1f'>
              <div className='MuiBox-root css-fgimv3'>
                <Row>
                  <Col>
                    <div className='search'>
                      <input
                        className='pl-2'
                        id='searchBox'
                        placeholder='Search User'
                        value={this.state.inputText || ''}
                        onChange={(event) => {
                          this.props.onfilterTable({
                            type: 'FILTER_COMPLETED_FORM_USER_LIST',
                            value: event.target.value
                          });
                          let inputText = event.target.value;
                          this.setState({ inputText: inputText });
                        }}
                      />
                      <BiReset
                        id='resetTableListBtn'
                        alt='Reset'
                        className='icon_button'
                        onClick={() => {
                          this.setState({ inputText: null });
                          this.props.viewCompletedForms({ planPager: 4 });
                        }}
                      />
                    </div>
                  </Col>

                  {this.props.role === 'Root' &&
                  this.props.viewDeletedUsers === false ? (
                    <Col>
                      <select
                        id='selectClientUserTable'
                        onChange={this.handleClientFilterChange}
                      ></select>
                    </Col>
                  ) : null}

                  <Col>
                    <div className='dateRange'>
                      <div className='startDate'>
                        <DatePicker
                          selected={this.state.startDate}
                          onChange={this.handleStartDateChange}
                          className='datepickerStyle'
                        />
                      </div>
                      <div className='endDate'>
                        <DatePicker
                          selected={this.state.lastDate}
                          onChange={this.handleLastDateChange}
                          className='datepickerStyle'
                        />
                            
                      </div>
                      <button
                        className='go'
                        title='Go'
                        onClick={() =>
                          this.props.onfilterTable({
                            type: 'FILTER_HEALTHSCREENREPORT_BY_DATE_RANGE',
                            startdate: this.state.startDate,
                            lastdate: this.state.lastDate
                          })
                        }
                      >
                        Go
                      </button>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <CSVLink
                        data={this.state.csvArr}
                        filename={'Health Screen Report.csv'}
                      >
                        <AiFillPrinter
                          height={35}
                          width={35}
                          alt='Print'
                          className='icon_button icon-button-print'
                          src={Print}
                          onClick={() =>
                            this.getCSVData(
                              this.props.viewFilteredList
                                ? this.props.filteredList
                                : this.props.cFormsList
                            )
                          }
                        />
                      </CSVLink>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className='MuiBox-root css-17uua2w'>
                <Table
                  responsive
                  columns={columns}
                  data={
                    this.props.viewFilteredList
                      ? this.props.filteredList
                      : this.props.cFormsList
                  }
                />
                {/* <Container>
								<Row>
									<Col>
									</Col>
								</Row>
							</Container> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
export default CompletedFormsTable;
