import * as React from 'react';
import clsx from 'clsx';

import { TreeItem, useTreeItem } from '@mui/x-tree-view/TreeItem';
import Typography from '@mui/material/Typography';

import FolderItem from './FolderItem';

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
  const {
    classes,
    className,
    nodeId,
    itemData,
    icon: iconProp,
    expansionIcon,
    displayIcon
  } = props;

  const {
    disabled,
    expanded,
    selected,
    // focused,
    handleExpansion,
    handleSelection,
    preventSelection
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;
  // console.log('itemData: ', itemData);

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event) => {
    handleSelection(event);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        // [classes.focused]: focused,
        [classes.disabled]: disabled
      })}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      <div className='library__tree-item'>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div onClick={handleExpansionClick} className={classes.iconContainer}>
          {icon}
        </div>
        <Typography
          onClick={handleSelectionClick}
          component='div'
          className={classes.label}
        >
          <FolderItem itemData={itemData} />
        </Typography>
      </div>
    </div>
  );
});

const FolderTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
  // console.log('FolderTreeItem/props: ', props);
  return (
    <TreeItem ContentComponent={CustomContent} {...props} ref={ref}>
      {props.children}
    </TreeItem>
  );
});

export default FolderTreeItem;
