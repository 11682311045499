import { createCompanyLibrary } from './library';
import { createCompany, getNextCompanyID } from '../db/companies/operations';
import { getCompanyByCompanyID, updateCompany } from '../db/companies/query';
import { addFileToFolderDocByID } from '../db/folders/operations';
import {
  getDownloadURLFromPath,
  uploadFileToStorage
} from '../storage/operations';

export const uploadCompanyLogo = async (file, companyID, rootFolderID) => {
  try {
    console.log('file: ', file);
    console.log('companyID: ', companyID);
    console.log('rootFolderID: ', rootFolderID);

    const timestamp = new Date().toISOString();
    const uploadedBy = 'System Admin';

    const baseStoragePath = `companies/${companyID}/`;
    const fullStoragePath = `${baseStoragePath}${companyID}`;
    console.log('fullStoragePath: ', fullStoragePath);

    const folderFileData = {
      companyID: companyID,
      fileName: companyID,
      fileSize: file.size,
      fileType: file.type,
      storagePathname: fullStoragePath,
      uploadedAt: timestamp,
      uploadedBy
    };
    console.log('folderFileData: ', folderFileData);

    const uploadResult = await uploadFileToStorage(fullStoragePath, file);
    console.log('uploadResult: ', uploadResult);

    const folderUpdateResult = await addFileToFolderDocByID(
      rootFolderID,
      folderFileData
    );
    console.log('folderUpdateResult: ', folderUpdateResult);

    const logoDownloadURL = await getDownloadURLFromPath(fullStoragePath);
    console.log('logoDownloadURL: ', logoDownloadURL);

    return {
      success: true,
      logoDownloadURL
    };
  } catch (error) {
    console.log('ERROR - firebase/actions/company/uploadCompanyLogo: ', error);
    return {
      success: false,
      logoDownloadURL: null
    };
  }
};

export const createNewCompany = async (data) => {
  try {
    console.log('createCompany/data: ', data);

    const companyID = await getNextCompanyID();
    console.log('companyID: ', companyID);

    const createCompanyResult = await createCompany(companyID, data);
    console.log('createCompanyResult: ', createCompanyResult);

    const createLibraryResult = await createCompanyLibrary(companyID);
    console.log('createLibraryResult: ', createLibraryResult);

    // handle company logo upload
    if (data.logo) {
      console.log('data.logo: ', data.logo);

      // upload logo to storage; add file to root folder;
      const uploadCompanyLogoResult = await uploadCompanyLogo(
        data.logo,
        companyID,
        createLibraryResult.rootFolderID
      );
      console.log('uploadCompanyLogoResult: ', uploadCompanyLogoResult);

      const updatedCompanyResult = await updateCompany(companyID, {
        logo: uploadCompanyLogoResult.logoDownloadURL
      });
      console.log('updatedCompanyResult: ', updatedCompanyResult);
    }

    const newCompany = await getCompanyByCompanyID(companyID);
    console.log('newCompany: ', newCompany);

    return {
      success: true,
      company: newCompany
    };
  } catch (error) {
    console.log('ERROR - createNewCompany: ', error);
    return {
      success: false,
      message: error.message,
      company: null
    };
  }
};
