export function objectToArray(obj) {
  return Object.keys(obj).map((key) => ({
    id: key,
    ...obj[key]
  }));
}

export function isObjectEmpty(obj) {
  if (!obj) return true;

  return Object.keys(obj).length === 0;
}

export const getDisplayFileType = (fileType) => {
  switch (fileType) {
    case 'application/pdf':
      return 'PDF';

    case 'application/vnd.ms-powerpoint':
      return 'PPT';

    case 'text/csv':
      return 'CSV';

    case 'application/vnd.ms-excel':
      return 'XLS';

    case 'image/jpeg':
      return 'JPEG';

    case 'image/png':
      return 'PNG';

    case 'text/plain':
      return 'TEXT';

    case 'video/mp4':
      return 'MP4 video';

    case 'video/quicktime':
      return 'Quicktime video';

    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'sheet';

    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'document';

    default:
      return 'Unknown type';
  }
};

export const generateRandomString = (length) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};
