import {
  addCompany,
  getAllCompanyIDs,
  getCompanyByCompanyID,
  updateCompany
} from './query';

import { firestoreTimestamp } from '../../utils';

export const getNextCompanyID = async () => {
  try {
    const companyIDs = await getAllCompanyIDs();

    const onlyIdsStartingCLI = companyIDs.filter((id) => id.startsWith('CLI'));

    const idNumbers = onlyIdsStartingCLI.map((id) => parseInt(id.slice(3)));

    const maxIdNumber = Math.max(...idNumbers);
    const nextNumberString = (maxIdNumber + 1).toString();
    const nextIdString =
      maxIdNumber < 10
        ? `00${nextNumberString}`
        : maxIdNumber < 100
        ? `0${nextNumberString}`
        : nextNumberString;

    const companyID = `CLI${nextIdString}`;
    console.log('companyID: ', companyID);

    return companyID;
  } catch (error) {
    console.log('ERROR - db/getNextCompanyID: ', error);
    return null;
  }
};

const companyFieldDefaultValues = {
  adminLicensesUsed: 0,
  companyURL: '',
  deactivated: false,
  deleted: false,
  domain: '',
  emergencyPlans: [],
  features: {
    meeting: true,
    messages: true,
    library: true,
    emergencyChecklist: true,
    teams: true,
    flash: true,
    forms: true
  },
  isActive: true,
  licensesUsed: 0,
  logo: '',
  users: []
};

export const createCompany = async (companyID, data) => {
  try {
    console.log('createCompany/companyID: ', companyID);
    const {
      address,
      adminLicensesAssigned,
      city,
      clientName,
      domain,
      licensesAssigned,
      phoneNumber,
      stateName
    } = data;

    let companyData = {
      id: companyID,
      companyID,
      ...companyFieldDefaultValues,
      address,
      adminLicensesAssigned,
      city,
      clientName,
      domain,
      licensesAssigned,
      phoneNumber,
      stateName,
      createdAt: firestoreTimestamp(new Date()),
      dateJoined: firestoreTimestamp(new Date()),
      dateLastLoggedIn: firestoreTimestamp(new Date('1970-01-01T00:00:00Z'))
    };

    const company = await addCompany(companyData);

    return company;
  } catch (error) {
    console.log('ERROR - db/createCompany: ', error);
    return null;
  }
};

export const getCompanyLicensesUsed = async (companyID) => {
  try {
    const company = await getCompanyByCompanyID(companyID);

    if (!company) {
      return { success: false };
    }

    return {
      success: true,
      licensesUsed: company.licensesUsed,
      adminLicensesUsed: company.adminLicensesUsed
    };
  } catch (error) {
    console.log('ERROR - db/getCompanyLicensesUsed: ', error);
    return { success: false };
  }
};

export const updateCompanyLicenses = async (
  companyID,
  action,
  isAdminLicense
) => {
  try {
    const { success, licensesUsed, adminLicensesUsed } =
      await getCompanyLicensesUsed(companyID);

    if (!success) {
      console.log('returning because !success');
      return { success: false };
    }

    let update = {
      licensesUsed,
      adminLicensesUsed
    };

    if (action === 'decrement') {
      if (isAdminLicense) {
        update.adminLicensesUsed -= 1;
      } else {
        update.licensesUsed -= 1;
      }
    } else {
      if (isAdminLicense) {
        update.adminLicensesUsed += 1;
      } else {
        update.licensesUsed += 1;
      }
    }

    const company = await updateCompany(companyID, update);
    console.log('Updated company: ', !!company);

    // return { success: true, company };
  } catch (error) {
    console.log('ERROR - db/updateCompanyLicenses: ', error);
    return { success: false };
  }
};
