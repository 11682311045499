export const formatUnixToDateTimeString = (unix) => {
  const timestamp = unix * 1000;
  const date = new Date(timestamp);
  const dateTimeString = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  }).format(date);

  return dateTimeString;
};

export const getTimeAndDateStringsFromUnixTimestamp = (unix) => {
  if (!unix) return { date: '', time: '' };

  const dateTimeString = formatUnixToDateTimeString(unix);
  const dateAndTimeStrings = dateTimeString.split(',');

  return {
    date: dateAndTimeStrings[0],
    time: dateAndTimeStrings[1].trim()
  };
};
