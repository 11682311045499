import React, { Component } from 'react';
import Exit from './style/exit.png';
import "./style/addUserDialog.css";
import { GrClose } from 'react-icons/gr';

class AddFileDialog extends Component {
    constructor() {
        super();
        this.state = {
            file: '',
            fileName: '',
            folderName: '',
            type: '',
        }
        this.handleAddFile = this.handleAddFile.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.handleFileNameChange = this.handleFileNameChange.bind(this)
        this.showModal = this.showModal.bind(this)
        this.hideModal = this.hideModal.bind(this)

    }
    handleAddFile() {
        this.props.onAddFile({
            file: this.state.file,
            itemName: this.state.fileName,
            type: this.state.type,
        })
        this.hideModal();
        document.getElementById('choosefile').value = null;
        document.getElementById('filename').value = null;
    }
    handleUploadFile(event) {
        // event.preventDefault();
        let reader = new FileReader();
        // get the file thats been uploads
        let f = event.target.files[0];
        // after the file finished loading
        if (f !== undefined) {
            reader.onloadend = () => {
                this.setState({ 
                    fileName: f.name,
                    type: f.type,
                    file: f,
                });
            }
        }
        // Updates the state correctly but I am not sure why
        reader.readAsDataURL(f)
    }
    handleFileNameChange(event) {
        this.setState({fileName: event.target.value});

    }
    // display the upload file dialog
    showModal () {
        this.props.onEditBreadCrumbs({
            type: 'APPEND_BREADCRUMB',
            breadCrumbs: " > Upload File"
        })
        const addFileDialog = document.getElementById('addFileDialog');
        addFileDialog.showModal();
        // displays the modal and empties the fields
        this.setState({
            file: '',
            fileName: '',
        });
    }
    // hide the upload file dialog
    hideModal () {
        this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Library"
        })
        const addFileDialog = document.getElementById('addFileDialog');
        addFileDialog.close();
    }
    render() {
        return (
            <section>
                <nav role="navigation">
                
                    <button type="button" onClick={this.showModal} className="btn">Upload File</button>
                </nav>
                <dialog id="addFileDialog" className="bigDialogBox">
                    <GrClose id="exit-dialog-icon" src={Exit} height={28} onClick={this.hideModal} alt="exit button"/>
                    <h2>Upload File</h2>
                    <br/>
                    <label>Choose File:
                        <input type="file" onChange={this.handleUploadFile} id="choosefile"/>
                    </label>
                    <br/>
                    <input type="text" value={this.state.fileName} onChange={this.handleFileNameChange} placeholder="File Name" id="filename"/>
                    <br/>
                    <div className="btn--flex">
                        <button onClick={this.handleAddFile} type="button" className="btn--confirm dialog__btn">Confirm</button>
                        <button onClick={this.hideModal} type="button" className="btn--close dialog__btn">Cancel</button>
                    </div>
                </dialog>
            </section>
        )
    }
}
export default AddFileDialog;