import { useEffect, useState } from 'react';

import ConfirmDelete from './ConfirmDelete';

import { downloadFileFromStorage } from '../../firebase/storage/operations';
import { deleteFile } from '../../firebase/actions/library';
import { getDisplayFileType } from '../../utils/data';

import { AiFillFile, AiOutlineDownload } from 'react-icons/ai';

import '../style/LibraryTreeItem.css';

const FileItem = ({ itemData }) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const {
    fileName,
    fileType,
    parentFolderID,
    storagePathname,
    uploadedAt
    // uploadedBy
  } = itemData;

  const timestamp = new Date(uploadedAt).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });

  const type = getDisplayFileType(fileType);

  useEffect(() => {
    return () => {
      setIsProcessing(false);
    };
  }, []);

  const handleDeleteClick = (e) => {
    try {
      console.log('delete file: ', parentFolderID, storagePathname);
      if (isProcessing) return;

      setIsProcessing(true);
      deleteFile(parentFolderID, storagePathname);
    } catch (error) {
      console.log('error: ', error);
      setIsProcessing(false);
    }
  };

  const handleDownloadClick = async (e) => {
    try {
      e.stopPropagation();

      if (isProcessing) return;

      setIsProcessing(true);

      await downloadFileFromStorage(storagePathname, fileName);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className='library__tree-item__content'>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <div style={{ width: '18rem' }}>
          <AiFillFile
            title='File'
            className='icon_button'
            height={25}
            width={25}
            alt='descriptor'
          />

          <span style={{ paddingLeft: '1rem' }}>{fileName}</span>
        </div>
      </div>
      <div className='library__tree-item__content-section'>
        <span style={{ width: '50%' }}>{timestamp}</span>
        <span style={{ width: '20%' }}>{type}</span>
        <span>
          <AiOutlineDownload
            title='Download File'
            height={25}
            width={25}
            alt='Download'
            className='icon_button'
            onClick={(e) => handleDownloadClick(e)}
          />
        </span>
        <span style={{ width: '18px' }} />
        <span>
          <ConfirmDelete
            item={fileName}
            itemType={'file'}
            onConfirm={(e) => handleDeleteClick(e)}
          />
        </span>
      </div>
    </div>
  );
};

export default FileItem;
