import firebase from 'firebase/app';

const firebaseFns = firebase.functions();

const firestoreTimestamp = (date) =>
  firebase.firestore.Timestamp.fromDate(date);

const firestoreServerTimestamp = () =>
  firebase.firestore.FieldValue.serverTimestamp();

const firestoreArrayRemove = (array) =>
  firebase.firestore.FieldValue.arrayUnion(array);

const firestoreArrayUnion = (array) =>
  firebase.firestore.FieldValue.arrayUnion(array);

export {
  firebaseFns,
  firestoreArrayRemove,
  firestoreArrayUnion,
  firestoreServerTimestamp,
  firestoreTimestamp
};
