import React, { Component, Fragment } from 'react';
import Table from './Table';
import Styles from './Styles';
import Exit from './style/exit.png';
import Chart from './style/chart.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BsPieChartFill } from 'react-icons/bs';
import { GrClose } from 'react-icons/gr';

class DashBoard extends Component {
  constructor() {
    super();
    this.state = {
      viewPie: false, // {Bool} Root admin determines whether to display the piechart
      viewUserPie: false,
      licensesUsed: 0, // {Number} # of used licenses for Client
      licensesAssigned: 0, // {Number} # of assigned licenses for Client
      adminLicensesUsed: 0,
      adminLicensesAssigned: 0
    };
    this.viewPieChart = this.viewPieChart.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
  }
  componentDidMount() {
    if (this.props.role === 'Super Admin' || this.props.role === 'Manager') {
      let idx = this.props.clientList.findIndex(
        (client) => client.companyID === this.props.companyID
      );
      let licensesUsed = 0;
      let licensesAssigned = 0;
      if (idx !== -1) {
        licensesUsed = this.props.clientList[idx].licensesUsed;
        licensesAssigned = this.props.clientList[idx].licensesAssigned;
        this.setState({
          licensesUsed: licensesUsed,
          licensesAssigned: licensesAssigned
        });
      }
      this.viewPieChart(licensesUsed, licensesAssigned);
    }
  }
  showModal() {
    const chartBoxDialog = document.getElementById('chartBoxDialog');
    chartBoxDialog.style.left = '105px';
    chartBoxDialog.showModal();
  }
  hideModal() {
    const chartBoxDialog = document.getElementById('chartBoxDialog');
    chartBoxDialog.close();
  }
  viewPieChart(licensesUsed, licensesAssigned) {
    let status = !this.state.viewPie;
    if (status) {
      setTimeout(() => {
        var canvas = document.getElementById('can');
        if (canvas !== undefined && canvas !== null) {
          var ctx = canvas.getContext('2d');
          var lastend = 0;
          var data = [licensesAssigned - licensesUsed, licensesUsed];
          var myTotal = 0;
          var myColor = ['white', '#22397A'];
          for (var e = 0; e < data.length; e++) {
            myTotal += data[e];
          }
          for (var i = 0; i < data.length; i++) {
            ctx.fillStyle = myColor[i];
            ctx.beginPath();
            ctx.strokeStyle = '#C9C9C9';
            ctx.lineWidth = 3;
            ctx.moveTo(canvas.width / 2, canvas.height / 2);
            ctx.arc(
              canvas.width / 2,
              canvas.height / 2,
              canvas.height / 2,
              lastend,
              lastend + Math.PI * 2 * (data[i] / myTotal),
              false
            );
            ctx.lineTo(canvas.width / 2, canvas.height / 2);
            ctx.stroke();
            ctx.fill();
            lastend += Math.PI * 2 * (data[i] / myTotal);
          }
        }
      }, 40);
    }
    this.setState({
      viewPie: status,
      licensesUsed: licensesUsed,
      licensesAssigned: licensesAssigned
    });
  }
  viewAdminPieChart(licensesUsed, licensesAssigned) {
    let status = !this.state.viewPie;
    if (status) {
      setTimeout(() => {
        var canvas = document.getElementById('admincan');
        if (canvas !== undefined && canvas !== null) {
          var ctx = canvas.getContext('2d');
          var lastend = 0;
          var data = [licensesAssigned - licensesUsed, licensesUsed];
          var myTotal = 0;
          var myColor = ['white', '#22397A'];
          for (var e = 0; e < data.length; e++) {
            myTotal += data[e];
          }
          for (var i = 0; i < data.length; i++) {
            ctx.fillStyle = myColor[i];
            ctx.beginPath();
            ctx.strokeStyle = '#C9C9C9';
            ctx.lineWidth = 3;
            ctx.moveTo(canvas.width / 2, canvas.height / 2);
            ctx.arc(
              canvas.width / 2,
              canvas.height / 2,
              canvas.height / 2,
              lastend,
              lastend + Math.PI * 2 * (data[i] / myTotal),
              false
            );
            ctx.lineTo(canvas.width / 2, canvas.height / 2);
            ctx.stroke();
            ctx.fill();
            lastend += Math.PI * 2 * (data[i] / myTotal);
          }
        }
      }, 40);
    }
    this.setState({
      viewPie: status,
      adminLicensesUsed: licensesUsed,
      adminLicensesAssigned: licensesAssigned
    });
  }
  viewUserPieChart(companyID) {
    let status = !this.state.viewUserPie;
    // let userChartData = [0, 0, 0, 0]; //[superadmin,manager,external_user,user]
    if (status) {
      this.props.getUserChartData(companyID);
      setTimeout(() => {
        let userChartData = this.props.userChartData;
        console.log(userChartData);
        var canvas = document.getElementById('usercan');
        if (canvas !== undefined && canvas !== null) {
          var ctx = canvas.getContext('2d');
          var lastend = 0;
          var data = userChartData;
          var myTotal = 0;
          var myColor = ['white', 'blue', 'red', 'green'];
          for (var e = 0; e < data.length; e++) {
            myTotal += data[e];
          }
          for (var i = 0; i < data.length; i++) {
            ctx.fillStyle = myColor[i];
            ctx.beginPath();
            ctx.strokeStyle = '#C9C9C9';
            ctx.lineWidth = 3;
            ctx.moveTo(canvas.width / 2, canvas.height / 2);
            ctx.arc(
              canvas.width / 2,
              canvas.height / 2,
              canvas.height / 2,
              lastend,
              lastend + Math.PI * 2 * (data[i] / myTotal),
              false
            );
            ctx.lineTo(canvas.width / 2, canvas.height / 2);
            ctx.stroke();
            ctx.fill();
            lastend += Math.PI * 2 * (data[i] / myTotal);
          }
        }
      }, 240);
    }
    this.setState({
      viewUserPie: status
    });
  }
  render() {
    var columns = [
      {
        Header: 'Users',
        columns: [
          {
            Header: 'Client Code',
            accessor: 'companyID'
          },
          {
            Header: 'Name',
            accessor: 'clientName'
          },
          {
            Header: 'License Used/Assigned',
            Cell: ({ row }) => {
              return (
                <button id='taskButton'>
                  {row.original.licensesUsed !== undefined &&
                  row.original.licensesUsed !== null
                    ? row.original.licensesUsed +
                      '/' +
                      row.original.licensesAssigned
                    : 'NA'}
                </button>
              );
            },
            accessor: 'licensesUsed'
          },
          {
            Header: 'Admin License Used/Assigned',
            Cell: ({ row }) => {
              let adminLicensesUsed = 0;
              let adminLicensesAssigned = 0;
              if (
                row.original.adminLicensesUsed !== undefined ||
                row.original.adminLicensesUsed !== null
              ) {
                adminLicensesUsed = row.original.adminLicensesUsed
                  ? row.original.adminLicensesUsed
                  : 0;
              }
              if (
                row.original.adminLicensesAssigned !== undefined ||
                row.original.adminLicensesAssigned !== null
              ) {
                adminLicensesAssigned = row.original.adminLicensesAssigned;
              }
              return (
                <button id='taskButton'>
                  {adminLicensesUsed + '/' + adminLicensesAssigned}
                </button>
              );
            },
            accessor: 'adminLicensesUsed'
          },
          {
            Header: ' ',
            Cell: ({ row }) => {
              let adminLicensesUsed = 0;
              let adminLicensesAssigned = 0;
              if (
                row.original.adminLicensesUsed !== undefined ||
                row.original.adminLicensesUsed !== null
              ) {
                adminLicensesUsed = row.original.adminLicensesUsed;
              }
              if (
                row.original.adminLicensesAssigned !== undefined ||
                row.original.adminLicensesAssigned !== null
              ) {
                adminLicensesAssigned = row.original.adminLicensesAssigned;
              }

              return (
                <BsPieChartFill
                  height={25}
                  width={25}
                  alt='chart'
                  className='icon_button'
                  src={Chart}
                  onClick={() => {
                    this.viewPieChart(
                      row.original.licensesUsed,
                      row.original.licensesAssigned
                    );
                    this.viewAdminPieChart(
                      adminLicensesUsed,
                      adminLicensesAssigned
                    );
                    this.viewUserPieChart(row.original.id);
                    this.showModal();
                  }}
                />
              );
            }
          }
        ]
      }
    ];
    let body = <p>Loading Dashboard</p>;
    if (this.props.role === 'Super Admin' || this.props.role === 'Manager') {
      body = (
        <div id='pieChartBoxSuperAdmin'>
          <p id='pieTitle'>Licenses Used / Assigned</p>
          <p id='pieChartStat'>
            {this.state.licensesUsed + '/' + this.state.licensesAssigned}
          </p>
          <canvas id='can' width='200' height='200'></canvas>
        </div>
      );
    } else if (this.props.role === 'Root') {
      body = (
        <Fragment>
          <article id='dashboardTableCont'>
            <Styles>
              {/* gets the user data from the userPage class and fills in the table */}
              <Table columns={columns} data={this.props.clientList} />
            </Styles>
          </article>
          <dialog id='chartBoxDialog' className='bigDialogBox'>
            <GrClose
              id='exit-dialog-icon'
              src={Exit}
              height={28}
              onClick={this.hideModal}
              alt='close pop up'
            />

            <div id='pieChartBox'>
              <Container fluid>
                <Row>
                  <Col>
                    <p id='pieTitle'>User Licenses</p>
                    <p id='pieChartStat'>
                      {this.state.licensesUsed +
                        '/' +
                        this.state.licensesAssigned}
                    </p>
                    <canvas id='can' width='200' height='200'></canvas>
                  </Col>
                  <Col>
                    <p id='pieTitle'>Admin Licenses</p>
                    <p id='pieChartStat'>
                      {this.state.adminLicensesUsed +
                        '/' +
                        this.state.adminLicensesAssigned}
                    </p>
                    <canvas id='admincan' width='200' height='200'></canvas>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <canvas id='usercan' width='200' height='200'></canvas>
                  </Col>
                  <Col>
                    <p id='pieTitle'>Client Licenses per user</p>
                    <p>
                      <div
                        style={{
                          display: 'inline',
                          float: 'left',
                          width: '20px',
                          height: '20px',
                          margin: '5px',
                          border: '1px solid rgba(0, 0, 0, .2)',
                          background: 'white'
                        }}
                      ></div>
                      Super Admin: {this.props.userChartData[0]}
                    </p>
                    <p>
                      <div
                        style={{
                          display: 'inline',
                          float: 'left',
                          width: '20px',
                          height: '20px',
                          margin: '5px',
                          border: '1px solid rgba(0, 0, 0, .2)',
                          background: 'blue'
                        }}
                      ></div>
                      Manager: {this.props.userChartData[1]}
                    </p>
                    <p>
                      <div
                        style={{
                          display: 'inline',
                          float: 'left',
                          width: '20px',
                          height: '20px',
                          margin: '5px',
                          border: '1px solid rgba(0, 0, 0, .2)',
                          background: 'red'
                        }}
                      ></div>
                      External User: {this.props.userChartData[2]}
                    </p>
                    <p>
                      <div
                        style={{
                          display: 'inline',
                          float: 'left',
                          width: '20px',
                          height: '20px',
                          margin: '5px',
                          border: '1px solid rgba(0, 0, 0, .2)',
                          background: 'green'
                        }}
                      ></div>
                      User: {this.props.userChartData[3]}
                    </p>
                  </Col>
                </Row>
              </Container>
            </div>
          </dialog>
        </Fragment>
      );
    }
    return (
      <main id='dashboardPage'>
        <p id='breadCrumbs'>DashBoard</p>

        <div className='MuiBox-root'>
          <div className='MuiPaper-root'>
            <div className='MuiBox-root css-1idik3a'>
              <div className='MuiBox-root css-uujcwg'>
                <h5 className='css-epib0t'>DashBoard</h5>
                <span className='css-17vat79'></span>
              </div>
            </div>
            <div className='MuiTableContainer-root css-13ypi1f'>
              <div className='MuiBox-root css-17uua2w'>{body}</div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
export default DashBoard;
