import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyArTWd8FeiIudJDqBNYr91_buYDlnBHT_4',
  authDomain: 'crisis-management-15d38.firebaseapp.com',
  databaseURL: 'https://crisis-management-15d38.firebaseio.com',
  projectId: 'crisis-management-15d38',
  storageBucket: 'crisis-management-15d38.appspot.com',
  messagingSenderId: '545680720706',
  appId: '1:545680720706:web:675a110a0072cf6854bedb',
  measurementId: 'G-FL1EB3HHNF'
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const auth = firebaseApp.auth();
export const db = firebaseApp.firestore();
db.enablePersistence();

export const storage = firebaseApp.storage();

export default firebaseApp;
