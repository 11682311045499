import React, { Component, Fragment } from 'react';

import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent
} from 'react-pro-sidebar';
import Select from 'react-select';

import ClientSettingsTable from './ClientSettingsTable';
import ClientTable from './ClientTable';
import CompletedFormTable from './CompletedFormTable';
import DashBoard from './DashBoard';
import FeatureSettingsTable from './FeatureSettingsTable';
import Library from './Library';
import LibraryTable from './LibraryTable';
import PlanTable from './PlanTable';
import Reports from './Reports';
import RollCallTable from './RollCallTable';
import SignIn from './SignIn';
import TaskTable from './TaskTable';
import UserGroupTable from './UserGroupTable';
import UserTable from './UserTable';
import VaccineSettingsTable from './VaccineSettingsTable';

import FlashLogoBlue from './style/FlashLogoBlue.png';
import BackArrow from './style/backArrow.png';
import ForwardArrow from './style/forwardArrow.png';
import Exit from './style/exit.png';
import PlaceholderImage from './style/default_profile.png';
import logo_flash from './style/Flash_logo.png';

import {
  // MdHome,
  // MdPeople,
  MdDomain,
  // MdAssignment,
  // MdLibraryBooks,
  // MdInsertChart,
  MdSettings,
  MdFormatAlignJustify,
  MdLocalLibrary,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight
} from 'react-icons/md';
import { FaUsers } from 'react-icons/fa';
import { BsFileEarmarkRuled } from 'react-icons/bs';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { FiLogOut } from 'react-icons/fi';

import './style/UserTable.css';
import './style/ClientSettings.css';
import '../index.css';
import 'react-pro-sidebar/dist/css/styles.css';
import './style/userPage.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//import envConfig from 'env';

import Store from '../store';
import { createNewCompany } from '../firebase/actions/company';
import { updateCompanyLicenses } from '../firebase/db/companies/operations';
import { fetchLibrary } from '../firebase/actions/library';
import { auth, db, storage } from '../firebase';

import {
  firebaseFns,
  firestoreArrayRemove,
  firestoreArrayUnion,
  firestoreServerTimestamp,
  firestoreTimestamp
} from 'firebase/utils';

// import FBapp from '../firebaseConfig';
// const storageService = firebase.storage();
// const storageRef = storageService.ref();
// const db = FBapp.firestore();
const storageRef = storage.ref();

let image = 'http://simpleicon.com/wp-content/uploads/user1-256x256.png';

var date = new Date().toLocaleString();
const initialState = {
  date: date,
  deletedUsers: [], // array of deleted users from the user list
  viewDeletedUsers: false, // boolean to determine whether to see userList/deletedUserList
  appOnlyUsers: false, // set to false because bug in UserTable causes filter not to work on initial load
  userList: [], // list of all the users in the user list
  clientList: [], // list of all the clients
  repository: [], // list of all the folders/files that are displayed in the Knowledge Base Page

  loggedData: [],
  pageNum: 1, // controls which component is renders (default: User List)
  // controls the style for the main menu and which item is highlighted
  highlight: [
    'mainItem',
    'mainItem selectedItem',
    'mainItem',
    'mainItem',
    'mainItem',
    'mainItem',
    'mainItem'
  ],
  isLoggedIn: false, // keeps track of the login status of the user
  email: '', //updates the email when a user is trying to login
  password: '', // updates the password when a user is trying to login
  uid: '', // {String} UID of the current user
  userName: '', // {String} name of the current user
  role: '', // the current user's role (Root, Super Admin, User)
  companyID: '', // the current user's companyID
  path: 'companies', // current working directory
  folderRepos: [], // all the folders in 'repoInfo'
  planList: [], // all the plans in the 'emergencyPlans'
  viewTasks: false, // view Tasks when the button is pressed
  viewRollCall: false, // view Roll Call
  taskList: [], // list of all the tasks
  planName: '', // displays on the Task List page
  rollCallList: [], // all the users in the plan for roll call
  numCompleted: 0, // # of completed tasks or # of checked in users
  totalNum: 0, // # of tasks in ERP or # of users in ERP
  planID: '', // {String ID} current ERP viewed for rollcall. Needed to change user checkin status
  taskUserList: [], // in task list page, on create task, view users in ERP
  ugCheck: false, // User Group Check in order to view the User Group Table
  groupList: [], // {Array: [ JS Objs ]} all the user groups in 'userGroups' collection
  userHighlight: ['topLine', 'bottomLine', 'bottomLine'], // {Array: [ Strings ]} user page highlighting tabs
  showBackArrow: false, // {boolean} conditional for displaying the back arrow
  leaderName: '', // on view tasklist, display the plan leader
  breadCrumbs: 'Users', // displays the current page the user is viewing
  viewFilteredList: false, // {Bool} display the filtered list
  filteredList: [], // {Array of Objects} the filtered list displayed
  toggleMenu: true, // {Boolean} false: menu is 300px & true: menu is 100px
  planHighlight: ['topLine', 'bottomLine', 'bottomLine'], // {Array of Strings} Response Plans navigation tabs
  viewCheckList: true, // {Boolean} View the checklist by default
  rcList: [], // {Array of Objects} (name, users, withNotifications, isRCActive, id, userStatus)
  rcUserList: [], // {Array of Objects} Users (name, isCheckedIn, status)
  numGreen: 0, // {Number} rollcall user list status for green
  numYellow: 0, // {Number} rollcall user list status for yellow
  numRed: 0, // {Number} rollcall user list status for red
  messages: [], // {Array of Objects} messages
  messageLogs: [], // {Array of Objects} message Logs of current user
  currMessageLog: {}, // {Object} keys: messageUsers, messageOwner, latestTimeStamp, usersRead, title
  viewUserChat: false, // {Bool} true: viewing a specific chat, false: viewing all the convos
  newMsg: '', // {String} the users new message
  otherUserName: '', // {String} name of the other user in the chat
  viewSearchForChat: false, // {Bool} determines whether to show the back arrow when search for user
  // to start a convo
  userListBox: [], // {Array of Obejcts} value and label keys for the Searchable dropdown menu
  selectedUsersForNewConvo: [], // {Array of objects} {value: UID, label: name}
  adminName: '', // {string} name of the admin logged in for email template
  planPager: 2, // {int} // 0 = active checklists // 1 = template Lists // 2 = roll Call Columns // 3 = rc User List Columns //4 = Reports
  subPlanPager: 0, //to show any sub page under a page in reports page.
  tmplList: [], // {Array of Objects} template obs populate the tmpl table
  gUserList: [], // {Array of Objs} global userlist to make two users dont have the same email
  viewDeletedClients: false, // {Boolean} true: view deleted clients / false: view active clients
  clientHighlight: ['topLine', 'bottomLine'], // {Array: [ Strings ]} client page highlighting tabs
  deletedClients: [], // list of all the deleted clients
  isClientAccount: false, // {Boolean} determines whether user or client is logged in
  activeNotification: false, // {Boolean} determines whether the user has a active notifcation
  teamName: '', // {String} name of the team when creating a new convo
  domain: '',
  dispalayMessage: '',
  //Completed forms
  cFormsList: [], //store completed forms list
  viewCompletedForms: false,
  reportsHiglight: ['topLine', 'bottomLine', 'bottomLine', 'bottomLine'],
  //Meant for Forms and Client settings for form
  formList: [],
  formSettings: [],
  userChartData: [0, 0, 0, 0],
  forgotPassword: false,
  companySettings: {
    vaccineSettings: false,
    isTrial: false,
    features: {
      meeting: false,
      messages: false,
      library: false,
      emergencyChecklist: false,
      teams: false,
      flash: false,
      forms: false
    }
  },
  countForLicenses: 0
};
// NOTES:
// Dashboorad: PlanPager: Set to 1 - BY DEFAULT
// Users: PlanPager: Set to 2 - BY DEFAULT
// Clients: PlanPager: Set to 3 - BY DEFAULT
// Checklist: PlanPager: Set to 4 - BY DEFAULT
// 		Active Checklist - viewPlans()/viewChecklist() - subPlanPager: Set to 1
// 		Template - viewTemplates() - subPlanPager: Set to 2
// 		Flash - viewRollCallList - subPlanPager: Set to 3
// Library: PlanPager: Set to 5 - BY DEFAULT
// Reports: PlanPager: Set to 6 - BY DEFAULT
// 		Active Checklist - viewPlans()/viewChecklist() - subPlanPager: Set to 1
// 			Checklist Tasks - viewTasks - subPlanPager: Set to 1
// 		Flash - viewRollCallList - subPlanPager: Set to 2
// 			Flash-Details - viewTemplateTasks - subPlanPager: Set to 2
// 			ViiewRollCallUsers - subPlanPager: Set to 3
// 		Library - subPlanPager - NA
// 		Covid-19 - subPlanPager - 0
// 		HealhScreen - viewCompletedForms - subPlanPager - NA
// ClientSettings: PlanPager: Set to 7 - BY DEFAULT

class App extends Component {
  constructor() {
    super();
    var startState = {};
    startState = initialState;
    this.state = startState;
    /* Login Functions */
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
    this.clientSettings = this.clientSettings.bind(this);
    this.vaccineSettings = this.vaccineSettings.bind(this);
    this.featureSettings = this.featureSettings.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    /* Page Navigation Functions */
    this.handleToggleMenu = this.handleToggleMenu.bind(this);
    this.viewDashboard = this.viewDashboard.bind(this);
    this.viewUsers = this.viewUsers.bind(this);
    this.viewClients = this.viewClients.bind(this);
    this.viewUserGroups = this.viewUserGroups.bind(this);
    this.viewKnowledge = this.viewKnowledge.bind(this);
    this.viewPlans = this.viewPlans.bind(this);
    this.viewTasks = this.viewTasks.bind(this);
    this.viewRollCall = this.viewRollCall.bind(this);
    this.viewReports = this.viewReports.bind(this);
    this.viewCompletedForms = this.viewCompletedForms.bind(this);
    this.viewDeletedUserList = this.viewDeletedUserList.bind(this);
    this.viewSelectedRepo = this.viewSelectedRepo.bind(this);
    this.navigateToChild = this.navigateToChild.bind(this);
    this.navigateToParent = this.navigateToParent.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.editBreadCrumbs = this.editBreadCrumbs.bind(this);
    /* Knowledge Page Functions */
    this.deleteFile = this.deleteFile.bind(this);
    this.addFile = this.addFile.bind(this);
    this.addFolder = this.addFolder.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.deleteFolder = this.deleteFolder.bind(this);
    this.editFolderName = this.editFolderName.bind(this);
    this.setFolderPassword = this.setFolderPassword.bind(this);
    this.removeFolderPassword = this.removeFolderPassword.bind(this);
    this.handleClientFilterChangeForRep =
      this.handleClientFilterChangeForRep.bind(this);
    /* Response Plans Functions */
    this.createPlan = this.createPlan.bind(this);
    this.activeToggle = this.activeToggle.bind(this);
    this.practiceToggle = this.practiceToggle.bind(this);
    this.deletePlan = this.deletePlan.bind(this);
    this.deactivatePlan = this.deactivatePlan.bind(this);
    this.editPlan = this.editPlan.bind(this);
    this.addTask = this.addTask.bind(this);
    this.editTask = this.editTask.bind(this);
    this.deleteTask = this.deleteTask.bind(this);
    this.handleTaskStatusChange = this.handleTaskStatusChange.bind(this);
    this.handleUserCheckedInChange = this.handleUserCheckedInChange.bind(this);
    this.viewRollCallList = this.viewRollCallList.bind(this);
    this.viewChecklist = this.viewChecklist.bind(this);
    this.createRollCall = this.createRollCall.bind(this);
    this.createRollCallCB = this.createRollCallCB.bind(this);
    this.fetchRollCallData = this.fetchRollCallData.bind(this);
    this.deleteRollCall = this.deleteRollCall.bind(this);
    this.archive = this.archive.bind(this);
    this.editRollCall = this.editRollCall.bind(this);
    this.rcActiveToggle = this.rcActiveToggle.bind(this);
    this.updateUserStatus = this.updateUserStatus.bind(this);
    this.updateUserSafety = this.updateUserSafety.bind(this);
    this.viewRollCallUsers = this.viewRollCallUsers.bind(this);
    this.changeStatusColor = this.changeStatusColor.bind(this);
    this.resetTaskList = this.resetTaskList.bind(this);
    this.viewTemplates = this.viewTemplates.bind(this);
    this.updateCompanyID = this.updateCompanyID.bind(this);
    /* User Group Functions */
    this.addUserGroup = this.addUserGroup.bind(this);
    this.editUserGroup = this.editUserGroup.bind(this);
    this.deleteUserGroup = this.deleteUserGroup.bind(this);
    this.filterTable = this.filterTable.bind(this);
    /* Client List Functions */
    this.toggleClient = this.toggleClient.bind(this);
    this.editClient = this.editClient.bind(this);
    this.deleteClient = this.deleteClient.bind(this);
    this.addClient = this.addClient.bind(this);
    this.addClientCallBack = this.addClientCallBack.bind(this);
    this.sendUsersMessage = this.sendUsersMessage.bind(this);
    this.viewClientList = this.viewClientList.bind(this);
    this.viewDeletedClientList = this.viewDeletedClientList.bind(this);
    this.reactivateClient = this.reactivateClient.bind(this);
    /* User List Functions */
    this.addManyNewUsers = this.addManyNewUsers.bind(this);
    this.addNewUser = this.addNewUser.bind(this);
    this.addNewUserCB = this.addNewUserCB.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.editUser = this.editUser.bind(this);
    this.toggleUser = this.toggleUser.bind(this);
    this.reactivateUser = this.reactivateUser.bind(this);
    this.handleClientFilterChange = this.handleClientFilterChange.bind(this);
    this.createUserLog = this.createUserLog.bind(this);
    this.getUserLocation = this.getUserLocation.bind(this);
    /* Messages Component */
    this.showChatBox = this.showChatBox.bind(this);
    this.hideChatBox = this.hideChatBox.bind(this);
    this.fetchUserMessages = this.fetchUserMessages.bind(this);
    this.sendNewMsg = this.sendNewMsg.bind(this);
    this.getCurrentMsgs = this.getCurrentMsgs.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.createNewMessageLog = this.createNewMessageLog.bind(this);
    this.searchUsersForChat = this.searchUsersForChat.bind(this);
    this.getConvoName = this.getConvoName.bind(this);
    this.handleUsersInConvoChange = this.handleUsersInConvoChange.bind(this);
    this.createNewGroupMessageLog = this.createNewGroupMessageLog.bind(this);
    this.handleMessagePicChange = this.handleMessagePicChange.bind(this);
    this.handleNewConvoTeamChange = this.handleNewConvoTeamChange.bind(this);
    this.validateMessageLogExists = this.validateMessageLogExists.bind(this);
    this.sendUserReminder = this.sendUserReminder.bind(this);
    /* Template Functions */
    this.createTemplate = this.createTemplate.bind(this);
    this.fetchTemplateData = this.fetchTemplateData.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);
    this.editTemplate = this.editTemplate.bind(this);
    this.editTemplateName = this.editTemplateName.bind(this);
    this.viewTasksFromTemplate = this.viewTasksFromTemplate.bind(this);
    /* Library Functions */
    this.createLibraryLog = this.createLibraryLog.bind(this);

    this.toggleForm = this.toggleForm.bind(this);
    /*Vaccine Setting*/
    this.toggleVaccineClient = this.toggleVaccineClient.bind(this);

    this.getUserChartData = this.getUserChartData.bind(this);

    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.getCompanySettings = this.getCompanySettings.bind(this);

    this.toggleFeatureSettings = this.toggleFeatureSettings.bind(this);
  }

  /************************* Login Page functions /*************************/

  signIn(email, password) {
    // check to determine whether the user's account has been deactivated
    // firebase
    //   .auth()
    auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        // check if the user has been disabled
        const user = auth.currentUser;
        // const user = firebase.auth().currentUser;
        const userRef = db.collection('users').doc(user.uid);
        userRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              this.getCompanySettings(doc.data().companyID);
              // console.log(this.state.companySettings['isTrial'])
              // assuming that the document exists
              if (doc.data().deactivated) {
                // the user has been deactivated
                alert(
                  `${
                    doc.data().firstName + ' ' + doc.data().lastName
                  }'s account has been disabled. Please contact your administrator if this is in error.`
                );
              } else if (this.state.companySettings['isTrial']) {
                alert(
                  'your account is in Trial, to gain access to the webportal, please contact to michael.darling@tpcm-usa.com'
                );
                this.signOut();
              } else {
                if (
                  !(
                    doc.data().role === 'Root' ||
                    doc.data().role === 'Super Admin' ||
                    doc.data().role === 'Manager'
                  )
                ) {
                  if (
                    doc.data().role === 'User' ||
                    doc.data().role === 'External User'
                  )
                    alert(
                      'You donnot have the access to login through the web portal!'
                    );
                  else alert('Please Input Admin Credentials to Login');
                  return;
                }
                // the user has not been deactivated
                let firstPath = 'companies';
                //  append to the path if a Super Admin or User
                if (doc.data().role !== 'Root') {
                  firstPath = firstPath + '/' + doc.data().companyID;
                }
                let adminName =
                  doc.data().firstName + ' ' + doc.data().lastName;

                if (
                  doc.data().dateOfflineShouldEnd !== undefined &&
                  doc.data().dateOfflineShouldEnd !== null
                ) {
                  // assume logged in user is not supposed to be offline anymore
                  if (
                    doc.data().isOffline !== undefined &&
                    doc.data().isOffline !== null &&
                    doc.data().isOffline
                  ) {
                    // assume logged in user is offline
                    if (doc.data().dateOfflineShouldEnd < new Date()) {
                      var userRef = db.collection('users').doc(user.uid);
                      userRef
                        .update({ isOffline: false })
                        .then(() =>
                          console.log('Document successfully updated!')
                        )
                        .catch((error) =>
                          console.error('Error updating document: ', error)
                        );
                    }
                  }
                }

                const userName = `${doc.data().firstName} ${
                  doc.data().lastName
                }`;

                this.setState({
                  isLoggedIn: true,
                  userName: userName,
                  role: doc.data().role,
                  companyID: doc.data().companyID,
                  path: firstPath,
                  uid: user.uid,
                  adminName: adminName
                });
                Store.assign({
                  user: {
                    uid: user.uid,
                    email: doc.data().email,
                    userName,
                    companyID: doc.data().companyID,
                    role: doc.data().role
                  }
                });

                this.fetchClientData();
                this.fetchUserData();
                this.fetchUserMessages();
                this.fetchUserGroupData();
                // console.log(doc.data().role + ' has Logged in Successfully')
                this.addSnapshotListeners();
                this.viewUsers();
                // setTimeout(() => {
                // 	var selectClientUserTable = document.getElementById('selectClientUserTable'); // get the select Client tag
                // 	if (selectClientUserTable !== null && selectClientUserTable !== undefined) {
                // 		selectClientUserTable.innerHTML = '';
                // 		let curr = { 'text': 'Select Client', 'value': "" }
                // 		selectClientUserTable.options.add( new Option(curr.text, curr.value, curr.selected) )
                // 		for (let client of this.state.clientList) {
                // 			curr = { 'text': client.clientName, 'value': client.clientName }
                // 			selectClientUserTable.options.add( new Option(curr.text, curr.value, curr.selected) )
                // 		}
                // 	}
                // }, 500)
              }
            } else {
              // user was not found -> check if user is a client
              // iterate through companies collection and check if any of the emails match
              db.collection('companies')
                .get()
                .then((querySnapshot) => {
                  const data = querySnapshot.docs.map((doc) => doc.data());
                  for (let item of data) {
                    if (item.email === user.email) {
                      if (item.deactivated) {
                        // user has been deactivated
                        alert(
                          `${item.clientName}'s account has been disabled. Please contact your administrator if this is in error.`
                        );
                      } else {
                        // user has successfully logged in
                        this.setState({
                          isLoggedIn: true,
                          role: 'Super Admin',
                          companyID: item.id,
                          path: `companies/${item.id}`,
                          isClientAccount: true
                        });
                        this.getCompanySettings(item.id);
                        this.fetchUserData();
                        this.fetchUserMessages();
                        this.fetchClientData();
                        this.fetchUserGroupData();
                        console.log('Client has Logged in Successfully');
                        this.addSnapshotListeners();
                      }
                      break;
                    }
                  }
                });
            }
          })
          .catch((error) => console.log('Error getting document:', error));
      })
      .catch(() => alert('Please enter a valid Username and Password'));
  }
  signOut() {
    this.setState(initialState);
    // firebase
    //   .auth()
    auth
      .signOut()
      .then(() => console.log('Successfully Signed out'))
      .catch((error) => console.log('Error signing out: ', error));
  }
  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }
  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }
  handleForgotPassword() {
    if (this.state.forgotPassword) {
      this.setState({ forgotPassword: false });
    } else {
      this.setState({ forgotPassword: true });
    }
  }
  forgotPassword(email = null) {
    if (email == null) {
      email = this.state.email;
    }
    // firebase
    //   .auth()
    auth
      .sendPasswordResetEmail(email)
      .then(function () {
        // Email sent.
        alert('Email has been sent to your registered email address');
      })
      .catch(function (error) {
        // An error happened.
        alert('Email not found');
      });
  }
  /************************* Component Navigation Functions *************************/
  // comment this out when you want to login
  componentDidMount() {
    auth.onAuthStateChanged(() => {
      var user = auth.currentUser;
      if (user !== null && user !== undefined) {
        if (user.email !== null && user.email !== undefined) {
          var userRef = db.collection('users').doc(user.uid);
          userRef.get().then((query) => {
            let data = query.data();
            if (data !== undefined && data !== null) {
              this.signIn(user.email, data.password);
            }
          });
        }
      }
    });

    // handle ResizeObserver loop limit exceeded error
    // Caused by addUserDialog closing
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }

      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        e.preventDefault();
        console.log(
          'ResizeObserver loop completed with undelivered notifications.'
        );
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }

  addSnapshotListeners() {
    db.collection('emergencyPlans').onSnapshot((querySnapshot) => {
      var planList = [];
      const pList = querySnapshot.docs;
      for (let i = 0; i < pList.length; i++) {
        planList.push(pList[i].data());
      }
      let fsSize = planList.length; // firestore messages size
      let stSize = this.state.planList.length; // state messages size
      // stSize == 0 only when the user has just opened the chat
      if (stSize > 0) {
        // assume that the user has already viewed the chat
        if (fsSize !== stSize) {
          this.fetchPlanData();
        }
      }

      //console.log(planList);
    });
    db.collection('rollCalls').onSnapshot((querySnapshot) => {
      var rcList = [];
      const rollList = querySnapshot.docs;
      for (let i = 0; i < rollList.length; i++) {
        rcList.push(rollList[i].data());
      }
      let fsSize = rcList.length; // firestore messages size
      let stSize = this.state.rcList.length; // state messages size
      // stSize == 0 only when the user has just opened the chat
      if (stSize > 0) {
        // assume that the user has already viewed the chat
        if (fsSize !== stSize) {
          this.fetchRollCallData();
        }
      }

      // console.log("CP2: "+rcList);
    });
    // check the messagelog to see if the new message is sent to the user
    // dynamically update the messages when someone replies
    // adds a listener to the messages collection
    db.collection('messages').onSnapshot((querySnapshot) => {
      // console.log("CP3: ");
      const messageList = querySnapshot.docs.map((message) => {
        let mid = message.id;
        message = message.data();
        message.id = mid;
        return message;
      });
      messageList.sort((a, b) => a.timeStamp.seconds - b.timeStamp.seconds);
      let lastMessage = messageList[messageList.length - 1];
      let logRef = db.collection('messageLog').doc(lastMessage.logReference.id);
      logRef.get().then((query) => {
        let mLog = query.data();
        // assume messageUsers is not defined
        if (
          mLog === undefined ||
          mLog.messageUsers === undefined ||
          mLog.messageUsers === null
        )
          return;
        // assume the current user is not in the convo
        if (
          mLog.messageUsers.findIndex((user) => user.id === this.state.uid) ===
          -1
        )
          return;
        if (
          mLog === undefined ||
          mLog.usersRead === undefined ||
          mLog.usersRead === null
        )
          return;
        //console.log("message is defined")
        // make sure the usersRead is defined
        if (
          lastMessage.fromID.id !== this.state.uid &&
          this.validateMessageLogExists(mLog) === false
        ) {
          // assume its a new user thread and not from the current user
          mLog.id = logRef.id;
          // check whether to add the html list item
          var convoList = document.getElementById('convoList');
          if (convoList !== null && convoList !== undefined) {
            const item = document.createElement('li');
            let logTitle = this.getConvoName(mLog);
            item.innerHTML = logTitle;
            // assume the current user has not seen the message
            let span = document.createElement('span');
            span.setAttribute('class', 'dot');
            item.prepend(span);
            item.setAttribute('data-mLogID', mLog.id);
            item.addEventListener('click', (curr) => {
              let mLogID = curr.path[0].getAttribute('data-mLogID');
              let idx = this.state.messageLogs.findIndex(
                (log) => log.id === mLogID
              );
              if (idx !== -1) {
                // make sure that it exists
                if (this.state.messageLogs[idx].messageUsers.length > 2) {
                  // more than 2 users === group chat
                  this.getCurrentMsgs(
                    mLogID,
                    this.state.messageLogs[idx].title
                  );
                  return;
                }
              }
              this.getCurrentMsgs(mLogID, curr.path[0].textContent);
            });
            convoList.appendChild(item);
          }
          this.state.messageLogs.push(mLog);
          this.setState({ messageLogs: this.state.messageLogs });
        }
        if (lastMessage.fromID.id !== this.state.uid) {
          // assume the new message is from another user
          let idx = this.state.messageLogs.findIndex(
            (log) => log.messageOwner.id === mLog.messageOwner.id
          );
          // update the usersRead state
          // this.state.messageLogs[idx].usersRead = [lastMessage.fromID];
          const updatedMsgLogs = [...this.state.messageLogs];
          updatedMsgLogs[idx].usersRead = [lastMessage.fromID];
          this.setState({ messageLogs: updatedMsgLogs });
        }
        let notificationCheck = true;
        for (let j = 0; j < mLog.usersRead.length; j++) {
          // iterate through all the users in the usersRead
          if (mLog.usersRead[j].id === this.state.uid) {
            // assume user has read the message
            notificationCheck = false;
          }
        }
        if (notificationCheck) {
          // assume there is a active unread message
          if (
            messageList.findIndex(
              (message) => message.logReference.id === logRef.id
            ) !== -1
          ) {
            // assume there is at least one message in the chat
            this.setState({ activeNotification: true });
          }
        }
        // make sure there are no duplicates
        let messageIdx = this.state.messages.findIndex(
          (curr) => curr.timeStamp === lastMessage.timeStamp
        );
        if (this.state.currMessageLog.id === lastMessage.logReference.id) {
          // assume new message is from current messagelog
          if (lastMessage.fromID.id !== this.state.uid && messageIdx === -1) {
            // adding message to the html message list
            var mList = document.getElementById('messageList');
            let idx = this.state.userList.findIndex(
              (curr) => curr.id === lastMessage.fromID.id
            );
            if (idx !== -1) {
              var itemName = document.createElement('li');
              itemName.setAttribute('class', 'otherUserName');
              itemName.innerHTML = this.state.userList[idx].name;
              mList.appendChild(itemName);
            }
            const item = document.createElement('li');
            item.setAttribute('class', 'otherUserMessage');
            if (lastMessage.isImage) {
              // console.log(lastMessage.id)
              // console.log(lastMessage.logReference.id)
              if (
                lastMessage.imageURL === undefined ||
                lastMessage.imageURL === null ||
                lastMessage.imageURL.length < 10
              ) {
                console.log('Message has an invalid imageURL field');
                return;
              }
              item.style.width = '100%';
              let img = document.createElement('img');
              img.setAttribute('src', lastMessage.imageURL);
              img.style.width = '95%';
              img.style.margin = '5px';
              item.appendChild(img);
              mList.appendChild(item);
              // not from the current user
              // add message to messages list
              this.setState({
                messages: [...this.state.messages, lastMessage]
              });
            } else {
              item.innerHTML = lastMessage.message;
              mList.appendChild(item);
              // not from the current user
              // add message to messages list
              this.setState({
                messages: [...this.state.messages, lastMessage]
              });
            }
          }
        } else if (this.state.viewUserChat === false) {
          // assume not viewing the current messagelog
          let convoList = document.getElementById('convoList');
          if (convoList !== undefined && convoList !== null) {
            var convos = convoList.getElementsByTagName('li');
            for (let convo of convos) {
              let mlogID = convo.getAttribute('data-mlogid');
              if (mlogID === lastMessage.logReference.id) {
                // assume li doesnt already have a red dot
                // console.log("Got a new message for curr user")
                if (convo.childElementCount === 0) {
                  // console.log("adding dot")
                  // assume is first element is a string
                  // assume the message doesnt already have a red bubble.
                  let span = document.createElement('span');
                  span.setAttribute('class', 'dot');
                  convo.prepend(span);
                }
              }
            }
          }
        }
      });
    });
    // Get the latest one by sorting the timestamps
    db.collection('taskLog').onSnapshot((querySnapshot) => {
      const taskLogs = querySnapshot.docs.map((log) => log.data());
      taskLogs.sort((a, b) => a.date.seconds - b.date.seconds);
      let latestLog = taskLogs[taskLogs.length - 1];
      // console.log(latestLog)
      // get the id of the task
      let taskID = latestLog.id;
      // console.log('taskID: ', taskID)
      let idx = this.state.taskList.findIndex((curr) => curr.id === taskID);
      if (idx !== -1) {
        // console.log("its in the current taskID")
        // taskLog is in the taskList
        for (let doc of latestLog.relatedItems) {
          let curr = doc.path.split('/');
          // curr[0] is the collection name
          if (curr[0] === 'users') {
            // curr[1] is the user ID
            let i = this.state.userList.findIndex(
              (item) => item.id === curr[1]
            );
            if (i !== -1) {
              // get the data format in the right format
              var ts = new Date(1970, 0, 1); // Epoch
              ts.setSeconds(latestLog.date.seconds - 7 * 60 * 60); // minus 7 hrs
              const mmddyyyy =
                ts.getDate() +
                '/' +
                (ts.getMonth() + 1) +
                '/' +
                ts.getFullYear();
              const timeArr = ts.toLocaleTimeString().split(':');
              let hhmm = timeArr[0] + ':' + timeArr[1] + timeArr[2].slice(2);

              const updatedTaskList = [...this.state.taskList];
              updatedTaskList[idx].completedBy = this.state.userList[i].name;
              updatedTaskList[idx].completedDt = mmddyyyy + ' ' + hhmm;
              // this.state.taskList[idx].completedBy =
              //   this.state.userList[i].name;
              // this.state.taskList[idx].completedDt = mmddyyyy + ' ' + hhmm;
              // console.log('Updated tasklist state bc of completion')
              this.setState({ taskList: updatedTaskList });
            }
          }
        }
      }
    });
    this.fetchCompletedFormsData();
    this.fetchformsData();

    setInterval(() => {
      // check if there is anything in local storage every minute
      // {array of objects} rollCall name, update_dt and users array of uids
      if (
        localStorage.getItem('activatedRollcalls') !== undefined &&
        localStorage.getItem('activatedRollcalls') !== null
      ) {
        let activatedRollcalls = [];
        if (this.testJSON(localStorage.getItem('activatedRollcalls'))) {
          activatedRollcalls = JSON.parse(
            localStorage.getItem('activatedRollcalls').toString()
          );
        }
        var notUpdatedRollCalls = [];
        var currTimestamp = new Date().getTime() / 1000;
        for (let rollCall of activatedRollcalls) {
          if (rollCall === undefined || rollCall === null) {
            continue;
          }
          // iterate through all the rollcalls
          if (currTimestamp > rollCall.update_dt) {
            // assume the 20min is up
            for (let uid of rollCall.users) {
              // iterate through all the
              this.changeStatusColor(uid, rollCall.name);
            }
          } else {
            // assume rollcall was activated less than 20 min ago
            notUpdatedRollCalls.push(rollCall);
          }
        }
        localStorage.setItem(
          'activatedRollcalls',
          JSON.stringify(notUpdatedRollCalls)
        );
      }
    }, 1000 * 60);
  }

  testJSON(text) {
    if (
      /^[\],:{}\s]*$/.test(
        text
          .replace(/\\["\\/bfnrtu]/g, '@')
          .replace(
            /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g,
            ']'
          )
          .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
      )
    ) {
      return true;
      //the json is ok
    } else {
      return false;
      //the json is not ok
    }
  }

  validateMessageLogExists(newLog) {
    let currArr = newLog.messageUsers.map((docRef) => docRef.id);
    let currStr = currArr.sort().join(',');
    for (let log of this.state.messageLogs) {
      // array of user IDs
      let userArr = log.messageUsers.map((docRef) => docRef.id);
      let userStr = userArr.sort().join(',');
      if (userStr === currStr) {
        return true;
      }
    }
    return false;
  }

  handleBackClick() {
    if (this.state.planPager === 6) {
      this.viewReports({
        planPager: this.state.planPager,
        subPlanPager: this.state.subPlanPager
      });
    } else {
      this.viewPlans();
    }
  }

  editBreadCrumbs(action) {
    // console.log("Action: "+action);
    let curr = '';
    if (action.type === 'APPEND_BREADCRUMB') {
      // assume user is viewing a dialog
      curr = this.state.breadCrumbs + action.breadCrumbs;
    } else if (action.type === 'REMOVE_BREADCRUMB') {
      // assume user exited from a dialog
      curr = action.breadCrumbs;
    }
    this.setState({ breadCrumbs: curr });
  }

  viewDashboard() {
    this.setState({
      highlight: [
        'mainItem selectedItem',
        'mainItem',
        'mainItem',
        'mainItem',
        'mainItem',
        'mainItem',
        'mainItem'
      ],
      pageNum: 5
    });
  }

  getUserChartData(companyID) {
    let userList = this.state.userList;
    let companyUserList = [];
    let superadmin = 0;
    let manager = 0;
    let external_user = 0;
    let user = 0;
    for (let u of userList) {
      if (u.companyID === companyID) {
        companyUserList.push(u);
      }
    }

    for (let u of companyUserList) {
      if (u.deleted === false || u.deleted === undefined) {
        // console.log(u.role);
        if (u.role === 'User') {
          user++;
        } else if (u.role === 'Manager') {
          manager++;
        } else if (u.role === 'Super Admin') {
          superadmin++;
        } else if (u.role === 'External User') {
          external_user++;
        }
      }
    }

    let userChartData = [superadmin, manager, external_user, user];

    console.log(userChartData);

    this.setState({
      userChartData: userChartData
    });
  }

  viewUserGroups() {
    this.fetchUserGroupData();
    this.setState({
      ugCheck: true,
      userHighlight: ['bottomLine', 'topLine', 'bottomLine'],
      viewFilteredList: false
    });
  }

  viewDeletedUserList() {
    this.setState({
      ugCheck: false,
      viewDeletedUsers: true,
      userHighlight: ['bottomLine', 'bottomLine', 'topLine'],
      viewFilteredList: false
    });
  }

  toggleAppUsers(action) {
    if (action === true) {
      this.setState({
        appOnlyUsers: true
      });
    }
  }

  // when pageNum is 1, view the UserList
  viewUsers(action, appOnly = this.state.appOnlyUsers) {
    // console.log("viewUsers > SPP: "+action.subPlanPager)
    // console.log("viewUsers > PP: "+action.planPager)

    let userList = this.state.userList;
    userList = userList.sort((a, b) => {
      var nameA = a.name.toUpperCase();
      var nameB = b.name.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

    this.setState(
      {
        highlight:
          action?.planPager === 6
            ? [
                'mainItem',
                'mainItem',
                'mainItem',
                'mainItem',
                'mainItem',
                'mainItem selectedItem',
                'mainItem'
              ]
            : [
                'mainItem  selectedItem',
                'mainItem',
                'mainItem',
                'mainItem',
                'mainItem',
                'mainItem',
                'mainItem'
              ],
        reportsHighlight:
          action?.subPlanPager === 0 && action?.planPager === 6
            ? [
                'bottomLine',
                'bottomLine',
                'bottomLine',
                'topLine',
                'bottomLine'
              ]
            : [
                'bottomLine',
                'bottomLine',
                'bottomLine',
                'bottomLine',
                'topLine'
              ],
        pageNum: 1,
        ugCheck: false, // not user groups
        viewDeletedUsers: false, // not deleted users
        userHighlight: ['topLine', 'bottomLine', 'bottomLine'], //user page navigation
        showBackArrow: false, //back arrow
        breadCrumbs: 'Users',
        viewFilteredList: false,
        userList: userList, // sort the userList alphabetically
        planPager: action?.planPager ? action?.planPager : 2,
        subPlanPager: action?.subPlanPager ? action.subPlanPager : 0
      },
      () => {}
    );
    if (appOnly === true) {
      this.setState(
        {
          appOnlyUsers: true
        },
        () => {
          this.filterTable({ type: 'FILTER_APP_USER_LIST' });
        }
      );
    } else {
      this.setState({
        appOnlyUsers: false
      });
    }

    // console.log('viewUsers-2 state > SPP: ' + this.state.subPlanPager);
    // console.log('viewUsers-2 state > PP: ' + this.state.planPager);

    setTimeout(() => {
      var selectClientUserTable = document.getElementById(
        'selectClientUserTable'
      ); // get the select Client tag
      if (
        selectClientUserTable !== null &&
        selectClientUserTable !== undefined
      ) {
        selectClientUserTable.innerHTML = '';
        let curr = { text: 'Select Client', value: '' };
        selectClientUserTable.options.add(
          new Option(curr.text, curr.value, curr.selected)
        );
        for (let client of this.state.clientList) {
          curr = { text: client.clientName, value: client.clientName };
          selectClientUserTable.options.add(
            new Option(curr.text, curr.value, curr.selected)
          );
        }
      }
    }, 50);
  }

  // when pageNum is 2, view the ClientList
  viewClients() {
    // console.log("viewClients > SPP: "+this.state.subPlanPager)
    // console.log("viewClients > PP: "+this.state.planPager)

    this.setState({
      highlight: [
        'mainItem',
        'mainItem',
        'mainItem selectedItem',
        'mainItem',
        'mainItem',
        'mainItem',
        'mainItem'
      ],
      pageNum: 2,
      showBackArrow: false, // hide the back arrow
      breadCrumbs: 'Clients',
      viewFilteredList: false
    });

    // console.log("viewClients-2 > SPP: "+this.state.subPlanPager)
    // console.log("viewClients-2 state > PP: "+this.state.planPager)
  }

  viewClientList() {
    // console.log("viewClientList > SPP: "+this.state.subPlanPager)
    // console.log("viewClientList > PP: "+this.state.planPager)

    this.setState({
      viewDeletedClients: false,
      clientHighlight: ['topLine', 'bottomLine']
    });
  }

  viewDeletedClientList() {
    this.setState({
      viewDeletedClients: true,
      clientHighlight: ['bottomLine', 'topLine']
    });
  }

  async getCompanySettings(company_id) {
    let companySettings = {
      vaccineTracking: false,
      healthScreen: false,
      isTrial: false
    };
    let features = {
      meeting: true,
      messages: true,
      library: true,
      emergencyChecklist: true,
      teams: true,
      flash: true,
      forms: true
    };
    companySettings.features = features;
    if (this.state.role !== 'Root') {
      db.collection('companies')
        .doc(company_id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            let company_data = doc.data();

            let vaccineTracking = false;
            if (company_data.isTrial !== undefined) {
              companySettings.isTrial = company_data.isTrial;
            }

            if (company_data.vaccineTracking !== undefined) {
              vaccineTracking = company_data.vaccineTracking;
            }
            if (company_data.features !== undefined) {
              companySettings.features = company_data.features;
            }

            companySettings.vaccineTracking = vaccineTracking;
          }
        })
        .then(() => {
          this.setState(
            {
              companySettings: companySettings
            },
            () => {
              // console.log(this.state.companySettings);
            }
          );
        });

      db.collection('forms')
        .get()
        .then((querySnapshot) => {
          const formList = querySnapshot.docs;

          for (let form of formList) {
            form = form.data();

            if (form.companies !== undefined && form.companies.length !== '') {
              for (let companies of form.companies) {
                if (company_id === companies.id) {
                  companySettings.healthScreen = true;
                  break;
                }
              }
            }
          }
        })
        .then(() => {
          this.setState(
            {
              companySettings: companySettings
            },
            () => {
              // console.log(this.state.companySettings);
            }
          );
        });
    } else {
      companySettings = { vaccineTracking: true, healthScreen: true };
      let features = {
        meeting: true,
        messages: true,
        library: true,
        emergencyChecklist: true,
        teams: true,
        flash: true,
        forms: true
      };
      companySettings.features = features;
      this.setState(
        {
          companySettings: companySettings
        },
        () => {
          console.log(this.state.companySettings);
        }
      );
    }
  }

  // when pageNum is 3, view the Response Plans
  viewPlans() {
    // console.log('viewPlans > PP: ' + this.state.planPager);

    // sort the list into ascending order
    // let planList = this.state.planList.sort((a,b) => {
    // 	var nameA = a.name.toUpperCase();
    // 	var nameB = b.name.toUpperCase();
    // 	if (nameA < nameB) return -1
    // 	if (nameA > nameB) return 1
    // })

    let planList = this.state.planList.sort((a, b) => {
      var secA = a.dateActivated?.seconds;
      var secB = b.dateActivated?.seconds;
      // console.log(secA + ' ' + secB);
      if (secA > secB) return -1;
      if (secA < secB) return 1;

      return 1;
    });

    this.fetchPlanData();
    // console.log(this.fetchPlanData());

    this.fetchRollCallData();
    this.fetchTemplateData();

    db.collection('tasks')
      .get()
      .then((querySnapshot) => {
        const taskList = querySnapshot.docs;

        // iterate through all the doc refs
        let taskArr = [];
        for (let task of taskList) {
          let tid = task.id;
          task = task.data();
          task.id = tid;
          taskArr.push(task);
        }
        this.setState({ taskList: taskArr });
      });
    // console.log("Tasks ended: ");
    // console.log("Plans ended: "+planList);

    let subPlanPager = 1;
    let planHighlight = ['topLine', 'BottomLine', 'bottomLine'];
    // this.props.planPager === 4 && this.props.subPlanPager === 2
    if (this.state.companySettings.features.emergencyChecklist !== true) {
      subPlanPager = 2;
      planHighlight = ['bottomLine', 'topLine', 'bottomLine'];
    }
    this.setState({
      highlight: [
        'mainItem',
        'mainItem',
        'mainItem',
        'mainItem selectedItem',
        'mainItem',
        'mainItem',
        'mainItem'
      ],
      planHighlight: planHighlight,
      pageNum: 3,
      viewTasks: false,
      viewRollCall: false,
      showBackArrow: false, // hide the back arrow
      breadCrumbs: 'Checklists',
      viewFilteredList: false,
      planList: planList,
      planPager: 4, //by default
      subPlanPager: subPlanPager
    });
    // console.log("viewPlans-2 > SPP: "+this.state.subPlanPager)
    // console.log("viewPlans-2 > PP: "+this.state.planPager)
  }

  resetTaskList() {
    this.setState({ viewFilteredList: false });
  }

  viewRollCallList(action) {
    // console.log('viewRollCallList > SPP: ' + this.state.subPlanPager);
    // console.log('viewRollCallList > PP: ' + this.state.planPager);
    // console.log('viewRollCallList/action: ', action);

    // sort the list into ascending order
    let rollCallList = this.state.rcList.sort((a, b) => {
      var nameA = a.name.toUpperCase();
      var nameB = b.name.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 1;
    });

    let arcrollCallList = this.state.arcList.sort((a, b) => {
      var nameA = a.name.toUpperCase();
      var nameB = b.name.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 1;
    });

    this.setState({
      planHighlight: ['bottomLine', 'bottomLine', 'topLine'],
      viewCheckList: false,
      viewFilteredList: false,
      viewCompletedForms: false,
      viewRollCall: true,
      rcList: rollCallList,
      arcList: arcrollCallList,
      planPager: action.planPager ? action.planPager : 4,
      subPlanPager: action.subPlanPager ? action.subPlanPager : 3,
      reportsHighlight: [
        'bottomLine',
        'topLine',
        'bottomLine',
        'bottomLine',
        'bottomLine'
      ]
    });

    // console.log("viewRollCallList-2 > SPP: "+action.subPlanPager)
    // console.log("viewRollCallList-2 > PP: "+action.planPager)
  }

  fetchRollCallData() {
    //console.log("fetch roll call dataa");
    let rollCallList = [];
    let archivedRollCallList = [];
    db.collection('rollCalls')
      .get()
      .then((querySnapshot) => {
        const rollCalls = querySnapshot.docs;
        // iterate through all the doc refs
        for (let rollCall of rollCalls) {
          let id = rollCall.id; // get the ID of the rollCall

          rollCall = rollCall.data(); // access the data of the user doc ref
          // assmue not root and filter out non company roll calls
          //console.log("Name: " + rollCall.name + " RCACTIVE: " + rollCall.isRCActive + " ARCHIVED: " + rollCall.isArchived) ;
          if (
            this.state.role !== 'Root' &&
            rollCall.companyID !== this.state.companyID
          )
            continue;
          rollCall.id = id;
          if (rollCall.logo === undefined || rollCall.logo.length <= 45) {
            rollCall.logo =
              'https://firebasestorage.googleapis.com/v0/b/crisis-management-15d38.appspot.com/o/companies%2Fflash_mini.png?alt=media&token=940ef3e5-d4a7-44a9-9714-887d2d7610a9';
          }
          // if (this.state.role !== 'Root' && rollCall.isRCActive  && ( !rollCall.isArchived || rollCall.isArchived == undefined))  {
          if (
            this.state.role !== 'Root' &&
            (!rollCall.isArchived || rollCall.isArchived === undefined)
          ) {
            // assume the user is a Root and the ERP is from a different companyID
            rollCallList.push(rollCall);
          }

          // else if(this.state.role === 'Root' && rollCall.isRCActive  && ( !rollCall.isArchived || rollCall.isArchived == undefined))
          else if (
            this.state.role === 'Root' &&
            (!rollCall.isArchived || rollCall.isArchived === undefined)
          ) {
            rollCallList.push(rollCall);
          } else if (rollCall.isArchived) {
            // assume the user is a Root and the ERP is from a different companyID
            archivedRollCallList.push(rollCall);
          }
        }
        rollCallList = rollCallList.sort((a, b) => {
          var nameA = a.name.toUpperCase();
          var nameB = b.name.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 1;
        });
        archivedRollCallList = archivedRollCallList.sort((a, b) => {
          var nameA = a.name.toUpperCase();
          var nameB = b.name.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 1;
        });
        this.setState({ rcList: rollCallList, arcList: archivedRollCallList });
      });
  }

  viewTemplates() {
    // console.log("viewTemplates > SPP: "+this.state.subPlanPager)
    // console.log("viewTemplates > PP: "+this.state.planPager)
    this.setState({
      planHighlight: ['bottomLine', 'topLine', 'bottomLine'],
      viewCheckList: false,
      viewFilteredList: false,
      planPager: 4, //Prev was 1, but changed to 4 by dafault
      subPlanPager: 2 ////Prev was 1, but changed to 2 by dafault
    });
    //
    // console.log("viewTemplates-2 > SPP: "+this.state.subPlanPager)
    // console.log("viewTemplates-2 > PP: "+this.state.planPager)
  }

  viewChecklist() {
    // console.log("viewChecklist > SPP: "+this.state.subPlanPager)
    // console.log("viewChecklist > PP: "+this.state.planPager)
    //console.log("viewChecklist")
    this.setState({
      reportsHighlight: [
        'topLine',
        'bottomLine',
        'bottomLine',
        'bottomLine',
        'bottomLine'
      ],
      planHighlight: ['topLine', 'bottomLine', 'bottomLine'],
      viewCheckList: true,
      viewFilteredList: false,
      planPager: 4, //Prev was 1, bu changed to 4 by dafault
      subPlanPager: 1 //Prev was 0, bu changed to 1 by dafault
    });

    // console.log("viewChecklist-2 > SPP: "+this.state.subPlanPager)
    // console.log("viewChecklist-2 > PP: "+this.state.planPager)
  }

  // when pageNum is 4, view the Knowledge Base
  viewKnowledge(action) {
    // console.log("viewKnowledge > SPP: "+this.state.subPlanPager)
    // console.log("viewKnowledge > PP: "+this.state.planPager)
    // console.log("viewKnowledge");
    this.fetchKnowledgeData();
    fetchLibrary(this.state.companyID);
    this.fetchLibraryLogData();
    this.setState({
      highlight:
        action.planPager === 6
          ? [
              'mainItem',
              'mainItem',
              'mainItem',
              'mainItem',
              'mainItem',
              'mainItem selectedItem',
              'mainItem'
            ]
          : [
              'mainItem',
              'mainItem',
              'mainItem',
              'mainItem',
              'mainItem selectedItem',
              'mainItem',
              'mainItem'
            ],
      pageNum: 4,
      showBackArrow: false, // hide the back arrow
      breadCrumbs: 'Library',
      viewFilteredList: false,
      planPager: action.planPager ? action.planPager : 5,
      reportsHighlight: [
        'bottomLine',
        'bottomLine',
        'topLine',
        'bottomLine',
        'bottomLine'
      ]
    });
    setTimeout(() => {
      var selectClientUserTable = document.getElementById(
        'selectClientUserTable'
      ); // get the select Client tag
      if (
        selectClientUserTable !== null &&
        selectClientUserTable !== undefined
      ) {
        selectClientUserTable.innerHTML = '';
        let curr = { text: 'Select Client', value: '' };
        selectClientUserTable.options.add(
          new Option(curr.text, curr.value, curr.selected)
        );
        for (let client of this.state.clientList) {
          curr = { text: client.clientName, value: client.clientName };
          selectClientUserTable.options.add(
            new Option(curr.text, curr.value, curr.selected)
          );
        }
      }
    }, 50);
  }

  // onClick of ERP tasks, view associated tasks. need the ID of the plan
  viewTasks({ index }) {
    // userStatus: {} map of JS objs (key: UID, value: {checkedIn: bool, title: String})
    const planID = this.state.planList[index].id;
    const erpRef = db.collection('emergencyPlans').doc(planID);
    // TODO:
    // get all the task logs and create a map of taskID to {completedBy and completedDt}
    // db.collection("userGroups").where("users", "array-contains", userRef).get()
    // 	.then((querySnapshot) => {

    let tmap = {}; // map of taskID to {completedBy and completedDt}
    db.collection('taskLog')
      .where('relatedItems', 'array-contains', erpRef)
      .get()
      .then((querySnapshot) => {
        // populate tmap
        const taskLogs = querySnapshot.docs;

        for (let tLog of taskLogs) {
          tLog = tLog.data();
          for (let doc of tLog.relatedItems) {
            let curr = doc.path.split('/');

            // curr[0] is the collection name
            if (curr[0] === 'users') {
              // curr[1] is the user ID
              let idx = this.state.userList.findIndex(
                (item) => item.id === curr[1]
              );
              if (idx !== -1) {
                // get the data format in the right format
                var ts = new Date(1970, 0, 1); // Epoch
                ts.setSeconds(tLog.date.seconds - 7 * 60 * 60); // minus 7 hours to PST
                const mmddyyyy =
                  ts.getDate() +
                  '/' +
                  (ts.getMonth() + 1) +
                  '/' +
                  ts.getFullYear();
                const timeArr = ts.toLocaleTimeString().split(':');
                let hhmm = timeArr[0] + ':' + timeArr[1] + timeArr[2].slice(2);
                let currTask = {
                  completedBy: this.state.userList[idx].name,
                  completedDt: mmddyyyy + ' ' + hhmm
                };
                console.log('Current Task: ', currTask);
                tmap[tLog.id.id] = currTask;
              }
            }
          }
        }
      });

    erpRef.get().then(async (doc) => {
      // iterate through all the ERP's tasks
      if (doc.exists) {
        const planName = doc.data().name; // name of the current ERP
        const tasks = doc.data().tasks;
        // console.log("planName: "+planName);
        if (tasks.length === 0) {
          alert('This Emergency Response Plan does not have any tasks.');
          return;
        }
        // console.log("task length:"+tasks.length)
        var leaderName = 'N/A';
        if (doc.data().leader !== undefined && doc.data().leader !== null) {
          doc
            .data()
            .leader.get()
            .then((query) => {
              // get the name of the Leader from doc Reference
              const data = query.data();
              var fullName = 'N/A';
              if (data !== undefined && data !== null) {
                fullName = data.firstName + ' ' + data.lastName;
              }
              leaderName = fullName;
              console.log('leaderName ', leaderName);
            });

          console.log('leaderName ', leaderName);
          // console.log("erpRef in appjs");
        }
        var completedTasks = 0;
        let taskArr = []; // array of task objs associated with ERP
        for (let i = 0; i < tasks.length; i++) {
          const task = await tasks[i].get();
          const tdata = task.data();
          if (!tdata) return;

          if (tmap[tdata.id]) {
            tdata.completedBy = tmap[tdata.id].completedBy;
            tdata.completedDt = tmap[tdata.id].completedDt;
          }

          tdata.title = 'N/A';

          if (tdata.isComplete) {
            completedTasks += 1;
            if (tdata.userCompleted) {
              let idx = this.state.userList.findIndex(
                (item) => item.id === tdata.userCompleted.path.split('/')[1]
              );
              tdata.userCompletedName = this.state.userList[idx].name;
            } else {
              tdata.userCompletedName = '';
            }
          }
          taskArr.push(tdata);

          if (i === tasks.length - 1) {
            this.setState({
              viewTasks: true,
              planID: this.state.planList[index].id,
              planRowID: index,
              showBackArrow: true, // display the back arrow
              taskList: taskArr,
              planName: planName,
              numCompleted: completedTasks,
              totalNum: tasks.length,
              taskUserList: doc.data().users,
              leaderName: leaderName,
              breadCrumbs: 'Checklists > Task List',
              viewFilteredList: false
            });
          }

          // tasks[i].get().then((query) => {
          //   let tdata = query.data();
          //   if (tdata === undefined) {
          //     return; // make sure the task is defined
          //   }
          //   if (tmap[tdata.id] !== undefined && tmap[tdata.id] !== null) {
          //     // assume tmap is defined for the task id
          //     tdata.completedBy = tmap[tdata.id].completedBy;
          //     tdata.completedDt = tmap[tdata.id].completedDt;
          //   }
          //   // adding user title from the ERP's userStatus Map
          //   let title = 'N/A';
          //   // if (tdata.assignedUser.id !== undefined && tdata.assignedUser.id !== null) {
          //   // 	title = this.state.planList[index].userStatus[tdata.assignedUser.id].title
          //   // }
          //   tdata.title = title;

          //   if (tdata.isComplete) {
          //     completedTasks += 1;
          //     if (tdata.userCompleted) {
          //       let idx = this.state.userList.findIndex(
          //         (item) => item.id === tdata.userCompleted.path.split('/')[1]
          //       );
          //       tdata.userCompletedName = this.state.userList[idx].name;
          //     } else {
          //       tdata.userCompletedName = '';
          //     }
          //   }
          //   taskArr.push(tdata);
          //   if (i === tasks.length - 1) {
          //     this.setState({
          //       viewTasks: true,
          //       planID: this.state.planList[index].id,
          //       planRowID: index,
          //       showBackArrow: true, // display the back arrow
          //       taskList: taskArr,
          //       planName: planName,
          //       numCompleted: completedTasks,
          //       totalNum: tasks.length,
          //       taskUserList: doc.data().users,
          //       leaderName: leaderName,
          //       breadCrumbs: 'Checklists > Task List',
          //       viewFilteredList: false
          //     });
          //   }
          // });
        }
      }
    });

    // console.log("viewTasks-2 > SPP: ",this.state.taskList)
    // console.log("viewTasks-2 > PP: "+this.state.planPager)
  }

  viewTasksFromTemplate({ index }) {
    // console.log("viewTasksFromTemplate > SPP: "+this.state.subPlanPager)
    // console.log("viewTasksFromTemplate > PP: "+this.state.planPager)
    // userStatus: {} map of JS objs (key: UID, value: {checkedIn: bool, title: String})
    const tuid = this.state.tmplList[index].id;
    // console.log(tuid)
    const tmplRef = db.collection('templates').doc(tuid);
    tmplRef.get().then(async (doc) => {
      // iterate through all the ERP's tasks
      if (doc.exists) {
        const planName = doc.data().name; // name of the current ERP
        const tasks = doc.data().tasks;
        if (tasks.length === 0) {
          alert('This Emergency Response Plan does not have any tasks.');
          return;
        }
        var leaderName = 'N/A';
        var completedTasks = 0;
        let taskArr = []; // array of task objs associated with ERP

        for (let i = 0; i < tasks.length; i++) {
          const task = await tasks[i].get();
          let tdata = task.data();
          if (!tdata) return;
          tdata.title = 'N/A';

          if (tdata.isComplete) {
            completedTasks += 1;
            let idx = this.state.userList.findIndex(
              (item) => item.id === tdata.userCompleted.path.split('/')[1]
            );

            tdata.userCompletedName = this.state.userList[idx].name;
          }

          taskArr.push(tdata);

          if (i === tasks.length - 1) {
            this.setState({
              viewTasks: true,
              planRowID: index,
              planID: tuid,
              showBackArrow: true, // display the back arrow
              taskList: taskArr,
              planName: planName,
              numCompleted: completedTasks,
              totalNum: tasks.length,
              taskUserList: doc.data().users,
              leaderName: leaderName,
              breadCrumbs: 'Checklists > Task List',
              viewFilteredList: false
            });
          }

          // tasks[i].get().then((query) => {
          //   let tdata = query.data();
          //   if (tdata === undefined) {
          //     return; // make sure the task is defined
          //   }
          //   // adding user title from the ERP's userStatus Map
          //   let title = 'N/A';
          //   // if (tdata.assignedUser.id !== undefined && tdata.assignedUser.id !== null) {
          //   // 	title = this.state.planList[index].userStatus[tdata.assignedUser.id].title
          //   // }
          //   tdata.title = title;
          //   console.log(tdata.isComplete);
          //   if (tdata.isComplete) {
          //     completedTasks += 1;
          //     let idx = this.state.userList.findIndex(
          //       (item) => item.id === tdata.userCompleted.path.split('/')[1]
          //     );
          //     tdata.userCompletedName = this.state.userList[idx].name;
          //   }

          //   taskArr.push(tdata);
          //   if (i === tasks.length - 1) {
          //     this.setState({
          //       viewTasks: true,
          //       planRowID: index,
          //       planID: tuid,
          //       showBackArrow: true, // display the back arrow
          //       taskList: taskArr,
          //       planName: planName,
          //       numCompleted: completedTasks,
          //       totalNum: tasks.length,
          //       taskUserList: doc.data().users,
          //       leaderName: leaderName,
          //       breadCrumbs: 'Checklists > Task List',
          //       viewFilteredList: false
          //     });
          //   }
          // });
        }
      }
    });
    // console.log("viewTasksFromTemplate-2 > SPP: "+this.state.subPlanPager)/
    // console.log("viewTasksFromTemplate-2 > PP: "+this.state.planPager)
  }

  viewRollCall({ id }) {
    // console.log("viewRollCall > SPP: "+this.state.subPlanPager)
    // console.log("viewRollCall > PP: "+this.state.planPager)
    // use the plan ID to get all the users associated
    const planRef = db.collection('emergencyPlans').doc(id);
    planRef.get().then(async (doc) => {
      if (doc.exists) {
        let userArr = [];
        let planName = doc.data().name; // name of the current ERP
        let users = doc.data().users;
        let tmap = {}; // key = UID / val = # of tasks for user
        let cmap = {}; // key = UID / val = # of completed tasks for user
        let taskList = doc.data().tasks; // tasklist associated to the plan
        for (let task of taskList) {
          // iterate through all the tasks
          task.get().then((query) => {
            let data = query.data();
            let UID = data.assignedUser.id;
            if (tmap[UID]) {
              // assume tasks exists
              tmap[UID] += 1;
            }
            if (!(UID in tmap)) {
              // assume tasks not in tmap
              tmap[UID] = 1;
            }
            if (data.isComplete && cmap[UID]) {
              // assume the task is completed and in cmap
              cmap[UID] += 1;
            }
            if (data.isComplete && !(UID in cmap)) {
              // assume the task is complete but not in cmap
              cmap[UID] = 1;
            }
          });
        }
        var size = users.length; // get the number of users
        let userStatus = doc.data().userStatus;
        let count = 0;
        let numCheckedIn = 0; // # of checked in users
        // iterate through all the users in the plan to get their title/checkedIn values
        for (let UID in userStatus) {
          // access the User's Doc Ref
          const users = await db.collection('users').doc(UID).get();
          const data = users.data();

          if (userStatus[UID]['isCheckedIn']) {
            numCheckedIn += 1;
          }
          let currUser = {
            name: data.firstName + ' ' + data.lastName,
            title: userStatus[UID]['title'],
            isCheckedIn: userStatus[UID]['isCheckedIn'],
            numTasks: tmap[UID],
            numCompleted: cmap[UID],
            UID: UID // needed to update the checkIn Status of User
          };
          userArr.push(currUser);

          if (count === size - 1) {
            // assume the last user has been added to the rollcall
            this.setState({
              rollCallList: userArr,
              planName: planName,
              numCompleted: numCheckedIn,
              totalNum: size,
              planID: id, // need to update the correct ERP to be able to change checkinStatus of user
              viewRollCall: true,
              showBackArrow: true, // show the Back Arrow Button
              breadCrumbs: 'Checklists > Roll Call',
              viewFilteredList: false
            });
          }
          count += 1;

          // db.collection('users')
          //   .doc(UID)
          //   .get()
          //   .then((query) => {
          //     // to get the user's first/last name
          //     let data = query.data();
          //     if (userStatus[UID]['isCheckedIn']) {
          //       numCheckedIn += 1;
          //     }
          //     let currUser = {
          //       name: data.firstName + ' ' + data.lastName,
          //       title: userStatus[UID]['title'],
          //       isCheckedIn: userStatus[UID]['isCheckedIn'],
          //       numTasks: tmap[UID],
          //       numCompleted: cmap[UID],
          //       UID: UID // needed to update the checkIn Status of User
          //     };
          //     userArr.push(currUser);
          //     if (count === size - 1) {
          //       // assume the last user has been added to the rollcall
          //       this.setState({
          //         rollCallList: userArr,
          //         planName: planName,
          //         numCompleted: numCheckedIn,
          //         totalNum: size,
          //         planID: id, // need to update the correct ERP to be able to change checkinStatus of user
          //         viewRollCall: true,
          //         showBackArrow: true, // show the Back Arrow Button
          //         breadCrumbs: 'Checklists > Roll Call',
          //         viewFilteredList: false
          //       });
          //     }
          //     count += 1;
          //   });
        }
      }
    });
    // console.log("viewRollCall-2 > SPP: "+this.state.subPlanPager)
    // console.log("viewRollCall-2 > PP: "+this.state.planPager)
  }

  // when pageNum is 6, view the Reports
  async viewReports(action) {
    // console.log("View Reports");
    // console.log("viewReports > SPP: "+action.subPlanPager)
    // console.log("viewReports > PP: "+action.planPager)
    // sort the list into ascending order
    // console.log(typeof(this.state.planList))
    let planList = this.state.planList.sort((a, b) => {
      var nameA = a.name.toUpperCase();
      var nameB = b.name.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 1;
    });

    this.fetchPlanData(1);
    this.fetchRollCallData();
    this.fetchTemplateData();

    db.collection('tasks')
      .get()
      .then((querySnapshot) => {
        const taskList = querySnapshot.docs;

        // iterate through all the doc refs
        let taskArr = [];
        for (let task of taskList) {
          let tid = task.id;
          task = task.data();
          task.id = tid;

          taskArr.push(task);
        }
        this.setState({ taskList: taskArr });
      });
    // console.log("Tasks ended: ");
    // console.log("Plans ended: "+planList);

    this.setState({
      highlight: [
        'mainItem',
        'mainItem',
        'mainItem',
        'mainItem',
        'mainItem',
        'mainItem selectedItem',
        'mainItem'
      ],
      pageNum: 6,
      viewTasks: false,
      viewRollCall: false,
      showBackArrow: false, // hide the back arrow
      breadCrumbs: 'Reports',
      viewFilteredList: false,
      planList: planList,
      viewCompletedForms: false,
      viewChecklist: true,
      planPager: action.planPager ? action.planPager : 6, //Prev was 4, but changed to 6 by dafault
      subPlanPager: action.subPlanPager ? action.subPlanPager : 1,
      reportsHighlight:
        action.subPlanPager === 2
          ? ['bottomLine', 'topLine', 'bottomLine', 'bottomLine', 'bottomLine']
          : ['topLine', 'bottomLine', 'bottomLine', 'bottomLine', 'bottomLine']
    });

    //console.log("end of vie reports")
    // console.log("viewReports-2 > SPP: "+this.state.subPlanPager)
    // console.log("viewReports-2 > PP: "+this.state.planPager)
  }

  viewCompletedForms(action) {
    // console.log("viewCompletedForms > SPP: "+this.state.subPlanPager)
    // console.log("viewCompletedForms > PP: "+this.state.planPager)
    // console.log("View Completed Forms");
    // // sort the list into ascending order
    let cFormsList = [];
    // console.log(this.state.cFormsList[0].companyID)
    if (this.state.cFormsList !== undefined) {
      for (let i = 0; i < this.state.cFormsList.length; i++) {
        if (
          this.state.cFormsList[i].companyID === this.state.companyID &&
          this.state.role !== 'Root'
        ) {
          cFormsList.push(this.state.cFormsList[i]);
        }

        if (this.state.role === 'Root') {
          cFormsList.push(this.state.cFormsList[i]);
        }
      }
    } else {
      cFormsList = [...this.state.cFormsList];
    }

    // console.log(typeof(this.state.cFormsList));
    this.setState({
      highlight: [
        'mainItem',
        'mainItem',
        'mainItem',
        'mainItem',
        'mainItem',
        'mainItem',
        'mainItem selectedItem'
      ],
      pageNum: 6,
      showBackArrow: false, // hide the back arrow
      breadCrumbs: 'Reports',
      viewChecklist: false,
      viewRollCall: false,
      viewTasks: false,
      viewFilteredList: false,
      viewCompletedForms: true,
      cFormsList: cFormsList,
      planPager: action.planPager ? action.planPager : 7,
      reportsHighlight: [
        'bottomLine',
        'bottomLine',
        'bottomLine',
        'bottomLine',
        'topLine'
      ]
    });

    // console.log("viewCompletedForms-2 > SPP: "+this.state.subPlanPager)
    // console.log("viewCompletedForms-2 > PP: "+this.state.planPager)
  }

  fetchCompletedFormsData() {
    let cfArr = [];
    let cfUserMap = {};
    let cfFormMap = {};
    let i = 0,
      j = 0;
    let userRef;

    db.collection('completedForms')
      .get()
      .then((querySnapshot) => {
        const cfList = querySnapshot.docs;
        // console.log(cfList);
        // iterate through all the doc refs
        for (let cf of cfList) {
          cf = cf.data();
          cfArr.push(cf);

          cfUserMap[i] = cf.user !== undefined ? cf.user.id : 'undefined';
          i++;
          cfFormMap[j] = cf.form !== undefined ? cf.form.id : 'undefined';
          j++;
        }
        //update the array with all needed
        for (i = 0; i < cfArr.length; i++) {
          cfArr[i]['user'] = cfUserMap[i];
          cfArr[i]['form'] = cfFormMap[i];
        }

        //get the user information from usertable using cfusermap doc reference
        for (let item of cfArr) {
          if (item['user'] !== undefined) {
            userRef = db.collection('users').doc(item['user']);
            userRef
              .get()
              .then((querySnapshot) => {
                if (querySnapshot.exists) {
                  const ulist = querySnapshot.data();

                  // if (this.state.role !== 'Root' && (ulist.companyID == this.state.companyID))  {
                  // 		item['name'] = ulist.firstName+' '+ulist.lastName
                  //     	item['email'] = ulist.email
                  //     	item['phoneNumber'] = ulist.phoneNumber
                  //     	item['profilePicture'] = ulist.profilePicture
                  //     	item['companyID'] = ulist.companyID

                  // }
                  // if(this.state.role === 'Root')
                  // {
                  item['name'] = ulist.firstName + ' ' + ulist.lastName;
                  item['email'] = ulist.email;
                  item['phoneNumber'] = ulist.phoneNumber;
                  item['profilePicture'] = ulist.profilePicture;
                  item['companyID'] = ulist.companyID;

                  // }
                }
              })
              .catch((error) => {
                if (this.state.isLoggedIn)
                  console.log('Error getting document:', error);
                else {
                  console.log('The user is logged out automatically');
                }
              });
          }
        }

        // /console.log(cfArr);
        // update State of Completed Forms array list
        this.setState({
          cFormsList: cfArr
        });

        // console.log("fetchCompletedFormsData"+typeof(this.state.cFormsList));
      });
  }

  fetchformsData() {
    let formArr = [];
    let i = 0;
    db.collection('forms')
      .get()
      .then((querySnapshot) => {
        const formList = querySnapshot.docs;

        for (let form of formList) {
          let fId = form.id;
          form = form.data();
          form.id = fId;
          formArr.push(form);

          // get the index of it in the formArr
          let idx = formArr.indexOf(form);
          if (form.companies !== undefined && form.companies.length !== '') {
            for (let companies of form.companies) {
              formArr[idx]['companies'][i] = companies.id;
              i++;
            }
          }
        }

        this.setState({
          formList: formArr
        });
      });
    // .then(() => {
    //   console.log('forms fetched...');
    // });
  }

  fetchUserData() {
    // console.log("Userdata fetch ");
    var userMap = {};
    let taskArr = [];
    db.collection('tasks')
      .get()
      .then((querySnapshot) => {
        const taskList = querySnapshot.docs;
        // iterate through all the doc refs
        for (let task of taskList) {
          let tid = task.id;
          task = task.data();
          task.id = tid;
          taskArr.push(task);
          if (
            task.assignedUser !== undefined &&
            task.assignedUser.length !== ''
          ) {
            // assume the assigned user is a doc ref
            if (!(task.assignedUser.id in userMap)) {
              // assume userMap doesnt contain the user yet
              userMap[task.assignedUser.id] = 1;
            }
            if (task.assignedUser.id in userMap) {
              // assume userMap contains the user
              userMap[task.assignedUser.id] += 1;
            }
          }
        }
      });
    let gUserList = [];
    let deletedUsersArr = [];
    if (this.state.role === 'Manager') {
      let userArr = [];
      // assume current user is a manager
      let umap = {}; // hash map of UIDs so we dont have any duplicates
      let userRef = db.collection('users').doc(this.state.uid);
      // query all users who are in the manager's userGroups
      db.collection('userGroups')
        .where('users', 'array-contains', userRef)
        .get()
        .then((querySnapshot) => {
          const userGroups = querySnapshot.docs;
          for (let i = 0; i < userGroups.length; i++) {
            let ulist = userGroups[i].data().users;
            for (let j = 0; j <= ulist.length; j++) {
              // iterate through all the userRefs in a group
              let curruser = ulist[j];
              if (curruser !== undefined && !(curruser.id in umap)) {
                // assume user is not a duplicate
                curruser.get().then((query) => {
                  let UID = curruser.id; // get the UID of the user
                  let user = query.data(); // access the data of the user doc ref
                  // iterating through the users and normalizing the data
                  // so that each user has the necessary key/value pairs
                  user.id = UID; // assign the id field to the UID
                  gUserList.push(user);
                  user.name = user.firstName + ' ' + user.lastName;
                  user.phone = user.phoneNumber + '';
                  user.phone = user.phone.replace('(', '');
                  user.phone = user.phone.replace(')', '');
                  user.phone = user.phone.replace(' ', '');
                  user.phone = user.phone.replace('--', '-');

                  let user_type = 'SMS';
                  if (user.fcmToken !== undefined && user.fcmToken != null) {
                    let android = false;
                    let iOS = false;
                    if (user.fcmToken.android !== undefined) {
                      if (user.fcmToken.android[0] !== undefined) {
                        android = true;
                      }
                    }
                    if (user.fcmToken.iOS !== undefined) {
                      if (user.fcmToken.iOS[0] !== undefined) {
                        iOS = true;
                      }
                    }

                    if (android || iOS) {
                      user_type = 'APP';
                    }
                  }
                  user.user_type = user_type;
                  console.log(user_type);
                  // assume the signed in user is a super admin and the companyID doesnt match
                  if (
                    this.state.role !== 'Root' &&
                    user.companyID !== this.state.companyID
                  )
                    return;
                  // assume the signed in user is a super admin and the current user is a root
                  if (this.state.role !== 'Root' && user.role === 'Root')
                    return;
                  user.tasks = 0;
                  if (UID in userMap) {
                    user.tasks = userMap[UID];
                  }
                  if (
                    !('role' in user) ||
                    user.role.toLowerCase() === 'user' ||
                    user.role.length === 0
                  ) {
                    user.role = 'User';
                  }
                  // users that either do not have a profile picture, or their profile pciture is a document reference
                  if (
                    !user.profilePicture ||
                    user.profilePicture.length <= 45 ||
                    user.profilePicture.substr(0, 5) === 'users'
                  ) {
                    // user.profilePicture = image
                    user.profilePicture = PlaceholderImage;
                    //image set here
                  }
                  if (user.deleted) {
                    // user is deleted
                    user.role = 'User';
                    deletedUsersArr.push(user);
                  }
                  if (!user.deleted) {
                    // user is not deleted
                    userArr.push(user);
                  }
                  if (i === userGroups.length - 1 && j === ulist.length - 1) {
                    // assume iterated through all the userGroups
                    userArr.sort((a, b) => {
                      var nameA = a.name.toUpperCase();
                      var nameB = b.name.toUpperCase();
                      if (nameA < nameB) return -1;
                      if (nameA > nameB) return 1;
                      return 0;
                    });
                    // update State of Userlist and deletedUsers
                    // console.log(gUserList)
                    this.setState({
                      userList: userArr,
                      deletedUsers: deletedUsersArr,
                      gUserList: gUserList,
                      taskList: taskArr
                    });
                  }
                });
                umap[curruser.id] = 1; // map of UIDs
              }
            }
          }
        })
        .catch((error) => console.log('Error getting documents: ', error));
    } else {
      let userArr = [];
      // console.log("Users collection function");
      // assume curr user is super admin or root admin
      db.collection('users')
        .get()
        .then((querySnapshot) => {
          const userList = querySnapshot.docs;
          // iterate through all the doc refs
          for (let user of userList) {
            let UID = user.id; // get the UID of the user
            user = user.data(); // access the data of the user doc ref
            // iterating through the users and normalizing the data
            // so that each user has the necessary key/value pairs
            user.id = UID; // assign the id field to the UID
            gUserList.push(user);
            user.name = user.firstName + ' ' + user.lastName;
            user.phone = user.phoneNumber + '';
            user.phone = user.phone.replace('(', '');
            user.phone = user.phone.replace(')', '');
            user.phone = user.phone.replace(' ', '');
            user.phone = user.phone.replace('--', '-');

            let user_type = 'SMS';
            if (user.fcmToken !== undefined && user.fcmToken != null) {
              let android = false;
              let iOS = false;
              if (user.fcmToken.android !== undefined) {
                if (user.fcmToken.android[0] !== undefined) {
                  android = true;
                }
              }
              if (user.fcmToken.iOS !== undefined) {
                if (user.fcmToken.iOS[0] !== undefined) {
                  iOS = true;
                }
              }

              if (android || iOS) {
                user_type = 'APP';
              }
            }
            user.user_type = user_type;

            // assume the signed in user is a super admin and the companyID doesnt match
            if (
              this.state.role !== 'Root' &&
              user.companyID !== this.state.companyID
            )
              continue;
            // assume the signed in user is a super admin and the current user is a root
            if (this.state.role !== 'Root' && user.role === 'Root') continue;
            user.tasks = 0;
            if (UID in userMap) {
              user.tasks = userMap[UID];
            }
            if (
              !('role' in user) ||
              user.role.toLowerCase() === 'user' ||
              user.role.length === 0
            ) {
              user.role = 'User';
            }
            // users that either do not have a profile picture, or their profile pciture is a document reference
            if (
              !user.profilePicture ||
              user.profilePicture.length <= 45 ||
              user.profilePicture.substr(0, 5) === 'users'
            ) {
              // user.profilePicture = image
              user.profilePicture = PlaceholderImage;
            }
            if (user.deleted) {
              user.role = 'User';
              deletedUsersArr.push(user);
              continue;
            }

            userArr.push(user);
          }
          userArr = userArr.sort((a, b) => {
            var nameA = a.name.toUpperCase();
            var nameB = b.name.toUpperCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          });
          // update State of Userlist and deletedUsers
          this.setState({
            userList: userArr,
            deletedUsers: deletedUsersArr,
            gUserList: gUserList,
            taskList: taskArr
          });
        });
    }
  }

  fetchClientData() {
    let cmap = {}; // get number of checklists for each client
    db.collection('emergencyPlans')
      .get()
      .then((querySnapshot) => {
        const clist = querySnapshot.docs.map((doc) => doc.data());
        for (let item of clist) {
          // console.log("item:"+item.companyID);
          // make sure that the emergencyPlan is a doc ref and the task is complete
          if (item.companyID !== undefined) {
            if (cmap[item.companyID] && item.isActive) {
              cmap[item.companyID] += 1;
            }
            if (!(item.companyID in cmap)) {
              cmap[item.companyID] = 1;
            }
          }

          // console.log("cmap:"+cmap[item.companyID]+" "+item.name+" "+item.isActive);
        }
      });
    let clientArr = [];
    let deletedClientArr = [];
    db.collection('companies')
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        for (let client of data) {
          client.companyID = client.id;
          // client.plans = client.emergencyPlans.length
          let features = {
            meeting: true,
            messages: true,
            library: true,
            emergencyChecklist: true,
            teams: true,
            flash: true,
            forms: true
          };
          if (client.features === undefined) {
            client.features = features;
          }
          if (client.isTrial !== undefined) {
            client.isTrial = true;
          } else {
            client.isTrial = false;
          }
          client.plans = 0;
          if (
            cmap[client.companyID] !== undefined &&
            cmap[client.companyID] !== null
          ) {
            client.plans = cmap[client.companyID];
          }

          if (
            client.logo === undefined ||
            client.logo === null ||
            client.logo.length <= 45
          ) {
            client.logo = image;
          }
          if ('pocEmail' in client) {
            client.email = client.pocEmail;
            client.phoneNumber = client.pocPhoneNumber;
            if (client.clientName === undefined || client.clientName === null) {
              client.clientName = client.name;
            }
          }
          // assume the client has been marked as deleted
          if (client.deleted) {
            deletedClientArr.push(client);
          } else {
            // add client to an array
            clientArr.push(client);
          }
        }
        clientArr.sort((a, b) => {
          var nameA = a.clientName.toUpperCase();
          var nameB = b.clientName.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;

          return 1;
        });

        // update client list state
        this.setState({
          clientList: clientArr,
          deletedClients: deletedClientArr
        });
      });
  }
  // console.log("planArr :"+planArr);
  fetchPlanData(reports = 0) {
    let tmap = {}; // key: ERP id / value: number of completed tasks
    db.collection('tasks')
      .get()
      .then((querySnapshot) => {
        const tasks = querySnapshot.docs.map((doc) => doc.data());
        for (let task of tasks) {
          // make sure that the emergencyPlan is a doc ref and the task is complete
          if (task.emergencyPlan !== undefined && task.isComplete) {
            if (tmap[task.emergencyPlan.id]) {
              tmap[task.emergencyPlan.id] += 1;
            }
            if (!(task.emergencyPlan.id in tmap)) {
              tmap[task.emergencyPlan.id] = 1;
            }
          }
        }
        return tmap;
      })
      .then((tmap) => {
        // add the number of complete plans
        let planArr = [];
        db.collection('emergencyPlans')
          .get()
          .then((querySnapshot) => {
            const plans = querySnapshot.docs;
            for (let i = 0; i < plans.length; i++) {
              let item = plans[i];
              const planID = item.id; // ERP's id
              item = item.data();
              // TODO: not checking be leader fields is missing in some docs
              item.leaderName = 'N/A';
              let idx = this.state.userList.findIndex(
                (user) => user.id === item.leader.id
              );
              if (idx !== -1) {
                item.leaderName = this.state.userList[idx].name;
              }
              let userArr = [];
              for (let i = 0; i < item.users.length; i++) {
                let uid = item.users[i].id;
                for (let j = 0; j < this.state.userList.length; j++) {
                  if (this.state.userList[j].id === uid) {
                    userArr.push(this.state.userList[j].name);
                  }
                  if (
                    i === item.users.length - 1 &&
                    j === this.state.userList.length - 1
                  ) {
                    item.userNames = userArr.join(', ');
                  }
                }
              }
              // normalize the plans so they have all the necessary fields
              if (!item.hasOwnProperty('deactivated')) {
                item.deactivated = false;
              }
              if (!item.hasOwnProperty('isArchived')) {
                item.isArchived = false;
              }

              item.dateDeactivatedSec = 0;
              if (item.dateDeactivated) {
                item.dateDeactivatedString = new Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
                }).format(item.dateDeactivated.seconds * 1000);
                item.dateDeactivatedSec = item.dateDeactivated.seconds;
              }
              item.dateActivatedSec = 0;
              if (item.dateActivated) {
                item.dateActivatedString = new Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
                }).format(item.dateActivated.seconds * 1000);
                item.dateActivatedSec = item.dateActivated.seconds;
              }

              item.completedTasks = tmap[planID];

              //completion percentage

              let numTasks = 0;
              let numCompleted = 0;
              let percent_completed = 0;
              if ('tasks' in item && item.tasks !== undefined) {
                numTasks = item.tasks.length;
              }

              if (
                'completedTasks' in item &&
                item.completedTasks !== undefined
              ) {
                //assume the ERP has completed tasks
                numCompleted = item.completedTasks;
              }

              percent_completed = isNaN(numCompleted / numTasks)
                ? 0
                : Math.round((numCompleted / numTasks) * 100);

              item.percent_completed = percent_completed;

              //Status Strings

              let status_string = 'Active';
              if (
                item.isArchived === true ||
                (item.isArchived === true && item.deactivated === true)
              ) {
                status_string = 'Archived';
              } else if (
                item.deactivated === true &&
                (item.isArchived === undefined ||
                  item.isArchived === null ||
                  item.isArchived === false)
              ) {
                status_string = 'Deactivated';
              }

              item.status_string = status_string;

              // TODO: please make sure craun is adding leaders to the emergencyPlans
              // assume the leader has been defined (Doc Ref)
              if (
                this.state.role === 'Root' &&
                item.isActive &&
                !reports &&
                !item.isArchived
              ) {
                // assume the user is a Root and the ERP is from a different companyID
                planArr.push(item);
              } else if (this.state.role === 'Root' && reports) {
                planArr.push(item);
              }

              if (
                this.state.role !== 'Root' &&
                this.state.companyID === item.companyID &&
                item.isActive &&
                !reports &&
                !item.isArchived
              ) {
                // assume the user is a Super Admin and the ERP is from the same companyID
                planArr.push(item);
              } else if (
                this.state.role !== 'Root' &&
                this.state.companyID === item.companyID &&
                reports
              ) {
                // assume the user is a Super Admin and the ERP is from the same companyID
                planArr.push(item);
              }
              if (i === plans.length - 1) {
                // console.log(this.state.planPager);
                if (this.state.planPager === 4) {
                  planArr = planArr.sort((a, b) => {
                    let secA = a.dateActivatedSec;
                    let secB = b.dateActivatedSec;
                    if (secA > secB) return -1;
                    if (secA < secB) return 1;
                    return 1;
                  });
                } else {
                  planArr = planArr.sort((a, b) => {
                    var nameA = a.name.toUpperCase();
                    var nameB = b.name.toUpperCase();
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 1;
                  });
                }

                this.setState({ planList: planArr });
              }
            }
          });
      });
  }

  fetchUserGroupData() {
    let groupArr = [];
    db.collection('userGroups')
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs;
        let groupID; // get the UID of the doc ref
        // iterate through all the doc refs
        for (let group of data) {
          groupID = group.id; // get the UID of the user
          group = group.data(); // access the data of the user doc ref)
          if (
            this.state.role !== 'Root' &&
            group.companyID !== this.state.companyID
          ) {
            continue; // for super admins, filter out groups w diff companyIDs
          }
          // get the id of the group
          group.id = groupID;
          groupArr.push(group);
        }
        groupArr = groupArr.sort((a, b) => {
          var nameA = a.name.toUpperCase();
          var nameB = b.name.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 1;
        });

        this.setState({ groupList: groupArr });
      });
  }

  fetchLibraryLogData() {
    db.collection('libraryLog')
      .get()
      .then((querySnapshot) => {
        const loggedInfo = querySnapshot.docs.map((doc) => doc.data());
        let itemArr = [];

        for (let item of loggedInfo) {
          itemArr.push(item);
        }
        this.setState({ loggedData: itemArr });
        // console.log(itemArr)
      });
  }

  // Iterating through the array and creating JSON elements to populate
  // the table. The path is which repo the user is currently looking at.
  // The fullPath is what the path will look like if the row is clicked.
  fetchKnowledgeData() {
    db.collection('repoInfo')
      .get()
      .then((querySnapshot) => {
        // ?? this fetches ALL repoInfo docs, not just the ones for the current company - why??
        const repoInfo = querySnapshot.docs.map((doc) => doc.data());
        console.log('repoInfo records retrieved: ', repoInfo.length);

        let itemArr = [];
        let folderArr = []; // all the folders in every client directory used for upload file
        let currPath;
        for (let item of repoInfo) {
          /* assume that the item was marked as deleted and
           * only display deleted folders for root users */
          // if(item.deleted) continue;

          if (item.deleted && this.state.role !== 'Root') continue;
          if (item.folder && item.fullPath.split('/').length === 3)
            folderArr.push(item);
          if (item.fullPath === undefined) continue;

          currPath = item.fullPath.split('/');
          currPath.pop();
          currPath = currPath.join('/');
          // ensuring that the correct files are displayed
          if (this.state.path === currPath) {
            itemArr.push(item);
            // console.log(item.deleted+"--"+item.itemName+"---"+this.state.role);
          }
        }
        // console.log('itemArr: ', itemArr);
        // console.log('folderArr: ', folderArr);

        this.setState({ repository: itemArr, folderRepos: folderArr });
        // console.log(itemArr)
      });
  }

  vaccineSettings() {
    let clients = this.state.clientList;
    let currentcompany = [];
    for (let c of clients) {
      if (c.id === this.state.companyID) {
        currentcompany.push(c);
      }
    }
    this.setState({
      highlight: [
        'mainItem',
        'mainItem',
        'mainItem',
        'mainItem',
        'mainItem',
        'mainItem',
        'mainItem selectedItem'
      ],
      pageNum: 8,
      vaccineClient: currentcompany
    });
  }

  featureSettings() {
    let clients = this.state.clientList;
    this.setState({
      highlight: [
        'mainItem',
        'mainItem',
        'mainItem',
        'mainItem',
        'mainItem',
        'mainItem',
        'mainItem selectedItem'
      ],
      pageNum: 9,
      clientList: clients
    });
  }

  toggleFeatureSettings(featureKey, companyID) {
    // update the table

    let client = null;
    let clientList = this.state.clientList;
    let i = 0;
    let idx = 0;
    clientList.forEach((c) => {
      if (c.id === companyID) {
        client = c;
        idx = i;
      }
      i++;
    });

    if (client !== null) {
      let toggle = true;
      if (featureKey === 'meeting') {
        toggle = !client.features.meeting;
        clientList[idx].features.meeting = toggle;
      }
      if (featureKey === 'messages') {
        toggle = !client.features.messages;
        clientList[idx].features.messages = toggle;
      }
      if (featureKey === 'library') {
        toggle = !client.features.library;
        clientList[idx].features.library = toggle;
      }
      if (featureKey === 'emergencyChecklist') {
        toggle = !client.features.emergencyChecklist;
        clientList[idx].features.emergencyChecklist = toggle;
      }
      if (featureKey === 'teams') {
        toggle = !client.features.teams;
        clientList[idx].features.teams = toggle;
      }
      if (featureKey === 'flash') {
        toggle = !client.features.flash;
        clientList[idx].features.flash = toggle;
      }
      if (featureKey === 'forms') {
        toggle = !client.features.forms;
        clientList[idx].features.forms = toggle;
      }

      this.setState({ clientList: clientList });

      const clientRef = db.collection('companies').doc(companyID);
      clientRef
        .update({ features: clientList[idx].features })
        .then(() => {
          console.log('Document successfully updated!');
          this.getCompanySettings(companyID);
        })
        .catch((error) => console.error('Error updating document: ', error));
    }
  }

  async clientSettings() {
    let formSettingsArr = [];
    // let forms = [];

    await this.fetchformsData();
    // combined list of companies, forms and users under that companies into one
    for (let clientInfo of this.state.clientList) {
      for (let form of this.state.formList) {
        formSettingsArr.push(clientInfo);
        let idx = parseInt(formSettingsArr.indexOf(clientInfo));
        if (form.companies.includes(clientInfo.companyID)) {
          if (formSettingsArr[idx]['forms'] !== undefined) {
            formSettingsArr[idx]['forms'].push(form.id);
          } else {
            formSettingsArr[idx]['forms'] = [form.id];
          }
        } else {
          formSettingsArr[idx]['forms'] = [];
        }
      }
    }

    this.setState(
      {
        highlight: [
          'mainItem',
          'mainItem',
          'mainItem',
          'mainItem',
          'mainItem',
          'mainItem',
          'mainItem selectedItem'
        ],
        pageNum: 7,
        formSettings: formSettingsArr
      },
      () => console.log('')
    );
  }

  toggleForm(row) {
    const idx = row.id;
    const compID = this.state.clientList[idx].id;
    let formList = [...this.state.formList];
    let formSettings = [...this.state.formSettings];
    // let formId = [{ forms: row.formId }];

    for (let form of formList) {
      //just add that in the company list of formlist
      if (
        (form.title === 'Daily Health Screener' || form.id === row.fromId) &&
        row.toggleValue === 1
      ) {
        if (!form['companies'].includes(compID)) {
          form['companies'].push(compID);
        }
      } else if (
        (form.title === 'Daily Health Screener' || form.id === row.fromId) &&
        row.toggleValue === 0
      ) {
        const index = form['companies'].indexOf(compID);
        if (index > -1) {
          form['companies'].splice(index, 1);
        }
      }
    }
    //formsettings is not getting populated well: TODO

    //just add the form id into the formsettings in that specific company id : TODO

    for (let item of formSettings) {
      // console.log(item)
      if (row.toggleValue === true) {
        if (item['companyID'] === compID) {
          if (
            item['forms'] !== undefined &&
            !item['forms'].includes(row.formId)
          ) {
            item['forms'].push(row.formId);
          } else {
            item['forms'] = [row.formId];
          }
        }
      } else {
        if (item['companyID'] === compID && item['forms'] !== undefined) {
          for (let id of item['forms']) {
            if (id === row.formId) {
              const index = item['forms'].indexOf(row.formId);
              // console.log(index)
              if (index > -1) item['forms'].splice(index, 1);
            }
          }
        }
      }
    }

    // console.log(formList)

    this.setState({ formList: formList });
    this.setState({ formSettings: formSettings });

    let refArr = [];

    //alter the db with the operaation depending on what the togglevalue is
    for (let item of this.state.formList) {
      for (let comp of item['companies']) {
        if (comp !== undefined && comp !== null)
          refArr.push(db.collection('companies').doc(comp));
      }
    }
    if (
      (refArr !== undefined || refArr !== null) &&
      refArr.length > 0 &&
      (row.formId !== undefined || row.formId !== null)
    ) {
      db.collection('forms')
        .doc(row.formId)
        .update({ companies: refArr })
        .then(() => console.log('Document successfully updated!'))
        .catch((error) => console.error('Error updating document: ', error));
    }

    this.getCompanySettings(this.state.companyID);
  }
  /******************************* User List Functions **********************************/
  handleClientFilterChange({ name }) {
    if (name !== '') {
      let idx = this.state.clientList.findIndex(
        (client) => client.clientName === name
      );
      if (idx !== -1) {
        const companyID = this.state.clientList[idx].id;
        let userList = [];
        for (let i = 0; i < this.state.userList.length; i++) {
          if (this.state.userList[i].companyID === companyID) {
            userList.push(this.state.userList[i]);
          }
        }
        this.setState({
          viewFilteredList: true,
          filteredList: userList
        });
      }
    } else {
      this.setState({
        viewFilteredList: false,
        filterecdList: []
      });
    }
  }
  // Remove a user from the list
  // retrieves the index from the UserTable class
  // marks the user as deactived so the user does not show up in the list
  deleteUser(row) {
    const deleteIndex = row.id;
    const deletedUser = this.state.userList[deleteIndex];
    // get the UID of the user that is being deleted
    const UID = deletedUser.id;
    const deletedUserCompanyID = deletedUser.companyID;

    this.state.userList.splice(deleteIndex, 1);
    this.setState({
      userList: this.state.userList,
      deletedUsers: [deletedUser, ...this.state.deletedUsers]
    });
    // mark the user as deleted
    db.collection('users')
      .doc(UID)
      .set(
        {
          deleted: true,
          deactivated: true
        },
        { merge: true }
      )
      .then(() => {
        let id = this.state.clientList.findIndex(
          (client) => client.companyID === deletedUserCompanyID
        );
        let licensesUsed = this.state.clientList[id].licensesUsed;
        let adminLicensesUsed = this.state.clientList[id].adminLicensesUsed;

        let license_obj = {
          licensesUsed: licensesUsed > 0 ? licensesUsed - 1 : 0
        };

        if (deletedUser.role === 'Super Admin') {
          license_obj = {
            adminLicensesUsed: adminLicensesUsed > 0 ? adminLicensesUsed - 1 : 0
          };
        }

        db.collection('companies')
          .doc(deletedUserCompanyID)
          .update(license_obj)
          .then(() => {
            this.fetchClientData();
          });
      });

    let arr = [];
    arr.push({
      document: this.state.userList[deleteIndex].id,
      logType: 'deleted',
      source: 'web-console',
      oldValue: false,
      newValue: true
    });

    this.createUserLog(arr[0]);
  }

  reactivateUser(row) {
    // properties: index, UID
    const user = this.state.deletedUsers[row.index];
    const companyID = user.companyID;
    let id = this.state.clientList.findIndex(
      (client) => client.companyID === companyID
    );

    if (
      this.state.clientList[id].licensesAssigned >
      this.state.clientList[id].licensesUsed
    ) {
      user.deactivated = false;
      this.state.deletedUsers.splice(row.index, 1);
      this.setState({
        deletedUsers: this.state.deletedUsers,
        userList: [user, ...this.state.userList]
      });
      // reactivate the user
      db.collection('users')
        .doc(row.UID)
        .set(
          {
            deleted: false,
            deactivated: false
          },
          { merge: true }
        )
        .then(() => {
          db.collection('companies')
            .doc(companyID)
            .update({
              licensesUsed: this.state.clientList[id].licensesUsed + 1
            })
            .then(() => {
              this.fetchClientData();
            });
        });
    } else {
      alert(
        'You have used all available licenses, please delete a user or contact your sales rep to add more to your account'
      );
    }
  }

  reactivateClient(row) {
    // properties: index, id
    const client = this.state.deletedClients[row.index];
    client.deactivated = false;
    this.state.deletedClients.splice(row.index, 1);
    this.setState({
      deletedClients: this.state.deletedClients,
      clientList: [client, ...this.state.clientList]
    });
    // reactivate the user
    db.collection('companies').doc(row.id).set(
      {
        deleted: false,
        deactivated: false
      },
      { merge: true }
    );
  }

  toggleUser(row) {
    let userIdx = row.index;

    if (row.email) {
      /** Prior dev comment:
       * if email exist that means the id is from gUserList and that will not match the userList hence us ethe email to get the idx of the UserList
       */
      userIdx = this.state.userList.findIndex(
        (user) => user.email === row.email
      );
    }

    const user = this.state.userList[userIdx];

    const currDeactivatedVal = user.deactivated;
    const newDeactivatedVal = !user.deactivated;

    let userList = this.state.userList;
    userList[userIdx].deactivated = newDeactivatedVal;

    this.setState({ userList: userList });

    // update the user data in Firestore
    var userRef = db.collection('users').doc(user.id);
    userRef
      .update({ deactivated: newDeactivatedVal })
      .then(() => {
        console.log('User successfully updated!');

        if (user.role === 'Super Admin' && newDeactivatedVal) {
          updateCompanyLicenses(user.companyID, 'decrement', true);

          if (user.fcmToken) {
            updateCompanyLicenses(user.companyID, 'decrement', false);
          }
          return;
        }

        if (user.role === 'Super Admin' && !newDeactivatedVal) {
          updateCompanyLicenses(user.companyID, 'increment', true);

          if (user.fcmToken) {
            updateCompanyLicenses(user.companyID, 'increment', false);
          }
          return;
        }

        if (user.role === 'User' && user.user_type === 'SMS') {
          return;
        }

        if (
          user.role === 'User' &&
          newDeactivatedVal &&
          user.user_type === 'APP'
        ) {
          updateCompanyLicenses(user.companyID, 'decrement', false);
          return;
        }

        if (
          user.role === 'User' &&
          !newDeactivatedVal &&
          user.user_type === 'APP'
        ) {
          updateCompanyLicenses(user.companyID, 'increment', false);
          return;
        }
      })
      .catch((error) => console.error('Error updating user: ', error));

    let arr = [];
    arr.push({
      document: this.state.userList[userIdx].id,
      logType: 'toggled',
      source: 'web-console',
      oldValue: currDeactivatedVal,
      newValue: newDeactivatedVal
    });

    this.createUserLog(arr[0]);
  }

  // Only updates the data in Cloud Firestore
  // Does not edit the Firebase Auth DB
  async editUser(row) {
    try {
      const userIdx = row.index;
      let userList = this.state.userList;
      const user = userList[userIdx];
      const userID = user.id;
      const roleHasChanged = row.role !== userList[userIdx].role;

      if (roleHasChanged) {
        let clientIdx = this.state.clientList.findIndex(
          (client) => client.companyID === userList[userIdx].companyID
        );
        const client = this.state.clientList[clientIdx];
        // if user is changed from user => admin,
        // then increment adminLicensesUsed
        if (row.role === 'Super Admin') {
          if (client.adminLicensesUsed < client.adminLicensesAssigned) {
            updateCompanyLicenses(client.companyID, 'increment', true);
          } else {
            alert('All available admin licenses are currently in use.');
          }
        }

        if (row.role === 'User') {
          if (client.licensesUsed < client.licensesAssigned) {
            updateCompanyLicenses(client.companyID, 'decrement', true);
          } else {
            alert('All available licenses are currently in use.');
          }
        }

        this.fetchClientData();
      }

      /**
       * Todo: implement email update for users
       * also requires update to user's Auth record
       * because email is used as the unique identifier
       * */

      // TODO: Add new data fields here???
      // console.log(row.emergencyName);
      userList[userIdx].name = row.name;
      userList[userIdx].email = row.email;
      userList[userIdx].phone = row.phone;
      userList[userIdx].emergencyPhone = row.emergencyPhone;
      userList[userIdx].emergencyName = row.emergencyName;
      userList[userIdx].password = row.password;
      userList[userIdx].role = row.role;
      // console.log("UL:" + userList);
      let nameArr = row.name.split(' ');
      let first = nameArr[0];
      nameArr.shift();
      let last = nameArr.join(' ');
      // edit the user data in Firestore

      const userRef = db.collection('users').doc(userID);

      // profile picture did not change
      // TODO: Add new fields here??? Can they be changed on form?
      let updatedUser = {
        email: row.email,
        firstName: first,
        lastName: last,
        phoneNumber: row.phone + '',
        emergencyPhone: row.emergencyPhone + '',
        emergencyName: row.emergencyName,
        password: row.password,
        role: row.role
      };

      if (row.profilePicture === '') {
        // updates the state
        this.setState({ userList: userList });
        userRef
          .update(updatedUser)
          .then(() => console.log('Document successfully updated!'))
          .catch((error) => console.error('Error updating document: ', error));
      }
      // if the user is changing the profile picture
      else {
        // uploading the new profile picture to firebase
        const uploadTask = storageRef
          .child(`users/${userID}/${userID}.jpeg`)
          .put(row.profilePicture);
        uploadTask.on('state_changed', null, null, () => {
          // got the URL of the firebase storage image
          uploadTask.snapshot.ref.getDownloadURL().then((url) => {
            // updates the users profile picture in the UserList
            userList[userIdx].profilePicture = url;
            // updates the state
            this.setState({ userList: userList });
            updatedUser.profilePicture = url;
            userRef
              .update(updatedUser)
              .then(() => console.log('Document successfully updated!'))
              .catch((error) =>
                console.error('Error updating document: ', error)
              );
          });
          console.log(
            'Successfully stored and retrieve the url from the image'
          );
        });
      }
    } catch (error) {
      console.log('Error editing user: ', error);
    }
  }

  async getUserLocation(user_id) {
    let location = '0';
    let locationTime = '0';
    let locationAddress = '';

    let idx = this.state.userList.findIndex((user) => user.id === user_id);
    let user_info = this.state.userList[idx];

    if (user_info['fcmToken'] !== undefined && user_info['fcmToken'] !== null) {
      // assume the fcmToken is defined
      // if(user_info.fcmToken.android){
      let tokensList = [];
      let req = '';

      if (user_info.fcmToken.android) {
        if (user_info.fcmToken.android.length > 0) {
          tokensList = Object.values(user_info.fcmToken.android);
          req = 'requestLocationUpdateAndroid';
        }
      }
      if (user_info.fcmToken.iOS) {
        if (user_info.fcmToken.iOS.length > 0) {
          tokensList = Object.values(user_info.fcmToken.iOS);
          req = 'requestLocationUpdateApple';
        }
      }
      // execute the firebase function
      let data = {
        users: [tokensList]
      };
      const send = firebaseFns.httpsCallable(req);
      // const send = firebase.functions().httpsCallable(req);
      await send(data)
        .then(async (result) => {
          const userRef = db.collection('users').doc(user_id);

          await userRef.get().then((doc) => {
            if (doc.exists) {
              let values = doc.data();
              location = values.location;
              locationTime = values.locationTime;
              locationAddress = values.locationAddress;
            }
          });
        })
        .catch((error) => console.log('Error: ', error));
      // }
    }

    return {
      location: location,
      locationTime: locationTime,
      locationAddress: locationAddress
    };
  }

  checkNewUser(user) {
    let error = -1;
    if (!user.hasOwnProperty('companyID')) {
      error = 'companyID header is missing';
      return error;
    }
    if (!user.hasOwnProperty('firstName')) {
      error = 'firstName header is missing';
      return error;
    }
    if (!user.hasOwnProperty('lastName')) {
      error = 'lastName header is missing';
      return error;
    }
    if (!user.hasOwnProperty('phoneNumber')) {
      error = 'phoneNumber header is missing';
      return error;
    }
    if (!user.hasOwnProperty('role')) {
      error = 'role is missing';
      return error;
    }
    if (!user.hasOwnProperty('password')) {
      error = 'password is missing';
      return error;
    }
    if (!user.hasOwnProperty('email')) {
      error = 'email is missing';
      return error;
    }
    if (user.companyID.toString().length !== 6) {
      error = 'Company ID is incorrect';
      return error;
    }
    if (user.companyID.toString().length === 6) {
      let idx = this.state.clientList.findIndex(
        (client) => client.companyID === user.companyID
      );

      if (idx === -1) {
        error = 'Invalid Company ID';
      }
      return error;
    }
    return error; // the xlsx form is in the correct format
  }

  // adds many users to the User List
  // Format of data that is retrieved from an XLSX file
  // Must have these keys {email, firstName, lastName, phoneNumber, companyID, role, password}
  // Optional keys {company, department, displayName, employeeID, employeeType, officeNumber, physicalDeliveryOfficeName, samAccountName}
  addManyNewUsers(file) {
    // extract all the users from the file
    // add them to the list
    let userData = file.data;
    let correctFormat = true;
    for (let user of userData) {
      let error = this.checkNewUser(user);
      if (error !== -1) {
        //assume that a user does not have all the necessary fields
        correctFormat = false;
        alert(
          error +
            '\n' +
            'Please upload the xlsx file in the correct format: [companyID, firstName, lastName, phoneNumber, role, password, email]'
        );
        break;
      }
    }
    if (correctFormat) {
      for (let user of userData) {
        const newUser = {
          companyID: user.companyID,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          password: user.password,
          phoneNumber: user.phoneNumber,
          profilePicture: '',
          role: user.role,
          company: user.company || '',
          department: user.department || '',
          displayName: user.displayName || '',
          emergencyName: user.emergencyName || '',
          emergencyPhone: user.emergencyPhone || '',
          employeeID: user.employeeID || '',
          employeeType: user.employeeType || '',
          officeNumber: user.officeNumber || '',
          physicalDeliveryOfficeName: user.physicalDeliveryOfficeName || '',
          samAccountName: user.samAccountName || ''
        };

        this.addNewUser(newUser, () =>
          console.log('addManyNewUsers/newUser: ', newUser)
        );
      }
    }
  }

  addNewUserCB(
    newuser,
    timestampDJ,
    timestampDLL,
    url,
    UID,
    licensesUsed = 0,
    adminLicensesUsed = 0
  ) {
    // assume pic was NOT uploaded
    // if (url === '') newuser.profilePicture = image;
    // // assume pic was uploaded
    // else newuser.profilePicture = url;

    // only Root Users can set a diff companyID for new users
    // otherwise the companyID is default to the Super Admins companyID
    newuser.companyID =
      this.state.role === 'Root'
        ? newuser.companyID + ''
        : this.state.companyID; // convert to string

    newuser.id = UID;

    // make copy of data to avoid adding extra fields to the database
    const userData = { ...newuser };
    console.log('addNewUserCB/userData: ', userData);

    newuser.profilePicture = url ? url : image;
    newuser.name = newuser.firstName + ' ' + newuser.lastName;
    newuser.user_type = 'SMS';
    newuser.phone = newuser.phoneNumber;

    this.setState({ countForLicenses: this.state.countForLicenses + 1 });

    // this.setState({userList: [newuser, ...this.state.userList]});

    let userList = this.state.userList;
    userList.push(newuser);
    this.setState({ userList: userList });

    // adds a user to the 'users' collection in Cloud Firestore
    db.collection('users')
      .doc(UID)
      .set({
        // companyID: newuser.companyID,
        // firstName: newuser.firstName,
        // lastName: newuser.lastName,
        // password: newuser.password,
        // title: 'Developer',
        // email: newuser.email,
        // phoneNumber: newuser.phone + '', // convert to string
        // emergencyPhone: newuser.emergencyPhone + '', // convert to string
        // emergencyName: newuser.emergencyName,
        // role: newuser.role,
        ...userData,
        profilePicture: url,

        dateJoined: timestampDJ,
        dateLastLogged: timestampDLL,
        isActive: false,
        deactivated: false,
        deleted: false,
        groups: 0,
        tasks: 0,
        isOffline: false
      })
      .then(() => {
        console.log('User successfully added!!!');
      })
      .then(() => {
        let company_update = {
          licensesUsed: licensesUsed + this.state.countForLicenses
        };
        if (newuser.role === 'Super Admin') {
          company_update = {
            adminLicensesUsed: adminLicensesUsed + this.state.countForLicenses
          };
        }
        console.log(company_update);

        db.collection('companies')
          .doc(newuser.companyID)
          .update(company_update)
          .then(() => {
            console.log('Licenses updated successfully!!!');
            alert(newuser.email + ' added successfully');
            this.fetchClientData();
          })
          .catch(() => console.log('Licenses did not get updated'));
      })
      .catch(() => console.error('Error adding user'));
  }

  // Adds a user to the Admin Console
  // retrieves the new user info from the addUserDialog class
  // 1) Creates a new user in Firebase Auth and creates the UID/dateJoined/dateLastLogged
  // 2) Creates a new directory in Firebase Storage in 'users' called the UID
  // 3) Adds the profile picture into the directory and its called the UID
  // 4) Updates the userList array and rerenders the UserList Component
  // 4) Adds the user to Cloud FireStore to the Users Collection
  addNewUser(newuser, cb) {
    let clientName = '';
    let idx = this.state.clientList.findIndex(
      (client) => client.companyID === this.state.companyID
    );
    let adminName = this.state.adminName;
    // console.log('addNewUser/newuser: ', newuser);

    if (idx !== -1) {
      clientName = this.state.clientList[idx].clientName;
    }

    if (this.state.role === 'Root') {
      // assume root admin is creating user
      // update the person of contact for the welcome email
      let i = this.state.userList.findIndex(
        (user) =>
          user.companyID === newuser.companyID && user.role === 'Super Admin'
      );
      if (i !== -1) {
        adminName = this.state.userList[i].name;
      }
    }
    if (this.state.role === 'Super Admin') {
      newuser.companyID = this.state.companyID;
    }

    let id = this.state.clientList.findIndex(
      (client) => client.companyID === newuser.companyID
    );

    let licenses_available = false;

    if (this.state.clientList[id] !== undefined && id !== undefined) {
      //meaning - a trial user as trial user doesnot have companyid
      let adminLicensesUsed = this.state.clientList[id].adminLicensesUsed
        ? this.state.clientList[id].adminLicensesUsed
        : 0;
      let adminLicensesAssigned = this.state.clientList[id]
        .adminLicensesAssigned
        ? this.state.clientList[id].adminLicensesAssigned
        : 0;

      let licensesUsed = this.state.clientList[id].licensesUsed
        ? this.state.clientList[id].licensesUsed
        : 0;
      let licensesAssigned = this.state.clientList[id].licensesAssigned
        ? this.state.clientList[id].licensesAssigned
        : 0;

      if (newuser.role === 'Super Admin') {
        if (
          adminLicensesAssigned !== 0 &&
          adminLicensesAssigned > adminLicensesUsed
        ) {
          // adminLicensesUsed = adminLicensesUsed + 1;
          licenses_available = true;
        }
      } else if (newuser.role === 'User') {
        if (licensesAssigned !== 0 && licensesAssigned > licensesUsed) {
          // licensesUsed = licensesUsed + 1;
          licenses_available = true;
        }
      }

      if (licenses_available) {
        // production
        fetch(
          'https://us-central1-crisis-management-15d38.cloudfunctions.net/createAccount/newUser',
          {
            // dev
            // fetch(firebaseFunctionsUrl + '/createAccount/newUser',{
            // fetch('https://us-central1-crisis-management-development.cloudfunctions.net/createAccount/newUser', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': 'http://localhost:3000/',
              'Access-Control-Allow-Methods': 'GET, PUT, POST, OPTIONS',
              'Access-Control-Allow-Headers': '*'
            },
            body: JSON.stringify({
              email: newuser.email,
              password: newuser.password,
              adminName: adminName,
              clientName: clientName,
              firstName: newuser.firstName,
              companyID: newuser.companyID
            })
          }
        )
          .then((res) => res.text())
          .then((data) => {
            let user = JSON.parse(data);
            // assuming that a user was created in Firebase Auth
            const UID = user.uid;
            const dateJoined = new Date(user.metadata.creationTime);
            const timestampDJ = firestoreTimestamp(dateJoined);
            // firebase.firestore.Timestamp.fromDate(dateJoined);
            const dateLastLogged = new Date(user.metadata.lastSignInTime);
            const timestampDLL = firestoreTimestamp(dateLastLogged);
            // firebase.firestore.Timestamp.fromDate(dateLastLogged);

            if (newuser.profilePicture === '') {
              this.addNewUserCB(
                newuser,
                timestampDJ,
                timestampDLL,
                newuser.profilePicture,
                UID,
                licensesUsed,
                adminLicensesUsed
              );
            } else {
              // creates a child directory called the UID, and the file is placed inside this directory
              const uploadTask = storageRef
                .child(`users/${UID}/${UID}.jpeg`)
                .put(newuser.profilePicture);
              uploadTask.on(
                'state_changed',
                (snapshot) => console.log('state changed'),
                (error) => console.log(error),
                () => {
                  // got the URL of the firebase storage image
                  uploadTask.snapshot.ref.getDownloadURL().then((url) => {
                    this.addNewUserCB(
                      newuser,
                      timestampDJ,
                      timestampDLL,
                      url,
                      UID,
                      licensesUsed,
                      adminLicensesUsed
                    );
                  });
                  console.log(
                    'Successfully stored and retrieve the url from the image'
                  );
                }
              );
            }
          })
          .catch((error) => {
            alert(
              'Error adding User: ' +
                newuser.email +
                '. Make sure to use a unique Email'
            );
            console.log(error.code + ' | ' + error.message);
          });

        let arr = [];
        arr.push({
          logType: 'New User Added',
          source: 'web-console',
          newValue: 'Email: ' + newuser.email
        });
        this.createUserLog(arr[0]);
        cb();
      } else {
        alert('There is no enough licenses');
      }
    } else {
      alert(
        "This is a trial account and users can't to be added. Please contact the admin for it"
      );
    }
  }

  toggleVaccineClient(row) {
    // get the index of the selected client
    const companyID = row.index;
    // update the table
    let client = null;
    let clientList = this.state.clientList;
    let i = 0;
    let idx = 0;
    clientList.forEach((c) => {
      if (c.id === companyID) {
        client = c;
        idx = i;
      }
      i++;
    });

    if (client !== null) {
      const toggle = !client.vaccineTracking;

      clientList[idx].vaccineTracking = toggle;
      this.setState({ clientList: clientList });

      const clientRef = db.collection('companies').doc(companyID);
      clientRef
        .update({ vaccineTracking: toggle })
        .then(() => {
          console.log('Document successfully updated!');
          this.getCompanySettings(companyID);
        })
        .catch((error) => console.error('Error updating document: ', error));
    }
  }
  /******************************* Client List Functions ***********************************/
  toggleClient(row) {
    // get the index of the selected client
    const idx = row.index;
    // get the companyID of the selected client
    const companyID = this.state.clientList[idx].companyID;
    // get the value of the deactivated key of the client
    const toggle = !this.state.clientList[idx].deactivated;
    // update the table
    let clientList = this.state.clientList;
    clientList[idx].deactivated = toggle;
    this.setState({ clientList: clientList });
    const clientRef = db.collection('companies').doc(companyID);
    clientRef
      .update({ deactivated: toggle })
      .then(() => console.log('Document successfully updated!'))
      .catch((error) => console.error('Error updating document: ', error));
  }
  editClient(row) {
    const idx = row.index;
    var clientList = this.state.clientList;
    // TODO: check if user is trying to change a password
    // Then check if user is changing own password; update the password
    clientList[idx].domain = row.domain;
    clientList[idx].clientName = row.clientName;
    clientList[idx].address =
      row.address + ' ' + row.city + ' ' + row.stateName;
    clientList[idx].phoneNumber = row.phoneNumber;
    clientList[idx].address = row.address;
    clientList[idx].city = row.city;
    clientList[idx].stateName = row.stateName;
    clientList[idx].licensesAssigned = row.licensesAssigned;
    clientList[idx].adminlicensesAssigned = row.adminLicensesAssigned;
    const companyID = clientList[idx].companyID;
    // update the firestore document for the client
    const clientRef = db.collection('companies').doc(companyID);
    clientRef
      .update({
        domain: row.domain,
        clientName: row.clientName,
        phoneNumber: row.phoneNumber + '',
        address: row.address,
        city: row.city,
        stateName: row.stateName,
        licensesAssigned: parseInt(row.licensesAssigned),
        adminLicensesAssigned: parseInt(row.adminLicensesAssigned)
      })
      .then(() => {
        if (row.logo !== '') {
          // assume that the user changed the logo
          const uploadTask = storageRef
            .child(`companies/${companyID}/${companyID}.jpeg`)
            .put(row.logo);
          uploadTask.on(
            'state_changed',
            () => {},
            (error) => console.log(error),
            () => {
              // got the URL of the firebase storage image
              uploadTask.snapshot.ref.getDownloadURL().then((url) => {
                clientList[idx].logo = url;
                this.setState({ clientList: clientList });
                // updated the url stored in firebase
                const client = db.collection('companies').doc(companyID);
                client
                  .update({ logo: url })
                  .then(() => console.log('Document successfully updated!'))
                  .catch((error) =>
                    console.error('Error updating document: ', error)
                  );
              });
              // console.log('Successfully stored and retrieve the url from the image');
            }
          );
        } else {
          this.setState({ clientList: clientList });
          this.fetchClientData();
        }
      })
      .catch((error) => console.error('Error updating document: ', error));
  }
  // marks the client as deleted and physically removes the client from the List
  deleteClient(row) {
    let deleteIndex = row.index;
    const client = this.state.clientList[deleteIndex];
    // get the UID of the client that is being deleted
    let companyID = this.state.clientList[deleteIndex].id;
    this.state.clientList.splice(deleteIndex, 1);
    this.setState({
      clientList: this.state.clientList,
      deletedClients: [client, ...this.state.deletedClients]
    });
    db.collection('companies')
      .doc(companyID)
      .set({ deleted: true, deactivated: true }, { merge: true });
  }
  // TODO: This is depreciated - remove
  addClientCallBack(newClient, timestampDJ, timestampDLL, url) {
    // console.log(": 14");
    if (url === '') {
      // assume logo was NOT uploaded
      newClient.logo = image;
    } else {
      // assume logo was uploaded
      newClient.logo = url;
    }
    newClient.deactivated = false;
    newClient.plans = 0;
    // newClient.licensesAssigned = 50
    newClient.adminlicensesUsed = 0;
    newClient.deleted = false;
    this.state.clientList.unshift(newClient);
    this.setState({ clientList: this.state.clientList });
    db.collection('companies')
      .doc(newClient.companyID)
      .set({
        logo: url,
        clientName: newClient.clientName,
        id: newClient.companyID,
        dateJoined: timestampDJ,
        dateLastLogged: timestampDLL,
        companyURL: 'vsam.dev',
        description: 'working on it',
        domain: newClient.domain,
        address: newClient.address,
        city: newClient.city,
        stateName: newClient.stateName,
        phoneNumber: newClient.phoneNumber + '',
        isActive: false,
        deactivated: false,
        emergencyPlans: [],
        users: [],
        licensesAssigned: parseInt(newClient.licensesAssigned),
        adminLicensesAssigned: parseInt(newClient.adminLicensesAssigned),
        adminLicensesUsed: 0,
        licensesUsed: 0,
        features: {
          meeting: true,
          messages: true,
          library: true,
          emergencyChecklist: true,
          teams: true,
          flash: true,
          forms: true
        },
        deleted: false
      })
      .then(() => {
        console.log('User successfully added!!!');
        this.fetchClientData();
      })
      .catch(() => console.error('Error adding user'));
    const ts = new Date();
    // MM/DD/YYY
    const mmddyyyy =
      ts.getDate() + '/' + (ts.getMonth() + 1) + '/' + ts.getFullYear();
    const timeArr = ts.toLocaleTimeString().split(':');
    // HH:MM AM/PM
    let hhmm = timeArr[0] + ':' + timeArr[1] + timeArr[2].slice(2);
    let folderItem = {
      itemName: newClient.companyID,
      companyID: newClient.companyID,
      path: 'companies',
      fullName: newClient.clientName,
      fullPath: `companies/${newClient.companyID}`,
      uploadTime: mmddyyyy + ' ' + hhmm,
      folder: true
    };
    // adding the client folder to 'repoInfo'
    db.collection('repoInfo')
      .doc(`companies-${newClient.companyID}`)
      .set(folderItem)
      .then(() =>
        console.log('Client folder successfully added the Repository')
      )
      .catch(() => console.error('Error adding folder'));
  }

  async addClient(data) {
    try {
      // create company
      const result = await createNewCompany(data);
      console.log('addClient/result: ', result);

      if (!result.success) {
        alert('Error adding client');
        return;
      }

      // update this.state.clientList
      const currentClientList = [...this.state.clientList];
      currentClientList.unshift(result.company);

      this.setState({ clientList: currentClientList });

      return {
        success: true
      };
    } catch (error) {
      console.log(error);
      return {
        success: false
      };
    }
  }
  sendUsersMessage({ companyID }) {
    // filter all the users to match the companyID
    let userList = this.state.userList.filter(
      (user) => user.companyID === companyID
    );
    let tokenArr = []; // array of fcmTokens
    for (let user of userList) {
      if (user.fcmToken !== undefined) {
        tokenArr.push(user.fcmToken);
      }
    }
    let res = window.prompt(
      'Send a short message to all the users of the client:'
    );
    if (res !== null && res.length > 0) {
      const message = {
        title: 'Message from the Client',
        message: res,
        userTokens: tokenArr
      };
      // execute the firebase function
      const globalNotification = firebaseFns.httpsCallable(
        'sendGlobalNotification'
      );
      globalNotification(message)
        .then((result) => console.log(result))
        .catch((error) => console.log('Error: ', error));
    }
  }
  /********************** Knowledge Base functions ***************************/
  downloadFile(data) {
    if (!data.folder) {
      // assume its a file
      const fileRef = storageRef.child(data.storagePath);
      fileRef
        .getDownloadURL()
        .then((url) => {
          var xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            var blob = xhr.response;
            const urlFromBlob = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = urlFromBlob;
            // the itemName you want
            a.download = data.itemName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(urlFromBlob);
          };
          xhr.open('GET', url);
          xhr.send();
        })
        .catch((error) => console.log(error));
    }
    if (data.folder) {
      // console.log('downloading a folder')
      // Create a reference under which you want to list
      var listRef = storageRef.child(data.storagePath);
      // Find all the prefixes and items.
      listRef
        .listAll()
        .then(function (res) {
          res.prefixes.forEach(function (folderRef) {
            // console.log('Folder: ', folderRef)
            // All the prefixes under listRef.
            // You may call listAll() recursively on them.
          });
          res.items.forEach(function (itemRef) {
            // console.log('Item: ', itemRef)
            // All the items under listRef.
          });
        })
        .catch(function (error) {
          // Uh-oh, an error occurred!
        });
    }
  }
  deleteFile(row, splice = true) {
    if (row.folder) alert('Error: A folder can not be deleted');
    if (!row.folder) {
      // delete the file from the array
      if (splice) {
        this.state.repository.splice(row.index, 1);
      }
      // update the state
      this.setState({ repository: this.state.repository });
      // get the document name of the item that needs to be deleted
      // let docName = row.fullPath.split('/').join('-'); // not used

      db.collection('repoInfo')
        .doc(row.id)
        .update({ deleted: true })
        .then(() => {
          // delete the document from Firestore
          db.collection('repoInfo')
            .doc(row.id)
            .delete()
            .then(() => console.log('Document successfully deleted!'))
            .catch((error) =>
              console.error('Error removing document: ', error)
            );
          // Create a reference to the file in Storage to delete
          var file = storageRef.child(row.fullPath);
          // Delete the file
          file
            .delete()
            .then(() => console.log('file was successfully deleted'))
            .catch((error) => console.log(error));
        });
    }
  }

  deleteFolder(row, splice = true) {
    if (splice) {
      this.state.repository.splice(row.index, 1);
    }
    this.setState({ repository: this.state.repository });
    let docName = row.path.split('/').join('-');
    // mark the folder as deleted
    db.collection('repoInfo')
      .doc(docName)
      .set({ deleted: true }, { merge: true });

    //check if Folder has files
    db.collection('repoInfo')
      .get()
      .then((querySnapshot) => {
        const fileInfo = querySnapshot.docs.map((doc) => doc.data());
        for (let item of fileInfo) {
          if (item.path === row.path) {
            if (item.folder === false) {
              this.deleteFile(item, false);
            }
            if (item.folder === true) {
              this.deleteFolder(item, false);
            }
          }
        }
      });
  }

  setFolderPassword(row) {
    const user = auth.currentUser;
    let folderName = row.path.split('/').join('-');
    const repoRef = db.collection('repoInfo').doc(folderName);

    repoRef.get().then((doc) => {
      if (doc.exists) {
        // get all the keys from folder doc
        let values = doc.data();

        values.password = row.password;
        values.protected = true;
        values.lastUpdatedBy = user.email;
        values.lastUpdatedDate = this.state.date;
        // create a new document with the updated name
        // const docName = (this.state.path).split('/').join('-') + '-' + row.folderName;

        db.collection('repoInfo')
          .doc(folderName)
          .set(values)
          .then(() => console.log('Folder doc successfully added!'))
          .catch(() => console.error('Error adding folder doc'));
        // change the protected status in the list
        let repository = this.state.repository;
        repository[row.index].password = row.password;
        repository[row.index].protected = true;
        // update state
        this.setState({ repository: repository });
      } else console.log('No such document!');
    });
  }

  removeFolderPassword(row) {
    const user = auth.currentUser;
    let folderName = row.path.split('/').join('-');
    const repoRef = db.collection('repoInfo').doc(folderName);

    repoRef.get().then((doc) => {
      if (doc.exists) {
        // get all the keys from folder doc
        let values = doc.data();

        values.password = '';
        values.protected = false;
        values.lastUpdatedBy = user.email;
        values.lastUpdatedDate = this.state.date;
        // create a new document with the updated name
        // const docName = (this.state.path).split('/').join('-') + '-' + row.folderName;

        db.collection('repoInfo')
          .doc(folderName)
          .set(values)
          .then(() => console.log('Folder doc successfully added!'))
          .catch(() => console.error('Error adding folder doc'));
        // change the protected status in the list
        let repository = this.state.repository;
        repository[row.index].password = '';
        repository[row.index].protected = false;
        // update state
        this.setState({ repository: repository });
      } else console.log('No such document!');
    });
  }

  editFolderName(row) {
    const user = auth.currentUser;

    let oldFullPath; // used to discover all the folders current children
    let oldName = row.path.split('/').join('-'); // old folder document name
    const repoRef = db.collection('repoInfo').doc(oldName);
    // get the folder's doc to create the newly updated doc
    repoRef.get().then((doc) => {
      if (doc.exists) {
        // get all the keys from folder doc
        let values = doc.data();
        oldFullPath = values.fullPath.split('/'); //keep track of the old path
        if (!values.hasOwnProperty('alias')) {
          // assuming that the Object does already have an alias
          values.alias = values.fullPath;
        }
        values.itemName = row.folderName;
        values.fullPath = this.state.path + '/' + row.folderName;
        values.lastUpdatedBy = user.email;
        values.lastUpdatedDate = this.state.date;
        // create a new document with the updated name
        const docName =
          this.state.path.split('/').join('-') + '-' + row.folderName;
        db.collection('repoInfo')
          .doc(docName)
          .set(values)
          .then(() => console.log('Folder doc successfully added!'))
          .catch(() => console.error('Error adding folder doc'));
        // change the name in the list
        let repository = this.state.repository;
        repository[row.index].itemName = row.folderName;
        // changes the full path in the table for navigation to child
        repository[row.index].fullPath = this.state.path + '/' + row.folderName;
        // update state
        this.setState({ repository: repository });
      } else console.log('No such document!');
      // delete the doc of the old folder
      db.collection('repoInfo')
        .doc(oldName)
        .delete()
        .then(() => console.log('Document successfully deleted!'))
        .catch((error) => console.error('Error removing document: ', error));
    });
    // get the children's document information
    db.collection('repoInfo')
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        for (let item of data) {
          let itemPath = item.fullPath.split('/');
          // check if the curr Item is a child of the folder
          if (itemPath.length > oldFullPath.length) {
            let frontPath = itemPath.slice(0, oldFullPath.length);
            // check if the arrays are equal
            if (JSON.stringify(frontPath) === JSON.stringify(oldFullPath)) {
              // assuming that this doc is a child of the folder
              let childDoc = itemPath.join('-'); // child document name
              var childRef = db.collection('repoInfo').doc(childDoc);
              // get the values from the child doc
              childRef.get().then((offspring) => {
                if (offspring.exists) {
                  let newVals = offspring.data();
                  // update the fullPath
                  let newFullPath = newVals.fullPath.replace(
                    row.itemName,
                    row.folderName
                  );
                  newVals.alias = newVals.fullPath; // keep track of the Storage path
                  newVals.fullPath = newFullPath;
                  newVals.path = newVals.path.replace(
                    row.itemName,
                    row.folderName
                  );
                  newFullPath = newFullPath.split('/').join('-');
                  // add the new updated doc to Firestore
                  db.collection('repoInfo')
                    .doc(newFullPath)
                    .set(newVals)
                    .then(() => {
                      console.log('Child doc successfully added!');
                    })
                    .catch(() => {
                      console.error('Error adding child doc');
                    });
                } else console.log('No such child document!');
                // delete the old doc from Firebase
                db.collection('repoInfo')
                  .doc(childDoc)
                  .delete()
                  .then(() => console.log('Child successfully deleted!'))
                  .catch((error) =>
                    console.error('Error removing child: ', error)
                  );
              });
            }
          }
        }
      });
  }
  addFolder(data) {
    if (this.state.uid.length > 0) {
      // in order to get the full name of the user
      var docRef = db.collection('users').doc(this.state.uid);
      docRef.get().then((doc) => {
        if (doc.exists) {
          let fullName = doc.data().firstName + ' ' + doc.data().lastName;
          const ts = new Date(); // get the current timestamp
          // MM/DD/YYY
          const mmddyyyy =
            ts.getDate() + '/' + (ts.getMonth() + 1) + '/' + ts.getFullYear();
          const timeArr = ts.toLocaleTimeString().split(':');
          // HH:MM AM/PM
          let hhmm = timeArr[0] + ':' + timeArr[1] + timeArr[2].slice(2);
          let docName = (this.state.path + '/' + data.itemName)
            .split('/')
            .join('-');
          let folderItem = {
            itemName: data.itemName,
            companyID: this.state.companyID,
            path: this.state.path,
            fullName: fullName,
            fullPath: this.state.path + '/' + data.itemName,
            uploadTime: mmddyyyy + ' ' + hhmm,
            folder: true
          };
          // add the folderItem to the Repo array
          this.state.repository.push(folderItem);
          // update the state
          this.setState({ repository: this.state.repository });
          // add the folderItem to Firestore
          db.collection('repoInfo')
            .doc(docName)
            .set(folderItem)
            .then(() => console.log('Folder successfully added!!!'))
            .catch(() => console.error('Error adding folder'));
        }
      });
    } else console.log('Please Log into your account');
  }

  addFileCB(fData, currTime, fullName, subFolderPath) {
    // set the file document name
    const docName = (subFolderPath + '-' + fData.itemName).split('/').join('-');
    let elementName = fData.itemName;
    if (fData.type === 'image/jpeg' || fData.type === 'imgage/jpeg') {
      elementName = fData.itemName.split('.')[0] + '.jpeg';
      fData.type = 'image/jpeg';
    } else if (fData.type === 'image/jpg') {
      elementName = fData.itemName.split('.')[0] + '.jpg';
      fData.type = 'image/jpg';
    } else if (fData.type === 'image/png') {
      elementName = fData.itemName.split('.')[0] + '.png';
      fData.type = 'image/png';
    }
    const fileDoc = {
      itemName: elementName,
      companyID: this.state.companyID,
      fullName: fullName,
      path: subFolderPath,
      fullPath: subFolderPath + '/' + elementName,
      storagePath: subFolderPath + '/' + elementName,
      uploadTime: currTime,
      folder: false,
      fileType: fData.type,
      id: docName
    };
    // add file to the client directory and update the state
    this.setState({
      repository: [fileDoc, ...this.state.repository],
      path: subFolderPath
    });
    // create a document in FireStore under repoInfo
    db.collection('repoInfo')
      .doc(docName)
      .set(fileDoc)
      .then(() => console.log('File successfully added!!!'))
      .catch(() => console.error('Error adding file'));
    // adding the file to Storage
    storageRef
      .child(`${subFolderPath}/${elementName}`)
      .put(fData.file)
      .then(() => console.log('Successfully added file to storage'))
      .catch((error) => console.log(error));
  }

  addFile(fData) {
    if (this.state.uid.length > 0) {
      const ts = new Date(); // get the timestamp for the table
      // MM/DD/YYY
      const mmddyyyy =
        ts.getDate() + '/' + (ts.getMonth() + 1) + '/' + ts.getFullYear();
      const timeArr = ts.toLocaleTimeString().split(':');
      // MM/DD/YYY HH:MM AM/PM
      const currTime =
        mmddyyyy + ' ' + timeArr[0] + ':' + timeArr[1] + timeArr[2].slice(2);
      // get the user collection for the User's name
      const userRef = db.collection('users').doc(this.state.uid);
      // fetch the docs values
      userRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            // name of the user who uploaded the file
            const fullName = doc.data().firstName + ' ' + doc.data().lastName;
            // assume the user did not add subfolder
            this.addFileCB(fData, currTime, fullName, this.state.path);
          } else console.log('No such document!');
        })
        .catch((error) => console.log('Error getting document:', error));
    } else console.log('No user is signed in.');
  }
  // Currently updating the table using FireStore instead of Storage
  navigateToChild(row) {
    // getting all the items repoInfo

    db.collection('repoInfo')
      .get()
      .then((querySnapshot) => {
        const repoList = querySnapshot.docs;
        // iterate through all the doc refs
        let itemArr = [];
        let currPath;
        for (let repoInfo of repoList) {
          let rid = repoInfo.id;
          let item = repoInfo.data();
          item.id = rid;

          if (item.deleted) continue;
          if (item.fullPath === undefined) continue;
          currPath = item.fullPath.split('/');
          currPath.pop();
          currPath = currPath.join('/');
          // ensuring that the correct files are displayed
          if (row.path === currPath) {
            itemArr.push(item);
          }
        }
        this.setState({
          repository: itemArr,
          path: row.path,
          viewFilteredList: false
        });
      });
  }
  navigateToParent(row) {
    if (row.path === '') {
      // assume user is viewing the companies Repo
      return;
    }
    if (this.state.role !== 'Root' && row.path === 'companies') {
      // assume Super Admin is trying to view the companies Repo
      console.log('Only Root Admins can view the companies Repo');
      return;
    }
    // getting all the items repoInfo
    // db.collection("repoInfo").get()
    // .then(querySnapshot => {
    // 	const repoInfo = querySnapshot.docs.map(doc => doc.data());
    // 	let itemArr = [];
    // 	let itemPath;
    //
    // 	for (let item of repoInfo) {
    // 		if (item.deleted) continue;
    // 		if (item.fullPath === undefined) continue
    // 		itemPath = item.fullPath.split('/');
    // 		itemPath.pop();
    // 		itemPath = itemPath.join('/')
    // 		// ensuring that only parent items are shown
    // 		if (row.path === itemPath) itemArr.push(item)
    // 	}
    // 	this.setState({
    // 		repository: itemArr,
    // 		path: row.path,
    // 		viewFilteredList: false
    // 	 })
    // })
    db.collection('repoInfo')
      .get()
      .then((querySnapshot) => {
        const repoList = querySnapshot.docs;
        // iterate through all the doc refs
        let itemArr = [];
        let currPath;
        for (let repoInfo of repoList) {
          let rid = repoInfo.id;
          let item = repoInfo.data();
          item.id = rid;

          if (item.deleted) continue;
          if (item.fullPath === undefined) continue;
          currPath = item.fullPath.split('/');
          currPath.pop();
          currPath = currPath.join('/');
          // ensuring that the correct files are displayed
          if (row.path === currPath) {
            itemArr.push(item);
          }
        }
        this.setState({
          repository: itemArr,
          path: row.path,
          viewFilteredList: false
        });
      });
  }
  viewSelectedRepo({ path }) {
    db.collection('repoInfo')
      .get()
      .then((querySnapshot) => {
        const repoInfo = querySnapshot.docs.map((doc) => doc.data());
        let itemArr = [];
        let folderArr = []; // all the folders in every client directory used for upload file
        let currPath;
        console.log(this.state.role);
        for (let item of repoInfo) {
          /* assume that the item was marked as deleted and
           * only display deleted folders for root users */
          if (item.deleted && this.state.role !== 'Root') continue;
          if (item.folder && item.fullPath.split('/').length === 3)
            folderArr.push(item);
          if (item.fullPath === undefined) continue;
          currPath = item.fullPath.split('/');
          currPath.pop();
          currPath = currPath.join('/');
          // ensuring that the correct files are displayed
          if (path === currPath) itemArr.push(item);
        }
        itemArr.sort((a, b) => {
          var nameA = a.itemName.toUpperCase();
          var nameB = b.itemName.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;

          return 1;
        });
        this.setState({
          repository: itemArr,
          folderRepos: folderArr,
          path: path
        });
      });
  }
  /**************************** Response Plans Functions **************************/
  createPlan(plan) {
    console.log(plan);
    const planRef = db.collection('emergencyPlans').doc();
    const planID = planRef.id;
    let idx = this.state.groupList.findIndex(
      (group) => group.name === plan.teamName
    );
    if (idx !== -1) {
      // assume a user group was selected
      let umap = {};
      for (let user of plan.users) {
        umap[user.id] = 1;
      }
      for (let nuser of this.state.groupList[idx].users) {
        if (!(nuser.id in umap)) {
          umap[nuser.id] = 1;
          plan.users.push(nuser);
        }
      }
    }
    var userRef = db.collection('users').doc(this.state.uid);
    const eplan = {
      // plan added to 'emergencyPlans' collection
      id: planID,
      name: plan.planName,
      companyID: this.state.companyID,
      isActive: true, // Active Column
      isPractice: plan.isPractice, // Practice Column (Drill)
      deactivated: false, //Enabled Column
      users: plan.users,
      tasks: [],
      titleList: plan.titleList,
      userStatus: plan.userStatus,
      leader: userRef,
      createdByRole: this.state.role,
      teamName: plan.teamName
    };
    db.collection('emergencyPlans')
      .doc(planID)
      .set(eplan)
      .then(() => console.log('Plan successfully added!!!'))
      .catch(() => console.error('Error adding plan'));
    eplan.leaderName = this.state.adminName; // displays the leaderName in the table
    // set the title for the users column in the active checklist table
    let userArr = [];
    for (let i = 0; i < eplan.users.length; i++) {
      let uid = eplan.users[i].id;
      for (let j = 0; j < this.state.userList.length; j++) {
        if (this.state.userList[j].id === uid) {
          userArr.push(this.state.userList[j].name);
        }
        if (
          i === eplan.users.length - 1 &&
          j === this.state.userList.length - 1
        ) {
          eplan.userNames = userArr.join(', ');
        }
      }
    }
    // update state for plans and tasks
    this.setState({
      planList: [...this.state.planList, eplan]
    });

    // Create copies of the tasks
    for (let i = 0; i < plan.tasks.length; i++) {
      let taskID = plan.tasks[i].id;
      let idx = this.state.taskList.findIndex((task) => task.id === taskID);
      if (idx !== -1) {
        let currTask = this.state.taskList[idx];
        let tdata = {
          taskName: currTask.name,
          assignedRC: '',
          assignedUser: '',
          description: currTask.description,
          taskFromTemplate: true,
          planID: planID
        };
        this.addTask(tdata);
      }
    }

    if (this.state.role === 'Root') {
      // change the companyID of root back to normal
      const rootRef = db.collection('users').doc(this.state.uid);
      rootRef.get().then((query) => {
        let data = query.data();
        this.setState({ companyID: data.companyID });
      });
    }

    // let userTokens = []; // array of user fcmTokens UNUSED VAR
    for (let i = 0; i < plan.users.length; i++) {
      let tokensList = [];
      let user = plan.users[i];
      user.get().then((query) => {
        let data = query.data();

        console.log(data);

        if (data.fcmToken !== undefined) {
          tokensList = Object.values(data.fcmToken);
          //console.log(tokensList)
          for (let token of tokensList) {
            if (token.length > 0) {
              // assume the fcmToken is defined
              // userTokens.push(token);
              const erpData = {
                erpName: plan.planName,
                userTokens: token,
                erpRef: planID,
                isActive: true,
                isPractice: false
              };

              console.log('plan-planName' + plan.planName);
              console.log(erpData);

              // execute the firebase function
              const alertERP = firebaseFns.httpsCallable('alertERPUsers');
              alertERP(erpData)
                .then((result) => {
                  console.log(plan.planName);
                  // add the notification log to firestore
                  let curr = 'Active: ' + plan.planName;
                  if (plan.isPractice) {
                    curr = 'Drill: ' + +plan.planName;
                  }
                  const logRef = db.collection('notificationHistory').doc();
                  const log = {
                    date: new Date(),
                    message: curr,
                    reference: planRef,
                    users: plan.users
                  };
                  db.collection('notificationHistory')
                    .doc(logRef.id)
                    .set(log)
                    .then(() => console.log('Successfully added the log'))
                    .catch((error) => console.log('Error adding log: ', error));
                })
                .catch((error) => console.log('Error: ', error));
            }
          }
        }

        // if (i === plan.users.length - 1) {
        // 	// assume its the last element in the array

        // }
      });
    }
  }
  practiceToggle(row) {
    const index = row.index;
    const planID = row.id;
    const toggle = !this.state.planList[index].isPractice;
    if (typeof this.state.planList[index] === 'object') {
      const planListCopy = [...this.state.planList];
      planListCopy[index].isPractice = toggle;

      this.setState({ planList: planListCopy });
    }

    const plan = db.collection('emergencyPlans').doc(planID);
    plan
      .update({ isPractice: toggle })
      .then(() => console.log('Document successfully updated!'))
      .catch((error) => console.error('Error updating document: ', error));
  }
  activeToggle({ index }) {
    const toggle = !this.state.planList[index].isActive;
    let planList = this.state.planList;
    planList[index].isActive = toggle;
    this.setState({ planList: planList });
    const planID = this.state.planList[index].id;
    const plan = db.collection('emergencyPlans').doc(planID);
    if (toggle) {
      let userTokens = []; // array of user fcmTokens
      for (let i = 0; i < this.state.planList[index].users.length; i++) {
        let user = this.state.planList[index].users[i];
        user.get().then((query) => {
          let data = query.data();
          if (data.fcmToken !== undefined) {
            // assume the fcmToken is defined
            userTokens.push(data.fcmToken);
          }
          if (i === this.state.planList[index].users.length - 1) {
            console.log('Active Toggle: ', this.state.planList[index].name);
            // assume its the last element in the array
            const erpData = {
              erpName: this.state.planList[index].name,
              userTokens: userTokens,
              erpRef: planID,
              isActive: toggle,
              isPractice: this.state.planList[index].isPractice
            };
            // execute the firebase function
            const alertERP = firebaseFns.httpsCallable('alertERPUsers');
            alertERP(erpData)
              .then((result) => {
                // add the notification log to firestore
                let curr = result.data.message;
                if (curr === undefined || curr === null) {
                  curr = 'Value is undefined';
                }
                const logRef = db.collection('notificationHistory').doc();
                const log = {
                  date: new Date(),
                  message: curr,
                  reference: plan,
                  users: this.state.planList[index].users
                };
                db.collection('notificationHistory')
                  .doc(logRef.id)
                  .set(log)
                  .then(() => console.log('Successfully added the log'))
                  .catch((error) => console.log('Error adding log: ', error));
              })
              .catch((error) => console.log('Error: ', error));
          }
        });
      }
    }
    plan
      .update({ isActive: toggle })
      .then(() => console.log('Document successfully updated!'))
      .catch((error) => console.error('Error updating document: ', error));
  }
  editPlan(row) {
    const planID = this.state.planList[row.index].id;
    // the doc reference is saved to each new task
    var ERPref = db.collection('emergencyPlans').doc(planID);
    // add new tasks to Firestore
    for (let currTask of row.newTasks) {
      let taskRef = db.collection('tasks').doc();
      row.taskList.push(taskRef);
      let taskID = taskRef.id;
      let etask = {
        id: taskID,
        name: currTask.taskName,
        emergencyPlan: ERPref,
        assignedUser: '',
        dateCompleted: '',
        isComplete: false,
        description: currTask.description,
        taskUser: '',
        assignedRC: currTask.assignedRC
      };
      // TODOOOO on edit plan is messing up the assignedUser is the name instead of UID
      if (currTask.assignedUser.length > 0) {
        let idx = this.state.userList.findIndex(
          (user) => user.name === currTask.assignedUser
        );
        let UID = this.state.userList[idx].id;
        // assume assigned user was selected for the task
        let userRef = db.collection('users').doc(UID);
        // in order to get the name of the assignedUser
        userRef.get().then((query) => {
          let data = query.data();
          let fullName = data.firstName + ' ' + data.lastName;
          etask.assignedUser = userRef;
          etask.taskUser = fullName;
          db.collection('tasks')
            .doc(taskID)
            .set(etask)
            .then(() => console.log('Task successfully added!!!'))
            .catch(() => console.error('Error adding task'));
        });
      } else {
        // assume assigned user was NOT selected for the task
        db.collection('tasks')
          .doc(taskID)
          .set(etask)
          .then(() => console.log('Task successfully added!!!'))
          .catch(() => console.error('Error adding task'));
      }
    }
    let planList = this.state.planList;
    planList[row.index].name = row.planName;
    planList[row.index].users = row.userList;
    planList[row.index].tasks = row.taskList;
    planList[row.index].titleList = row.titleList;
    this.setState({ planList: planList });
    // make changes to existing tasks
    for (let key in row.taskNameChangeMap) {
      let taskRef = db.collection('tasks').doc(key);
      // update the name of the task
      taskRef
        .update({ name: row.taskNameChangeMap[key] })
        .then(() => console.log('Document successfully updated!'))
        .catch((error) => console.error('Error updating document: ', error));
    }
    // Update the ERP Document
    ERPref.update({
      userStatus: row.userStatus,
      name: row.planName,
      tasks: row.taskList,
      titleList: row.titleList,
      users: row.userList
    })
      .then(() => console.log('Document successfully updated!'))
      .catch((error) => console.error('Error updating document: ', error));
  }
  //this function is deppreciated and no more used
  deletePlan(row) {
    const planID = this.state.planList[row.index].id;
    db.collection('emergencyPlans')
      .doc(planID)
      .delete()
      .then(() => console.log('Document successfully deleted!'))
      .catch((error) => console.error('Error removing document: ', error));
    this.state.planList.splice(row.index, 1);
    this.setState({ planList: this.state.planList });
  }

  deactivatePlan(row) {
    const planID = this.state.planList[row.index].id;
    const checklist = db.collection('emergencyPlans').doc(planID);
    let date = new Date();
    checklist
      .update({
        deactivated: true,
        dateDeactivated: date,
        dateDeactivatedString: new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        }).format(date.seconds * 1000)
      }) //archived but no deleted completely
      .then(() => console.log('Checklist Archived successfully!'))
      .catch((error) => console.error('Error updating document: ', error));
  }

  /******************************** Task List Functions *************************/
  addTask(tdata) {
    // console.log(this.state.planID)
    // console.log(tdata.subPlanPager)

    // add task to tasks collection and add the task to the ERP
    var ERPref;
    let planID;
    if (
      tdata.taskFromTemplate !== undefined &&
      tdata.taskFromTemplate !== null &&
      tdata.taskFromTemplate
    ) {
      //No sure why this if stmt exists and when it gets fulfills
      // task from template
      planID = tdata.planID;
      ERPref = db.collection('emergencyPlans').doc(tdata.planID);
    } else if (tdata.subPlanPager === 2) {
      planID = this.state.planID;
      ERPref = db.collection('templates').doc(this.state.planID);
    } else {
      planID = this.state.planID;
      ERPref = db.collection('emergencyPlans').doc(this.state.planID);
    }

    const taskRef = db.collection('tasks').doc();
    const taskID = taskRef.id;
    var etask = {
      id: taskID,
      name: tdata.taskName,
      emergencyPlan: ERPref,
      assignedUser: '',
      assignedRC: tdata.assignedRC,
      dateCompleted: '',
      isComplete: false,
      description: tdata.description,
      taskUser: ''
    };
    if (tdata.assignedUser.length > 0) {
      // assume assigned user was selected
      let userRef = db.collection('users').doc(tdata.assignedUser);
      userRef.get().then((query) => {
        let data = query.data();
        let fullName = data.firstName + ' ' + data.lastName;
        etask.assignedUser = userRef;
        etask.taskUser = fullName;
        // update state of the taskList
        this.setState({
          taskList: [...this.state.taskList, etask],
          totalNum: this.state.totalNum + 1
        });
        // console.log(this.state.taskList)
        db.collection('tasks')
          .doc(taskID)
          .set(etask)
          .then(() => console.log('Task successfully added!!!'))
          .catch(() => console.error('Error adding task'));
      });
    } else {
      // assume assigned user was NOT selected
      this.setState({
        taskList: [...this.state.taskList, etask],
        totalNum: this.state.totalNum + 1
      });
      db.collection('tasks')
        .doc(taskID)
        .set(etask)
        .then(() => console.log('Task successfully added!!!'))
        .catch(() => console.error('Error adding task'));
    }

    console.log(this.state.planList);
    // Update the ERP Document append the task to the tasks
    ERPref.update({ tasks: firestoreArrayUnion(taskRef) })
      .then(() => {
        // on creation, update the state of # of tasks
        let idx = this.state.planList.findIndex((plan) => plan.id === planID);
        if (idx !== -1) {
          for (let e of this.state.planList[idx].tasks) {
            console.log(e.id);
            console.log(taskID);
            console.log('');
          }
          // this.state.planList[idx].tasks.push(taskRef)
          this.setState({ planList: this.state.planList });
          console.log('Document successfully updated!');
        } else {
          console.log('Failed to find active checklist');
        }
      })
      .catch((error) => console.error('Error updating document: ', error));
  }
  editTask(row) {
    const taskID = this.state.taskList[row.index].id;
    const taskRef = db.collection('tasks').doc(taskID);
    const taskList = this.state.taskList;
    taskList[row.index].name = row.taskName;
    taskList[row.index].description = row.description;
    taskList[row.index].taskUser = 'N/A';
    taskList[row.index].assignedRC = row.assignedRC;
    if (row.assignedUser.length > 0) {
      // assume assigned user is selected
      const userRef = db.collection('users').doc(row.assignedUser);
      let idx = this.state.userList.findIndex(
        (user) => user.id === row.assignedUser
      );
      let fullName = this.state.userList[idx].name;
      // update state
      taskList[row.index].taskUser = fullName;
      this.setState({ taskList: taskList });
      // update the firebase document
      taskRef
        .update({
          name: row.taskName,
          description: row.description,
          assignedUser: userRef,
          assignedRC: row.assignedRC,
          taskUser: fullName
        })
        .then(() => console.log('Document successfully updated!'))
        .catch((error) => console.error('Error updating document: ', error));
    } else {
      this.setState({ taskList: taskList });
      // update the firebase document
      taskRef
        .update({
          name: row.taskName,
          description: row.description,
          assignedUser: '',
          assignedRC: row.assignedRC,
          taskUser: ''
        })
        .then(() => console.log('Document successfully updated!'))
        .catch((error) => console.error('Error updating document: ', error));
    }
  }
  deleteTask(row) {
    const ERPref = db.collection('emergencyPlans').doc(this.state.planID);
    ERPref.get().then((query) => {
      const data = query.data();
      if (data.isActive) {
        // assume the ERP is active
        alert('Cannot Delete a Task from an Active Plan ');
      } else {
        // assume the plan is deactivated
        this.state.taskList.splice(row.index);
        let numCompleted = this.state.numCompleted;
        if (row.isComplete) {
          // assume the task was completed
          numCompleted = numCompleted - 1;
        }
        this.setState({
          taskList: this.state.taskList,
          totalNum: this.state.totalNum - 1,
          numCompleted: numCompleted
        });
        const taskRef = db.collection('tasks').doc(row.id);
        // update the ERP tasks array of task document references
        ERPref.update({
          tasks: firestoreArrayRemove(taskRef)
        })
          .then(() =>
            console.log('Successfully removed the Task from the Checklist')
          )
          .catch((error) => console.error('Error updating document: ', error));
        // delete the task Document
        taskRef
          .delete()
          .then(() => console.log('Document successfully deleted!'))
          .catch((error) => console.error('Error removing document: ', error));
      }
    });
  }
  handleTaskStatusChange(row) {
    const status = !row.isComplete;
    let taskList = this.state.taskList;
    taskList[row.index].isComplete = status;
    if (status === true) {
      taskList[row.index].dateCompleted = firestoreServerTimestamp();
      taskList[row.index].completedBy = this.state.adminName;
    }
    let numCompleted = this.state.numCompleted;
    if (status) {
      // assume the task has been marked as completed
      numCompleted += 1;
    } else if (!status) {
      numCompleted -= 1;
    }
    // console.log('Assigned RC: ', row.assignedRC)
    if (row.assignedRC !== '') {
      // assume Task has an assigned Roll Call
      let idx = this.state.rcList.findIndex(
        (rollCall) => rollCall.name === row.assignedRC
      );
      // console.log(this.state.rcList)
      // console.log('index: ', idx)
      if (idx >= 0 && !this.state.rcList[idx].isRCActive) {
        // assume Roll Call exisiting and is not active
        this.rcActiveToggle({ index: idx });
      }
    }
    this.setState({
      taskList: taskList,
      numCompleted: numCompleted
    });
    var task = db.collection('tasks').doc(this.state.taskList[row.index].id);
    task
      .update({
        isComplete: status,
        dateCompleted: firestoreServerTimestamp(),
        completedBy: this.state.adminName
      })
      .then(() => console.log('Document successfully updated!'))
      .catch((error) => console.error('Error updating document: ', error));
    if (status === true) {
      var relatedItems = [];
      relatedItems.push(this.state.taskList[row.index].emergencyPlan);
      relatedItems.push(db.collection('users').doc(this.state.uid));
      const taskLogID = db.collection('taskLog').doc().id;
      let taskLog = {
        date: new Date(),
        id: task,
        logType: 'taskCompletionChange',
        message:
          this.state.adminName +
          ' has changed task ' +
          this.state.taskList[row.index].name +
          ' to complete.',
        relatedItems: relatedItems
      };
      db.collection('taskLog')
        .doc(taskLogID)
        .set(taskLog)
        .then(() => console.log('TaskLog successfully added!!!'))
        .catch(() => console.error('Error adding taskLog'));
    }
  }
  handleUserCheckedInChange(row) {
    const status = !row.isCheckedIn;
    let rollCallList = this.state.rollCallList;
    rollCallList[row.index].isCheckedIn = status;
    let numCompleted = this.state.numCompleted;
    if (status) {
      numCompleted += 1;
    } else {
      numCompleted -= 1;
    }
    this.setState({
      rollCallList: rollCallList,
      numCompleted: numCompleted
    });
    var usersUpdate = {};
    usersUpdate[`userStatus.${row.UID}.isCheckedIn`] = status;
    const ERP = db.collection('emergencyPlans').doc(this.state.planID);
    ERP.update(usersUpdate)
      .then(() => console.log('Document successfully updated!'))
      .catch((error) => console.error('Error updating document: ', error));

    // this.fetchRollCallData();
  }

  sendUserReminder(action) {
    // let userTokens; // UNUSED VAR
    let tokensList = [];
    let info = [];

    //get the fcm tokens list and user info from users collection using the user id
    if (action.row !== null) {
      console.log('if');
      let idx = this.state.userList.findIndex(
        (user) => user.id === action.row.user
      );
      info.push(this.state.userList[idx]);
      console.log(info);
    } else if (action.row === null && action.users !== undefined) {
      console.log('else');
      for (let user of action.users) {
        info.push(user);
      }
    }

    for (let user of info) {
      // console.log(user)

      if (user['fcmToken'] !== undefined && user['fcmToken'] !== null) {
        // console.log(user['firstName'])
        // console.log(user['fcmToken'])
        // assume the fcmToken is defined
        tokensList = Object.values(user.fcmToken);
        //console.log(tokensList)
        for (let token of tokensList) {
          if (token.length > 0) {
            let userRef = db.collection('users').doc(user['id']);

            let log = {
              date: new Date(),
              message: 'Please fill out your heath screening form.',
              users: userRef,
              reference: null
            };

            const data = {
              // sent to firebase func
              name: user['firstName'],
              tokens: token,
              title: 'Reminder for filling out your Health Form',
              message: 'Please fill out your heath screening form',
              collection: 'users',
              reference: user['id'],
              id: user['id']
            };

            // execute the firebase function
            const send = firebaseFns.httpsCallable('alertUserByAdmin');
            send(data)
              .then((result) => console.log(result))
              .catch((error) => console.log('Error: ', error));

            var logRef = db.collection('notificationHistory').doc();
            db.collection('notificationHistory').doc(logRef.id).set(log);
          }
        }
      }
    }
  }

  addUserGroup(row) {
    // array of user doc refs
    let userArr = [];
    for (let user of row.users) {
      // user reference
      let uref = db.collection('users').doc(user.id);
      userArr.push(uref);
    }
    // get the index of the leader
    const idx = row.users.findIndex((user) => user.name === row.leader);
    db.collection('users')
      .doc(row.users[idx].id)
      .get()
      .then((query) => {
        const data = query.data();
        const email = data.email;
        const phoneNumber = data.phoneNumber;
        const fullName = data.firstName + ' ' + data.lastName;
        var userGroup = {
          name: row.name,
          leader: fullName, //leader's name
          email: email, // leader's email
          phoneNumber: phoneNumber, // leader's phone number
          companyID: this.state.companyID,
          users: userArr
        };
        const gref = db.collection('userGroups').doc();
        db.collection('userGroups')
          .doc(gref.id)
          .set(userGroup)
          .then(() => console.log('User Group successfully added!!!'))
          .catch(() => console.error('Error adding UserGroup'));
        userGroup.id = gref.id;
        this.setState({ groupList: [userGroup, ...this.state.groupList] });
      });
  }
  deleteUserGroup(row) {
    this.state.groupList.splice(row.index, 1);
    this.setState({ groupList: this.state.groupList });
    db.collection('userGroups')
      .doc(row.id)
      .delete()
      .then(() => console.log('Successfully removed the User Group'))
      .catch((error) => console.log('Error removing the User Group', error));
  }
  editUserGroup(row) {
    let userArr = []; // array of user doc references
    for (let user of row.users) {
      // user reference
      let uref = db.collection('users').doc(user.id);
      userArr.push(uref);
    }
    // get the index of the leader
    let idx = row.users.findIndex((user) => user.name === row.leader);

    if (idx === -1) {
      alert('Cannot find selected Group Leader');
      return;
    }

    // get the document reference of the user
    db.collection('users')
      .doc(row.users[idx].id)
      .get()
      .then((query) => {
        let data = query.data();
        let email = data.email;
        let phoneNumber = data.phoneNumber;
        let fullName = data.firstName + ' ' + data.lastName;
        // updateState
        let groupList = this.state.groupList;
        groupList[row.index].name = row.name;
        groupList[row.index].email = email;
        groupList[row.index].phoneNumber = phoneNumber;
        groupList[row.index].users = userArr;
        this.setState({ groupList: groupList });
        // get the groupID
        const groupID = this.state.groupList[row.index].id;
        // update the group reference
        const groupRef = db.collection('userGroups').doc(groupID);
        groupRef
          .update({
            name: row.name,
            email: email,
            leader: fullName,
            phoneNumber: phoneNumber,
            users: userArr
          })
          .then(() => console.log('Document successfully updated!'))
          .catch((error) => console.error('Error updating document: ', error));
      });
  }

  filterTable(action) {
    var fList = [];
    if (action.type === 'FILTER_USER_GROUPS') {
      fList = this.state.groupList.filter(
        (group) => group.name.toLowerCase().search(action.value) !== -1
      );
    } else if (action.type === 'FILTER_USER_LIST') {
      if (this.state.viewDeletedUsers) {
        fList = this.state.deletedUsers.filter(
          (user) =>
            user.name.toLowerCase().search(action.value.toLowerCase()) !== -1
        );
      } else if (this.state.appOnlyUsers) {
        fList = this.state.userList.filter(
          (user) =>
            (user.name.toLowerCase().search(action.value.toLowerCase()) !==
              -1 ||
              user.email.search(action.value.toLowerCase()) !== -1 ||
              user.phone.search(action.value.toLowerCase()) !== -1) &&
            user.user_type === 'APP'
        );
      } else {
        fList = this.state.userList.filter(
          (user) =>
            user.name.toLowerCase().search(action.value.toLowerCase()) !== -1
        );
      }
    } else if (action.type === 'FILTER_APP_USER_LIST') {
      fList = this.state.userList.filter((user) => user.user_type === 'APP');
      this.setState({
        appOnlyUsers: true
      });
    } else if (action.type === 'FILTER_COMPLETED_FORM_USER_LIST') {
      let tmpUsers = [];
      this.state.cFormsList.map((user) => {
        if (user.name) {
          if (user.name.toLowerCase().search(action.value) !== -1)
            tmpUsers.push(user);
        }
        return null; // to avoid warning; s/b refactored to forEach
      });

      fList = tmpUsers;
    } else if (action.type === 'FILTER_CLIENT_LIST') {
      let filteredclient = [];
      this.state.clientList.map((client) => {
        if (client.clientName.toLowerCase().search(action.value) !== -1) {
          console.log(client);
          filteredclient.push(client);
        }

        return null; // to avoid warning; s/b refactored to forEach
      });

      fList = filteredclient;
      // fList = this.state.clientList.filter((client) => ( client.clientName.search(action.value) !== -1) )
    } else if (action.type === 'FILTER_ROLL_CALL_LIST') {
      if (action.filter === '') {
        fList = this.state.rcUserList;
      } else {
        fList = this.state.rcUserList.filter(
          (user) => user.status === action.filter
        );
      }
      this.setState({
        viewFilteredList: true,
        filteredList: fList
      });
      return;
    } else if (action.type === 'FILTER_CHECK_LIST') {
      fList = this.state.planList.filter(
        (plan) => plan.name.toLowerCase().search(action.value) !== -1
      );
    } else if (action.type === 'FILTER_CHECK_LIST_BY_COMPANYID') {
      fList = this.state.planList.filter(
        (plan) => plan.companyID.search(action.value) !== -1
      );
    } else if (action.type === 'FILTER_ROLLCALL_LIST_BY_COMPANYID') {
      fList = this.state.rcList.filter(
        (plan) => plan.companyID.search(action.value) !== -1
      );
    } else if (action.type === 'FILTER_HEALTHSCREENREPORT_BY_DATE_RANGE') {
      if (action.startdate && action.lastdate) {
        let sd = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }).format(action.startdate);
        sd = Date.parse(sd);
        sd = sd / 1000;
        // console.log("sd: "+sd)
        let ld = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }).format(action.lastdate);
        ld = Date.parse(ld);
        ld = ld / 1000;
        // console.log("ld: "+ld)
        let tempusers = [];
        this.state.cFormsList.map((item) => {
          if (item.date !== undefined && item.date !== null) {
            if (item.date.seconds !== undefined || item.date.seconds !== null)
              if (item.date.seconds >= sd && item.date.seconds <= ld) {
                tempusers.push(item);
              }
          }
          return null; // to avoid warning; s/b refactored to forEach
        });

        fList = tempusers;
      }
    } else if (action.type === 'FILTER_LIBRARY_BY_DATE_RANGE') {
      if (action.startdate && action.lastdate) {
        let sd = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }).format(action.startdate);
        console.log('sd: ' + sd);
        let ld = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }).format(action.lastdate);
        console.log('ld: ' + ld);
        let temprepo = [];
        // TODO - update to use library
        this.state.repository.map((repo) => {
          // console.log(repo)
          if (repo.uploadTime !== undefined) {
            if (repo.uploadTime >= sd && repo.uploadTime <= ld) {
              temprepo.push(repo);
            }
          }
          return null; // to avoid warning; s/b refactored to forEach
        });

        // console.log(temprepo)
        fList = temprepo;
        // console.log(fList.length);
      }
    } else if (action.type === 'FILTER_CHECK_LIST_BY_DATE_RANGE') {
      if (action.startdate && action.lastdate) {
        console.log('start date: ' + action.startdate);
        console.log('last date: ' + action.lastdate);

        let sd = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }).format(action.startdate);
        sd = Date.parse(sd);
        sd = sd / 1000;
        let ld = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }).format(action.lastdate);
        ld = Date.parse(ld);
        ld = ld / 1000;
        console.log('startdate: ' + sd);
        console.log('lastdate: ' + ld);
        // console.log(this.state.planList[11])
        // console.log(this.state.planList[11].dateActivated.seconds)
        // console.log(this.state.planList[11].dateDeactivated.seconds)
        // if(this.state.planList[11].dateActivated.seconds >= sd && this.state.planList[11].dateDeactivated.seconds <= ld)
        // 	console.log("Yes,dateDeactivated <= last date in the range and >= startdate")
        // else
        // 	console.log("No,dateDeactivated > last date in the range")
        // // this.state.planList.map(plan => {console.log(plan)})
        // fList = this.state.planList.filter(function(plan, sd, ld){
        // 	if(plan.dateActivated !== undefined)
        // 	{
        // 		if(plan.dateDeactivated !== undefined){
        // 			if(plan.dateActivated.seconds >= sd && plan.dateDeactivated.seconds <= ld){
        // 				console.log(plan);
        // 				console.log(sd);
        // 				console.log(ld);
        // 				return plan;
        // 			}
        // 		}
        // 	}
        // })
        // 			    fList = this.state.planList.filter(plan => plan.dateActivated.seconds >= sd && plan.dateDeactivated.seconds <= ld)
        let templan = [];
        this.state.planList.map((plan) => {
          if (
            plan.dateActivated !== undefined &&
            plan.dateActivated !== null &&
            plan.dateDeactivated !== undefined &&
            plan.dateDeactivated !== null
          ) {
            if (
              plan.dateActivated.seconds >= sd &&
              plan.dateDeactivated.seconds <= ld
            ) {
              templan.push(plan);
            }
          }
          return null; // to avoid warning; s/b refactored to forEach
        });
        // console.log(templan)
        fList = templan;
        // console.log(fList.length);
      }
    } else if (action.type === 'FILTER_ROLLCALL_LIST_BY_DATE_RANGE') {
      if (action.startdate && action.lastdate) {
        let sd = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }).format(action.startdate);
        sd = Date.parse(sd);
        sd = sd / 1000;
        // console.log(sd);
        let ld = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }).format(action.lastdate);
        ld = Date.parse(ld);
        ld = ld / 1000;
        // console.log(ld);
        let temprc = [];
        this.state.rcList.map((rc) => {
          // console.log(rc);

          if (rc.dateActivated !== undefined) {
            if (
              rc.dateActivated.seconds >= sd &&
              rc.dateActivated.seconds <= ld
            ) {
              temprc.push(rc);
            }
          }
          return null; // to avoid warning; s/b refactored to forEach
        });
        // console.log(templan)
        fList = temprc;
        // console.log(fList.length);
      }
    } else if (action.type === 'FILTER_ROLLCALL_LIST') {
      fList = this.state.rcList.filter(
        (rollCall) => rollCall.name.toLowerCase().search(action.value) !== -1
      );
    } else if (action.type === 'FILTER_TEMPLATE_LIST') {
      fList = this.state.tmplList.filter(
        (tmpl) => tmpl.name.toLowerCase().search(action.value) !== -1
      );
    } else if (action.type === 'FILTER_TASK_LIST') {
      fList = this.state.taskList.filter(
        (task) => task.name.toLowerCase().search(action.value) !== -1
      );
    } else if (action.type === 'FILTER_LIBRARY_LIST') {
      // TODO - update to use Store.library
      fList = this.state.repository.filter(
        (repo) => repo.itemName.toLowerCase().search(action.value) !== -1
      );
    }
    this.setState({
      viewFilteredList: true,
      filteredList: fList
    });
  }
  handleToggleMenu() {
    let status = !this.state.toggleMenu;
    this.setState({ toggleMenu: status });
  }
  /** Params: rcName {String} name of the current Roll Call
   * 			uid {String} unique ID of the current user
   */
  changeStatusColor(uid, rcName) {
    /*****
     * There are 3 things I have to update
     * 1) the rcList state -> an array of RollCalls
     * 2) the Roll Call stored on firebase -> an object
     * 3) the rcUserList -> an array of Users (in case the user is view the list)
     */
    let rcList = this.state.rcList;
    let rcID;
    let idx; // idx to update the rcList element
    for (let i = 0; i < rcList.length; i++) {
      let rc = rcList[i];
      if (rc.name === rcName) {
        // assume its the current Roll Call item
        if (rc.users[uid].status === 'yellow') {
          // assume the status is yellow
          rc.users[uid].status = 'red';
          // TODO: update the timestamp??
        }
        rcID = rc.id; // get the id of the roll call
        idx = i;
        break;
      }
    }
    // if the rcUserList is open to the current
    // TODOOOOOOOOOOOOOOOOOOOOOOOO make sure youre viewing the correct Roll Call
    let rcUserList = this.state.rcUserList;
    for (let user of rcUserList) {
      if (user.uid === uid && user.status === 'yellow') {
        // assume the current user has a yellow status
        user.status = 'red';
        // TODO: update the timestamp??

        // update the progress bars
        let numYellow = this.state.numYellow;
        let numRed = this.state.numRed;
        this.setState({
          numYellow: numYellow - 1,
          numRed: numRed + 1
        });
        // this.state.numYellow -= 1;
        // this.state.numRed += 1;
        break;
      }
    }
    // updates the Roll Call Firebase doc
    if (rcID) {
      var rollCall = db.collection('rollCalls').doc(rcID);
      rollCall
        .update({ users: rcList[idx].users })
        .then(() => console.log('Successfully updated the Roll Call'))
        .catch((error) => console.log('Error: ', error));
      this.setState({
        rcUserList: rcUserList,
        rcList: rcList,
        numYellow: this.state.numYellow,
        numRed: this.state.numRed
      });
      setTimeout(() => {
        this.fetchPlanData();
      }, 10 * 1000);
    }
  }
  createRollCallCB(url, action, rcID) {
    let isRCActive = false;
    if (
      action.isRCActive !== undefined &&
      action.isRCActive !== null &&
      action.isRCActive
    ) {
      // assume roll call should be active on creation
      isRCActive = action.isRCActive;
      isRCActive = true;
    }
    let date = new Date();

    const rollCall = {
      name: action.name,
      users: action.userMap,
      isRCActive: isRCActive,
      withNotifications: action.withNotifications,
      rcDescription: action.rcDescription,
      companyID: this.state.companyID,
      createdByName: this.state.userName ? this.state.userName : null, // this may not be defined
      createdByRole: this.state.role,
      dateActivated: date,
      // dateActivatedString: new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(date.seconds*1000),
      isArchived: false,
      hasTimedOut: false,
      type: action.flashType
    };
    if (url === '') {
      // assume logo was NOT uploaded
      rollCall.logo = image;
    } else {
      // assume logo was uploaded
      rollCall.logo = url;
    }
    rollCall.id = rcID; // need the id in the UI state
    this.state.rcList.unshift(rollCall);
    this.setState({ rcList: this.state.rcList });
    db.collection('rollCalls')
      .doc(rcID)
      .set(rollCall)
      .then(() => console.log('Roll Call successfully added!!!'))
      .catch(() => console.error('Error adding Roll Call'));
    let userTokens = []; // array of user fcmTokens
    let userArr = []; // array of user doc refs
    let count = 0;
    for (let rcUser in action.userMap) {
      let userRef = db.collection('users').doc(rcUser);
      userArr.push(userRef);
      let idx = this.state.userList.findIndex((curr) => curr.id === rcUser);
      let data = this.state.userList[idx];
      if (data.fcmToken !== undefined) {
        // assume the fcmToken is defined
        userTokens.push(data.fcmToken);
      }
      if (count === Object.keys(action.userMap).length - 1) {
        // assume its the last element in the array
        const rcData = {
          // sent to firebase func
          name: action.name,
          users: userTokens,
          isActive: isRCActive,
          id: rcID,
          message: isRCActive
            ? 'Please check-in to your Roll Call.'
            : 'Roll Call is deactivated.'
        };
        // execute the firebase function
        const alertRollCall = firebaseFns.httpsCallable('alertRollCallUsers');
        alertRollCall(rcData)
          .then((result) => console.log(result))
          .catch((error) => console.log('Error: ', error));
      }
      count += 1;
    }
    if (this.state.role === 'Root') {
      // change the companyID of root back to normal
      const rootRef = db.collection('users').doc(this.state.uid);
      rootRef.get().then((query) => {
        let data = query.data();
        this.setState({ companyID: data.companyID });
      });
    }
    // adds to the users notification history
    var rollRef = db.collection('rollCalls').doc(rcID);
    let log = {
      date: new Date(),
      message: 'Roll Call: ' + action.name + ' was activated.',
      users: userArr,
      reference: rollRef
    };
    var logRef = db.collection('notificationHistory').doc();
    db.collection('notificationHistory').doc(logRef.id).set(log);
  }
  createRollCall(action) {
    // TODO: add createdBy: userId to the roll call
    var rollRef = db.collection('rollCalls').doc();
    const rcID = rollRef.id;
    let idx = this.state.groupList.findIndex(
      (group) => group.name === action.teamName
    );
    if (idx !== -1) {
      // assume a user group was selected
      let umap = {};
      for (let user in action.userMap) {
        umap[user] = 1;
      }
      for (let nuser of this.state.groupList[idx].users) {
        if (nuser.id !== undefined && !(nuser.id in umap)) {
          umap[nuser.id] = 1;
          // console.log("actionMap: ", action.userMap)
          // console.log(nuser.id)
          // get the name of the curr user
          let i = this.state.userList.findIndex((curr) => curr.id === nuser.id);
          if (i !== -1) {
            let item = { name: this.state.userList[i].name };
            action.userMap[nuser.id] = item;
          }
        }
      }
    }
    // {array of objects} rollCall name, update_dt and users array of uids
    var activatedRollcalls = [];
    if (localStorage.getItem('activatedRollcalls') !== null) {
      if (localStorage.getItem('activatedRollcalls').length > 0) {
        activatedRollcalls = JSON.parse(
          localStorage.getItem('activatedRollcalls').toString()
        );
      }
    }
    let currRollCall = {
      name: action.name,
      users: [],
      update_dt: new Date().getTime() / 1000 + 60 * 20
    };
    for (let user in action.userMap) {
      currRollCall.users.push(user);
      action.userMap[user].isCheckedIn = false;
      action.userMap[user].status = 'yellow';
      action.userMap[user].user_id = user;
    }
    activatedRollcalls.push(currRollCall);
    // console.log(activatedRollcalls)
    localStorage.setItem(
      'activatedRollcalls',
      JSON.stringify(activatedRollcalls)
    );
    if (action.logo === '') {
      // assume logo was NOT uploaded
      this.createRollCallCB(action.logo, action, rcID);
    } else {
      // assume logo was uploaded
      // creates a child directory called the UID, and the file is placed inside this directory
      const uploadTask = storageRef
        .child(`rollCalls/${rcID}/${rcID}.jpeg`)
        .put(action.logo);
      uploadTask.on(
        'state_changed',
        () => {},
        (error) => console.log(error),
        () => {
          // got the URL of the firebase storage image
          uploadTask.snapshot.ref.getDownloadURL().then((url) => {
            this.createRollCallCB(url, action, rcID);
          });
          console.log(
            'Successfully stored and retrieve the url from the image'
          );
        }
      );
    }
  }
  // can only edit the RC name, # of users, description and notification setting
  editRollCall(action) {
    // TODO: make sure youre editing the correct item (filtered or not)
    let rcID = this.state.rcList[action.index].id;
    // status and isCheckedIn cannot be updated here
    let updatedMap = {};
    let userMap = this.state.rcList[action.index].users;
    for (let user in action.userMap) {
      // go through all the users in the edited user map
      if (!(user in userMap)) {
        // assume its a new user
        updatedMap[user] = {
          name: action.userMap[user].name,
          isCheckedIn: false,
          status: 'yellow'
        };
      } else {
        // assume user already exists in RC
        updatedMap[user] = {
          name: userMap[user].name,
          isCheckedIn: userMap[user].isCheckedIn,
          status: userMap[user].status
        };
      }
    }
    let rcList = this.state.rcList;
    rcList[action.index].name = action.name;
    rcList[action.index].users = updatedMap;
    rcList[action.index].withNotifications = action.withNotifications;
    rcList[action.index].rcDescription = action.rcDescription;
    // update the Roll Call array of roll Call references
    const rollCallRef = db.collection('rollCalls').doc(rcID);
    rollCallRef
      .update({
        name: action.name,
        users: updatedMap,
        withNotifications: action.withNotifications,
        rcDescription: action.rcDescription
      })
      .then(() => {
        if (action.logo !== '') {
          // assume that the user changed the logo
          const uploadTask = storageRef
            .child(`rollCalls/${rcID}/${rcID}.jpeg`)
            .put(action.logo);
          uploadTask.on(
            'state_changed',
            () => {},
            (error) => console.log(error),
            () => {
              // got the URL of the firebase storage image
              uploadTask.snapshot.ref.getDownloadURL().then((url) => {
                rcList[action.index].logo = url;
                this.setState({ rcList: rcList });
                // update the url stored in firebase
                const rollCall = db.collection('rollCalls').doc(rcID);
                rollCall
                  .update({ logo: url })
                  .then(() => console.log('Document successfully updated!'))
                  .catch((error) =>
                    console.error('Error updating document: ', error)
                  );
              });
              console.log(
                'Successfully stored and retrieve the url from the image'
              );
            }
          );
        } else {
          this.setState({ rcList: rcList });
        }
      })
      .catch((error) => console.error('Error updating document: ', error));

    let userTokens = []; // array of user fcmTokens
    let userArr = []; // array of user doc refs
    let count = 0;
    let isRCActive = this.state.rcList[action.index].isRCActive; // active status of flash
    for (let rcUser in updatedMap) {
      let userRef = db.collection('users').doc(rcUser);
      userArr.push(userRef);
      let idx = this.state.userList.findIndex((curr) => curr.id === rcUser);
      let data = this.state.userList[idx];
      if (data.fcmToken !== undefined) {
        // assume the fcmToken is defined
        userTokens.push(data.fcmToken);
      }
      if (count === Object.keys(updatedMap).length - 1) {
        // assume its the last element in the array
        const rcData = {
          // sent to firebase func
          name: action.name + ' has been updated',
          users: userTokens,
          isActive: isRCActive,
          id: rcID,
          // message: (isRCActive) ? "Please check-in to your Roll Call." : "Roll Call is deactivated."
          message: action.rcDescription
        };
        // execute the firebase function
        const alertRollCall = firebaseFns.httpsCallable('alertRollCallUsers');
        alertRollCall(rcData)
          .then((result) => console.log(result))
          .catch((error) => console.log('Error: ', error));
      }
      count += 1;
    }
  }
  deleteRollCall(rcID) {
    // 	let idx = this.state.rcList.findIndex((curr) => curr.id === rcID)
    // 	if (idx !== -1) {
    // 		let id = this.state.rcList[idx].id;
    // 		db.collection('rollCalls').doc(id).delete()
    // 		.then(() => console.log('Successfully removed the Roll Call'))
    // 		.catch((error) => console.log('Error removing the Roll Call', error))
    // 		this.state.rcList.splice(idx, 1)
    // 		this.setState({rcList: this.state.rcList})
    // 	}
  }

  archive(action) {
    console.log('here4 ');
    //let status = this.state.rcList[action.index].isRCActive

    if (action.checklist) {
      const ID = this.state.planList[action.index].id;
      const checklist = db.collection('emergencyPlans').doc(ID);
      checklist
        .update({
          isArchived: action.isArchived,
          notes: action.notes
        }) //archived but no deleted completely
        .then(() => console.log('Checklist Archived successfully!'))
        .catch((error) => console.error('Error updating document: ', error));
    }
    if (action.rollcall) {
      const ID = this.state.rcList[action.index].id;
      const rollCall = db.collection('rollCalls').doc(ID);
      rollCall
        .update({
          isArchived: action.isArchived,
          notes: action.notes
        }) //archived but no deleted completely
        .then(() => console.log('Roll Call Archived successfully!'))
        .catch((error) => console.error('Error updating document: ', error));
    }

    //if isRCActive == false : means that particular item is active.
  }

  rcActiveToggle({ index }) {
    // console.log(index);
    const toggle = !this.state.rcList[index].isRCActive;
    // console.log(this.state.rcList[index].isRCActive);
    // send notification to users
    let userTokens = []; // array of user fcmTokens
    let count = 0;
    for (let user in this.state.rcList[index].users) {
      let idx = this.state.userList.findIndex((curr) => curr.id === user);
      let data = this.state.userList[idx];
      if (data !== undefined && data.fcmToken !== undefined) {
        // assume the fcmToken is defined
        userTokens.push(data.fcmToken);
      }
      if (count === Object.keys(this.state.rcList[index].users).length - 1) {
        // assume its the last element in the array
        const rcData = {
          // sent to firebase func
          name: this.state.rcList[index].name,
          users: userTokens,
          isActive: toggle,
          id: this.state.rcList[index].id,
          message: toggle
            ? 'Please check-in to your Roll Call.'
            : 'Roll Call is deactivated'
        };
        // execute the firebase function
        const alertERollCall = firebaseFns.httpsCallable('alertRollCallUsers');
        alertERollCall(rcData)
          .then((result) => console.log(result))
          .catch((error) => console.log('Error: ', error));
      }
      count += 1;
    }
    let rcList = this.state.rcList;
    rcList[index].isRCActive = toggle;
    this.setState({ rcList: rcList });
    const ID = this.state.rcList[index].id;
    const rollCall = db.collection('rollCalls').doc(ID);
    rollCall
      .update({ isRCActive: toggle })
      .then(() => console.log('Document successfully updated!'))
      .catch((error) => console.error('Error updating document: ', error));

    // {array of objects} rollCall name, update_dt and users array of uids
    var activatedRollcalls = [];
    if (
      localStorage.getItem('activatedRollcalls') !== undefined &&
      localStorage.getItem('activatedRollcalls') !== null
    ) {
      activatedRollcalls = JSON.parse(
        localStorage.getItem('activatedRollcalls').toString()
      );
    }
    let currRollCall = {
      name: rcList[index].name,
      users: [],
      update_dt: new Date().getTime() / 1000 + 60 * 20
    };
    if (toggle) {
      for (let user in rcList[index].users) {
        currRollCall.users.push(user);
        // assume the Roll Call just got activated
        setTimeout(() => {
          this.changeStatusColor(user, rcList[index].name);
        }, 20 * 60 * 1000);
      }
      activatedRollcalls.push(currRollCall);
      localStorage.setItem(
        'activatedRollcalls',
        JSON.stringify(activatedRollcalls)
      );
    }
  }
  updateUserStatus(row) {
    // update the checkIn property
    let userMap = this.state.rcList[row.index].users;
    userMap[row.uid].isCheckedIn = true;
    userMap[row.uid].status = 'green';
    userMap[row.uid].dateLastUpdated = new Date();
    // update state
    let rcList = this.state.rcList;
    rcList[row.index].users = userMap;
    this.setState({ rcList: rcList });
    // update firestore
    const rcID = this.state.rcList[row.index].id;
    var rcRef = db.collection('rollCalls').doc(rcID);
    rcRef
      .update({ users: userMap })
      .then(() => this.viewRollCallUsers({ id: rcID }))
      .catch((error) => console.log('Error: ', error));
    // updated the rcUserList that is displayed
    // for the roll call userList
    // this.viewRollCallUsers({id: row.index})
    // console.log("rcID:"+rcID);
    // console.log("row.index:"+row.index);
    // 	this.viewRollCallUsers({id: rcID});
  }
  updateUserSafety(row) {
    // update the checkIn property
    /** TODO: set/update the date/time */
    let userMap = this.state.rcList[row.index].users;
    let inDanger = false;
    if (userMap[row.uid].inDanger !== undefined) {
      inDanger = !userMap[row.uid].inDanger;
    } else {
      inDanger = true;
    }
    userMap[row.uid].inDanger = inDanger;
    // why is this set to green if inDanger could be true?
    userMap[row.uid].status = 'green';
    userMap[row.uid].dateLastUpdated = new Date();
    // update state
    let rcList = this.state.rcList;
    rcList[row.index].users = userMap;
    this.setState({ rcList: rcList });
    // update firestore
    const rcID = this.state.rcList[row.index].id;
    var rcRef = db.collection('rollCalls').doc(rcID);
    rcRef
      .update({ users: userMap })
      .then(() => this.viewRollCallUsers({ id: rcID }))
      .catch((error) => console.log('Error: ', error));
    // updated the rcUserList that is displayed
    // for the roll call userList
    // this.viewRollCallUsers({id: row.index})
    // console.log("rcID:"+rcID);
    // console.log("row.index:"+row.index);
    // 	this.viewRollCallUsers({id: rcID});
  }
  viewRollCallUsers(row) {
    // console.log('1--:' + row.id);
    // rcList contains all the Roll Calls
    // find the index of the clicked on item
    const rcidx = this.state.rcList.findIndex((curr) => curr.id === row.id);

    let userList = this.state.rcList[rcidx].users;
    var rcUserList = [];
    let numGreen = 0;
    let numYellow = 0;
    let numRed = 0;
    // console.log(userList);
    for (let user in userList) {
      let color = userList[user].status; // the status of the user
      const idx = this.state.userList.findIndex((curr) => curr.id === user);
      if (
        idx !== -1 &&
        this.state.userList[idx].isOffline !== undefined &&
        this.state.userList[idx].isOffline
      ) {
        // as long as they are offline, their status will be black
        color = 'black';
      } else if (color === 'green') {
        numGreen += 1;
      } else if (color === 'yellow') {
        numYellow += 1;
      } else {
        numRed += 1;
      }
      console.log(user);
      let currUser = {
        name: userList[user].name,
        status: color,
        isCheckedIn: userList[user].isCheckedIn,
        inDanger: userList[user].inDanger,
        user_type: this.state.userList[idx].user_type,
        location: this.state.userList[idx].location,
        locationTime: this.state.userList[idx].locationTime,
        firstName: this.state.userList[idx].firstName,
        lastName: this.state.userList[idx].lastName,
        uid: user
      };
      rcUserList.push(currUser);
    }
    console.log('rcUserList');
    console.log(rcUserList);
    this.setState({
      rcUserList: rcUserList,
      numGreen: numGreen,
      numYellow: numYellow,
      numRed: numRed,
      viewFilteredList: false,
      // planPager: (row.planPager) ? (row.planPager) : 2,
      planPager: row.planPager ? row.planPager : 4,
      // subPlanPager: (row.subPlanPager) ? (row.subPlanPager) : 0,
      subPlanPager: row.subPlanPager ? row.subPlanPager : 4
    });
  }
  fetchUserMessages() {
    if (this.state.isClientAccount) {
      return;
    }
    // get all the messages related to the current user
    let messageLogs = [];
    var userRef = db.collection('users').doc(this.state.uid);
    db.collection('messageLog')
      .where('messageUsers', 'array-contains', userRef)
      .get()
      .then((querySnapshot) => {
        const mLogList = querySnapshot.docs;
        for (let i = 0; i < mLogList.length; i++) {
          let mLogID = mLogList[i].id;
          let mLog = mLogList[i].data();
          mLog.id = mLogID;
          messageLogs.push(mLog);
          if (mLog.usersRead !== undefined && mLog.usersRead !== null) {
            let notificationCheck = true;
            for (let j = 0; j < mLog.usersRead.length; j++) {
              // iterate through all the users in the usersRead
              if (mLog.usersRead[j].id === this.state.uid) {
                // assume user has read the message
                notificationCheck = false;
              }
            }
            if (notificationCheck) {
              // assume there is a active unread message
              this.setState({ activeNotification: true });
            }
          }
          if (i === mLogList.length - 1) {
            this.setState({ messageLogs: messageLogs });
          }
        }
      })
      .catch((error) => console.log('Error getting documents: ', error));
  }
  getCurrentMsgs(mLogID, otherUserName) {
    // assume the message Log exists and populate messages
    if (mLogID === undefined && mLogID === null) {
      console.log('error getting the message log');
      return;
    }
    // clear the convo list
    if (document.getElementById('convoList') !== null) {
      document.getElementById('convoList').innerHTML = '';
    }
    let idx = this.state.messageLogs.findIndex((mLog) => mLog.id === mLogID);
    console.log('getting curr messages', idx);
    let currMessageLog = {};
    currMessageLog = this.state.messageLogs[idx];
    // update the state of the messagelog usersDead
    var userRef = db.collection('users').doc(this.state.uid);
    this.state.messageLogs[idx].usersRead.push(userRef);
    this.setState({
      currMessageLog: currMessageLog,
      viewUserChat: true,
      otherUserName: otherUserName,
      activeNotification: false,
      messageLogs: this.state.messageLogs
    });
    let messages = [];
    var mLogRef = db.collection('messageLog').doc(mLogID);
    mLogRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          var userRef = db.collection('users').doc(this.state.uid);
          mLogRef.update({
            usersRead: firestoreArrayUnion(userRef)
          });
        } else console.log('No such document!');
      })
      .catch((error) => console.log('Error getting document:', error));

    db.collection('messages')
      .where('logReference', '==', mLogRef)
      .get()
      .then((querySnapshot) => {
        const mList = querySnapshot.docs;
        for (let i = 0; i < mList.length; i++) {
          let mid = mList[i].id;
          let curr = mList[i].data();
          curr.id = mid;
          messages.push(curr);
          if (i === mList.length - 1) {
            messages.sort((a, b) => a.timeStamp.seconds - b.timeStamp.seconds);
            document.getElementById('messageList').innerHTML = '';
            var messageList = document.getElementById('messageList');
            for (let message of messages) {
              var nameItem = document.createElement('li');
              var item = document.createElement('li');
              if (this.state.uid === message.fromID.id) {
                // assume user is the currently signed in user
                item.setAttribute('class', 'currUserMessage');
                nameItem.setAttribute('class', 'currUserName');
                nameItem.innerHTML = this.state.adminName;
                messageList.appendChild(nameItem);
              } else {
                let idx = this.state.userList.findIndex(
                  (curr) => curr.id === message.fromID.id
                );
                if (idx !== -1) {
                  nameItem.setAttribute('class', 'otherUserName');
                  nameItem.innerHTML = this.state.userList[idx].name;
                  messageList.appendChild(nameItem);
                }
                item.setAttribute('class', 'otherUserMessage');
              }
              if (message.isImage) {
                if (
                  message.imageURL === undefined ||
                  message.imageURL === null ||
                  message.imageURL.length < 10
                ) {
                  console.log('Message has an invalid imageURL field');
                  return;
                }
                item.style.width = '100%';
                let img = document.createElement('img');
                img.setAttribute('src', message.imageURL);
                img.style.width = '95%';
                img.style.margin = '5px';
                item.appendChild(img);
                if (
                  this.state.messageLogs[idx].messageUsers.length > 2 &&
                  this.state.uid !== message.fromID.id
                ) {
                  // group chat and not the current user
                  console.log('group chat here');
                }
                messageList.appendChild(item);
              } else {
                item.innerHTML = message.message;
                if (
                  this.state.messageLogs[idx].messageUsers.length > 2 &&
                  this.state.uid !== message.fromID.id
                ) {
                  // group chat and not the current user
                  console.log('group chat here');
                }
                messageList.appendChild(item);
              }
            }
            this.setState({ messages: messages });
          }
        }
      })
      .catch((error) => console.log('Error getting documents: ', error));
  }
  handleMessageChange(event) {
    this.setState({ newMsg: event.target.value });
  }
  // uid {String: uid of the otherUser} / otherUserName {String: name of the otherUser}
  createNewMessageLog(uid, otherUserName) {
    for (let mLog of this.state.messageLogs) {
      // iterating thru all the msgLogs to check if the convo already exists
      if (uid === mLog.messageUsers[0].id || uid === mLog.messageUsers[1].id) {
        // assume the message Log already exists and open chat
        this.getCurrentMsgs(mLog.id, otherUserName);
        return;
      }
    }
    // assume there is no message Log and create the messageLog doc
    var otherUserRef = db.collection('users').doc(uid);
    var currUserRef = db.collection('users').doc(this.state.uid);
    let mLogData = {
      latestTimestamp: new Date(),
      messageOwner: currUserRef,
      messageUsers: [currUserRef, otherUserRef], //update
      usersRead: [currUserRef]
    };
    var mLogRef = db.collection('messageLog').doc();
    db.collection('messageLog')
      .doc(mLogRef.id)
      .set(mLogData)
      .then(() => this.getCurrentMsgs(mLogRef.id, otherUserName))
      .catch((error) => console.log('Failed to upload mLog', error));
    mLogData.id = mLogRef.id;
    this.setState({
      messageLogs: [...this.state.messageLogs, mLogData],
      currMessageLog: mLogData
    });
  }

  sendNewMsg(file) {
    if (
      this.state.newMsg.length === 0 &&
      (file === undefined || file === null)
    ) {
      return;
    }
    var userRef = db.collection('users').doc(this.state.uid);
    var mLogRef = db.collection('messageLog').doc(this.state.currMessageLog.id);
    // create the message doc
    let userMessage = {};
    if (file !== undefined && file !== null) {
      userMessage = {
        fromID: userRef,
        imageHeightRatio: 1,
        isImage: true,
        logReference: mLogRef,
        timeStamp: new Date()
      };
    } else {
      userMessage = {
        fromID: userRef,
        isImage: false,
        logReference: mLogRef,
        message: this.state.newMsg,
        timeStamp: new Date()
      };
    }
    var messageList = document.getElementById('messageList');
    var nameItem = document.createElement('li');
    nameItem.setAttribute('class', 'currUserName');
    nameItem.innerHTML = this.state.adminName;
    messageList.appendChild(nameItem);
    var item = document.createElement('li');
    item.setAttribute('class', 'currUserMessage');
    // upload to firestore
    var message = db.collection('messages').doc();
    if (file !== undefined && file !== null) {
      // add image message to DOM
      // uploading the image to storage
      const uploadTask = storageRef
        .child('messages/' + mLogRef.id + '/' + message.id + '.jpeg')
        .put(file);
      uploadTask.on('state_changed', null, null, () => {
        // got the URL of the firebase storage image
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          userMessage.imageURL = url;
          db.collection('messages')
            .doc(message.id)
            .set(userMessage)
            .then(() => console.log('Successfully added the message'))
            .catch((error) => console.log('Failed to upload message', error));
          item.style.width = '100%';
          let img = document.createElement('img');
          img.setAttribute('src', url);
          img.style.width = '95%';
          img.style.margin = '5px';
          item.appendChild(img);
          messageList.appendChild(item);
          // not from the current user
          // add message to messages list
          this.setState({ messages: [...this.state.messages, userMessage] });
        });
        console.log('Successfully stored and retrieve the url from the image');
      });
    } else {
      // add text message to DOM
      db.collection('messages')
        .doc(message.id)
        .set(userMessage)
        .then(() => console.log('Successfully added the message'))
        .catch((error) => console.log('Failed to upload message', error));
      item.innerHTML = this.state.newMsg;
      messageList.appendChild(item);
      // udpate the local State
      this.setState({ messages: [...this.state.messages, userMessage] });
    }
    // send the notification
    let userTokens = []; // array of user fcmTokens
    let count = 0;
    for (let uref of this.state.currMessageLog.messageUsers) {
      let idx = this.state.userList.findIndex((curr) => curr.id === uref.id);
      let data = this.state.userList[idx];
      if (data.fcmToken !== undefined) {
        // assume the fcmToken is defined
        userTokens.push(data.fcmToken);
      }

      if (count === this.state.currMessageLog.messageUsers.length - 1) {
        // assume its the last element in the array
        const mdata = {
          // sent to firebase func
          title: 'New Message from ' + this.state.adminName,
          message: this.state.newMsg,
          userTokens: userTokens,
          messageReference: this.state.currMessageLog.id
        };
        console.log(mdata);
        // execute the firebase function
        // const sendMessageNotification = firebase
        //   .functions()
        const sendMessageNotification = firebaseFns.httpsCallable(
          'sendMessageNotification'
        );
        sendMessageNotification(mdata)
          .then((result) => console.log(result))
          .catch((error) => console.log('Error: ', error));
      }
      count += 1;
    }
    let title = this.state.currMessageLog.title;
    if (title === undefined || title === null || title === '') {
      title = this.getConvoName(this.state.currMessageLog);
    }
    let messageContent = this.state.newMsg;
    if (file !== undefined && file !== null) {
      messageContent = 'This message is an image';
    }
    if (this.state.currMessageLog.messageUsers.length > 2) {
      // update messageLog
      mLogRef.update({
        lastMessage: messageContent,
        title: title
      });
    } else {
      // update messageLog
      mLogRef.update({ lastMessage: messageContent });
    }

    let resultArr = this.state.currMessageLog.messageUsers.filter(
      (curr) => curr.id !== this.state.uid
    );
    let notificationRef = db.collection('notificationHistory').doc();
    let notificationID = notificationRef.id;
    let noitificationLog = {
      date: new Date(),
      message: this.state.adminName + ' has sent you a message',
      readUsers: [],
      reference: mLogRef,
      users: resultArr
    };

    db.collection('notificationHistory')
      .doc(notificationID)
      .set(noitificationLog)
      .then(() => console.log('Successfully added the message'))
      .catch((error) => console.log('Failed to upload message', error));
    this.setState({ newMsg: '' });
  }
  searchUsersForChat() {
    let userListBox = [];
    document.getElementById('convoList').innerHTML = '';
    var convoList = document.getElementById('convoList');
    for (let user of this.state.userList) {
      if (user.id === this.state.uid) {
        continue; // assume its the current user
      }
      userListBox.push({ value: user.id, label: user.name });
      var item = document.createElement('li');
      item.innerHTML = user.name;
      item.setAttribute('data-uid', user.id);
      item.addEventListener(
        'click',
        // create a convo with only one other person
        (item) =>
          this.createNewMessageLog(
            item.path[0].getAttribute('data-uid'),
            item.path[0].innerHTML
          )
      );
      convoList.appendChild(item);
    }
    // show the back arrow
    this.setState({
      viewSearchForChat: true,
      userListBox: userListBox
    });
    // add teams to the drop down menu
    setTimeout(() => {
      document.getElementById('searchBoxUserList').innerHTML = '';
      let editTeamPlan = document.getElementById('searchBoxUserList');
      let currOpt = { text: 'Select Team', value: '' };
      editTeamPlan.options.add(
        new Option(currOpt.text, currOpt.value, currOpt.selected)
      );
      for (let ug of this.state.groupList) {
        currOpt = { text: ug.name, value: ug.name };
        editTeamPlan.options.add(
          new Option(currOpt.text, currOpt.value, currOpt.selected)
        );
      }
    }, 100);
  }
  handleNewConvoTeamChange(event) {
    this.setState({ teamName: event.target.value });
  }
  // when viewing the convoList (different threads)
  // returns the name of the convo
  getConvoName(mLog) {
    let userName = '';
    if (mLog.messageUsers.length > 2) {
      // more than 2 users === group chat
      if (
        mLog.title !== null &&
        mLog.title !== undefined &&
        mLog.title !== ''
      ) {
        userName = mLog.title;
      } else {
        // assume 3 or more people in the chat
        let nameArr = [];
        for (let i = 0; i < mLog.messageUsers.length; i++) {
          let idx = this.state.userList.findIndex(
            (curr) => curr.id === mLog.messageUsers[i].id
          );
          if (idx !== -1) {
            nameArr.push(this.state.userList[idx].name);
          }
        }
        userName = nameArr.join(', ');
      }
    } else if (mLog.messageUsers[0].id !== this.state.uid) {
      // 2 person chat
      if (
        mLog.title !== null &&
        mLog.title !== undefined &&
        mLog.title !== ''
      ) {
        userName = mLog.title;
      } else {
        let idx = this.state.userList.findIndex(
          (user) => user.id === mLog.messageUsers[0].id
        );
        userName = idx === -1 ? 'User' : this.state.userList[idx].name;
      }
      // assume not the current user
    } else {
      if (
        mLog.title !== null &&
        mLog.title !== undefined &&
        mLog.title !== ''
      ) {
        userName = mLog.title;
      } else {
        // assume not the current user
        let idx = this.state.userList.findIndex(
          (user) => user.id === mLog.messageUsers[1].id
        );
        userName = idx === -1 ? 'User' : this.state.userList[idx].name;
      }
    }
    return userName;
  }

  showChatBox() {
    if (document.getElementById('messageList') !== null) {
      document.getElementById('messageList').innerHTML = '';
    }
    if (document.getElementById('convoList') !== null) {
      document.getElementById('convoList').innerHTML = '';
    }
    var convoList = document.getElementById('convoList');
    var addItem = document.createElement('li');
    addItem.innerHTML = '+ Add Conversation';
    addItem.addEventListener('click', () => this.searchUsersForChat());
    convoList.appendChild(addItem);
    for (let mLog of this.state.messageLogs) {
      // Each item needs to have an ID and the other user must have a uid
      // add all the exisiting conversations to the
      let userName = this.getConvoName(mLog);
      if (userName !== '') {
        var item = document.createElement('li');
        item.innerHTML = userName;
        // check is the user has an unread message
        if (
          mLog.usersRead !== undefined &&
          mLog.usersRead.findIndex((person) => person.id === this.state.uid) ===
            -1 &&
          this.state.activeNotification
        ) {
          // assume the current user has not seen the message
          let span = document.createElement('span');
          span.setAttribute('class', 'dot');
          item.prepend(span);
        }
        item.setAttribute('data-mLogID', mLog.id);
        item.addEventListener('click', (curr) => {
          let mLogID = curr.path[0].getAttribute('data-mLogID');
          let idx = this.state.messageLogs.findIndex(
            (log) => log.id === mLogID
          );
          if (idx !== -1) {
            // make sure that it exists
            if (this.state.messageLogs[idx].messageUsers.length > 2) {
              // more than 2 users === group chat
              this.getCurrentMsgs(mLogID, this.state.messageLogs[idx].title);
              return;
            }
          }
          this.getCurrentMsgs(mLogID, curr.path[0].textContent);
        });
        convoList.appendChild(item);
      }
    }
    this.setState({ viewSearchForChat: false });
    var chatBox = document.getElementById('chatBox');
    if (chatBox.getAttribute('open') === null) {
      chatBox.showModal();
    }
  }
  hideChatBox() {
    this.setState({
      viewUserChat: false,
      viewSearchForChat: false,
      messages: [],
      currMessageLog: {}
    });
    var chatBox = document.getElementById('chatBox');
    chatBox.close();
  }
  createTemplate(tdata) {
    const templateRef = db.collection('templates').doc();
    const tmplID = templateRef.id;
    var taskArr = [];
    var taskStateArr = [];
    for (let currTask of tdata.tasks) {
      let taskRef = db.collection('tasks').doc();
      taskArr.push(taskRef);
      let taskID = taskRef.id;
      let etask = {
        //task added to 'tasks' collection
        id: taskID, // need it to toggle the Tasks (complete/not)
        name: currTask.taskName,
        emergencyPlan: templateRef,
        assignedUser: '',
        dateCompleted: '',
        isComplete: false,
        description: currTask.description,
        taskUser: '',
        assignedRC: currTask.assignedRC
      };
      taskStateArr.push(etask);
      db.collection('tasks')
        .doc(taskID)
        .set(etask)
        .then(() => console.log('Task successfully added!!!'))
        .catch(() => console.error('Error adding task'));
    }
    const tmpl = {
      // plan added to 'emergencyPlans' collection
      id: tmplID,
      name: tdata.templateName,
      companyID: this.state.companyID,
      deactivated: false,
      tasks: taskArr,
      titleList: [],
      createdByRole: this.state.role
    };
    db.collection('templates')
      .doc(tmplID)
      .set(tmpl)
      .then(() => console.log('Template successfully added!!!'))
      .catch(() => console.error('Error adding plan'));
    this.setState({
      tmplList: [tmpl, ...this.state.tmplList],
      taskList: this.state.taskList.concat(taskStateArr)
    });
    if (this.state.role === 'Root') {
      // change the companyID of root back to normal
      const rootRef = db.collection('users').doc(this.state.uid);
      rootRef.get().then((query) => {
        let data = query.data();
        this.setState({ companyID: data.companyID });
      });
    }
  }
  deleteTemplate(index) {
    let id = this.state.tmplList[index].id;
    db.collection('templates')
      .doc(id)
      .delete()
      .then(() => console.log('Successfully removed the Template'))
      .catch((error) => console.log('Error removing the Template', error));
    this.state.tmplList.splice(index, 1);
    this.setState({ tmplList: this.state.tmplList });
  }
  editTemplate(row) {
    const tmplID = this.state.tmplList[row.index].id;
    // the doc reference is saved to each new task
    var templateRef = db.collection('templates').doc(tmplID);
    for (let currTask of row.newTasks) {
      let taskRef = db.collection('tasks').doc();
      row.taskList.push(taskRef);
      let taskID = taskRef.id;
      let etask = {
        id: taskID,
        name: currTask.taskName,
        emergencyPlan: templateRef,
        assignedUser: '',
        dateCompleted: '',
        isComplete: false,
        description: currTask.description,
        taskUser: '',
        assignedRC: currTask.assignedRC
      };
      // assume assigned user was NOT selected for the task
      db.collection('tasks')
        .doc(taskID)
        .set(etask)
        .then(() => console.log('Task successfully added!!!'))
        .catch(() => console.error('Error adding task'));
    }

    let tmplList = this.state.tmplList;
    tmplList[row.index].name = row.templateName;
    tmplList[row.index].tasks = row.taskList;
    this.setState({ tmplList: tmplList });
    // make changes to existing tasks
    for (let key in row.taskNameChangeMap) {
      let taskRef = db.collection('tasks').doc(key);
      // update the name of the task
      taskRef
        .update({ name: row.taskNameChangeMap[key] })
        .then(() => console.log('Document successfully updated!'))
        .catch((error) => console.error('Error updating document: ', error));
    }
    // Update the ERP Document
    templateRef
      .update({
        name: row.templateName,
        tasks: row.taskList
      })
      .then(() => console.log('Document successfully updated!'))
      .catch((error) => console.error('Error updating document: ', error));
  }
  editTemplateName(row) {
    let templateRef = db.collection('templates').doc(row.id);
    templateRef.update({
      name: row.templateName
    });
    this.setState({
      planName: row.templateName
    });
    this.viewTasksFromTemplate({ index: row.rowID });
  }
  fetchTemplateData() {
    let tmplArr = [];
    db.collection('templates')
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        for (let tmpl of data) {
          // add template to an array
          if (
            this.state.role !== 'Root' &&
            this.state.companyID !== tmpl.companyID
          )
            continue;
          tmplArr.push(tmpl);
        }
        tmplArr = tmplArr.sort((a, b) => {
          var nameA = a.name.toUpperCase();
          var nameB = b.name.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 1;
        });
        // update tmpl list state
        this.setState({ tmplList: tmplArr });
      });
    //console.log("fetchTemplateData");
  }
  updateCompanyID(data) {
    this.setState({
      companyID: data.companyID
    });
  }
  handleClientFilterChangeForRep(data) {
    let name = data.name;
    if (name !== '') {
      let idx = this.state.clientList.findIndex(
        (client) => client.clientName === name
      );
      if (idx !== -1) {
        const companyID = this.state.clientList[idx].id;
        console.log(companyID);
        db.collection('repoInfo')
          .get()
          .then((querySnapshot) => {
            const repoInfo = querySnapshot.docs.map((doc) => doc.data());
            let currPath;
            let fileList = [];
            for (let i = 0; i < repoInfo.length; i++) {
              let item = repoInfo[i];
              currPath = item.fullPath.split('/');
              let client = currPath[1];
              if (item.folder === false && client === companyID) {
                fileList.push(item);
              }
              if (i === repoInfo.length - 1) {
                this.setState({
                  viewFilteredList: true,
                  filteredList: fileList
                });
              }
            }
          });
      }
    } else {
      this.setState({
        viewFilteredList: false,
        filterecdList: []
      });
    }
  }
  handleUsersInConvoChange(event) {
    this.setState({ selectedUsersForNewConvo: event });
  }
  createNewGroupMessageLog() {
    let sArr = this.state.selectedUsersForNewConvo.map((user) => user.value);
    let str1 = sArr.sort().join(',');
    for (let mLog of this.state.messageLogs) {
      // iterating thru all the msgLogs to check if the convo already exists
      if (mLog.messageUsers !== undefined && mLog.messageUsers !== null) {
        let mArr = mLog.messageUsers.map((user) => user.id);
        let str2 = mArr.sort().join(',');
        if (str1 === str2) {
          alert('This group message already exists.');
          return;
        }
      }
    }
    var currUserRef = db.collection('users').doc(this.state.uid);
    let messageUsers = [currUserRef];
    for (let uid of sArr) {
      let userRef = db.collection('users').doc(uid);
      messageUsers.push(userRef);
    }
    if (this.state.teamName.length > 0) {
      // admin selected a team as well
      let idx = this.state.groupList.findIndex(
        (group) => group.name === this.state.teamName
      );
      if (idx !== -1) {
        for (let i = 0; i < this.state.groupList[idx].users.length; i++) {
          messageUsers.push(this.state.groupList[idx].users[i]);
        }
      }
    }
    let mLogData = {
      latestTimestamp: new Date(),
      messageOwner: currUserRef,
      messageUsers: messageUsers,
      usersRead: [currUserRef]
    };
    let title = '';
    if (sArr.length > 1) {
      // assume 3 or more people in the chat
      for (let i = 0; i < this.state.selectedUsersForNewConvo.length; i++) {
        title += this.state.selectedUsersForNewConvo[i].label + ', ';
      }
      title += this.state.adminName;
      mLogData.title = title;
    } else {
      title = this.state.selectedUsersForNewConvo[0].label;
    }
    var mLogRef = db.collection('messageLog').doc();
    db.collection('messageLog')
      .doc(mLogRef.id)
      .set(mLogData)
      .then(() => {
        this.getCurrentMsgs(mLogRef.id, title);
      })
      .catch((error) => console.log('Failed to upload mLog', error));
    mLogData.id = mLogRef.id;
    this.setState({
      messageLogs: [...this.state.messageLogs, mLogData],
      currMessageLog: mLogData,
      selectedUsersForNewConvo: []
    });
  }
  handleMessagePicChange(event) {
    event.preventDefault();
    let reader = new FileReader();
    // get the file thats been uploads
    let file = event.target.files[0];
    // after the file finished loading

    reader.onloadend = () => {
      this.sendNewMsg(file);
    };
    reader.readAsDataURL(file);
  }

  //ALL LOGS:
  //1) Library logs:
  // createLibraryLog(fileDeletedDate = null, fileDownloadedDate = null, fileUploadedDate = null, folderAddedDate = null,folderDeletedDate = null ) {
  createLibraryLog(row) {
    const user = auth.currentUser;
    // const rowRef = db.collection('libraryLog').add({
    db.collection('libraryLog').add({
      fileDeletedDate: row.fileDeletedDate ? row.fileDeletedDate : null,
      fileDownloadedDate: row.fileDownloadedDate
        ? row.fileDownloadedDate
        : null,
      fileUploadedDate: row.fileUploadedDate ? row.fileUploadedDate : null,
      folderAddedDate: row.folderAddedDate ? row.folderAddedDate : null,
      folderDeletedDate: row.folderDeletedDate ? row.folderDeletedDate : null,
      actionDoneBy: user.email,
      lastFolderName: row.lastFolderName ? row.lastFolderName : null,
      currentFolderName: row.folderName ? row.folderName : null,
      fileName: row.fileName ? row.fileName : null,
      lastUpdatedBy: row.lastUpdatedBy ? user.email : null,
      lastUpdatedDate: row.lastUpdatedDate ? row.lastUpdatedDate : null,
      filetype: row.fileType ? row.fileType : null
    });
    // console.log(user.email);
  }

  //2) User logs:
  createUserLog(row) {
    const user = auth.currentUser;
    // const rowRef = db.collection('userLog').add({
    db.collection('userLog').add({
      date: new Date(),
      logType: row.logType ? row.logType : 'There is a problem!',
      message: user.email + ' has made the changes',
      oldValue: row.oldValue ? row.oldValue : '-',
      newValue: row.newValue ? row.newValue : '-',
      source: row.source ? row.source : '-',
      document: row.document ? '/users/' + row.document : '-'
    });
  }

  render() {
    console.log('userList: ', this.state.userList);
    // console.log('Store.library: ', Store.library.get());
    let body = <p>Loading</p>;
    // let menuWidth = this.state.toggleMenu ? '75px' : '300px'; // UNUSED VAR

    const isLoggedIn = this.state.isLoggedIn; // checks if the user is logged in or not
    if (this.state.pageNum === 1) {
      //USERLIST
      // still retrieving the User List from cloud firestore
      if (this.state.userList.length === 0) {
        body = <p>Loading User List</p>;
      }
      if (this.state.userList.length > 0) {
        //console.log("user")
        body = (
          <UserTable
            userList={
              this.state.viewDeletedUsers
                ? this.state.deletedUsers
                : this.state.userList
            }
            clientList={this.state.clientList}
            onDeleteUser={this.deleteUser}
            onViewDeletedUsers={this.viewDeletedUserList}
            viewDeletedUsers={this.state.viewDeletedUsers}
            onEditUser={this.editUser}
            onToggleUser={this.toggleUser}
            onViewUserGroups={this.viewUserGroups}
            onViewUserList={(pageInfo) =>
              this.viewUsers(pageInfo.pageInfo, pageInfo.appOnly)
            }
            onAddNewUser={this.addNewUser}
            onAddManyNewUsers={this.addManyNewUsers}
            highlight={this.state.userHighlight}
            reportsHighlight={this.state.reportsHighlight}
            role={this.state.role}
            planPager={this.state.planPager}
            subPlanPager={this.state.subPlanPager}
            breadCrumbs={this.state.breadCrumbs}
            onEditBreadCrumbs={(breadCrumbs) =>
              this.editBreadCrumbs(breadCrumbs)
            }
            toggleMenu={this.state.toggleMenu}
            onfilterTable={this.filterTable}
            viewFilteredList={this.state.viewFilteredList}
            filteredList={this.state.filteredList}
            appOnlyUsers={this.state.appOnlyUsers}
            companyID={this.state.companyID}
            reactivateUser={this.reactivateUser}
            uid={this.state.uid}
            email={Store.user.email.get()}
            domain={this.state.domain}
            gUserList={this.state.gUserList}
            onClientFilterChange={this.handleClientFilterChange}
            createUserLog={this.createUserLog}
            // viewReports={this.state.viewReports} // duplicate
            viewReports={this.viewReports}
            onViewLibrary={(planPager) => this.viewKnowledge(planPager)}
            viewRollCallList={(action) => this.viewRollCallList(action)}
            cFormsList={this.state.cFormsList}
            // viewCompletedForms={this.state.viewCompletedForms} // duplicate
            viewCompletedForms={this.viewCompletedForms}
            sendForgotPasswordLink={this.forgotPassword}
            companySettings={this.state.companySettings}
            getUserLocation={this.getUserLocation}
          />
        );
      }
      // view the userGroup
      if (this.state.ugCheck) {
        body = (
          <UserGroupTable
            onViewUserList={this.viewUsers}
            userList={this.state.userList}
            companyID={this.state.companyID}
            onAddUserGroup={this.addUserGroup}
            groupList={this.state.groupList}
            onDeleteUserGroup={this.deleteUserGroup}
            onEditUserGroup={this.editUserGroup}
            onViewDeletedUsers={this.viewDeletedUserList}
            onViewUserGroups={this.viewUserGroups}
            highlight={this.state.userHighlight}
            breadCrumbs={this.state.breadCrumbs}
            onEditBreadCrumbs={(breadCrumbs) =>
              this.editBreadCrumbs(breadCrumbs)
            }
            toggleMenu={this.state.toggleMenu}
            role={this.state.role}
            onfilterTable={this.filterTable}
            viewFilteredList={this.state.viewFilteredList}
            filteredList={this.state.filteredList}
            createUserLog={this.createUserLog}
            companySettings={this.state.companySettings}
          />
        );
      }
    }
    if (this.state.pageNum === 2) {
      //CLIENT LISTS
      //console.log("client")
      body = (
        <ClientTable
          onSendUsersMessage={this.sendUsersMessage}
          clientList={
            this.state.viewDeletedClients
              ? this.state.deletedClients
              : this.state.clientList
          }
          onDeleteClient={this.deleteClient}
          onEditClient={this.editClient}
          onToggleClient={this.toggleClient}
          onAddClient={this.addClient}
          onViewClientList={this.viewClients}
          onViewClientListAction={this.viewClientList}
          onViewDeletedClientListAction={this.viewDeletedClientList}
          viewDeletedClients={this.state.viewDeletedClients}
          reactivateClient={this.reactivateClient}
          clientHighlight={this.state.clientHighlight}
          viewFilteredList={this.state.viewFilteredList}
          filteredList={this.state.filteredList}
          onfilterTable={this.filterTable}
          breadCrumbs={this.state.breadCrumbs}
          onEditBreadCrumbs={(breadCrumbs) => this.editBreadCrumbs(breadCrumbs)}
          toggleMenu={this.state.toggleMenu}
          companySettings={this.state.companySettings}
        />
      );
    }
    if (this.state.pageNum === 3) {
      //PLANLISTS / CHECKLISTS
      //console.log("checklist second")
      if (this.state.viewTasks) {
        //view tasklist
        if (this.state.taskList.length > 0) {
          body = (
            <TaskTable
              taskList={this.state.taskList}
              planName={this.state.planName}
              planID={this.state.planID}
              planRowID={this.state.planRowID}
              editTemplateName={this.editTemplateName}
              onTaskStatusClick={this.handleTaskStatusChange}
              numCompleted={this.state.numCompleted}
              totalNum={this.state.totalNum}
              selectedUsers={this.state.taskUserList}
              onAddTask={this.addTask}
              onDeleteTask={this.deleteTask}
              onEditTask={this.editTask}
              onViewTasks={this.resetTaskList}
              onRefreshTaskList={this.viewTasks}
              leaderName={this.state.leaderName}
              breadCrumbs={this.state.breadCrumbs}
              onBackClick={this.handleBackClick}
              toggleMenu={this.state.toggleMenu}
              rollCallList={this.state.rcList}
              viewFilteredList={this.state.viewFilteredList}
              filteredList={this.state.filteredList}
              onfilterTable={this.filterTable}
              planPager={this.state.planPager}
              subPlanPager={this.state.subPlanPager}
              companySettings={this.state.companySettings}
            />
          );
        } else body = <p>Loading Task List</p>;
      } else if (this.state.viewRollCallList) {
        //view rollCallList
        //console.log("flash second")
        if (this.state.rollCallList.length > 0) {
          body = (
            <RollCallTable
              rollCallList={this.state.rollCallList}
              planName={this.state.planName}
              onUserStatusChange={this.handleUserCheckedInChange}
              numCompleted={this.state.numCompleted}
              totalNum={this.state.totalNum}
              breadCrumbs={this.state.breadCrumbs}
              onBackClick={this.handleBackClick}
              planPager={this.state.planPager}
              subPlanPager={this.state.subPlanPager}
              companySettings={this.state.companySettings}
            />
          );
        } else body = <p>Loading Roll Call</p>;
      } // User List is filtered based on Company ID
      else {
        //console.log("checklist second")
        // console.log("PT")
        body = (
          <PlanTable
            planList={this.state.planList}
            onCreatePlan={this.createPlan}
            onActiveToggle={this.activeToggle}
            onPracticeToggle={this.practiceToggle}
            onDeletePlan={this.deletePlan}
            onDeactivatePlan={this.deactivatePlan}
            onEditPlan={this.editPlan}
            companyID={this.state.companyID}
            onUpdateCompanyID={this.updateCompanyID}
            onViewTasks={this.viewTasks}
            totalNum={this.state.totalNum}
            viewChecklist={this.viewChecklist}
            viewCheckList={this.state.viewCheckList}
            onViewRollCall={this.viewRollCall}
            viewRollCallList={(action) => this.viewRollCallList(action)}
            rollCallList={this.state.rcList}
            onAddRollCall={this.createRollCall}
            onDeleteRollCall={this.deleteRollCall}
            onEditRollCall={this.editRollCall}
            onRCActiveToggle={this.rcActiveToggle}
            userList={this.state.userList.filter(
              (user) => user.companyID === this.state.companyID
            )}
            onUserStatusChange={this.updateUserStatus}
            onUserSafetyChange={this.updateUserSafety}
            onViewUserList={(action) => this.viewRollCallUsers(action)}
            rcUserList={this.state.rcUserList}
            numGreen={this.state.numGreen}
            numYellow={this.state.numYellow}
            numRed={this.state.numRed}
            getUserLocation={this.getUserLocation}
            onArchive={this.archive}
            role={this.state.role}
            viewTemplates={this.viewTemplates}
            tmplList={this.state.tmplList}
            createTemplate={this.createTemplate}
            onDeleteTemplate={this.deleteTemplate}
            editTemplate={this.editTemplate}
            editTemplateName={this.editTemplateName}
            userGroups={this.state.groupList}
            clientList={this.state.clientList.map((client) => client.companyID)}
            viewTasksFromTemplate={this.viewTasksFromTemplate}
            viewFilteredList={this.state.viewFilteredList}
            filteredList={this.state.filteredList}
            onfilterTable={this.filterTable}
            breadCrumbs={this.state.breadCrumbs}
            onEditBreadCrumbs={this.editBreadCrumbs}
            toggleMenu={this.state.toggleMenu}
            planHighlight={this.state.planHighlight}
            reportsHighlight={this.state.reportsHighlight}
            planPager={this.state.planPager}
            subPlanPager={this.state.subPlanPager}
            companySettings={this.state.companySettings}
          />
        );
      }
    }
    if (this.state.pageNum === 4) {
      //LIBRARY LIST
      body =
        this.state.role === 'Root' ? (
          <LibraryTable
            repository={this.state.repository}
            onViewLibrary={this.viewKnowledge}
            onDeleteFile={this.deleteFile}
            onAddFile={this.addFile}
            onAddFolder={this.addFolder}
            onDownloadFile={this.downloadFile}
            onDeleteFolder={this.deleteFolder}
            onEditFolderName={this.editFolderName}
            onSetFolderPassword={this.setFolderPassword}
            onRemovePassword={this.removeFolderPassword}
            folderRepos={this.state.folderRepos}
            onViewSelectedRepo={this.viewSelectedRepo}
            breadCrumbs={this.state.breadCrumbs}
            onEditBreadCrumbs={this.editBreadCrumbs}
            planPager={this.state.planPager}
            reportsHighlight={this.state.reportsHighlight}
            path={this.state.path}
            role={this.state.role}
            onNavigateToParent={this.navigateToParent}
            onNavigateToChild={this.navigateToChild}
            companyID={this.state.companyID}
            clientList={this.state.clientList}
            viewFilteredList={this.state.viewFilteredList}
            filteredList={this.state.filteredList}
            onfilterTable={this.filterTable}
            onClientFilterChange={this.handleClientFilterChangeForRep}
            createLibraryLog={this.createLibraryLog}
            loggedData={this.state.loggedData}
            viewReports={this.viewReports}
            onViewUserList={(planPager) => this.viewUsers(planPager, false)}
            viewCompletedForms={this.viewCompletedForms}
            companySettings={this.state.companySettings}
          />
        ) : (
          <Library
            repository={this.state.repository}
            onViewLibrary={this.viewKnowledge}
            onDeleteFile={this.deleteFile}
            onAddFile={this.addFile}
            onAddFolder={this.addFolder}
            onDownloadFile={this.downloadFile}
            onDeleteFolder={this.deleteFolder}
            onEditFolderName={this.editFolderName}
            onSetFolderPassword={this.setFolderPassword}
            onRemovePassword={this.removeFolderPassword}
            folderRepos={this.state.folderRepos}
            onViewSelectedRepo={this.viewSelectedRepo}
            breadCrumbs={this.state.breadCrumbs}
            onEditBreadCrumbs={this.editBreadCrumbs}
            planPager={this.state.planPager}
            reportsHighlight={this.state.reportsHighlight}
            path={this.state.path}
            role={this.state.role}
            onNavigateToParent={this.navigateToParent}
            onNavigateToChild={this.navigateToChild}
            companyID={this.state.companyID}
            clientList={this.state.clientList}
            viewFilteredList={this.state.viewFilteredList}
            filteredList={this.state.filteredList}
            onfilterTable={this.filterTable}
            onClientFilterChange={this.handleClientFilterChangeForRep}
            createLibraryLog={this.createLibraryLog}
            loggedData={this.state.loggedData}
            viewReports={this.viewReports}
            onViewUserList={(planPager) => this.viewUsers(planPager, false)}
            viewCompletedForms={this.viewCompletedForms}
            companySettings={this.state.companySettings}
          />
        );
    }
    if (this.state.pageNum === 5) {
      //DASHBOARD LIST
      if (this.state.clientList.length === 0) {
        body = <p>Loading Dashboard</p>;
      } else {
        body = (
          <DashBoard
            clientList={this.state.clientList}
            getUserChartData={this.getUserChartData}
            userChartData={this.state.userChartData}
            role={this.state.role}
            companyID={this.state.companyID}
            companySettings={this.state.companySettings}
          />
        );
      }
    }
    if (this.state.pageNum === 6) {
      //REPORTS LIST
      if (this.state.viewTasks) {
        //view Tasklist
        if (this.state.taskList.length > 0) {
          //console.log("checklist")
          body = (
            <TaskTable
              taskList={this.state.taskList}
              onViewTasks={this.resetTaskList}
              onTaskStatusClick={this.handleTaskStatusChange}
              selectedUsers={this.state.taskUserList}
              onAddTask={this.addTask}
              onDeleteTask={this.deleteTask}
              onEditTask={this.editTask}
              numCompleted={this.state.numCompleted}
              totalNum={this.state.totalNum}
              planID={this.state.planID}
              planRowID={this.state.planRowID}
              planName={this.state.planName}
              leaderName={this.state.leaderName}
              onBackClick={this.handleBackClick}
              toggleMenu={this.state.toggleMenu}
              rollCallList={this.state.rcList}
              viewFilteredList={this.state.viewFilteredList}
              filteredList={this.state.filteredList}
              onfilterTable={this.filterTable}
              loggedData={this.state.loggedData}
              editTemplateName={this.editTemplateName}
              planPager={this.state.planPager}
              subPlanPager={this.state.subPlanPager}
              breadCrumbs={this.state.breadCrumbs}
              reportsHighlight={this.state.reportsHighlight}
              companySettings={this.state.companySettings}
            />
          );
        } else body = <p>Loading Task List</p>;
      } else if (this.state.viewRollCallList) {
        // view rollcalllist
        console.log('flash');
        if (this.state.rollCallList.length > 0) {
          body = (
            <RollCallTable
              rollCallList={this.state.rollCallList}
              planName={this.state.planName}
              onUserStatusChange={this.handleUserCheckedInChange}
              cFormsList={this.state.cFormsList}
              // viewCompletedForms={this.state.viewCompletedForms} // duplicate
              viewCompletedForms={this.viewCompletedForms}
              numCompleted={this.state.numCompleted}
              totalNum={this.state.totalNum}
              breadCrumbs={this.state.breadCrumbs}
              planPager={this.state.planPager}
              subPlanPager={this.state.subPlanPager}
              reportsHighlight={this.state.reportsHighlight}
              onBackClick={this.handleBackClick}
              companySettings={this.state.companySettings}
            />
          );
        } else {
          body = <p>Loading Roll Call</p>;
        }
      } else if (this.state.viewCompletedForms) {
        //view completed forms
        console.log('cFormsList');
        body = (
          <CompletedFormTable
            breadCrumbs={this.state.breadCrumbs}
            onEditBreadCrumbs={this.editBreadCrumbs}
            planHighlight={this.state.planHighlight}
            reportsHighlight={this.state.reportsHighlight}
            planPager={this.state.planPager}
            subPlanPager={this.state.subPlanPager}
            onNavigateToParent={this.navigateToParent}
            onNavigateToChild={this.navigateToChild}
            path={this.state.path}
            role={this.state.role}
            onBackClick={this.handleBackClick}
            cFormsList={this.state.cFormsList}
            // viewCompletedForms={this.state.viewCompletedForms} // duplicate
            viewCompletedForms={this.viewCompletedForms}
            sendReminder={(action) => this.sendUserReminder(action)}
            // REPORTS
            // viewReports={this.state.viewReports}
            viewReports={this.viewReports}
            //COVID REPORTS
            onViewUserList={(planPager) => this.viewUsers(planPager, false)}
            //LIBRARY REPORTS
            onViewLibrary={(planPager) => this.viewKnowledge(planPager)}
            //FLASH REPORTS
            viewRollCallList={(action) => this.viewRollCallList(action)}
            //FILTERING
            onfilterTable={this.filterTable}
            viewFilteredList={this.state.viewFilteredList}
            filteredList={this.state.filteredList}
            companySettings={this.state.companySettings}
          />
        );
      } //view reports list
      else {
        body = (
          <Reports
            //GENERAL

            breadCrumbs={this.state.breadCrumbs}
            onEditBreadCrumbs={this.editBreadCrumbs}
            planHighlight={this.state.planHighlight}
            reportsHighlight={this.state.reportsHighlight}
            planPager={this.state.planPager}
            subPlanPager={this.state.subPlanPager}
            onNavigateToParent={this.navigateToParent}
            onNavigateToChild={this.navigateToChild}
            path={this.state.path}
            role={this.state.role}
            companyID={this.state.companyID}
            onArchive={this.archive}
            // REPORTS
            // viewReports={this.state.viewReports}
            viewReports={this.viewReports}
            //CHECKLIST REPORT
            planList={this.state.planList}
            viewChecklist={this.viewChecklist}
            onViewTasks={this.viewTasks}
            numGreen={this.state.numGreen}
            numYellow={this.state.numYellow}
            numRed={this.state.numRed}
            taskList={this.state.taskList}
            // userList={this.state.userList} duplicate prop
            //FLASH REPORT
            onViewRollCall={this.viewRollCall}
            viewRollCallList={(action) => this.viewRollCallList(action)}
            onViewRCUserList={(action) => this.viewRollCallUsers(action)}
            rcUserList={this.state.rcUserList}
            rollCallList={this.state.arcList}
            //TEMPLATE REPORT
            viewTemplates={this.viewTemplates}
            tmplList={this.state.tmplList}
            viewTasksFromTemplate={this.viewTasksFromTemplate}
            // USERS REPORT
            userList={this.state.userList.filter(
              (user) => user.companyID === this.state.companyID
            )}
            onViewUserList={(planPager) => this.viewUsers(planPager, false)}
            userGroups={this.state.groupList}
            viewFilteredList={this.state.viewFilteredList}
            filteredList={this.state.filteredList}
            onfilterTable={this.filterTable}
            clientList={this.state.clientList.map((client) => client.companyID)}
            onUpdateCompanyID={this.updateCompanyID}
            // LIBRARY REPORT
            repository={this.state.repository}
            folderRepos={this.state.folderRepos}
            onViewSelectedRepo={this.viewSelectedRepo}
            onViewLibrary={(planPager) => this.viewKnowledge(planPager)}
            onClientFilterChange={this.handleClientFilterChangeForRep}
            createLibraryLog={this.createLibraryLog}
            //COMPLETED FORM TABLE REPORT
            cFormsList={this.state.cFormsList}
            // viewCompletedForms={this.state.viewCompletedForms} //duplicate prop
            viewCompletedForms={this.viewCompletedForms}
            companySettings={this.state.companySettings}
          />
        );
      }
    }
    if (this.state.pageNum === 7) {
      //ClientSettings
      body = (
        <ClientSettingsTable
          breadCrumbs={this.state.breadCrumbs}
          onEditBreadCrumbs={this.editBreadCrumbs}
          planHighlight={this.state.planHighlight}
          reportsHighlight={this.state.reportsHighlight}
          planPager={this.state.planPager}
          subPlanPager={this.state.subPlanPager}
          onNavigateToParent={this.navigateToParent}
          onNavigateToChild={this.navigateToChild}
          path={this.state.path}
          role={this.state.role}
          companyID={this.state.companyID}
          formSettings={this.state.formSettings}
          formList={this.state.formList}
          onToggleForm={(action) => this.toggleForm(action)}
          sendReminder={(action) => this.sendUserReminder(action)}
          userList={this.state.userList}
          // clientSettings={this.clientSettings}
          // clientSettings={this.state.clientSettings}
          clientList={this.state.clientList}
        />
      );
    }
    if (this.state.pageNum === 8) {
      //VaccineSettings
      body = (
        <VaccineSettingsTable
          breadCrumbs={this.state.breadCrumbs}
          onEditBreadCrumbs={this.editBreadCrumbs}
          planHighlight={this.state.planHighlight}
          reportsHighlight={this.state.reportsHighlight}
          planPager={this.state.planPager}
          subPlanPager={this.state.subPlanPager}
          onNavigateToParent={this.navigateToParent}
          onNavigateToChild={this.navigateToChild}
          path={this.state.path}
          role={this.state.role}
          companyID={this.state.companyID}
          userList={this.state.userList}
          onToggleVaccineClient={this.toggleVaccineClient}
          vaccineSettings={this.vaccineSettings}
          // vaccineSettings={this.state.vaccineSettings} // duplicate prop
          clientList={this.state.vaccineClient}
        />
      );
    }
    if (this.state.pageNum === 9) {
      //FeatureSettings
      body = (
        <FeatureSettingsTable
          breadCrumbs={this.state.breadCrumbs}
          onEditBreadCrumbs={this.editBreadCrumbs}
          planHighlight={this.state.planHighlight}
          reportsHighlight={this.state.reportsHighlight}
          planPager={this.state.planPager}
          subPlanPager={this.state.subPlanPager}
          onNavigateToParent={this.navigateToParent}
          onNavigateToChild={this.navigateToChild}
          path={this.state.path}
          role={this.state.role}
          companyID={this.state.companyID}
          userList={this.state.userList}
          toggleFeatureSettings={this.toggleFeatureSettings}
          clientList={this.state.clientList}
        />
      );
    }

    if (isLoggedIn) {
      return (
        <Fragment>
          {/* conditional rendering for the back arrow (position: absolute) */}

          <ProSidebar
            collapsed={this.state.toggleMenu}
            collapsedWidth='80px'
            width='300px'
            id='mainNav'
          >
            <SidebarHeader>
              {this.state.toggleMenu ? (
                <Menu>
                  <MenuItem
                    style={{ background: 'transparent', boxShadow: 'none' }}
                    icon={
                      <img src={logo_flash} style={{ width: '35px' }} alt='' />
                    }
                  ></MenuItem>
                </Menu>
              ) : (
                <Menu style={{ background: 'transparent', boxShadow: 'none' }}>
                  <MenuItem
                    style={{ background: 'transparent', boxShadow: 'none' }}
                    icon={
                      <img
                        src={FlashLogoBlue}
                        style={{ width: '150px' }}
                        alt=''
                      />
                    }
                  ></MenuItem>
                </Menu>
              )}
              <hr className='multi-divider'></hr>
            </SidebarHeader>
            <SidebarContent>
              {/* <main>
							<button onClick={this.handleToggleMenu}><img onClick={this.handleToggleMenu} src={BackArrow} height={25}
							width={25} /></button>
						</main> */}

              <Menu>
                <MenuItem
                  onClick={this.viewUsers}
                  className={this.state.highlight[1]}
                  icon={<FaUsers size={25} />}
                >
                  {this.state.toggleMenu ? '' : ' Users'}
                </MenuItem>
              </Menu>

              {this.state.role === 'Root' ? (
                <Menu>
                  <MenuItem
                    onClick={this.viewClients}
                    className={this.state.highlight[2]}
                    icon={<MdDomain size={25} />}
                  >
                    {this.state.toggleMenu ? '' : ' Clients'}
                  </MenuItem>
                </Menu>
              ) : (
                ''
              )}

              <Menu>
                <MenuItem
                  onClick={this.viewPlans}
                  className={this.state.highlight[3]}
                  icon={<AiOutlineFileSearch size={25} />}
                >
                  {this.state.toggleMenu ? '' : ' Checklists'}
                </MenuItem>
              </Menu>
              {this.state.companySettings.features.library === true ? (
                <Menu>
                  <MenuItem
                    onClick={this.viewKnowledge}
                    className={this.state.highlight[4]}
                    icon={<MdLocalLibrary size={25} />}
                  >
                    {this.state.toggleMenu ? '' : ' Library'}
                  </MenuItem>
                </Menu>
              ) : null}
              <Menu>
                <MenuItem
                  onClick={this.viewReports}
                  className={this.state.highlight[5]}
                  icon={<BsFileEarmarkRuled size={25} />}
                >
                  {this.state.toggleMenu ? '' : ' Reports'}
                </MenuItem>
              </Menu>
              <Menu>
                <SubMenu
                  title={this.state.toggleMenu ? '' : ' Settings'}
                  className={this.state.highlight[6]}
                  icon={<MdSettings size={25} />}
                >
                  {this.state.companySettings.features.forms === true ? (
                    <MenuItem
                      onClick={this.clientSettings}
                      icon={<MdFormatAlignJustify size={25} />}
                    >
                      Forms
                    </MenuItem>
                  ) : null}
                  <MenuItem
                    onClick={this.vaccineSettings}
                    icon={<MdFormatAlignJustify size={25} />}
                  >
                    Vaccine tracking
                  </MenuItem>
                  {this.state.role === 'Root' ? (
                    <MenuItem
                      onClick={this.featureSettings}
                      icon={<MdFormatAlignJustify size={25} />}
                    >
                      Features
                    </MenuItem>
                  ) : (
                    ''
                  )}
                  <MenuItem
                    onClick={this.viewDashboard}
                    icon={<MdFormatAlignJustify size={25} />}
                  >
                    Licenses
                  </MenuItem>
                </SubMenu>
              </Menu>
            </SidebarContent>
            <SidebarFooter>
              <hr className='multi-divider'></hr>
              <Menu iconShape='square'>
                <MenuItem
                  onClick={this.signOut}
                  title='Sign Out'
                  className={'mainItem'}
                >
                  <FiLogOut
                    style={{ width: '27px', height: '27px', color: '#000' }}
                    height={25}
                    width={25}
                    alt='Sign Out'
                  />
                  {this.state.toggleMenu ? '' : ' Sign Out'}
                </MenuItem>
              </Menu>
            </SidebarFooter>
          </ProSidebar>

          {this.state.showBackArrow ? (
            <img
              onClick={this.handleBackClick}
              id='backArrow'
              height={25}
              width={25}
              src={BackArrow}
              alt='back-arrow'
            />
          ) : null}

          {this.state.toggleMenu ? (
            <button className='toggleBtnCss' onClick={this.handleToggleMenu}>
              <MdKeyboardArrowRight
                onClick={this.handleToggleMenu}
                src={ForwardArrow}
                height={25}
                width={25}
              />
            </button>
          ) : (
            <button className='toggleBtnCss' onClick={this.handleToggleMenu}>
              <MdKeyboardArrowLeft
                onClick={this.handleToggleMenu}
                src={BackArrow}
                height={25}
                width={25}
              />
            </button>
          )}

          {body}
          {
            !(this.state.viewDeletedUsers || this.state.isClientAccount)
              ? null
              : ''
            // <img id="chatMessagesIcon" src={(this.state.activeNotification) ? ChatMessagesNotify : ChatMessages} alt="Messages"
            // 	onClick={this.showChatBox}/>
          }
          <dialog id='chatBox'>
            <img
              id='exit-dialog-icon'
              src={Exit}
              height={28}
              onClick={this.hideChatBox}
              alt='close pop up'
            />
            {this.state.viewUserChat ? (
              <Fragment>
                <img
                  onClick={() => {
                    this.setState({
                      viewUserChat: false,
                      messages: [],
                      currMessageLog: {}
                    });
                    setTimeout(() => {
                      this.showChatBox();
                    }, 500);
                  }}
                  id='chatBackArrow'
                  src={BackArrow}
                  alt='back-arrow'
                />
                <p id='chatTitle'>{this.state.otherUserName}</p>
                <section id='messagesBox'>
                  <ul id='messageList'></ul>
                </section>
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    this.sendNewMsg();
                  }}
                >
                  <label id='chatBoxPlusSign'>
                    <input
                      id='chatBoxSelectImage'
                      type='file'
                      accept='image/*'
                      onChange={this.handleMessagePicChange}
                    />
                    <span>+</span>
                  </label>
                  <input
                    type='text'
                    value={this.state.newMsg}
                    onChange={this.handleMessageChange}
                    id='chatBoxInput'
                    placeholder='Type a message...'
                  ></input>
                  <button id='sendChatBtn' type='submit'></button>
                </form>
              </Fragment>
            ) : (
              <Fragment>
                {this.state.viewSearchForChat ? (
                  <img
                    onClick={this.showChatBox}
                    id='chatBackArrow'
                    src={BackArrow}
                    alt='back-arrow'
                  />
                ) : null}
                <p id='chatTitle'>
                  {this.state.viewSearchForChat
                    ? 'Select a person'
                    : 'My Conversations'}
                </p>
                <section id='convoBox'>
                  {this.state.viewSearchForChat ? (
                    <Fragment>
                      <select
                        id='searchBoxUserList'
                        onChange={this.handleNewConvoTeamChange}
                      ></select>
                      <div style={{ display: 'flex' }}>
                        <Select
                          options={this.state.userListBox}
                          id='searchBox'
                          placeholder='Select a User'
                          isMulti
                          onChange={this.handleUsersInConvoChange}
                          classNamePrefix='select'
                          value={this.state.selectedUsersForNewConvo}
                        />
                        <span
                          onClick={this.createNewGroupMessageLog}
                          className='addItemPlan'
                          style={{ fontSize: '22px', height: 'fit-content' }}
                        >
                          +
                        </span>
                      </div>
                    </Fragment>
                  ) : null}
                  <ul id='convoList'></ul>
                </section>
              </Fragment>
            )}
          </dialog>
        </Fragment>
      );
    }
    if (!isLoggedIn) {
      return (
        <SignIn
          forgotPassword={this.state.forgotPassword}
          signIn={this.signIn}
          requestPasswordReset={this.forgotPassword}
          toggleForgotPassword={() =>
            this.setState({
              forgotPassword: !this.state.forgotPassword
            })
          }
        />
      );
    }
  }
}

export default App;
