import { observable } from '@legendapp/state';

const Store = observable({
  user: {
    uid: '',
    email: 'raygreycloud@gmail.com',
    userName: '',
    companyID: '',
    role: ''
  },
  library: {
    libraryID: '',
    rootFolder: {},
    rootFolderID: '',
    selectedFolder: '',
    isAddOrUploadDialogOpen: false
  }
});

export default Store;
