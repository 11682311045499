import CriticalEventReport from './CriticalEventReport';
import RollCallReport from './RollCallReport';
import MassNotificationReport from './MassNotificationReport';

const ReportDownload = ({ data }) => {
  // console.log('ReportDownload/data: ', data);

  if (data.type === 'Roll Call') {
    return <RollCallReport data={data} />;
  }
  if (data.type === 'Critical Event Alert') {
    return <CriticalEventReport data={data} />;
  }
  if (data.type === 'Mass Notification') {
    return <MassNotificationReport data={data} />;
  }

  return null;
};

export default ReportDownload;
