import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  table: {
    display: 'table',
    width: '50%',
    marginBottom: 20,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    flexDirection: 'row'
  },
  tableCol: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    marginTop: 5,
    marginLeft: 5,
    fontSize: 10
  },
  tableHeader: {
    width: '100%',
    backgroundColor: '#4472c4'
  },
  tableHeaderText: {
    margin: 'auto',
    marginTop: 5,
    marginBottom: 5,
    fontSize: 12,
    color: 'white'
  }
});

const UserTableNameOnly = ({ rows }) => {
  // console.log('UserTableNameOnly/rows: ', rows);

  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={styles.tableHeader}>
          <Text style={styles.tableHeaderText}>User Report</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>User Name</Text>
        </View>
      </View>
      {rows.map((row) => {
        const { id, name } = row;
        return (
          <View key={id} style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{name}</Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default UserTableNameOnly;
