import React, { Component, Fragment } from 'react';
import ProfilePreview from './style/default_profile.png';
import Input from 'react-phone-number-input/input';
// import Exit from './style/exit.png';
import './style/addUserDialog.css';
import { GrClose } from 'react-icons/gr';
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
//BOOTSRAP:
// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class AddUser extends Component {
  constructor() {
    super();
    this.state = {
      profilePicture: '',
      firstName: '',
      lastName: '',
      displayName: '',
      company: '',
      department: '',
      title: '',
      employeeType: 'Employee',
      phoneNumber: '',
      officeNumber: '',
      email: '',
      confirmEmail: '',
      password: '',
      confirmPassword: '',
      // TODO: why is this hardcoded?
      companyID: 'CLI009',
      role: '',
      emergencyPhone: '',
      emergencyName: '',
      physicalDeliveryOfficeName: '',
      samAccountName: '',
      imgSrc: ProfilePreview
    };
    this.handlePicChange = this.handlePicChange.bind(this);
    this.handleFirstnameChange = this.handleFirstnameChange.bind(this);
    this.handleLastnameChange = this.handleLastnameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleConfirmEmailChange = this.handleConfirmEmailChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleEmergencyNameChange = this.handleEmergencyNameChange.bind(this);
    this.handleEmergencyPhoneChange =
      this.handleEmergencyPhoneChange.bind(this);
    this.handleAddUser = this.handleAddUser.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange =
      this.handleConfirmPasswordChange.bind(this);
    this.handleCompanyIDChange = this.handleCompanyIDChange.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.handleDisplayNameChange = this.handleDisplayNameChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleEmployeeTypeChange = this.handleEmployeeTypeChange.bind(this);
    this.handleOfficePhoneChange = this.handleOfficePhoneChange.bind(this);
    this.handlePhyDeliveryOfcNameChange =
      this.handlePhyDeliveryOfcNameChange.bind(this);
    this.handleSamAccountNameChange =
      this.handleSamAccountNameChange.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }
  showModal() {
    if (this.props.role === 'Root') {
      var selectClient = document.getElementById('selectCompanyID'); // get the select Client tag
      selectClient.innerHTML = '';
      for (let client of this.props.clientList) {
        let curr = { text: client.clientName, value: client.companyID };
        selectClient.options.add(
          new Option(curr.text, curr.value, curr.selected)
        );
      }
    }
    if (this.props.role !== 'Root') {
      // assume Super Admin
      let idx = this.props.clientList.findIndex(
        (client) => client.companyID === this.props.companyID
      );
      //console.log(this.props.clientList[idx])
      if (
        idx === -1 ||
        this.props.clientList[idx].licensesUsed >=
          this.props.clientList[idx].licensesAssigned
      ) {
        // assume they dont have enough licenses to create user
        alert(
          'There are no more licenses available. Please remove inactive users or contact admin@tpcm-usa.com to purchase additional licenses.'
        );
        return;
      }
    }
    this.props.onEditBreadCrumbs({
      type: 'APPEND_BREADCRUMB',
      breadCrumbs: ' > New User'
    });
    this.setState({ role: 'User' });

    // add options to the role selection
    const roleOptions = [
      'User',
      'External User',
      'Manager',
      'Super Admin',
      'Root'
    ];
    const selectUserRole = document.getElementById('selectUserRole');
    selectUserRole.innerHTML = '';
    for (let role of roleOptions) {
      let curr = { text: role, value: role };
      // assume the user is not Root so they cant create a Root User
      if (role === 'Root' && this.props.role !== 'Root') continue;
      selectUserRole.options.add(
        new Option(curr.text, curr.value, curr.selected)
      );
    }

    const employeeTypeOptions = ['Employee', 'Contractor'];
    const selectEmployeeType = document.getElementById('selectEmployeeType');
    selectEmployeeType.innerHTML = '';
    for (let type of employeeTypeOptions) {
      let curr = { text: type, value: type };

      selectEmployeeType.options.add(
        new Option(curr.text, curr.value, curr.selected)
      );
    }

    const addNewUserDialog = document.getElementById('addNewUserDialog');
    // addNewUserDialog.style.left = (this.props.toggleMenu) ? '105px' : '330px'
    addNewUserDialog.showModal();
  }
  hideModal() {
    this.props.onEditBreadCrumbs({
      type: 'REMOVE_BREADCRUMB',
      breadCrumbs: 'Users'
    });
    const addNewUserDialog = document.getElementById('addNewUserDialog');
    addNewUserDialog.close();
    // displays the modal and empties the fields
    this.setState({
      profilePicture: '',
      firstName: '',
      lastName: '',
      displayName: '',
      company: '',
      department: '',
      title: '',
      employeeType: 'Employee',
      phoneNumber: '',
      officeNumber: '',
      email: '',
      confirmEmail: '',
      password: '',
      confirmPassword: '',
      // TODO: why is this hardcoded?
      companyID: 'CLI009',
      role: '',
      emergencyPhone: '',
      emergencyName: '',
      physicalDeliveryOfficeName: '',
      samAccountName: ''
    });
  }
  // adding the new user into the table
  handleAddUser() {
    const {
      profilePicture,
      firstName,
      lastName,
      displayName,
      company,
      department,
      title,
      employeeType,
      phoneNumber,
      officeNumber,
      email,
      confirmEmail,
      password,
      confirmPassword,
      companyID,
      role,
      emergencyPhone,
      emergencyName,
      physicalDeliveryOfficeName,
      samAccountName
    } = this.state;
    if (password !== confirmPassword) {
      alert('Please make sure that the passwords match.');
    } else if (password.length <= 7) {
      alert('Please make sure that the password is at least 8 characters.');
    } else if (email !== confirmEmail) {
      alert('Please make sure that the emails match.');
    } else if (phoneNumber.length !== 12) {
      alert('Please Input 10 digits for your phone number.');
    } else if (email.indexOf('@') === -1 || email.length === 0) {
      alert('Please make sure you enter a valid email');
    } else if (firstName.length === 0 || lastName.length === 0) {
      alert('Please enter your first name and last name');
    } else {
      const userData = {
        profilePicture: profilePicture ? profilePicture : '',
        firstName,
        lastName,
        displayName,
        company,
        department,
        title,
        employeeId: '',
        employeeType,
        phoneNumber: phoneNumber.slice(2),
        officeNumber: officeNumber.slice(2),
        email,
        password,
        companyID,
        role,
        emergencyPhone: emergencyPhone.slice(2),
        emergencyName,
        physicalDeliveryOfficeName,
        samAccountName
      };
      console.log('userData: ', userData);

      // sending the data to UserPage
      this.props.onSubmit(userData, () => {});
      this.hideModal();
    }
  }
  handlePicChange(event) {
    event.preventDefault();
    //console.log("[handlePicChange] triggered")
    let reader = new FileReader();
    // get the file thats been uploads
    let file = event.target.files[0];
    // after the file finished loading
    reader.onloadend = () => {
      //console.log("yesss sir")
      this.setState({
        profilePicture: file,
        imgSrc: reader.result
      });
    };
    reader.readAsDataURL(file);
  }
  handleFirstnameChange(event) {
    this.setState({ firstName: event.target.value });
  }
  handleLastnameChange(event) {
    this.setState({ lastName: event.target.value });
  }
  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }
  handleConfirmEmailChange(event) {
    this.setState({ confirmEmail: event.target.value });
  }
  handlePhoneChange(num) {
    this.setState({ phoneNumber: num });
  }
  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }
  handleConfirmPasswordChange(event) {
    this.setState({ confirmPassword: event.target.value });
  }
  handleCompanyIDChange(event) {
    this.setState({ companyID: event.target.value });
  }
  handleRoleChange(event) {
    this.setState({ role: event.target.value });
  }
  handleEmergencyNameChange(event) {
    this.setState({ emergencyName: event.target.value });
  }
  handleEmergencyPhoneChange(num) {
    this.setState({ emergencyPhone: num });
  }
  handleDisplayNameChange(event) {
    this.setState({ displayName: event.target.value });
  }
  handleCompanyChange(event) {
    this.setState({ company: event.target.value });
  }
  handleDepartmentChange(event) {
    this.setState({ department: event.target.value });
  }
  handleTitleChange(event) {
    this.setState({ title: event.target.value });
  }
  handleEmployeeTypeChange(event) {
    this.setState({ employeeType: event.target.value });
  }
  handleOfficePhoneChange(phoneNumber) {
    this.setState({ officeNumber: phoneNumber });
  }
  handlePhyDeliveryOfcNameChange(event) {
    this.setState({ physicalDeliveryOfficeName: event.target.value });
  }
  handleSamAccountNameChange(event) {
    this.setState({ samAccountName: event.target.value });
  }

  render() {
    return (
      <section>
        <nav role='navigation'>
          <button type='button' onClick={this.showModal} className='btn'>
            Create User
          </button>
        </nav>
        <dialog id='addNewUserDialog' className='bigDialogBox'>
          <GrClose
            id='exit-dialog-icon'
            height={28}
            onClick={this.hideModal}
            alt='exit button'
          />

          {/* <Container> */}
          <h2>Create User</h2>

          <Row>
            <Col lg={2} className='mt-3'>
              <label>Profile Picture</label>
              <label className='inputProfile'>
                <input
                  type='file'
                  accept='image/*'
                  id='uploadImage'
                  onChange={this.handlePicChange}
                />
                <img src={this.state.imgSrc} alt='profile' />
              </label>
            </Col>
            <Col lg={10}>
              <Row>
                <Col lg={4}>
                  <label>First Name</label>
                  <input
                    type='text'
                    value={this.state.firstName}
                    onChange={this.handleFirstnameChange}
                    placeholder='First Name'
                  />
                </Col>
                <Col lg={4}>
                  <label>Last Name</label>
                  <input
                    type='text'
                    value={this.state.lastName}
                    onChange={this.handleLastnameChange}
                    placeholder='Last Name'
                  />
                </Col>
                <Col lg={4}>
                  <label>Display Name</label>
                  <input
                    type='text'
                    value={this.state.displayName}
                    onChange={this.handleDisplayNameChange}
                    placeholder='Display Name'
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <label>Company</label>
                  <input
                    type='text'
                    value={this.state.company}
                    onChange={this.handleCompanyChange}
                    placeholder='Company'
                  />
                </Col>
                <Col lg={4}>
                  <label>Department</label>
                  <input
                    type='text'
                    value={this.state.department}
                    onChange={this.handleDepartmentChange}
                    placeholder='Department'
                  />
                </Col>
                <Col lg={4}>
                  <label>Title</label>
                  <input
                    type='text'
                    value={this.state.title}
                    onChange={this.handleTitleChange}
                    placeholder='Title'
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <label>Employee Type:</label>
                  <select
                    id='selectEmployeeType'
                    value={this.state.employeeType}
                    onChange={this.handleEmployeeTypeChange}
                  ></select>
                </Col>

                <Col lg={4}>
                  <label>Phone Number</label>
                  <Input
                    country='US'
                    value={this.state.phoneNumber}
                    onChange={this.handlePhoneChange}
                    maxLength={14}
                    placeholder='Phone Number'
                  />
                </Col>
                <Col lg={4}>
                  <label>Office Phone Number</label>
                  <Input
                    country='US'
                    value={this.state.officeNumber}
                    onChange={this.handleOfficePhoneChange}
                    maxLength={14}
                    placeholder='Office Phone Number'
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <label>Email</label>
                  <input
                    type='email'
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                    placeholder='Email'
                  />
                </Col>
                <Col lg={4}>
                  <label>Confirm</label>
                  <input
                    type='email'
                    value={this.state.confirmEmail}
                    onChange={this.handleConfirmEmailChange}
                    placeholder='Confirm Email'
                  />
                </Col>
                {this.props.role === 'Root' ? (
                  <Fragment>
                    <Col lg={4}>
                      <label>Client Code:</label>

                      <select
                        id='selectCompanyID'
                        value={this.state.companyID}
                        onChange={this.handleCompanyIDChange}
                      ></select>
                    </Col>
                  </Fragment>
                ) : null}
              </Row>

              <Row>
                <Col lg={4}>
                  <label>Physical Delivery Office Name</label>
                  <input
                    type='text'
                    value={this.state.physicalDeliveryOfficeName}
                    onChange={this.handlePhyDeliveryOfcNameChange}
                    placeholder='Physical Delivery Office Name'
                  />
                </Col>
                <Col lg={4}>
                  <label>Sam Account Name</label>
                  <input
                    type='text'
                    value={this.state.samAccountName}
                    onChange={this.handleSamAccountNameChange}
                    placeholder='Sam Account Name'
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={4}>
                  <label>Password</label>
                  <input
                    type='password'
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                    placeholder='Password'
                  />
                </Col>
                <Col lg={4}>
                  <label>Confirm Password</label>
                  <input
                    type='password'
                    value={this.state.confirmPassword}
                    onChange={this.handleConfirmPasswordChange}
                    placeholder='Confirm Password'
                  />
                </Col>
                <Col lg={4}>
                  <label>Admin Level:</label>
                  <select
                    id='selectUserRole'
                    value={this.state.role}
                    onChange={this.handleRoleChange}
                  ></select>
                </Col>
              </Row>

              <Row>
                <Col lg={4}>
                  <label>Emergency Contact: Full Name</label>
                  <input
                    type='text'
                    value={this.state.emergencyName}
                    onChange={this.handleEmergencyNameChange}
                    placeholder='Emergency Contact: Full Name'
                  />
                </Col>
                <Col lg={4}>
                  <label>Emergency Contact: Phone Number</label>
                  <Input
                    country='US'
                    value={this.state.emergencyPhone}
                    onChange={this.handleEmergencyPhoneChange}
                    maxLength={14}
                    placeholder='Emergency Contact: Phone Number'
                  />
                </Col>
                <Col lg={4}></Col>
              </Row>

              <Row>
                <Col>
                  <div className='btn--flex'>
                    <button
                      onClick={this.handleAddUser}
                      type='button'
                      className='btn--confirm dialog__btn'
                    >
                      Confirm
                    </button>
                    <button
                      onClick={this.hideModal}
                      type='button'
                      className='btn--close dialog__btn'
                    >
                      Cancel
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* </Container> */}
        </dialog>
      </section>
    );
  }
}

export default AddUser;
