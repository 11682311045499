import PdfDownload from './PdfDownload';
import ReportPageWrapper from './ReportPageWrapper';
import AlertTable from './AlertTable';
import UserTableNameOnly from './UserTableNameOnly';
// import MessageTable from './MessageTable';

import { formatUnixToDateTimeString } from '../../utils/datetime';
import { objectToArray } from '../../utils/data';

/**
 * TODOS:
 * Include MessageReport (requires messageData from ???)
 * */

const ReportDocument = ({
  AlertReport,
  UserReport
  // MessageReport
}) => (
  <ReportPageWrapper pageStyles={{ padding: '18px' }}>
    <AlertReport />
    <UserReport />
    {/* <MessageReport /> */}
  </ReportPageWrapper>
);

const CriticalEventReport = ({ data }) => {
  const fileName = 'critical_event_report.pdf';

  const {
    createdByName,
    dateActivated,
    dateDeactivated,
    name,
    rcDescription,
    type,
    users
  } = data;

  let dateActivatedString = '';
  let timeActivatedString = '';
  if (dateActivated) {
    const dateTimeActivated = formatUnixToDateTimeString(dateActivated.seconds);
    const dateTimeStringsActivated = dateTimeActivated.split(',');
    dateActivatedString = dateTimeStringsActivated[0];
    timeActivatedString = dateTimeStringsActivated[1].trim();
  }

  let dateDeactivatedString = '';
  let timeDeactivatedString = '';
  if (dateDeactivated) {
    const dateTimeDeactivated = formatUnixToDateTimeString(
      dateDeactivated.seconds
    );
    const dateTimeStringsDeactivated = dateTimeDeactivated.split(',');
    dateDeactivatedString = dateTimeStringsDeactivated[0];
    timeDeactivatedString = dateTimeStringsDeactivated[1].trim();
  }

  const usersData = objectToArray(users);

  const alertData = {
    type,
    name,
    message: rcDescription,
    createdBy: createdByName ? createdByName : null,
    recipientCount: usersData.length,
    dateActivated: dateActivatedString,
    timeActivated: timeActivatedString,
    dateDeactivated: dateDeactivatedString,
    timeDeactivated: timeDeactivatedString
  };

  // create tables
  const AlertReport = () => <AlertTable data={alertData} />;

  const UserReport = () => <UserTableNameOnly rows={usersData} />;

  // const MessageReport = () => <MessageTable rows={messageData} />;

  // combine into one document called Report
  let Report = () => (
    <ReportDocument
      AlertReport={AlertReport}
      UserReport={UserReport}
      // MessageReport={MessageReport}
    />
  );

  return <PdfDownload DownloadDocument={Report} filename={fileName} />;
};

export default CriticalEventReport;
