import { Route, Routes } from 'react-router-dom';

import App from 'App';
import AppLegacy from 'components/AppLegacy';

const AppRoutes = () => (
  <Routes>
    <Route path='/' element={<App />}>
      <Route index element={<AppLegacy />} />
    </Route>
  </Routes>
);

export default AppRoutes;
