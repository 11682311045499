import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { getTimeAndDateStringsFromUnixTimestamp } from '../../utils/datetime';

/**
 * TODOS:
 * Include date and time for each user status (requires data from ???)
 * */

// Create styles
const styles = StyleSheet.create({
  table: {
    display: 'table',
    width: 'auto',
    marginBottom: 20,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  tableCol: {
    // width: '50%',
    width: '25%', // for 4 columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    marginTop: 5,
    marginLeft: 5,
    fontSize: 10
  },
  tableHeader: {
    width: '100%',
    backgroundColor: '#4472c4'
  },
  tableHeaderText: {
    margin: 'auto',
    marginTop: 5,
    marginBottom: 5,
    fontSize: 12,
    color: 'white'
  }
});

const UserTableFull = ({ rows }) => {
  // check for dateLastUpdated
  // parse; set date and time
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={styles.tableHeader}>
          <Text style={styles.tableHeaderText}>User Report</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>User Name</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Status</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Date</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Time</Text>
        </View>
      </View>
      {rows.map((row) => {
        let time = 'n/a';
        let date = 'n/a';
        const { id, name, status } = row;
        const statusLabel =
          status === 'green' ? 'Safe' : status === 'red' ? 'Danger' : '';

        if (row.dateLastUpdated) {
          const dateTime = getTimeAndDateStringsFromUnixTimestamp(
            row.dateLastUpdated.seconds
          );

          time = dateTime.time;
          date = dateTime.date;
        }

        return (
          <View key={id} style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{name}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{statusLabel}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{date}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{time}</Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default UserTableFull;
