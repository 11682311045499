import firebaseApp from '../../firebaseApp';
import { firestoreArrayUnion } from '../../utils';

const db = firebaseApp.firestore();
const collectionName = 'folders';

export const getFolderByCompanyID = async (companyID) => {
  try {
    const folderRef = db.collection(collectionName);

    const snapshot = await folderRef.where('companyID', '==', companyID).get();

    if (snapshot.empty) {
      return null;
    }

    const folderData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }));

    return folderData;
  } catch (error) {
    console.log('ERROR - db/getFolderByCompanyID: ', error);
    return null;
  }
};

export const getFolderByDocumentID = async (documentID) => {
  try {
    console.log('documentID: ', documentID);
    const folderRef = db.collection(collectionName).doc(documentID);

    const doc = await folderRef.get();
    // console.log('doc: ', doc);

    if (doc.exists) {
      const data = doc.data();

      if (data.folders.length > 0) {
        let folders = [];

        for (const subfolderRef of data.folders) {
          // console.log('folder: ', folder);
          const subfolder = await subfolderRef.get();

          if (subfolder.exists) {
            const subfolderData = await subfolder.data();
            folders.push(subfolderData);
          }
        }

        data.folders = folders;
      }
    } else {
      return null;
    }
  } catch (error) {
    console.log('ERROR - db/getFolderByDocumentID: ', error);
    return null;
  }
};

export const getFolderNameAndParentIDByDocumentID = async (documentID) => {
  try {
    const folderRef = db.collection(collectionName).doc(documentID);

    const doc = await folderRef.get();

    if (doc.exists) {
      const data = doc.data();

      return {
        folderName: data.folderName,
        parentFolderID: data.parentFolderID
      };
    } else {
      return null;
    }
  } catch (error) {
    console.log('ERROR - db/getFolderNameAndParentIDByDocumentID: ', error);
    return null;
  }
};

export const getFolderFilesByFolderID = async (folderID) => {
  try {
    const folderRef = db.collection(collectionName).doc(folderID);

    const doc = await folderRef.get();

    if (doc.exists) {
      const data = doc.data();

      return data.files;
    } else {
      return null;
    }
  } catch (error) {
    console.log('ERROR - db/getFolderNameAndParentIDByDocumentID: ', error);
    return null;
  }
};

export const getAllFoldersbyRef = async (folderRef) => {
  try {
    // console.log('folderRef: ', folderRef);

    const doc = await folderRef.get();

    // console.log('doc: ', doc);

    if (doc.exists) {
      const data = await doc.data();

      if (data.folders.length > 0) {
        let folders = [];

        for (const subfolderRef of data.folders) {
          // console.log('subfolderRef: ', subfolderRef);
          const subfolderData = await getAllFoldersbyRef(subfolderRef);

          folders.push(subfolderData);
        }

        data.folders = folders;
      }

      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log('ERROR - db/getAllFoldersbyRef: ', error);
    return null;
  }
};

export const getFolderbyRef = async (folderRef) => {
  try {
    const doc = await folderRef.get();
    // get first level of folders
    if (doc.exists) {
      const data = await doc.data();

      if (data.folders.length > 0) {
        // get second level of folders
        let folders = [];

        for (const subfolderRef of data.folders) {
          // console.log('folder: ', folder);
          const subfolder = await subfolderRef.get();

          if (subfolder.exists) {
            const subfolderData = await subfolder.data();

            if (subfolderData.folders.length > 0) {
              // get third level of folders
              let subfolderFolders = [];

              for (const subfolderFolderRef of subfolderData.folders) {
                // console.log('folder: ', folder);
                const subfolderFolder = await subfolderFolderRef.get();

                if (subfolderFolder.exists) {
                  const subfolderFolderData = await subfolderFolder.data();
                  subfolderFolders.push(subfolderFolderData);
                }
              }

              subfolderData.folders = subfolderFolders;
            }

            folders.push(subfolderData);
          }
        }

        data.folders = folders;
      }

      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log('ERROR - db/getFolderbyRef: ', error);
    return null;
  }
};

export const getFolderRefByDocumentID = async (documentID) => {
  try {
    const folderRef = db.collection(collectionName).doc(documentID);

    if (!folderRef) {
      return null;
    }

    return folderRef;
  } catch (error) {
    console.log('ERROR - db/getFolderRefByDocumentID: ', error);
    return null;
  }
};

export const createFolderDocRef = async () => {
  try {
    const folderRef = db.collection(collectionName).doc();

    console.log('new folderRef: ', folderRef.id);

    if (folderRef.id) {
      return folderRef;
    } else {
      return null;
    }
  } catch (error) {
    console.log('ERROR - db/createFolderDocRef: ', error);
    return null;
  }
};

export const setFolderDocByRef = async (folderRef, data) => {
  try {
    console.log('folderRef: ', folderRef);
    console.log('data: ', data);

    const res = await folderRef.set(data);
    console.log('res: ', res);

    console.log('folderRef: ', folderRef.id);

    if (folderRef.id) {
      return folderRef;
    } else {
      return null;
    }
  } catch (error) {
    console.log('ERROR - db/setFolderDoc: ', error);
    return null;
  }
};

export const setFolderDocByID = async (documentID, data) => {
  try {
    console.log('documentID: ', documentID);
    console.log('data: ', data);

    const folderRef = await db
      .collection(collectionName)
      .doc(documentID)
      .set(data);

    console.log('new folderRef: ', folderRef);

    if (folderRef.id) {
      return folderRef;
    } else {
      return null;
    }
  } catch (error) {
    console.log('ERROR - db/setFolderDoc: ', error);
    return null;
  }
};

export const addFolderDoc = async (data) => {
  try {
    const folderRef = await db.collection(collectionName).add(data);

    if (folderRef.id) {
      return folderRef;
    } else {
      return null;
    }
  } catch (error) {
    console.log('ERROR - db/addFolderDoc: ', error);
    return null;
  }
};

export const updateFolderDocByID = async (documentID, data) => {
  try {
    const folderRef = db.collection(collectionName).doc(documentID);

    await folderRef.update(data);

    return folderRef;
  } catch (error) {
    console.log('ERROR - db/updateFolderDocByID: ', error);
    return null;
  }
};

export const addSubfolderByDocID = async (documentID, subfolderRef) => {
  try {
    const folderRef = db.collection(collectionName).doc(documentID);

    await folderRef.update({
      folders: firestoreArrayUnion(subfolderRef)
    });

    return folderRef;
  } catch (error) {
    console.log('ERROR - db/addSubfolderByDocID: ', error);
    return null;
  }
};

export const removeSubfolderByDocID = async (documentID, subfolderRef) => {
  try {
    const folderRef = db.collection(collectionName).doc(documentID);
    const folder = await folderRef.get();
    const folderData = folder.data();

    const updatedFolders = folderData.folders.filter((folder) => {
      return folder.id !== subfolderRef.id;
    });

    await folderRef.update({
      folders: updatedFolders
    });

    return folderRef;
  } catch (error) {
    console.log('ERROR - db/removeSubfolderByDocID: ', error);
    return null;
  }
};

export const deleteFolderDoc = async (documentID) => {
  try {
    const folderRef = db.collection(collectionName).doc(documentID);

    await folderRef.delete();

    if (folderRef) {
      return folderRef;
    } else {
      return null;
    }
  } catch (error) {
    console.log('ERROR - db/deleteFolderDoc: ', error);
    return null;
  }
};
