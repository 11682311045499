import * as React from 'react';

import ConfirmDelete from './ConfirmDelete';
import FolderEdit from './FolderEdit';
import FolderPassword from './FolderPassword';

import {
  deleteSubfolder,
  editFolderName,
  updateFolderPassword
} from '../../firebase/db/folders/operations';

import { AiFillFolder } from 'react-icons/ai';

import '../style/LibraryTreeItem.css';

const FolderItem = ({ itemData }) => {
  const {
    folderName,
    folderID,
    folders,
    files,
    updatedAt,
    createdAt,
    isProtected,
    password,
    parentFolderID
  } = itemData;

  const date = updatedAt || createdAt;
  const timestamp = new Date(date.toDate()).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });

  const folderIsNotEmpty =
    (folders && folders.length > 0) || (files && files.length > 0);

  const handleDeleteFolder = async () => {
    try {
      deleteSubfolder(folderID, parentFolderID);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const handleEditFolder = async (folderName) => {
    try {
      await editFolderName(folderID, folderName);
    } catch (error) {
      console.log('handleEditFolder/error: ', error);
    }
  };

  const handleUpdatePassword = async (update) => {
    try {
      await updateFolderPassword(folderID, update);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className='library__tree-item__content'>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <div style={{ width: '18rem' }}>
          <AiFillFolder
            title='Folder'
            className='icon_button'
            height={25}
            width={25}
            alt='descriptor'
          />

          <span style={{ paddingLeft: '1rem' }}>{folderName}</span>
        </div>
      </div>

      <div className='library__tree-item__content-section'>
        <span style={{ width: '50%' }}>{timestamp}</span>
        <span style={{ width: '20%' }}>Folder</span>
        <span>
          <FolderPassword
            folderName={folderName}
            isProtected={isProtected}
            currentPwd={password}
            updateFolderPassword={handleUpdatePassword}
          />
        </span>
        <span>
          <FolderEdit
            folderName={folderName}
            updateFolderName={handleEditFolder}
          />
        </span>
        <span>
          <ConfirmDelete
            item={folderName}
            itemType={'folder'}
            onConfirm={handleDeleteFolder}
            isDisabled={folderIsNotEmpty}
          />
        </span>
      </div>
    </div>
  );
};

export default FolderItem;
