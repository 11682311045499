import { Component } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Input from 'react-phone-number-input/input';

import { GrClose } from 'react-icons/gr';

import ProfilePreview from './style/default_profile.png';

import './style/addUserDialog.css';

class AddClient extends Component {
  constructor() {
    super();
    this.state = {
      logo: '',
      clientName: '',
      domain: '',
      phoneNumber: '',
      address: '',
      city: '',
      stateName: '',
      licensesAssigned: 0,
      adminLicensesAssigned: 1,
      imgSrc: ProfilePreview
    };
    this.handleLogoChange = this.handleLogoChange.bind(this);
    this.handleClientNameChange = this.handleClientNameChange.bind(this);
    this.handleDomainChange = this.handleDomainChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleStateNameChange = this.handleStateNameChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handlelicenseAssignedChange =
      this.handlelicenseAssignedChange.bind(this);
    this.handleAdminLicenseAssignedChange =
      this.handleAdminLicenseAssignedChange.bind(this);
    this.handleAddClient = this.handleAddClient.bind(this);
    this.showAddClientBox = this.showAddClientBox.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }
  showAddClientBox() {
    this.props.onEditBreadCrumbs({
      type: 'APPEND_BREADCRUMB',
      breadCrumbs: ' > New Client'
    });
    const addClientDialog = document.getElementById('addClientDialog');
    // addClientDialog.style.left = (this.props.toggleMenu) ? '105px' : '330px'
    addClientDialog.showModal();
    // displays the modal and empties the fields
    this.setState({
      logo: '',
      clientName: '',
      domain: '',
      address: '',
      city: '',
      stateName: '',
      phoneNumber: '',
      licensesAssigned: 0,
      adminLicensesAssigned: 1
    });
  }
  hideModal() {
    this.props.onEditBreadCrumbs({
      type: 'REMOVE_BREADCRUMB',
      breadCrumbs: 'Clients'
    });
    const addClientDialog = document.getElementById('addClientDialog');
    addClientDialog.close();
  }
  handleLogoChange(event) {
    event.preventDefault();
    let reader = new FileReader();
    // get the file thats been uploads
    let file = event.target.files[0];
    // after the file finished loading
    reader.onloadend = () => {
      this.setState({
        logo: file,
        imgSrc: reader.result
      });
    };
    reader.readAsDataURL(file);
  }
  handleClientNameChange(event) {
    this.setState({ clientName: event.target.value });
  }
  handleDomainChange(event) {
    this.setState({ domain: event.target.value });
  }
  handleAddressChange(event) {
    this.setState({ address: event.target.value });
  }
  handleStateNameChange(event) {
    this.setState({ stateName: event.target.value });
  }
  handleCityChange(event) {
    this.setState({ city: event.target.value });
  }
  handlePhoneChange(num) {
    this.setState({ phoneNumber: num });
  }
  handlelicenseAssignedChange(event) {
    let num = 0;
    if (event.target.value !== '') {
      num = event.target.value;
    }
    this.setState({ licensesAssigned: num });
  }
  handleAdminLicenseAssignedChange(event) {
    let num = 1;
    if (event.target.value !== '') {
      num = event.target.value;
    }
    this.setState({ adminLicensesAssigned: num });
  }
  handleAddClient() {
    // check required fields
    if (this.state.clientName.length < 2) {
      alert('Please make sure to enter a valid Company Name.');
    } else if (this.state.domain.length === 0) {
      alert(
        'Please make sure to enter a valid domain eg: "https://www.tpcm-usa.com". Looks like the domain field is empty.'
      );
    } else if (this.state.phoneNumber.length !== 12) {
      alert('Please Input 10 digits for your phone number.');
    } else if (
      this.state.address.length < 2 ||
      this.state.city.length < 2 ||
      this.state.stateName.length < 2
    ) {
      alert('Please make sure you enter a valid address/city/state');
    } else if (this.state.adminLicensesAssigned < 1) {
      alert('Please assign atleast one admin license.');
    } else {
      this.props.onAddClient({
        logo: this.state.logo,
        clientName: this.state.clientName,
        domain: this.state.domain,
        address: this.state.address,
        city: this.state.city,
        stateName: this.state.stateName,
        phoneNumber: this.state.phoneNumber.slice(2),
        licensesAssigned: this.state.licensesAssigned,
        adminLicensesAssigned: this.state.adminLicensesAssigned
      });
      this.hideModal();
    }
  }
  render() {
    return (
      <section>
        <nav role='navigation'>
          <button type='button' onClick={this.showAddClientBox} className='btn'>
            Create Client
          </button>
        </nav>
        <dialog id='addClientDialog' className='bigDialogBox'>
          <GrClose
            id='exit-dialog-icon'
            height={28}
            onClick={this.hideModal}
            alt='exit button'
          />

          <h2>Create Client</h2>

          <Row>
            <Col lg={2} className='mt-3'>
              <label>Company Logo:</label>
              <label className='inputProfile'>
                <input
                  type='file'
                  accept='image/*'
                  id='uploadImage'
                  onChange={this.handleLogoChange}
                />
                <img src={this.state.imgSrc} alt='profile' />
              </label>
            </Col>
            <Col lg={10}>
              <Row>
                <Col lg={4}>
                  <label>Domain *</label>
                  <input
                    type='text'
                    value={this.state.domain}
                    onChange={this.handleDomainChange}
                    placeholder='Domain *'
                  />
                </Col>
                <Col lg={4}>
                  <label>Company *</label>
                  <input
                    type='text'
                    value={this.state.clientName}
                    onChange={this.handleClientNameChange}
                    placeholder='Company Name *'
                  />
                </Col>
                <Col lg={4}>
                  <label>Street Address *</label>
                  <input
                    type='text'
                    value={this.state.address}
                    onChange={this.handleAddressChange}
                    placeholder='Street Address *'
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <label>City *</label>
                  <input
                    type='text'
                    value={this.state.city}
                    onChange={this.handleCityChange}
                    placeholder='City *'
                  />
                </Col>
                <Col lg={4}>
                  <label>State *</label>
                  <input
                    type='text'
                    value={this.state.stateName}
                    onChange={this.handleStateNameChange}
                    placeholder='State *'
                  />
                </Col>
                <Col lg={4}>
                  <label>Phone Number *</label>
                  <Input
                    country='US'
                    value={this.state.phoneNumber}
                    onChange={this.handlePhoneChange}
                    maxLength={14}
                    placeholder='Phone Number *'
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={4}>
                  <label>User Licenses</label>
                  <br />
                  <input
                    type='number'
                    value={this.state.licensesAssigned}
                    onChange={this.handlelicenseAssignedChange}
                    placeholder='Licenses Assigned'
                  />
                </Col>
                <Col lg={4}>
                  <label>Admin Licenses</label>
                  <br />
                  <input
                    type='number'
                    value={this.state.adminLicensesAssigned}
                    onChange={this.handleAdminLicenseAssignedChange}
                    placeholder='Licenses Assigned'
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className='btn--flex'>
                    <button
                      onClick={this.handleAddClient}
                      type='button'
                      className='btn--confirm dialog__btn'
                    >
                      Confirm
                    </button>
                    <button
                      onClick={this.hideModal}
                      type='button'
                      className='btn--close dialog__btn'
                    >
                      Cancel
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </dialog>
      </section>
    );
  }
}
export default AddClient;
