import { useEffect, useMemo, useState } from 'react';
import { useSelector } from '@legendapp/state/react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Input from '@mui/material/Input';

import { uploadFile } from '../../firebase/actions/library';
import { getFolderNameAndParentIDByDocumentID } from '../../firebase/db/folders/query';

import Store from '../../store/Store';

const FolderUpload = () => {
  const selectedFolderID = useSelector(Store.library.selectedFolder);
  const rootFolderID = useSelector(Store.library.rootFolderID);

  const [open, setOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [parentFolder, setParentFolder] = useState('root');
  const [file, setFile] = useState(null);

  const parentFolderID = useMemo(
    () => (selectedFolderID ? selectedFolderID : rootFolderID),
    [rootFolderID, selectedFolderID]
  );

  useEffect(() => {
    const getFolderName = async () => {
      try {
        const result = await getFolderNameAndParentIDByDocumentID(
          parentFolderID
        );
        setParentFolder(result.folderName);
      } catch (error) {
        console.log('error: ', error);
      }
    };

    if (!open) return;

    getFolderName();
  }, [open, parentFolderID]);

  const handleUploadFileClick = (e) => {
    e.stopPropagation();
    setOpen(true);
    Store.library.isAddOrUploadDialogOpen.set(true);
  };

  const handleCancel = () => {
    setOpen(false);
    Store.library.isAddOrUploadDialogOpen.set(false);
  };

  const handleFileUpload = (e) => {
    let reader = new FileReader();

    let file = e.target.files[0];

    if (file !== undefined) {
      reader.onloadend = () => {
        setFile(file);
      };
    }
    reader.readAsDataURL(file);
  };

  const handleSubmit = async () => {
    try {
      setIsProcessing(true);
      await uploadFile(file, parentFolderID, parentFolder);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setIsProcessing(false);
      setOpen(false);
    }
  };

  return (
    <>
      <Button
        variant='contained'
        onClick={(e) => handleUploadFileClick(e)}
        sx={{ backgroundColor: '#2152ff' }}
      >
        Upload File
      </Button>

      <Dialog
        id='folder-update-dialog'
        maxWidth='sm'
        open={open}
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      >
        <DialogTitle>Upload File</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span>Parent Folder:</span>
            <span
              style={{ paddingLeft: '1rem', fontSize: 16, fontWeight: 'bold' }}
            >
              {parentFolder}
            </span>
          </DialogContentText>

          <Input
            type='file'
            id='file-picker'
            label='Choose file to upload'
            onChange={(e) => handleFileUpload(e)}
            sx={{ padding: '1rem' }}
            style={{
              padding: '1rem',
              width: '100%'
            }}
          />

          {isProcessing && (
            <CircularProgress
              sx={{ position: 'absolute', top: '50%', left: '50%' }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>

          <Button
            variant='contained'
            onClick={handleSubmit}
            disabled={isProcessing}
          >
            Upload File
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FolderUpload;
