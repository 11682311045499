import { Component } from 'react';
import Input from 'react-phone-number-input/input';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import AddClientDialog from './addClientDialog';
import Table from './Table';

import { BiReset } from 'react-icons/bi';
import { ImPencil } from 'react-icons/im';
import { AiFillDelete } from 'react-icons/ai';
import { FiMessageSquare } from 'react-icons/fi';
import { GrClose } from 'react-icons/gr';
import Reset from './style/reset_btn.png';

class ClientTable extends Component {
  constructor() {
    super();
    this.state = {
      index: 0, // index of the selected client for edit
      domain: '', // the domain associated with  the client
      clientName: '', // the clients name
      address: '', // address of the client
      city: '', // city the client is located in
      stateName: '', //state the client is located in
      phoneNumber: '', // the clients phone number
      logo: '', // the file of the logo for the client
      imgSrc: '', // logo image source
      licensesAssigned: 0,
      adminLicensesAssigned: 1,
      inputText: undefined // {label: string, value: string}
    };
    this.handleLogoChange = this.handleLogoChange.bind(this);
    this.handleDomainChange = this.handleDomainChange.bind(this);
    this.handleClientNameChange = this.handleClientNameChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleStateNameChange = this.handleStateNameChange.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handlelicenseAssignedChange =
      this.handlelicenseAssignedChange.bind(this);
    this.handleAdminLicenseAssignedChange =
      this.handleAdminLicenseAssignedChange.bind(this);
    this.handleEditClient = this.handleEditClient.bind(this);
    this.hideEditClientBox = this.hideEditClientBox.bind(this);
  }
  // sends the data to the client page
  // triggered when the the confirm button in the dialog is pressed
  handleEditClient() {
    console.log(this.state.clientName);
    if (this.state.clientName.length < 2) {
      alert('Please make sure to enter a valid Company Name.');
    } else if (this.state.domain.length === 0) {
      alert(
        'Please make sure to enter a valid domain eg: "https://www.tpcm-usa.com". Looks like the domain field is empty.'
      );
    } else if (this.state.phoneNumber.length !== 12) {
      alert('Please Input 10 digits for your phone number.');
    } else if (
      this.state.address.length < 2 ||
      this.state.city.length < 2 ||
      this.state.stateName.length < 2
    ) {
      alert('Please make sure you enter a valid address/city/state');
    } else if (this.state.adminLicensesAssigned < 1) {
      alert('Please assign atleast one admin license.');
    } else {
      this.props.onEditClient({
        logo: this.state.logo,
        index: this.state.index,
        domain: this.state.domain,
        clientName: this.state.clientName,
        phoneNumber: this.state.phoneNumber.slice(2), //remove the first two characters of the number
        address: this.state.address,
        city: this.state.city,
        stateName: this.state.stateName,
        licensesAssigned: this.state.licensesAssigned,
        adminLicensesAssigned: this.state.adminLicensesAssigned
      });
      this.hideEditClientBox();
    }
  }
  handleLogoChange(event) {
    event.preventDefault();
    let reader = new FileReader();
    // get the file thats been uploads
    let file = event.target.files[0];
    // after the file finished loading
    reader.onloadend = () => {
      this.setState({
        logo: file,
        imgSrc: reader.result
      });
    };
    reader.readAsDataURL(file);
  }
  handleDomainChange(event) {
    this.setState({ domain: event.target.value });
  }
  handleClientNameChange(event) {
    this.setState({ clientName: event.target.value });
  }
  handleAddressChange(event) {
    this.setState({ address: event.target.value });
  }
  handleCityChange(event) {
    this.setState({ city: event.target.value });
  }
  handleStateNameChange(event) {
    this.setState({ stateName: event.target.value });
  }
  handlePhoneNumberChange(num) {
    this.setState({ phoneNumber: num });
  }
  handlelicenseAssignedChange(event) {
    let num = 0;
    if (event.target.value !== '') {
      num = event.target.value;
    }
    this.setState({ licensesAssigned: num });
  }
  handleAdminLicenseAssignedChange(event) {
    let num = 1;
    if (event.target.value !== '') {
      num = event.target.value;
    }
    this.setState({ adminLicensesAssigned: num });
  }
  showEditClientBox() {
    this.props.onEditBreadCrumbs({
      type: 'APPEND_BREADCRUMB',
      breadCrumbs: ' > Edit Client'
    });
    const editClientDialog = document.getElementById('editClientDialog');
    // editClientDialog.style.left = (this.props.toggleMenu) ? '105px' : '330px'
    editClientDialog.showModal();
  }
  hideEditClientBox() {
    this.props.onEditBreadCrumbs({
      type: 'REMOVE_BREADCRUMB',
      breadCrumbs: 'Clients'
    });
    const editClientDialog = document.getElementById('editClientDialog');
    editClientDialog.close();
  }
  render() {
    // console.log(newClient);
    // predefined names for the columns
    var columns = [
      {
        Header: 'Users',
        columns: [
          {
            Header: '   ',
            Cell: ({ row }) => {
              return (
                <img
                  style={{ borderRadius: '50%', background: 'lightgrey' }}
                  src={row.original.logo}
                  width='40'
                  height='40'
                  alt=''
                />
              );
            }
          },
          {
            Header: 'Client Name',
            accessor: 'clientName'
          },
          {
            Header: 'Client Code',
            accessor: 'companyID'
          },
          {
            Header: 'Domain',
            accessor: 'domain'
          },
          {
            Header: 'Phone Number', //4
            Cell: ({ row }) => {
              let num = row.original.phoneNumber;
              let phone = !num ? (
                ''
              ) : (
                <span>
                  {`(${num.substr(0, 3)}) ${num.substr(3, 3)}-${num.substr(6)}`}
                </span>
              );

              return phone;
            }
          },
          {
            Header: 'Plans',
            accessor: 'plans'
          },
          {
            Header: 'Enabled',
            // add the Toggle Switch over here
            Cell: ({ row }) => {
              let enabled = !row.original.deactivated;
              // save as string and then do the logic
              return (
                <label title='Enable Toggle' className='switch'>
                  <input type='checkbox' defaultChecked={enabled} />
                  <span
                    className='slider round'
                    onClick={() => {
                      this.props.onToggleClient({ index: row.id });
                    }}
                  ></span>
                </label>
              );
            }
          },
          // send message to all users
          {
            Header: '       ',
            Cell: ({ row }) => {
              return (
                <FiMessageSquare
                  title='Send Message to Users'
                  height={35}
                  width={35}
                  alt='edit'
                  className='icon_button'
                  onClick={() =>
                    this.props.onSendUsersMessage({
                      companyID: row.original.companyID
                    })
                  }
                />
              );
            }
          },
          {
            // Edit button functionality
            Header: '  ',
            Cell: ({ row }) => {
              return (
                <ImPencil
                  title='Edit Client'
                  height={25}
                  width={25}
                  alt='edit'
                  className='icon_button'
                  onClick={(event) => {
                    event.persist();
                    const rData = row.original;
                    this.showEditClientBox();
                    let adminlicenses = 1;
                    if (rData.adminLicensesAssigned !== undefined) {
                      adminlicenses = rData.adminLicensesAssigned;
                    }
                    this.setState({
                      // imgSrc: rData.logo[0].props.src,
                      imgSrc: rData.logo, // updating the logo src
                      domain: rData.domain,
                      clientName: rData.clientName,
                      phoneNumber: '+1' + rData.phoneNumber,
                      address: rData.address,
                      city: rData.city,
                      stateName: rData.stateName,
                      licensesAssigned: rData.licensesAssigned,
                      adminLicensesAssigned: adminlicenses,
                      index: row.id // The the index of the selected client
                    });
                  }}
                />
              );
            }
          },
          {
            // Delete button functionality
            Header: ' ',
            Cell: (row) => {
              return (
                <AiFillDelete
                  title='Delete Client'
                  height={25}
                  width={25}
                  alt='delete'
                  className='icon_button'
                  onClick={(event) => {
                    event.persist();
                    var res = window.confirm(
                      'Are you sure you want to delete the Client?'
                    );
                    if (res) {
                      // Supposed to send the row ID of the User the admin wants to delete
                      this.props.onDeleteClient({ index: row.row.id });
                    }
                  }}
                />
              );
            }
          }
        ]
      }
    ];

    if (this.props.viewDeletedClients) {
      // assume the deleted clients table is displayed
      columns[0].columns.splice(5, 5); // remove the deleted button column
      columns[0].columns.push({
        Header: 'Reactivate CLient',
        Cell: ({ row }) => (
          <button
            id='taskButton'
            onClick={() => {
              let res = window.confirm(
                'Are you sure you want to reactivate the client?'
              );
              if (res) {
                this.props.reactivateClient({
                  index: row.id,
                  id: row.original.id
                });
              }
            }}
          >
            Reactivate
          </button>
        )
      });
    }
    return (
      <main>
        <p id='breadCrumbs'>{this.props.breadCrumbs}</p>

        <div className='MuiBox-root'>
          <div className='MuiPaper-root'>
            <div className='MuiBox-root css-1idik3a'>
              <div className='MuiBox-root css-uujcwg'>
                <h5 className='css-epib0t'>Client List</h5>
                <span className='css-17vat79'></span>
              </div>
              <div className='css-1yjo05o'>
                <ul className='topnav'>
                  <li
                    id='secondaryNavigationItems'
                    onClick={this.props.onViewClientListAction}
                    className={this.props.clientHighlight[0]}
                  >
                    Clients
                  </li>
                  <li
                    id='secondaryNavigationItems'
                    onClick={this.props.onViewDeletedClientListAction}
                    className={this.props.clientHighlight[1]}
                  >
                    Deleted
                  </li>
                </ul>
              </div>
            </div>
            <div className='MuiTableContainer-root css-13ypi1f'>
              <div className='MuiBox-root css-fgimv3'>
                <Row>
                  <Col lg={4}>
                    <div className='search'>
                      <input
                        className='pl-2'
                        id='searchBox'
                        placeholder='Search Client'
                        value={this.state.inputText}
                        onChange={(event) => {
                          this.props.onfilterTable({
                            type: 'FILTER_CLIENT_LIST',
                            value: event.target.value
                          });
                          let inputText = event.target.value;
                          this.setState({ inputText: inputText });
                        }}
                      />

                      <BiReset
                        id='resetTableListBtn'
                        src={Reset}
                        alt='Reset'
                        className='icon_button'
                        onClick={() => {
                          this.props.onViewClientList();
                          this.setState({ inputText: undefined });
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={3}>
                    <ul className='addItem'>
                      <AddClientDialog
                        onAddClient={(newClient) =>
                          this.props.onAddClient(newClient)
                        }
                        onEditBreadCrumbs={(breadCrumbs) =>
                          this.props.onEditBreadCrumbs(breadCrumbs)
                        }
                        toggleMenu={this.props.toggleMenu}
                      />
                    </ul>
                  </Col>
                </Row>
              </div>

              <div className='MuiBox-root css-17uua2w'>
                <Table
                  responsive
                  columns={columns}
                  data={
                    this.props.viewFilteredList
                      ? this.props.filteredList
                      : this.props.clientList
                  }
                />
                {/* <Container>
									<Row>
										<Col>
										</Col>
									</Row>
								</Container> */}
              </div>
            </div>
          </div>
        </div>

        {/* Supposed to display when the user clicks on the edit user button */}
        <dialog id='editClientDialog' className='bigDialogBox'>
          <GrClose
            id='exit-dialog-icon'
            height={28}
            onClick={this.hideEditClientBox}
            alt='exit button'
          />

          <Row>
            <Col lg={12}>
              <h2>Edit Client</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={2} className='mt-3'>
              <label>Profile Picture:</label>
              <label className='inputProfile'>
                <input
                  type='file'
                  accept='image/*'
                  id='uploadImage'
                  onChange={this.handleLogoChange}
                />
                <img src={this.state.imgSrc} alt='profile' />
              </label>
            </Col>
            <Col lg={10}>
              <Row>
                <Col lg={4}>
                  <label>Domain *</label>
                  <input
                    type='text'
                    value={this.state.domain}
                    onChange={this.handleDomainChange}
                  />
                </Col>
                <Col lg={4}>
                  <label>Company *</label>
                  <input
                    type='text'
                    value={this.state.clientName}
                    onChange={this.handleClientNameChange}
                  />
                </Col>
                <Col lg={4}>
                  <label>Street Address *</label>
                  <input
                    type='text'
                    value={this.state.address}
                    onChange={this.handleAddressChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <label>City *</label>
                  <input
                    type='text'
                    value={this.state.city}
                    onChange={this.handleCityChange}
                  />
                </Col>
                <Col lg={4}>
                  <label>State *</label>
                  <input
                    type='text'
                    value={this.state.stateName}
                    onChange={this.handleStateNameChange}
                  />
                </Col>
                <Col lg={4}>
                  <label>Phone Number *</label>
                  <Input
                    country='US'
                    value={this.state.phoneNumber}
                    onChange={this.handlePhoneNumberChange}
                    maxLength={14}
                    placeholder='Phone Number'
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={4}>
                  <label>User Licenses Assigned</label>
                  <input
                    type='number'
                    value={this.state.licensesAssigned}
                    onChange={this.handlelicenseAssignedChange}
                    placeholder='Licenses Assigned'
                  />
                </Col>
                <Col lg={4}>
                  <label>Admin Licenses Assigned</label>
                  <input
                    type='number'
                    value={this.state.adminLicensesAssigned}
                    onChange={this.handleAdminLicenseAssignedChange}
                    placeholder='Licenses Assigned'
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className='btn--flex'>
                    <button
                      onClick={this.handleEditClient}
                      type='button'
                      className='btn--confirm dialog__btn'
                    >
                      Confirm
                    </button>
                    <button
                      onClick={this.hideEditClientBox}
                      type='button'
                      className='btn--close dialog__btn'
                    >
                      Cancel
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </dialog>
      </main>
    );
  }
}
export default ClientTable;
