import { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';

import AddUserListBox from './AddUserListBox';
import Styles from './Styles';
import Table from './Table';

import { AiFillDelete, AiOutlinePlus } from 'react-icons/ai';
import { BiReset } from 'react-icons/bi';
import { GrClose } from 'react-icons/gr';
import { ImPencil } from 'react-icons/im';

class UserGroupTable extends Component {
  constructor() {
    super();
    this.state = {
      editGroup: false, // {Boolean} true: editing a User Group & false: creating a new User Group
      index: null, // {Number} index of the User Group being edited
      userList: [], // {Array of JS objects (name, id)}
      leader: '', // {string} name of the group leader
      name: '', // {string} name of the group
      userListBox: [], // {Array of Objects {label: name, value: id}} list of all the users sent to the UserListBox
      dialogTitle: 'Create Group', // {String} User Group title text
      inputText: null, // {label: string, value: string}
      selectedUsers: [] // {Array of objects} {value: UID, label: name}
    };
    this.handleUserListChange = this.handleUserListChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleLeaderChange = this.handleLeaderChange.bind(this);
    this.validateUserGroupInputs = this.validateUserGroupInputs.bind(this);
    this.handleUserGroupAction = this.handleUserGroupAction.bind(this);
    this.populateUserGroupBox = this.populateUserGroupBox.bind(this);
    this.showUserGroupBox = this.showUserGroupBox.bind(this);
    this.hideUserGroupBox = this.hideUserGroupBox.bind(this);
    this.addUserOptions = this.addUserOptions.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.showUserBoxModal = this.showUserBoxModal.bind(this);
    this.hideUserBoxModal = this.hideUserBoxModal.bind(this);
  }
  /************************** User Group Functions ***************************************/
  handleUserListChange(event) {
    this.setState({ selectedUsers: event });
  }
  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }
  handleLeaderChange(event) {
    this.setState({ leader: event.target.value });
  }
  validateUserGroupInputs() {
    const { name, leader, editGroup, index } = this.state;

    if (!name) {
      alert('Please enter a valid Group Name.');
      return false;
    }
    if (!leader) {
      alert('Please select a Group Leader.');
      return false;
    }

    if (editGroup) {
      // assume creating a new User Group
      for (let group of this.props.groupList) {
        if (group.name === name && group.index !== index) {
          alert('Group Name already exists. Please enter a new Group Name.');
          return false;
        }
      }
    } else {
      // assume editing a User Group
      for (let group of this.props.groupList) {
        if (group.name === name) {
          alert('Group Name already exists. Please enter a new Group Name.');
          return false;
        }
      }
    }

    return true;
  }
  async handleUserGroupAction() {
    try {
      // validate the input fields
      const areValid = this.validateUserGroupInputs();

      if (!areValid) return;

      const { name, leader, editGroup, index, userList } = this.state;
      if (editGroup) {
        // assume editing a User Group
        let updatedGroup = {
          name,
          leader,
          users: userList,
          index
        };
        this.props.onEditUserGroup(updatedGroup);
      } else {
        // assume creating a new User Group
        let newGroup = {
          name,
          leader,
          users: userList
        };
        this.props.onAddUserGroup(newGroup);
      }

      this.hideUserGroupBox();
    } catch (error) {
      console.log('Error in handleUserGroupAction: ', error);
      alert(
        `${
          this.state.editGroup ? 'Edit' : 'Create'
        } Group error - please try again or contact support.`
      );
      return;
    }
  }
  // Description: On edit User Group, populate the group box with the exisiting users
  //              and set the value of the group leader
  // Parameter: {Object (User Group fields)} exisiting User Group that is being edited
  populateUserGroupBox(data) {
    // create the html tags for the selected user list
    /* values needed to add options to the selectUserForTask */
    var selectGroupLeader = document.getElementById('editLeaderUserGroup');
    selectGroupLeader.innerHTML = '';
    var currentLeader = { text: data.leader, value: data.leader };
    selectGroupLeader.options.add(
      new Option(currentLeader.text, currentLeader.value)
    );
    let selectedUsers = data.users;

    for (let i = 0; i < selectedUsers.length; i++) {
      selectedUsers[i]
        .get()
        .then((query) => {
          if (!query.exists) return;

          let uData = query.data();
          if (!uData) return;

          const fullName = `${uData.firstName} ${uData.lastName}`;
          let UID = selectedUsers[i].id;
          let currUser = {
            name: fullName,
            id: UID
          };
          // update the state of userList
          this.state.userList.push(currUser);

          //Get the default from the firebase collection and add that as the first option
          var currOpt = { text: fullName, value: fullName };
          // add the current user to the task user dropdown
          if (currentLeader.text !== fullName) {
            selectGroupLeader.options.add(
              new Option(currOpt.text, currOpt.value)
            );
          }
          var item = document.createElement('li'); // create the list item
          var userName = document.createElement('span'); // add the userName to the li
          userName.innerHTML = fullName;
          var minusSign = document.createElement('span');
          minusSign.innerHTML = 'X';
          minusSign.setAttribute('class', 'selectedUserMinusSign');
          minusSign.setAttribute('id', UID);
          minusSign.addEventListener('click', this.removeUser);
          item.appendChild(userName); // add the description to the element
          item.appendChild(minusSign);
          document.getElementById('editUserGroupList').appendChild(item);
        })
        .catch((error) =>
          console.log('Error getting data from User doc Reference: ', error)
        );
    }
    this.props.onEditBreadCrumbs({
      type: 'APPEND_BREADCRUMB',
      breadCrumbs: ' > Edit Group'
    });
    // display dialog
    const ugDialog = document.getElementById('addUserGroupDialog');
    // ugDialog.style.left = (this.props.toggleMenu) ? '130px' : '330px'
    ugDialog.showModal();
    this.setState({
      name: data.name,
      leader: data.leader,
      userList: this.state.userList
    });
  }
  // Description: Display the User Group Box and append the breadcrumbs
  showUserGroupBox() {
    this.props.onEditBreadCrumbs({
      type: 'APPEND_BREADCRUMB',
      breadCrumbs: ' > Create Group'
    });
    // display dialog
    const ugDialog = document.getElementById('addUserGroupDialog');
    // ugDialog.style.left = (this.props.toggleMenu) ? '130px' : '330px'
    ugDialog.showModal();
  }
  // Description: Close the User Group Box and clear the select drop down
  //              menu and clear the user list
  hideUserGroupBox() {
    const ugDialog = document.getElementById('addUserGroupDialog');
    ugDialog.close();
    document.getElementById('editUserGroupList').innerHTML = '';
    document.getElementById('editLeaderUserGroup').innerHTML = '';
    this.props.onEditBreadCrumbs({
      type: 'REMOVE_BREADCRUMB',
      breadCrumbs: 'Users'
    });
    // clear all data and update state
    this.setState({
      editGroup: false,
      index: null,
      leader: '',
      name: '',
      userList: [],
      dialogTitle: 'Create Group'
    });
  }
  // this.props.userList.filter((user) => {
  //     if (user.companyID === this.props.companyID) {
  //         var fullName = user.firstName + " " + user.lastName
  //         return {'value': user.id, 'label': fullName}
  //     }
  // })

  /********************************* User List Functions ****************************************/
  // Description: On User List view, add all the users to the User List Box
  addUserOptions() {
    let userListBox = [];
    // Add all the user options to the list
    for (let user of this.props.userList) {
      // iterate through the entire UserList
      if (user.companyID === this.props.companyID) {
        var fullName = user.firstName + ' ' + user.lastName;
        userListBox.push({ value: user.id, label: fullName });
      }
    }
    this.setState({ userListBox: userListBox });
  }
  // Description: On click, remove the user from the User Group and remove
  //              the user from the html leader drop down menu
  // Parameter: {Object HTML of the List Item} List Item stores the name of the user
  removeUser(e) {
    const res = window.confirm(
      'Are you sure you want to remove the user from the group?'
    );

    if (res) {
      const userToRemoveIdx = this.state.userList.findIndex(
        (user) => user.id === e.target.id
      );

      const updatedUserList = this.state.userList.filter(
        (user) => user.id !== e.target.id
      );
      this.setState({ userList: updatedUserList });

      const editLeaderUserGroup = document.getElementById(
        'editLeaderUserGroup'
      );

      editLeaderUserGroup.remove(userToRemoveIdx);

      e.target.parentNode.remove();
    }
  }
  // Description: Display the User List Box
  showUserBoxModal() {
    this.addUserOptions();
    const UserBoxDialog = document.getElementById('UserBoxDialog');
    UserBoxDialog.showModal();
  }
  // Description: Close the User List Box and add the selected users to the
  //              User Group Box and populate the leader drop down menu
  // Parameter: {Object (Array of Objects (String name))}
  hideUserBoxModal(row) {
    // add items to the DOM
    if (row !== undefined && row.hasOwnProperty('selectedUsers')) {
      let selectedUsers = row.selectedUsers;
      if (selectedUsers !== undefined && selectedUsers.length > 0) {
        // text fields
        let fullName;
        // create the html tags for the selected user list
        var item; // the list item
        var userName; // the span element to store name of the user
        var minusSign; // the remove user from plan icon
        /* values needed to add options to the selectUserForTask */
        var selectGroupLeader = document.getElementById('editLeaderUserGroup');
        let currOpt;
        for (let i = 0; i < selectedUsers.length; i++) {
          // assume its a duplicate
          if (
            this.state.userList.findIndex(
              (user) => user.id === selectedUsers[i].id
            ) !== -1
          )
            continue;
          // update the state of userList
          this.state.userList.push(selectedUsers[i]);
          fullName = selectedUsers[i].name;
          currOpt = { text: fullName, value: fullName };
          // add the current user to the task user dropdown
          selectGroupLeader.options.add(
            new Option(currOpt.text, currOpt.value, currOpt.selected)
          );
          item = document.createElement('li'); // create the list item
          userName = document.createElement('span'); // add the userName to the li
          userName.innerHTML = fullName;
          minusSign = document.createElement('span');
          minusSign.innerHTML = 'X';
          minusSign.setAttribute('class', 'selectedUserMinusSign');
          minusSign.addEventListener('click', this.removeUser);
          item.appendChild(userName); // add the description to the element
          item.appendChild(minusSign);
          document.getElementById('editUserGroupList').appendChild(item);
        }
      }
    }
    if (this.state.userList.length > 0 && this.state.leader === '') {
      // assume users were just added and the leader was not selected yet
      this.setState({ leader: this.state.userList[0].name });
    }
    // hide the UserList box
    const UserBoxDialog = document.getElementById('UserBoxDialog');
    UserBoxDialog.close();
  }
  render() {
    // predefined names for the columns
    var columns = [
      {
        Header: 'UserGroups',
        columns: [
          {
            Header: 'Group Name',
            accessor: 'name'
          },
          {
            Header: 'Leader Email',
            accessor: 'email'
          },
          {
            Header: 'Phone Number',
            Cell: ({ row }) => {
              let num = row.original.phoneNumber;
              let phone = !num ? (
                ''
              ) : (
                <span>
                  {`(${num.substr(0, 3)}) ${num.substr(3, 3)}-${num.substr(6)}`}
                </span>
              );

              return phone;
            }
          },
          {
            Header: 'Client',
            accessor: 'companyID'
          },
          {
            Header: 'Users',
            Cell: ({ row }) => {
              if (
                row.original.users === undefined ||
                row.original.users === null
              ) {
                return 0;
              }
              return row.original.users.length;
            }
          },
          {
            // Edit button functionality
            Header: '   ',
            Cell: ({ row }) => {
              return (
                <ImPencil
                  title='Edit Group'
                  ght={25}
                  width={25}
                  alt='edit'
                  className='icon_button'
                  onClick={() => {
                    let data = row.original;
                    data.index = row.id;
                    this.setState({
                      editGroup: true,
                      index: row.id,
                      dialogTitle: 'Edit Group'
                    });
                    this.populateUserGroupBox(data);
                  }}
                />
              );
            }
          },
          {
            // Delete button functionality
            Header: ' ',
            Cell: ({ row }) => {
              return (
                <AiFillDelete
                  title='Delete Group'
                  height={25}
                  width={25}
                  alt='delete'
                  className='icon_button'
                  onClick={() => {
                    let res = window.confirm(
                      'Are you sure you want to delete the Plan?'
                    );
                    if (res) {
                      let data = row.original;
                      data.index = row.id;
                      this.props.onDeleteUserGroup(data);
                    }
                  }}
                />
              );
            }
          }
        ]
      }
    ];
    if (this.props.role !== 'Root') {
      // assume either Super Admin or Manager is viewing the table
      columns[0].columns.splice(3, 1); // remove the deleted button column
    }

    return (
      <main>
        <p id='breadCrumbs'>{this.props.breadCrumbs}</p>
        <div className='MuiBox-root'>
          <div className='MuiPaper-root'>
            <div className='MuiBox-root css-1idik3a'>
              <div className='MuiBox-root css-uujcwg'>
                <h5 className='css-epib0t'>User List</h5>
                <span className='css-17vat79'></span>
              </div>
              <div className='css-1yjo05o'>
                <ul className='topnav'>
                  <li
                    id='secondaryNavigationItems'
                    onClick={this.props.onViewUserList}
                    className={this.props.highlight[0]}
                  >
                    Users
                  </li>
                  <li
                    id='secondaryNavigationItems'
                    onClick={this.props.onViewUserGroups}
                    className={this.props.highlight[1]}
                  >
                    Groups
                  </li>
                  <li
                    id='secondaryNavigationItems'
                    onClick={this.props.onViewDeletedUsers}
                    className={this.props.highlight[2]}
                  >
                    Deleted
                  </li>
                </ul>
              </div>
            </div>

            <div className='MuiTableContainer-root css-13ypi1f'>
              <div className='MuiBox-root css-fgimv3'>
                <nav>
                  {/* <Container fluid>  */}
                  <Row>
                    <Col lg={9}>
                      <div className='search'>
                        <Row className='w-100'>
                          <Col lg={4}>
                            <Select
                              options={this.props.groupList.map((group) => {
                                return { value: group.name, label: group.name };
                              })}
                              id='searchBox'
                              placeholder='Search Group'
                              value={this.state.inputText}
                              onChange={(event) => {
                                this.props.onfilterTable({
                                  type: 'FILTER_USER_GROUPS',
                                  value: event.value
                                });
                                let inputText = {
                                  label: event.label,
                                  value: event.label
                                };
                                this.setState({ inputText: inputText });
                              }}
                            />
                          </Col>
                          <Col lg={6}>
                            <input
                              id='searchBox'
                              placeholder='Search Group'
                              value={this.state.inputText || ''}
                              style={{ width: '80%' }}
                              onChange={(event) => {
                                this.props.onfilterTable({
                                  type: 'FILTER_USER_GROUPS',
                                  value: event.target.value
                                });
                                let inputText = event.target.value;
                                this.setState({ inputText: inputText });
                              }}
                            />

                            <BiReset
                              id='resetTableListBtn'
                              alt='Reset'
                              className='icon_button'
                              onClick={() => {
                                this.props.onViewUserGroups();
                                this.setState({ inputText: null });
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <ul className='addItem'>
                        <li>
                          <button
                            type='button'
                            onClick={this.showUserGroupBox}
                            className='btn'
                          >
                            Create Group
                          </button>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  {/* </Container> */}
                </nav>
              </div>
              <div className='MuiBox-root css-17uua2w'>
                <Container>
                  <Row>
                    <Col>
                      <Table
                        responsive
                        columns={columns}
                        data={
                          this.props.viewFilteredList
                            ? this.props.filteredList
                            : this.props.groupList
                        }
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </div>

        <Styles>{/* gets the user group data */}</Styles>
        {/* User Group Form for Creating/Editing */}
        <dialog id='addUserGroupDialog' className='bigDialogBox'>
          <GrClose
            id='exit-dialog-icon'
            height={28}
            onClick={this.hideUserGroupBox}
            alt='exit button'
          />

          <Row>
            <Col lg={12}>
              <h2>{this.state.dialogTitle}</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <label>Group Name</label>
              <input
                type='text'
                value={this.state.name}
                onChange={this.handleNameChange}
                placeholder='Group Name'
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <label>Users</label>

              <ul id='editUserGroupList'></ul>
              <div className='addItemPlan' onClick={this.showUserBoxModal}>
                <span>
                  <AiOutlinePlus />
                </span>
                <span className='addItemText'>Add New User</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <label>Group Leader</label>
              <select
                id='editLeaderUserGroup'
                style={{ marginBottom: '20px' }}
                value={this.state.leader}
                onChange={this.handleLeaderChange}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className='btn--flex'>
                <button
                  onClick={this.handleUserGroupAction}
                  type='button'
                  className='btn--confirm dialog__btn'
                >
                  Confirm
                </button>
                <button
                  onClick={this.hideUserGroupBox}
                  type='button'
                  className='btn--close dialog__btn'
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </dialog>
        <AddUserListBox
          onHideUserBox={this.hideUserBoxModal}
          dialogHeader={'Group'}
          options={this.state.userListBox}
          selectedUsers={this.state.selectedUsers}
          handleUserListChange={this.handleUserListChange}
        />
      </main>
    );
  }
}
export default UserGroupTable;
