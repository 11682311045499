import * as React from 'react';

import FolderTreeItem from './FolderTreeItem';
import FileItems from './FileItems';

const FolderItems = ({ folders = [] }) => {
  // console.log('FolderItems/folders: ', folders);

  return (
    <>
      {folders.map((folder) => {
        // console.log('FolderItems/folder: ', folder);
        if (!folder) return null;

        const { files, folderID, folderName, folders } = folder;
        const folderProps = { itemData: { ...folder, isFolder: true } };
        const hasFiles = files && files.length > 0;
        const hasFolders = folders && folders.length > 0;

        return (
          <FolderTreeItem
            key={folderID}
            nodeId={folderID}
            label={folderName}
            ContentProps={folderProps}
          >
            {hasFolders && <FolderItems folders={folders} />}
            {hasFiles && <FileItems files={files} parentFolderID={folderID} />}
          </FolderTreeItem>
        );
      })}
    </>
  );
};

export default FolderItems;
