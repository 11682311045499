import firebaseApp from '../../firebaseApp';

const db = firebaseApp.firestore();
const collectionName = 'companies';

export const getCompanyByCompanyID = async (companyID) => {
  try {
    const docRef = db.collection(collectionName).doc(companyID);

    const doc = await docRef.get();

    if (doc.exists) {
      return doc.data();
    } else {
      return null;
    }
  } catch (error) {
    console.log('ERROR - db/getCompanyByCompanyID: ', error);
    return null;
  }
};

export const getAllCompanyIDs = async () => {
  try {
    const docRef = db.collection(collectionName);

    const snapshot = await docRef.get();

    if (snapshot.empty) {
      console.log('No Companies Found!');
      return null;
    }

    const companyIDs = snapshot.docs.map((doc) => doc.id);

    return companyIDs;
  } catch (error) {
    console.log('ERROR - db/getAllCompanyIDs: ', error);
    return null;
  }
};

export const createCompanyDocRef = async () => {
  try {
    const companyRef = db.collection(collectionName).doc();

    console.log('createCompanyDocRef/new companyRef: ', companyRef.id);

    if (companyRef.id) {
      return companyRef;
    } else {
      return null;
    }
  } catch (error) {
    console.log('ERROR - db/createCompanyDocRef: ', error);
    return null;
  }
};

export const addCompany = async (data) => {
  try {
    const companyRef = db.collection(collectionName).doc(data.companyID);

    await companyRef.set(data);

    return companyRef;
  } catch (error) {
    console.log('ERROR - db/addCompany: ', error);
    return null;
  }
};

export const updateCompany = async (companyID, update) => {
  try {
    const companyRef = db.collection(collectionName).doc(companyID);

    await companyRef.update(update);

    return companyRef;
  } catch (error) {
    console.log('ERROR - db/updateCompany: ', error);
    return error;
  }
};
